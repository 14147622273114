<section class="forDesk">
    <div class="container">
            <div mat-dialog-content>
                <h3 class="no-warp">Are You Sure Want to Remove?</h3>
            </div>
            <div mat-dialog-actions class="div">
                <button class="btn btn-danger" (click)="onYesClick()">Yes</button>
                <button class="btn btn-danger ml-3" (click)="onNoClick()">No</button>
            </div>
    </div>
</section>
<section class="forMobile">
    <div class="container">
            <div mat-dialog-content>
                <h6 class="no-warp">Are You Sure Want to Remove?</h6>
            </div>
            <div mat-dialog-actions class="div" style="display: flex;
    justify-content: space-evenly;">
                <button class="btn btn-danger" (click)="onYesClick()">Yes</button>
                <button class="btn btn-secondary ml-3" (click)="onNoClick()">No</button>
            </div>
    </div>
</section>

