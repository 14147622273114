<div class="row" style="display: flex" *ngIf="topLoader && !product.length">
  <div class="col-6" *ngFor="let item of topLoaderCounts">
    <app-skeleton-product-box></app-skeleton-product-box>
  </div>
</div>

<div class="card-container-scroll" *ngIf="product.length">
  <div class="card-container">
    <mat-card
      appearance="outlined"
      class="brands"
      *ngFor="let product of product; let i = index"
      (click)="sendToBoxOne(product)"
    >
      <mat-card-content>
        <span style="float: right" *ngIf="showHistoryIcon">
          <i class="fa fa-history"></i>
        </span>
        <div
          class="forImg"
          *ngIf="
            product?.first_two_images && product?.first_two_images.length > 0
          "
        >
          <img
            [src]="product.first_two_images[0]"
            width="115px"
            height="110px"
          />
        </div>
        <div class="forImg" *ngIf="product?.first_two_images === null">
          <img [src]="placeholder_img" width="115px" height="110px" />
        </div>
        <div style="float: right">
          <app-circlecolors
            *ngIf="product?.color"
            [color]="product?.color"
          ></app-circlecolors>
        </div>
      </mat-card-content>
      <br />
      <mat-card-content>
        <mat-list role="list" class="forCards">
          <mat-list-item role="listitem">
            <p style="font-size: x-small; font-weight: bold">
              {{ product?.title }}
            </p>
          </mat-list-item>

          <mat-list-item role="listitem">
            <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
          </mat-list-item>

          <mat-list-item role="listitem">
            ₹{{ product?.price }}
            <span style="float: right" class="onlyForMobile">
              <img [src]="logo[product?.brand]" alt="logo" width="50px" />
            </span>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>
</div>
