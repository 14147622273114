<div>
  <span class="btn btn-light" style="float: right" (click)="backToPrevious()"
    >X</span
  >
  <div class="conditions-container" *ngIf="forTerms">
    <h1>Term and Conditions</h1>

    <hr />

    <h2>
      Your use of the website and/or purchase from us are governed by following
      Terms and Conditions:
    </h2>
    <p>
      ● The content of the pages of this website is subject to change without
      notice.
    </p>

    <p>
      ● Neither we nor any third parties provide any warranty or guarantee as to
      the accuracy, timeliness, performance, completeness or suitability of the
      information and materials found or offered on this website for any
      particular purpose. You acknowledge that such information and materials
      may contain inaccuracies or errors and we expressly exclude liability for
      any such inaccuracies or errors to the fullest extent permitted by law.
    </p>

    <p>
      ● Your use of any information or materials on our website and/or product
      pages is entirely at your own risk, for which we shall not be liable. It
      shall be your own responsibility to ensure that any products, services or
      information available through our website and/or product pages meet your
      specific requirements.
    </p>

    <p>
      ● Our website contains material which is owned by or licensed to us. This
      material includes, but are not limited to, the design, layout, look,
      appearance and graphics. Reproduction is prohibited other than in
      accordance with the copyright notice, which forms part of these terms and
      conditions.
    </p>

    <p>
      ● All trademarks reproduced in our website which are not the property of,
      or licensed to, the operator are acknowledged on the website.
    </p>
    <p>
      ● Unauthorized use of information provided by us shall give rise to a
      claim for damages and/or be a criminal offense.
    </p>
    <p>
      ● From time to time our website may also include links to other websites.
      These links are provided for your convenience to provide further
      information.
    </p>
    <p>
      ● You may not create a link to our website from another website or
      document without SPARKLY AUTOMOBILES’s prior written consent.
    </p>
    <p>
      ● Any dispute arising out of use of our website and/or purchase with us
      and/or any engagement with us is subject to the laws of India .
    </p>
    <p>
      ● We, shall be under no liability whatsoever in respect of any loss or
      damage arising directly or indirectly out of the decline of authorization
      for any Transaction, on Account of the Cardholder having exceeded the
      preset limit mutually agreed by us with our acquiring bank from time to
      time
    </p>
    <hr />
    <p>
      For the purpose of these Terms and Conditions, The term "we", "us", "our"
      used anywhere on this page shall mean SPARKLY AUTOMOBILES, whose
      registered/operational office is Sparkly Automobiles, PORKUDAM NEAR SBO
      2ND COLONY, DOOR NO.56/2A, S S COLONY 4TH STREET, Madurai Madurai TAMIL
      NADU 625016 . "you", “your”, "user", “visitor” shall mean any natural or
      legal person who is visiting our website and/or agreed to purchase from
      us.
    </p>
  </div>

  <div class="conditions-container" *ngIf="forShipping">
    <h1>SHIPPING AND DELIVERY</h1>

    <hr />
    <p>
      ● For International buyers, orders are shipped and delivered through
      registered international courier companies and/or International speed post
      only.
    </p>
    <p>
      ● For domestic buyers, orders are shipped through registered domestic
      courier companies and /or speed post only. Orders are shipped within 0-7
      days or as per the delivery date agreed at the time of order confirmation
      and delivering of the shipment subject to Courier Company / post office
      norms.
    </p>
    <p>
      ● SPARKLY AUTOMOBILES is not liable for any delay in delivery by the
      courier company / postal authorities and only guarantees to hand over the
      consignment to the courier company or postal authorities within 0-7 days
      rom the date of the order and payment or as per the delivery date agreed
      at the time of order confirmation. Delivery of all orders will be to the
      address provided by the buyer.Delivery of our services will be confirmed
      on your mail ID as specified during registration.
    </p>
    <p>
      For any issues in utilizing our services you may contact our helpdesk on
      8668065701 or
      ['support@sparklyautomobiles.com'](mailto:support@sparklyautomobiles.com)
    </p>
  </div>

  <div class="conditions-container" *ngIf="forRefund">
    <h1>CANCEL AND REFUND</h1>

    <hr />
    <h2>
      SPARKLY AUTOMOBILES believes in helping its customers as far as possible,
      and has therefore a liberal cancellation policy. Under this policy:
    </h2>
    <p>
      ● Cancellations will be considered only if the request is made within 7
      days of placing the order. However, the cancellation request may not be
      entertained if the orders have been communicated to the vendors/merchants
      and they have initiated the process of shipping them.
    </p>
    <p>
      ● SPARKLY AUTOMOBILES does not accept cancellation requests for perishable
      items like flowers, eatables etc. However, refund/replacement can be made
      if the customer establishes that the quality of product delivered is not
      good.
    </p>
    <p>
      ● In case of receipt of damaged or defective items please report the same
      to our Customer Service team. The request will, however, be entertained
      once the merchant has checked and determined the same at his own end. This
      should be reported within 7 days of receipt of the products.
    </p>
    <p>
      ● In case you feel that the product received is not as shown on the site
      or as per your expectations, you must bring it to the notice of our
      customer service within 7 days of receiving the product. The Customer
      Service Team after looking into your complaint will take an appropriate
      decision.
    </p>
    <p>
      ● In case of complaints regarding products that come with a warranty from
      manufacturers, please refer the issue to them.
    </p>
    <p>
      ● In case of any Refunds approved by the SPARKLY AUTOMOBILES, it’ll take
      6-8 days for the refund to be processed to the end customer.
    </p>
  </div>
</div>

<div class="conditions-container"  *ngIf="forPrivacy">
  <h1>Privacy Policy</h1>
  <p><strong>Last Updated: 04/10/2024</strong></p>

  <p>
      At <strong>SPARKLY AUTOMOBILES</strong> (“we”, “our”, “us”), we value the trust our customers place in us when shopping at our online store. This Privacy Policy describes how we collect, use, and disclose your personal information when you visit or make a purchase from <strong>www.skullcrown.in</strong> (the “Site”).
  </p>

  <h2>1. Information We Collect</h2>

  <p>When you visit or make a purchase through the Site, we collect certain information from you:</p>

  <h3>A. Personal Information</h3>
  <ul>
      <li>Contact Information: Name, email address, phone number, billing address, shipping address.</li>
      <li>Payment Information: Credit card numbers, bank details (through our payment gateway partners).</li>
      <li>Account Information: Username, password, and account preferences.</li>
      <li>Order Information: Details about products ordered, order history, and purchase preferences.</li>
  </ul>

  <h3>B. Automatically Collected Information</h3>
  <p>When you visit the Site, we collect certain information about your device automatically, including:</p>
  <ul>
      <li>Device Information: IP address, browser type, operating system, device type, and unique device identifiers.</li>
      <li>Usage Information: Time and date of your visit, the pages you viewed, and the URL of the website that referred you to our site.</li>
  </ul>

  <h3>C. Cookies and Similar Technologies</h3>
  <p>
      We use cookies, pixel tags, and other tracking technologies to enhance your browsing experience and to collect information about your browsing behavior.
  </p>

  <h2>2. How We Use Your Information</h2>

  <p>We use the information we collect for the following purposes:</p>
  <ul>
      <li>To Fulfill Orders: Processing and delivering your purchases, including managing payments and providing order confirmations.</li>
      <li>To Improve Our Services: Analyzing how our customers use the Site to optimize and personalize the user experience.</li>
      <li>For Communication: Sending you emails regarding your orders, updates, and promotional offers (you can unsubscribe at any time).</li>
      <li>For Fraud Prevention: Monitoring for fraud, abuse, and any illegal activity on the Site.</li>
      <li>For Marketing: Providing personalized recommendations, retargeting ads, and other promotions based on your browsing and purchase history.</li>
  </ul>

  <h2>3. Sharing Your Information</h2>
  <p>We may share your personal information with:</p>
  <ul>
      <li>Service Providers: Payment processors, shipping companies, email service providers, and others that help us operate the Site.</li>
      <li>Business Transfers: If we sell or merge our business, your information may be transferred to the new owner.</li>
      <li>Compliance with Law: If required by law, we may share your information with government authorities, courts, or law enforcement.</li>
  </ul>

  <h2>4. Your Rights</h2>

  <p>Depending on your location and applicable privacy laws, you may have the following rights:</p>
  <ul>
      <li>Access: Request access to the personal information we hold about you.</li>
      <li>Correction: Request corrections to any inaccurate information we hold about you.</li>
      <li>Deletion: Request deletion of your personal information (subject to certain exceptions).</li>
      <li>Data Portability: Request to receive your personal information in a structured, machine-readable format.</li>
      <li>Opt-Out of Marketing: Unsubscribe from our marketing communications at any time.</li>
      <li>Cookie Preferences: Manage your cookie preferences through our Cookie Settings page.</li>
  </ul>

  <h2>5. Data Retention</h2>

  <p>
      We retain your personal information for as long as necessary to fulfill the purposes outlined in this policy, or as required by law.
  </p>

  <h2>6. Data Security</h2>
  <p>
      We use industry-standard security measures to protect your personal information from unauthorized access, use, or disclosure. However, no data transmission over the internet or storage system can be guaranteed to be 100% secure.
  </p>

  <h2>7. International Data Transfers</h2>
  <p>
      If you are accessing the Site from outside <strong>[your country]</strong>, your information may be transferred to and stored in a country that may not have the same data protection laws as your country. By using our services, you consent to such transfers.
  </p>

  <h2>8. Children’s Privacy</h2>
  <p>
      Our Site is not intended for individuals under the age of 18. We do not knowingly collect personal information from children. If you believe we have inadvertently collected information from a child, please contact us so we can take appropriate steps to delete it.
  </p>

  <h2>9. Updates to This Privacy Policy</h2>
  <p>
      We may update this Privacy Policy from time to time. Any changes will be posted on this page with a new effective date. We encourage you to review this policy periodically to stay informed about how we are protecting your information.
  </p>

  <h2>10. Contact Us</h2>
  <p>
      If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
  </p>
  <p>
      <strong>SPARKLY AUTOMOBILES</strong><br />
      Sparkly Automobiles, PORKUDAM NEAR SBO
      2ND COLONY, DOOR NO.56/2A, S S COLONY 4TH STREET, Madurai Madurai TAMIL
      NADU 625016<br />
      Contact: 8668065701 or
      ['support@sparklyautomobiles.com'](mailto:support@sparklyautomobiles.com)
  </p>
</div>