import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { Router, NavigationEnd } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Overlay } from "@angular/cdk/overlay";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { userService } from "../../services/user.service";
declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}
@Component({
  selector: "app-searchbar",
  templateUrl: "./searchbar.component.html",
  styleUrls: ["./searchbar.component.scss"],
  animations: [
    trigger("fadeInOut", [
      state("void", style({ opacity: 0 })),
      transition("void <=> *", animate(500)),
    ]),
  ],
})
export class SearchbarComponent implements OnInit {
  public products: Product[] = [];

  constructor(
    public productService: ProductService,
    private router: Router,
    private toastrService: ToastrService,
    private overlay: Overlay,
    private cdRef: ChangeDetectorRef,
    private userService: userService
  ) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // console.log(val.url)
        if (
          val.url == "/" ||
          val.url == "/home" ||
          val.url == "" ||
          val.url == "/home?tab=4" ||
          val.url == "/home?tab=0"
        ) {
          this.showBack = false;
        } else {
          this.showBack = true;
        }
      }
    });
  }

  public search: boolean = false;
  searchTerm: string = "";
  emptyTerm: string = "";
  overlayOpen = false;
  parentData: string;
  isSearchBarClicked: boolean = false;
  receivedSearch: any;
  receivedData: any;
  receivedCollection: any;
  currentRoute: string;
  searchTop: boolean = false;
  showBack: boolean = true;
  receivedBoolean: boolean;
  onInputSearchChange: boolean = false;
  fromRecent = false;

  ngOnInit(): void {
    // console.log( 'showBack:' ,this.showBack);
  }

  @ViewChild("searchInput") searchInput!: ElementRef;

  @HostListener("window:scroll", [])
  onWindowScroll() {
    const currentScrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (currentScrollPosition > 0) {
      this.searchTop = true;
    } else {
      this.searchTop = false;
    }

    if (this.overlayOpen == true) {
      window.scrollTo(0, -1);
    }
  }

  triggerSearchEvent(searchTerm: string): void {
    if (window.gtag) {
      window.gtag("event", "search", {
        search_term: searchTerm,
      });
    } else {
      // console.warn("gtag function is not available.");
    }
  }

  goBack() {
    if (this.router.url == "/" || this.searchTerm) {
      this.overlayClose();
      this.searchTerm = "";
    } else {
      window.history.back();
    }
  }
  receiveData(data: string) {
    this.receivedData = data;
    // console.log(data);
    if (this.receivedData) {
      this.searchTerm = this.receivedData;
    }
  }

  receiveSearch(data: any) {
    // console.log(data);
    this.receivedSearch = data;

    if (this.receivedSearch) {
      this.searchTerm = this.receivedSearch.search_term;
      this.fromRecent = true;
    }
    // console.log(this.receivedSearch);
  }
  receiveCollection(item: string) {
    this.receivedCollection = item;
    if (this.receivedCollection) {
      this.searchTerm = this.receivedCollection;
    }
  }
  recieveBoolean(item: boolean) {
    this.receivedBoolean = item;
    if (this.receivedBoolean) {
      this.onInputSearchChange = false;
    }
    // console.log(this.receivedBoolean);
  }

  sendUserSearch(type, term) {
    const session_id = JSON.parse(localStorage.getItem("anonymous-user-id"));
    const user_agent = JSON.parse(localStorage.getItem("User Agent"));
    const user_id = JSON.parse(localStorage.getItem("username")) || null;
    const IP = JSON.parse(localStorage.getItem("IP")) || null;

    const data = {
      user_id: user_id,
      session_id: session_id,
      ip_address: IP,
      user_agent: user_agent,
      action_type: "search",
      product_id: null,
      search_term: term,
      search_type: type,
      order_id: null,
      details: {
        filters: [],
        sort_order: "asc",
      },
    };
    // console.log(data);
    this.userService.addAction(data).subscribe((res) => console.log(res));
  }

  onSearch() {
    // console.log("Input changed:", this.onInputSearchChange);

    if (this.receivedBoolean && !this.onInputSearchChange) {
      this.router.navigate(["/shop/"], {
        queryParams: { collection: this.searchTerm.trim() },
      });
      const type = "collection";
      if (this.searchTerm) {
        this.sendUserSearch(type, this.searchTerm);
      }
      this.bySearch();
    } else if (this.fromRecent === true) {
      const queryParams = {
        [this.receivedSearch.search_type]: this.receivedSearch.search_term,
      };
      this.router.navigate(["/shop/"], {
        queryParams: queryParams,
      });
      this.toastrService.info(this.searchTerm, "Your Search Results");
    } else if (this.searchTerm.trim()) {
      this.triggerSearchEvent(this.searchTerm.trim());
      this.router.navigate(["/shop/"], {
        queryParams: { search: this.searchTerm.trim().replace(/\s+/g, "-") },
      });
      this.bySearch();
      const type = "search";
      if (this.searchTerm) {
        this.sendUserSearch(type, this.searchTerm.trim().replace(/\s+/g, "-"));
      }
      this.toastrService.info(this.searchTerm, "Your Search Results");
    }
  }
  overlayClose() {
    this.overlayOpen = false;
  }

  openClose(event: Event) {
    this.parentData = (event.target as HTMLInputElement).value;
    this.overlayOpen = true;
  }
  onSaveToLocalStorage() {
    let savedValues: string[] = JSON.parse(
      localStorage.getItem("userSearches") || "[]"
    );
    savedValues.push(this.searchTerm.trim());
    localStorage.setItem("userSearches", JSON.stringify(savedValues));
    this.searchTerm = "";
    // console.log("Values saved to localStorage:", savedValues);
  }

  onClickSaveToLocalStorage() {
    let savedValues: string[] = JSON.parse(
      localStorage.getItem("userSearches") || "[]"
    );
    savedValues.push(this.receivedSearch);
    localStorage.setItem("userSearches", JSON.stringify(savedValues));
    this.searchTerm = "";
    // console.log("Values saved to localStorage:", savedValues);
  }

  closeIcon() {
    if (this.searchTerm) {
      this.searchTerm = "";
      this.overlayClose();
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 0);
    } else {
      this.overlayClose();
    }
    // console.log(this.searchTerm);
  }
  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;

    this.onInputSearchChange = true;

    // console.log("Input changed:", inputElement.value, this.onInputSearchChange);
  }
  bySearch() {
    localStorage.setItem("bySearch", JSON.stringify(true));
  }
}
