<div class="col-grid-box" *ngFor="let item of topLoaderCounts">
  <app-skeleton-product-box
    *ngIf="topLoader && !uniqueBrandWithCollections.length"
  ></app-skeleton-product-box>
</div>

<div *ngFor="let brandItem of uniqueBrandWithCollections; let i = index">
  <div>
    <div style="text-decoration: underline red">
      <h4>
        <span>
          <img [src]="logo[brandItem.brand]" alt="logo" width="100px" />
        </span>
      </h4>
    </div>
    <div
      class="card-container-scroll"
      #scrollContainer
      [ngClass]="{ centered: !anArrayForCheck[i] }"
    >
      <span *ngIf="anArrayForCheck && anArrayForCheck[i]" class="circle-button">
        <i class="fa fa-arrow-circle-left" (click)="scrollLeft(cards3)"></i>
      </span>

      <div class="card-container" #cards3>
        <div
          class="cards test"
          *ngFor="let collection of brandItem.collections"
        >
          <mat-card (click)="onClick(collection)" class="d-flex flex-row mb-3">
            <mat-card-content>
              <div>
                <img
                  *ngIf="img_coll[collection]"
                  [src]="img_coll[collection]"
                  mat-card-xl-image
                />
                <img
                  *ngIf="!img_coll[collection]"
                  [src]="img_coll['duplicate']"
                  mat-card-xl-image
                />
              </div>
              <!-- *ngIf="imageForCollection[collection]?.length > 0" -->
              <!-- [src]="imageForCollection[collection][0]" -->
              <br />
              <div class="collc">
                <button class="btn btn-dark">
                  {{ collection }}
                </button>
              </div>
              <!-- <b
                style="
                  text-align: center;
                  display: flex;
                  justify-content: center;
                  color: rgba(128, 128, 128, 0.404);
                "
                >(click to view collection)</b
              > -->
            </mat-card-content></mat-card
          >
        </div>
      </div>
      <span *ngIf="anArrayForCheck && anArrayForCheck[i]" class="circle-button">
        <i class="fa fa-arrow-circle-right" (click)="scrollRight(cards3)"></i>
      </span>

      <br />
    </div>
  </div>
</div>
