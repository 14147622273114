import { Component, PLATFORM_ID, Inject, OnInit } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { LoadingBarService } from "@ngx-loading-bar/core";
import { map, delay, withLatestFrom } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Plugins, Capacitor } from "@capacitor/core";
import { ProductService } from "./shared/services/product.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map((v) => v[1])
  );
  skeletonLoader = true;
  alreadyId: any;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private loader: LoadingBarService,
    private productService: ProductService,
    translate: TranslateService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang("en");
      translate.addLangs(["en", "fr"]);
    }
    this.initializeApp();
    this.loaders.subscribe((progress) => {
      // console.log("progress", progress);
      // progress === 100 ||

      if (progress === 0) {
        this.skeletonLoader = false;

        // console.log("show-loader", this.skeletonLoader);
      } else {
        this.skeletonLoader = true;

        // console.log("loader", this.skeletonLoader);
      }
      this.productService.updateLoader(this.skeletonLoader);
    });
  }
  ngOnInit(): void {
    // this.loaders.subscribe((progress) => {
    //   if (progress === 100) {
    //     if (!localStorage.getItem("pageRefreshed")) {
    //       setTimeout(() => {
    //         localStorage.setItem("pageRefreshed", "true");
    //         window.location.reload();
    //       }, 500);
    //     }
    //   }
    // });
    // this.productService.ordNumForPdf.subscribe((id) => {
    //   console.log(id);
    //   if (id !== this.alreadyId) {
    //     console.log("yes");
    //     this.alreadyId = id;
    //     this.productService.updateorderNumFromDelivery(id);
    //   }
    // });
  }
  initializeApp() {
    if (Capacitor.isNativePlatform()) {
      StatusBar.setStyle({ style: Style.Dark });
      StatusBar.setOverlaysWebView({ overlay: false });
    }
  }
}
