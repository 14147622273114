import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { NavService, Menu } from "../../services/nav.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { LoginModelComponent } from "../modal/login/login.component";
import { AuthService } from "../../services/auth.service";
import { ChangeDetectorRef } from "@angular/core";
import { AuthGuard } from "../../services/auth-guard.service";
import { ProductService } from "../../services/product.service";
import { userService } from "../../services/user.service";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  public menuItems: Menu[];
  loggedInUser: string;
  menu: string;
  authenticated = false;
  user: boolean = false;
  condition: boolean;

  constructor(
    private router: Router,
    public navServices: NavService,
    private dialog: MatDialog,
    private authservice: AuthService,
    private authGuard: AuthGuard,
    private productService: ProductService,
    private userService: userService
  ) {
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }
  // ngAfterViewInit(): void {
  //   const interval = setInterval(() => {
  //     this.checkAuth();
  //   }, 1000);
  // }

  ngOnInit(): void {
    // this.authservice.isAuthenticated().then((data) => {
    //   this.authenticated = data;
    // console.log(this.authenticated);
    // });
    this.checkAuth();
  }

  async checkAuth() {
    let attempts = 0;
    const maxAttempts = 3;
    const interval = setInterval(() => {
      if (attempts < maxAttempts) {
        if (localStorage.getItem("username")) {
          this.condition = true;
          this.authenticated = true;
          // console.log("attempts-closed");
          clearInterval(interval);
        } else {
          // console.log("attempts++");
          attempts++;
        }
        this.navServices.items.subscribe((menuItems) => {
          // console.log(this.condition);
          this.menuItems = menuItems;
          this.menuItems[0].children.forEach((item) => {
            if (item.path === "/login") {
              item.title = this.condition ? "logout" : "login";
            }
          });
        });
        if (this.condition !== undefined && this.condition === true) {
          this.userService.getUserData().subscribe((res) => {
            // console.log(res);
            const profile = JSON.parse(res.body);
            this.loggedInUser = profile["full_name"];
            this.user = true;
            // console.log(this.loggedInUser);
          });
        }
      } else {
        // console.log("max-attempts");
        clearInterval(interval);
      }
    }, 1000);
  }
  // });
  // else {
  //   await this.authservice.getUserAccessToken().then((res) => {
  //     this.condition = true;
  //     this.authenticated = true;
  //   });
  // }
  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  openPopup() {
    this.dialog.open(LoginModelComponent, {
      width: "45%",
      height: "50%",
    });
  }

  checkIfUser(menu: any) {
    this.menu = menu;
    // console.log(this.menu);
    // this.productService.showLoaderSubject.subscribe((ans) => console.log(ans));
    // if (this.authenticated) {
    if (this.menu === "account&lists") {
      this.router.navigate(["/pages/new-dashboard"]);
    } else if (this.menu === "Orders") {
      this.router.navigate(["/pages/orders"]);
    }
    // }
  }

  accountListRoute(title: any) {
    // console.log(title.title);
    if (title.title == "cart" || title.title == "wishlist") {
      this.router.navigate(["/shop/" + title.title]);
    } else if (
      title.title == "dashboard" ||
      title.title == "contact" ||
      title.title == "forget-password" ||
      title.title == "compare" ||
      title.title == "customer-support"
    ) {
      this.router.navigate([title.path]);
    } else if (title.title == "logout" && this.authenticated) {
      this.authservice.signOut();
      this.router.navigate(["/login"]);
    } else if (title.title == "login" && !this.authenticated) {
      this.router.navigate(["/login"]);
    }
  }
}
