<div class="row" style="display: flex" *ngIf="topLoader && !product?.length">
  <div class="col-2" *ngFor="let item of topLoaderCounts">
    <app-skeleton-product-box></app-skeleton-product-box>
  </div>
</div>

<div
  *ngIf="product.length"
  class="card-container-scroll"
  [ngClass]="{ centered: product?.length <= 5 }"
>
  <span class="circle-button" *ngIf="!showHistoryIcon">
    <i class="fa fa-arrow-circle-left" (click)="scrollLeft(cards2)"></i>
  </span>
  <span
    *ngIf="showHistoryIcon && disableOverFlowX"
    class="circle-button"
    (click)="scrollLeft(cards2)"
  >
    <i class="fa fa-arrow-circle-left"></i>
  </span>

  <div class="cardsProduct" #cards2>
    <mat-card
      appearance="outlined"
      class="brands"
      *ngFor="let product of product; let i = index"
      (click)="sendToBoxOne(product)"
    >
      <mat-card-content>
        <span style="float: right; margin-bottom: 3%" *ngIf="showHistoryIcon">
          <i class="fa fa-history" style="font-size: x-large"></i>
        </span>
        <div
          *ngIf="
            product?.first_two_images && product?.first_two_images.length > 0
          "
        >
          <img mat-card-xl-image [src]="product.first_two_images[0]" />
        </div>
        <div *ngIf="product?.first_two_images === null">
          <img mat-card-xl-image [src]="placeholder_img" />
        </div>
        <div style="float: right">
          <app-circlecolors
            *ngIf="product?.color"
            [color]="product?.color"
          ></app-circlecolors>
        </div>
      </mat-card-content>
      <br />
      <mat-card-content>
        <mat-list role="list" class="forCards">
          <mat-list-item role="listitem">
            <p style="white-space: pre-wrap; overflow-wrap: break-word">
              {{ product?.title }}
            </p>
          </mat-list-item>

          <mat-list-item role="listitem">
            <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
          </mat-list-item>

          <mat-list-item role="listitem">
            ₹{{ product?.price }}
            <span style="float: right" class="onlyForMobile">
              <img [src]="logo[product?.brand]" alt="logo" width="50px" />
            </span>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>
  <span
    *ngIf="!showHistoryIcon"
    class="circle-button"
    (click)="scrollRight(cards2)"
  >
    <i class="fa fa-arrow-circle-right"></i>
  </span>

  <span
    *ngIf="showHistoryIcon && disableOverFlowX"
    class="circle-button"
    (click)="scrollRight(cards2)"
  >
    <i class="fa fa-arrow-circle-right"></i>
  </span>
</div>
