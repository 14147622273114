import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { ShopComponent } from "./shop/shop.component";
import { PagesComponent } from "./pages/pages.component";
import { ElementsComponent } from "./elements/elements.component";
import { FashionOneComponent } from "./home/fashion/fashion-one/fashion-one.component";
import { CollectionInfinitescrollComponent } from "./shop/collection/collection-infinitescroll/collection-infinitescroll.component";
import { LoginComponent } from "./pages/account/login/login.component";
import { CheckoutComponent } from "./pages/account/checkout/checkout.component";
import { AuthGuard } from "./shared/services/auth-guard.service";
import { OrderSuccessComponent } from "./pages/order-success/order-success.component";
import { ReturnWarrantyComponent } from "./pages/return-warranty/return-warranty.component";

const routes: Routes = [
  {
    path: "home",
    component: FashionOneComponent,
  },
  {
    path: "checkout",
    component: CheckoutComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "home",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "shop",
    component: ShopComponent,
    loadChildren: () => import("./shop/shop.module").then((m) => m.ShopModule),
  },
  //   {
  //     path:'Shop',
  // component:CollectionInfinitescrollComponent
  //   },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "return-warranty-policy",
    component: ReturnWarrantyComponent,
  },
  {
    path: "order/success",
    component: OrderSuccessComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "pages",
    component: PagesComponent,
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "elements",
    component: ElementsComponent,
    loadChildren: () =>
      import("./elements/elements.module").then((m) => m.ElementsModule),
  },
  {
    path: "**", // Navigate to Home Page if not found any page
    component: FashionOneComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabledBlocking",
      useHash: false,
      anchorScrolling: "enabled",
      scrollPositionRestoration: "enabled",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
