import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { LoginModelComponent } from "src/app/shared/components/modal/login/login.component";
import { ProductService } from "src/app/shared/services/product.service";
import { userService } from "src/app/shared/services/user.service";
import { NewAddressComponent } from "../new-address/new-address.component";
import { Location } from "@angular/common";
@Component({
  selector: "app-manage-address",
  templateUrl: "./manage-address.component.html",
  styleUrls: ["./manage-address.component.scss"],
})
export class ManageAddressComponent implements OnInit {
  userNewAddress: any[] = [];
  tabRes: any;
  loader: boolean = false;
  instruction: boolean = false;
  addNote: FormGroup;
  popClose: boolean = false;
  changingId: any;
  alwaysClose: boolean = false;
  newAddressSelector = false;
  forDashboardAddAddress = false;
  reLoadForAdd = false;
  @Input() address: boolean = false;

  @Input() billingAddress: boolean = false;

  @Input() id: any[] = [];
  @Input() qty: any[] = [];
  @Output() changingDeliverId = new EventEmitter<any>();
  @Output() changingBillingId = new EventEmitter<any>();
  // @Output() recievesAddressId = new EventEmitter<any>();
  constructor(
    private userService: userService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private location: Location
  ) {
    this.addNote = new FormGroup({
      notes: new FormControl(""),
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((res) => (this.tabRes = res.tab));
    this.getYourAddress();
    // console.log(this.billingAddress);
    // this.addNewAddress()
    // if (this.billingAddress || this.address) {
    //   this.newAddressSelector = false;
    // }
    // console.log(this.address, this.id, this.qty);
  }
  inputChangeValue(user: any) {
    this.changingId = user.address_id;
  }

  useThisAddress() {
    if (this.changingId && this.address) {
      this.changingDeliverId.emit(this.changingId);
    } else if (this.changingId && this.billingAddress) {
      this.changingBillingId.emit(this.changingId);
    }
  }

  addNewAddress() {
    if (this.address) {
      // const queryParams = {
      //   product_id:this.id.map((res)=> res).join(','),
      //   qty:this.qty.map((res)=> res).join(','),
      //   address:this.address
      // }
      // console.log(queryParams);
      //   this.router.navigate(['/pages/newaddress'],{queryParams:queryParams})
    } else {
      this.route.queryParams.subscribe((res) => (this.tabRes = res.tab));
      this.router.navigate(["/pages/newaddress"], {
        queryParams: { tab: this.tabRes },
      });
    }
  }

  getYourAddress() {
    this.loader = true;
    this.userService.getNewAddress().subscribe((res) => {
      this.loader = false;
      this.userNewAddress = JSON.parse(res.body);
      // console.log(this.userNewAddress);
      const defaulted = this.userNewAddress.find(
        (add) => add.is_default === true
      );
      // console.log(defaulted);
      if (this.userNewAddress.length) {
        if (this.userNewAddress.length == 1 && defaulted === undefined) {
          this.setAsFirstAddressAsDefault(this.userNewAddress[0].address_id);
          // console.log("setted");
        } else if (defaulted === undefined) {
          // console.log("undefined");
          this.setAsFirstAddressAsDefault(this.userNewAddress[0].address_id);
        }
      }
    });
  }
  setAsFirstAddressAsDefault(id: any) {
    // console.log("yes");
    const data = {
      address_id: id,
      is_default: true,
    };
    // console.log(data);

    this.userService.editDefault(data).subscribe((res) => {
      // console.log(res);
      this.reLoadForAdd = true;
    });
  }

  editYourAddress(id: number) {
    this.loader = true;
    if (this.instruction === true) {
      const data = {
        address_id: id,
        notes: this.addNote.value.notes,
      };
      // console.log(data);
      this.userService.editNewAddress(data).subscribe((res) => {
        this.loader = false;
        // console.log(res);
        this.getYourAddress();
      });
    } else {
      const data = btoa(JSON.stringify(id)).replace(/=/g, "");
      this.router.navigate(["/pages/newaddress"], {
        queryParams: { id: data, tab: this.tabRes },
      });
    }
  }

  removeYourAddress(id: number) {
    const data = {
      address_id: id,
      is_active: false,
    };
    this.openPopup().subscribe((res) => {
      if (res) {
        this.userService.removeAddress(data).subscribe((res) => {
          this.loader = false;
          // console.log(res);
          this.getYourAddress();
        });
        this.loader = true;
      } else {
        this.loader = false;
      }
    });
  }

  editDefault(id: number) {
    this.loader = true;
    const data = {
      address_id: id,
      is_default: true,
    };
    // console.log(data);

    this.userService.editDefault(data).subscribe((res) => {
      this.loader = false;
      // console.log(res);
      this.getYourAddress();
    });
  }

  openPopup() {
    const dialog = this.dialog.open(LoginModelComponent, {
      // width:'32%',
      // height:'25%',
      disableClose: true,
    });

    return dialog.afterClosed();
  }

  openPopupAddAddress() {
    this.openAddressPopup();
  }

  routeToaddAdress() {
    const queryParams = {
      product_id: this.id.map((res) => res).join(","),
      qty: this.qty.map((res) => res).join(","),
      address: this.address,
    };
    this.router.navigate(["/pages/newaddress"], { queryParams: queryParams });
  }

  async openAddressPopup() {
    // console.log("Opening dialog...");

    const dialog = this.dialog.open(NewAddressComponent, {
      width: "50%",
      height: "90%",
      data: {
        address: this.address,
      },
    });

    // Wait for the dialog to close and get the result
    const result = await dialog.afterClosed().toPromise(); // Convert observable to promise

    // Get the address after the dialog closes
    await this.getYourAddress();

    // Reload the page after the address is updated
    if (this.reLoadForAdd) {
      window.location.reload();
      this.reLoadForAdd = false;
    }
  }
  openNewAddressAsSelector() {
    this.newAddressSelector = !this.newAddressSelector;
    this.forDashboardAddAddress = !this.forDashboardAddAddress;
  }
  receiveData(event: any) {
    // console.log(event);

    if (event) {
      this.getYourAddress();
      this.newAddressSelector = false;
    }
  }
}
