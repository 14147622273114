<div *ngIf="!loader && product !== undefined">
  <div class="img-wrapper" [ngClass]="{ 'img-block': firstEight < 8 }">
    <div class="lable-block" [ngClass]="{ lab: product.stock == false }">
      <span class="lable3" *ngIf="product.new && product.stock == true"
        >new</span
      >
      <!-- <span class="lable4" *ngIf="product.sale && product.stock == true"
        >on sale</span
      > -->
      <span
        class="lable3"
        style="
          background-color: rgba(92, 91, 91, 0.712);
          font-size: 12px;
          border-radius: 0%;
          padding: 5px;
        "
        *ngIf="product.stock == false"
        >Sold Out</span
      >
    </div>
    <div class="front">
      <a>
        <div
          *ngIf="product && product.first_two_images !== null"
          [ngClass]="{
            allCardSize: product && product.first_two_images !== null
          }"
        >
          <img
            (click)="toNoSideBar(product.product_id, product.title, product)"
            style="background-color: white"
            [defaultImage]="ImageSrc ? ImageSrc : placeholder_img"
            [lazyLoad]="ImageSrc ? ImageSrc : product?.first_two_images[0]"
            class="img-fluid lazy-loading"
            alt=""
          />
        </div>
        <img
          (click)="toNoSideBar(product.product_id, product.title, product)"
          *ngIf="product && product.first_two_images == null"
          style="background-color: white"
          [defaultImage]="ImageSrc ? ImageSrc : placeholder_img"
          [lazyLoad]="ImageSrc ? ImageSrc : placeholder_img"
          class="img-fluid lazy-loading"
          alt=""
        />
        <span class="onlyForMobile" style="float: left">
          <a
            href="javascript:void(0)"
            title="Compare"
            (click)="addToCompare(product)"
            class="btn btn-light"
            style="font-size: 10px; line-height: 10px"
          >
            compare
            <!-- <i class="ti-reload" style="color: rgb(8, 8, 8)" aria-hidden="true"
              ></i
            > -->
          </a>
        </span>
        <span style="float: right" class="onlyForMobile">
          <app-circlecolors
            class="color"
            [color]="colorsForFilter"
          ></app-circlecolors>
        </span>
      </a>
    </div>
    <div
      class="back"
      *ngIf="onHowerChangeImage"
      [ngClass]="{
        allCardSize: onHowerChangeImage
      }"
    >
      <a (click)="toNoSideBar(product.product_id, product.title, product)">
        <!-- <img
          [src]="ImageSrc ? ImageSrc : product.variants[0].images[0]"
          class="img-fluid lazy-loading"
          alt="{{ product.variants[0].images[0] }}"
        /> -->
        <img
          *ngIf="product.first_two_images !== null"
          style="background-color: white"
          [defaultImage]="ImageSrc ? ImageSrc : placeholder_img"
          [lazyLoad]="ImageSrc ? ImageSrc : product?.first_two_images[1]"
          class="img-fluid lazy-loading"
          alt=""
        />
      </a>
    </div>

    <ul
      class="product-thumb-list"
      *ngIf="thumbnail && product?.first_two_images !== null"
    >
      <li
        class="grid_thumb_img"
        [class.active]="ImageSrc == image"
        *ngFor="let image of product?.first_two_images[0]"
      >
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image)">
          <img [lazyLoad]="image" />
        </a>
      </li>
    </ul>
    <div class="cart-info cart-wrap">
      <!-- <a href="javascript:void(0)" title="Add to cart" (click)="CartModal.openModal(product)"
        *ngIf="cartModal">
        <i class="ti-shopping-cart"></i>
      </a> -->
      <a
        href="javascript:void(0)"
        title="Add to cart"
        (click)="addToCart(product)"
        *ngIf="!cartModal"
      >
        <i class="ti-shopping-cart"></i>
      </a>
      <!-- <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a> -->
      <!-- <a
        href="javascript:void(0)"
        title="Quick View"
        (click)="QuickView.openModal()"
      >
        <i class="ti-search" aria-hidden="true"></i>
      </a> -->
      <a
        href="javascript:void(0)"
        title="Compare"
        (click)="addToCompare(product)"
      >
        <i class="ti-reload" aria-hidden="true"></i>
      </a>
    </div>
  </div>
  <div class="product-detail" [ngClass]="{ 'product-details': firstEight < 8 }">
    <div
      (click)="toNoSideBar(product.product_id, product.title, product)"
      [ngClass]="{ div: firstEight < 8 }"
    >
      <h6>
        {{ product?.title | titlecase }}
      </h6>

      <bar-rating [rate]="5" [readOnly]="true" class="rating"></bar-rating>

      <!-- <p>
        {{
          showMore
            ? product?.description
            : (product?.description | slice : 0 : 50)
        }}
        <span *ngIf="!showMore" class="dot">...</span>
        <a *ngIf="!showMore" (click)="showMore = !showMore">Show More</a>
        <a *ngIf="showMore" (click)="showMore = !showMore">Back</a>
      </p> -->
      <h4>
        {{
          product?.price * currency?.price
            | discount : product
            | currency : currency?.currency : "symbol"
        }}
      </h4>
      <div class="discount">
        <del *ngIf="product?.discount"
          ><span class="money">
            {{
              product?.price * currency?.price
                | currency : currency?.currency : "symbol"
            }}</span
          >
        </del>
        <span *ngIf="product.discount > 0" class="percentage"
          >{{ product.discount }}% off</span
        >

        <span style="float: right">
          <img [src]="logo[product.brand]" alt="logo" width="50px" />
        </span>
      </div>
      <ul class="color-variant">
        <app-circlecolors
          class="color"
          [color]="colorsForFilter"
        ></app-circlecolors>

        <!-- <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
          (click)="ChangeVariants(color, product)">
        </li> -->
      </ul>
    </div>
  </div>
</div>

<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view
  #quickView
  [product]="product"
  [currency]="currency"
></app-quick-view>
<app-cart-modal
  #cartModal
  [product]="product"
  [currency]="currency"
  *ngIf="cartModal"
></app-cart-modal>
