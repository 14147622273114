import {
  HttpClient,
  HttpClientModule,
  HttpHeaders,
  HttpParams,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, Observable, Subject, tap } from "rxjs";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class userService {
  baseUrl: string =
    "https://qarselokic.execute-api.us-east-1.amazonaws.com/dev/";

  constructor(private http: HttpClient) {}
  addNewAddress(data: any): Observable<any> {
    // console.log(data);
    return this.http.post<any>(this.baseUrl + "user/address", data);
  }

  getNewAddress(): Observable<any> {
    return this.http.get<any>(this.baseUrl + "user/address");
  }

  editNewAddress(data: any) {
    // console.log("edited Data", data);
    return this.http.put<any>(this.baseUrl + "user/address", data);
  }

  editDefault(data: any) {
    return this.http.put<any>(this.baseUrl + "user/address", data);
  }

  removeAddress(data: any) {
    return this.http.put<any>(this.baseUrl + "user/address", data);
  }

  signUpUserData(data: any) {
    // console.log(data);
    return this.http.post<any>(this.baseUrl + "user/signup", data);
  }

  editUserData(data: any) {
    return this.http.put<any>(this.baseUrl + "user", data);
  }

  getUserData() {
    return this.http.get<any>(this.baseUrl + "user");
  }

  postCartData(data: any) {
    // console.log(data);
    return this.http.post<any>(this.baseUrl + "user/cart", data);
  }

  getCartData() {
    return this.http.get<any>(this.baseUrl + "user/cart");
  }

  updateCartData(data: any) {
    // console.log(data);
    return this.http.put<any>(this.baseUrl + "user/cart", data);
  }

  DeleteCartData(data: any) {
    // console.log(data);
    return this.http.put<any>(this.baseUrl + "user/cart", data);
  }

  addWishlistData(data: any) {
    // console.log(data);
    return this.http.post<any>(this.baseUrl + "user/wishlist", data);
  }

  getWishlistData() {
    return this.http.get<any>(this.baseUrl + "user/wishlist");
  }

  DeleteWishlistData(data: any) {
    // console.log(data);
    return this.http.put<any>(this.baseUrl + "user/wishlist", data);
  }
  addComment(data: any) {
    return this.http.post<any>(this.baseUrl + "user/comment", data);
  }
  getPrisignedUrl(filename: any, prefix: any) {
    let param1 = new HttpParams()
      .set("file_name", filename)
      .set("folder_name", prefix);
    let api = this.baseUrl + "user/getpresignedurl/";
    return this.http.get(api, { params: param1 });
  }
  uploadImgeAwsS3(fileUrl: any, contentType: any, file: any) {
    const header = new HttpHeaders({
      "content-type": contentType,
      "x-amz-acl": "public-read",
    });
    const req = new HttpRequest("PUT", fileUrl, file, {
      headers: header,
    });
    return this.http.request(req);
  }
  getComments(product_id: string) {
    return this.http.get<any>(this.baseUrl + `product/comment/${product_id}`);
  }
  editComments(data: any) {
    return this.http.put<any>(this.baseUrl + "user/comment", data);
  }
  public getOrdersList() {
    return this.http.get<string>(this.baseUrl + "user/order");
  }

  public getOrdersDetails(receiptNumber: any) {
    return this.http.get<string>(this.baseUrl + `user/order/${receiptNumber}`);
  }
  addAction(data: any) {
    return this.http.post<any>(this.baseUrl + "usr/action", data);
  }

  private recentSearchBySubject = new Subject<any>();

  public updateRecentSearch(id: any, user: boolean) {
    // console.log("sub");
    this.getSearch(id, user).subscribe((res) => {
      if (res) {
        // console.log(res);
        this.recentSearchBySubject.next(res);
      }
    });
  }

  public get recentSearchBySub() {
    return this.recentSearchBySubject.asObservable();
  }

  getSearch(id: any, user: boolean) {
    let params;
    if (user === true) {
      params = new HttpParams().set("user_id", id);
    } else {
      params = new HttpParams().set("session_id", id);
    }
    if (params) {
      // console.log(params.toString());
      return this.http.get<any>(`${this.baseUrl}usr/action/search`, { params });
    }
  }

  private recentViewProduct$ = new Subject<any>();

  public updateViewedProduct(id: any, user: boolean) {
    // console.log("sub");
    this.getProductByAction(id, user).subscribe((res) => {
      if (res) {
        // console.log(res);
        this.recentViewProduct$.next(res);
      }
    });
  }

  public get viewedProduct() {
    return this.recentViewProduct$.asObservable();
  }
  getProductByAction(id: any, user: boolean) {
    let params;
    if (user === true) {
      params = new HttpParams().set("user_id", id);
    } else {
      params = new HttpParams().set("session_id", id);
    }
    if (params) {
      // console.log(params.toString());
      return this.http.get<any>(`${this.baseUrl}/usr/action/product`, {
        params,
      });
    }
  }

  checkUserExist(type: string, value: string) {
    let params;
    if (type === "phone") {
      params = new HttpParams().set(type, value);
    }
    if (params) {
      return this.http.get<any>(`${this.baseUrl}/user/verify/account`, {
        params,
      });
    }
  }

  getIp() {
    return this.http.get("https://ipapi.co/json");
  }
}
