import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Carousel } from "bootstrap";
import * as $ from "jquery"; // Import jQuery
@Component({
  selector: "app-card-categories",
  templateUrl: "./card-categories.component.html",
  styleUrls: ["./card-categories.component.scss"],
})
export class CardCategoriesComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}
  selectedItem: string | null = null;
  categories = {
    JACKET: "assets/imagecategoris/jackets.webp",
    GLOVES: "assets/imagecategoris/gloves.webp",
    BOOTS: "assets/imagecategoris/boots.webp",
    BALACLAVA: "assets/imagecategoris/balaclava.webp",
    SPARES: "assets/imagecategoris/spares.jpg",
    MECHANISM: "assets/imagecategoris/mechanism.webp",
    VISOR: "assets/imagecategoris/visor.jpg",
    PADDING: "assets/imagecategoris/padding.png",
    "AXOR-MARVEL": "assets/imagecategoris/axor-venom.png",
    "STUDDS-MARVEL": "assets/imagecategoris/studds-jet.png",
    "VEGA-MARVEL": "assets/imagecategoris/marvel-helmets.png",
    "AXOR-DC": "assets/imagecategoris/axor-batman.png",
    "VEGA-DC": "assets/imagecategoris/vega-dc.png",
  };

  price = {
    Filters: [
      {
        key: "low",
        img: "assets/imagecategoris/price-1.png",
        type: "HELMET",
      },
      {
        key: "mid",
        img: "assets/imagecategoris/price-2.png",
        type: "HELMET",
      },
      {
        key: "high",
        img: "assets/imagecategoris/price-3.png",
        type: "HELMET",
      },
      {
        key: "x-high",
        img: "assets/imagecategoris/price-4.png",
        type: "HELMET",
      },

      {
        key: "low",
        img: "assets/imagecategoris/price-5.png",
        type: "RIDING GEARS",
      },
      {
        key: "mid",
        img: "assets/imagecategoris/price-6.png",
        type: "RIDING GEARS",
      },
      {
        key: "high",
        img: "assets/imagecategoris/price-7.png",
        type: "RIDING GEARS",
      },
      {
        key: "x-high",
        img: "assets/imagecategoris/price-8.png",
        type: "RIDING GEARS",
      },
    ],
  };

  edition = {
    MARVEL: [
      {
        key: "AXOR-MARVEL",
        brand: "AXOR",
        details: "Axor-Marvel-Collections",
      },
      {
        key: "STUDDS-MARVEL",
        brand: "STUDDS",
        details: "Studds-Marvel-Collections",
      },
      {
        key: "VEGA-MARVEL",
        brand: "VEGA",
        details: "Vega-Marvel-Collections",
      },
    ],
    DC: [
      {
        key: "AXOR-DC",
        brand: "AXOR",
        details: "Axor-DC-Collections",
      },
      {
        key: "VEGA-DC",
        brand: "VEGA",
        details: "Vega-DC-Collections",
      },
    ],
  };

  collection = {
    MARVEL: "assets/imagecategoris/marvel.png",
    DC: "assets/imagecategoris/dc.png",
    MEN: "assets/imagecategoris/men.png",
    WOMEN: "assets/imagecategoris/women.png",
    KIDS: "assets/imagecategoris/kids.png",
  };

  collection_square = {
    MARVEL: "assets/imagecategoris/favourites (4).png",
    DC: "assets/imagecategoris/favourites (5).png",
    MEN: "assets/imagecategoris/favourites (1).png",
    WOMEN: "assets/imagecategoris/favourites (2).png",
    KIDS: "assets/imagecategoris/favourites (3).png",
  };

  name_coll = ["MEN", "WOMEN", "KIDS", "MARVEL", "DC"];

  tab: any;
  largeScreen = false;
  ngOnInit(): void {
    if (window.innerWidth > 767) {
      this.largeScreen = true;
    }
    console.log(this.largeScreen);
    this.comicClick("MARVEL");

    this.route.queryParams.subscribe((params) => {
      this.tab = params.tab;
    });
  }

  routeCategories(category: any) {
    if (category) {
      this.router.navigate(["/shop/"], {
        queryParams: { category: category, tab: this.tab },
      });
    }
  }
  routeCollections(collection: any) {
    if (collection) {
      this.router.navigate(["/shop/"], {
        queryParams: { collection: collection, tab: this.tab },
      });
    }
  }
  routePrice(item: any) {
    let minPrice;
    let maxPrice;
    console.log(item);

    if (item.key === "low") {
      minPrice = 1;
      maxPrice = 999;
    } else if (item.key === "mid") {
      minPrice = 999;
      maxPrice = 2999;
    } else if (item.key === "high") {
      minPrice = 2999;
      maxPrice = 4999;
    } else if (item.key === "x-high") {
      minPrice = 4999;
      maxPrice = 50000;
    }
    if (item && minPrice && maxPrice) {
      this.router.navigate(["/shop/"], {
        queryParams: {
          type: item.type,
          min_price: minPrice,
          max_price: maxPrice,
        },
      });
    }
  }
  SliderConfig = {
    items: 10,
    responsive: {
      0: {
        items: 1, // Number of items shown at mobile screen sizes
      },
      768: {
        items: 1, // Number of items shown at tablet screen sizes
      },
      1024: {
        items: 1, // Number of items shown at desktop screen sizes
      },
    },
    nav: false,
    dots: true,
    autoplay: true, // Enable auto-looping
    autoplayTimeout: 3000, // Time between slides (in milliseconds)
    autoplayHoverPause: true,
    loop: true, // Enable looping
    rewind: true,
  };

  @ViewChild("carouselSlides")
  carouselElement: ElementRef<HTMLElement>;

  carouselRef: Carousel;

  ngAfterViewInit() {
    setTimeout(() => {
      this.carouselRef = new Carousel(this.carouselElement.nativeElement, {
        slide: false,
        interval: 3000,
      });
      this.startSlide();
      this.cdr.detectChanges();
    }, 2000);
  }

  startSlide() {
    this.carouselRef.cycle();
  }
  // stopSlide() {
  //   this.carouselRef.pause();
  // }
  nextSlide() {
    this.carouselRef.next();
  }
  previousSlide() {
    this.carouselRef.prev();
  }

  comicClick(item: string) {
    this.selectedItem = item;
  }
  isSelected(item: string): boolean {
    return this.selectedItem === item;
  }

  sendtoCollectionbyBrand(brand) {
    if (brand) {
      this.router.navigate(["/shop/"], {
        queryParams: {
          brand: brand,
          collection: this.selectedItem,
          tab: this.tab,
        },
      });
    }
  }
  sendtoCollections(item) {
    let queryParams;
    if (item) {
      if (item === "MEN" || item === "WOMEN") {
        queryParams = {
          search: `${item} HELMET`,
        };
      } else if (item === "KIDS") {
        queryParams = {
          collection: `${item} HELMET`,
        };
      } else {
        queryParams = {
          collection: item,
        };
      }
    }
    if (queryParams) {
      // console.log(queryParams);
      this.router.navigate(["/shop/"], {
        queryParams: {
          ...queryParams,
          tab: this.tab,
        },
      });
    }
  }
  scrollLeft(element: HTMLElement) {
    element.scrollBy({ left: -800, behavior: "smooth" });
  }
  scrollRight(element: HTMLElement) {
    element.scrollBy({ left: 800, behavior: "smooth" });
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    if (window.innerWidth > 768) {
      this.largeScreen = true;
    } else {
      this.largeScreen = false;
    }
    this.cdr.detectChanges();
  }
}
