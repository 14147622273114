<!-- /*---------------------------------------------
---------------  riding gears - desk -----------------
---------------------------------------------
*/ -->
<div class="container">
  <div class="container1">
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <mat-card class="desk-categories">
          <div class="name">JACKETS</div>
          <img [src]="categories['JACKET']" mat-card-xl-image />

          <button class="btn btn-dark" (click)="routeCategories('JACKET')">
            Shop Now
          </button>
        </mat-card>
      </div>
      <div class="col-lg-3 col-md-6">
        <mat-card class="desk-categories">
          <div class="name">GLOVES</div>
          <img [src]="categories['GLOVES']" mat-card-xl-image />

          <button class="btn btn-dark" (click)="routeCategories('GLOVES')">
            Shop Now
          </button>
        </mat-card>
      </div>
      <div class="col-lg-3 col-md-6">
        <mat-card class="desk-categories">
          <div class="name">BOOTS</div>
          <img [src]="categories['BOOTS']" mat-card-xl-image />

          <button class="btn btn-dark" (click)="routeCategories('BOOTS')">
            Shop Now
          </button>
        </mat-card>
      </div>
      <div class="col-lg-3 col-md-6">
        <mat-card class="desk-categories">
          <div class="name">BALACLAVA / SLEEVES</div>
          <img [src]="categories['BALACLAVA']" mat-card-xl-image />

          <button class="btn btn-dark" (click)="routeCategories('BALACLAVA')">
            Shop Now
          </button>
        </mat-card>
      </div>
    </div>
  </div>

  <!-- /*---------------------------------------------
---------------  riding gears - mobile -----------------
---------------------------------------------
*/ -->

  <div class="container2">
    <div class="row">
      <div class="col-6">
        <mat-card class="desk-categories">
          <div class="name">JACKETS</div>
          <img [src]="categories['JACKET']" mat-card-md-image />

          <button class="btn btn-dark" (click)="routeCategories('JACKET')">
            Shop Now
          </button>
        </mat-card>
      </div>
      <div class="col-6">
        <mat-card class="desk-categories">
          <div class="name">GLOVES</div>
          <img [src]="categories['GLOVES']" mat-card-md-image />

          <button class="btn btn-dark" (click)="routeCategories('GLOVES')">
            Shop Now
          </button>
        </mat-card>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-6">
        <mat-card class="desk-categories">
          <div class="name">BOOTS</div>
          <img [src]="categories['BOOTS']" mat-card-md-image />

          <button class="btn btn-dark" (click)="routeCategories('BOOTS')">
            Shop Now
          </button>
        </mat-card>
      </div>
      <div class="col-6">
        <mat-card class="desk-categories">
          <div class="name">BALACLAVA / SLEEVES</div>
          <img [src]="categories['BALACLAVA']" mat-card-md-image />

          <button class="btn btn-dark" (click)="routeCategories('BALACLAVA')">
            Shop Now
          </button>
        </mat-card>
      </div>
    </div>
  </div>
  <br />

  <!-- /*---------------------------------------------
---------------  under Price -----------------
---------------------------------------------
*/ -->

  <!-- <div class="container"> -->
  <div class="title1 section-t-space">
    <h4>Trends</h4>
    <h2 class="title-inner1">Under Budget</h2>
  </div>
  <div style="display: flex" class="container price">
    <span class="side-arrow" (click)="scrollLeft(over)"
      ><i class="ti-angle-left"></i
    ></span>

    <!-- <div class="row"> -->
    <div [ngClass]="largeScreen ? 'overflow' : 'row'" #over>
      <div class="col-lg-3 col-6" *ngFor="let item of price['Filters']">
        <span class="img" (click)="routePrice(item)">
          <img [src]="item.img" mat-card-lg-image />
        </span>
      </div>
    </div>
    <!-- </div> -->
    <span class="side-arrow" (click)="scrollRight(over)"
      ><i class="ti-angle-right"></i
    ></span>
  </div>
</div>
<!-- </div> -->

<!-- /*---------------------------------------------
---------------  collections circle -----------------
---------------------------------------------
*/ -->

<br />
<div class="container">
  <div class="title1 section-t-space">
    <h4>Explore Your</h4>
    <h2 class="title-inner1">Favourites</h2>
  </div>
  <div class="favourites">
    <div class="row">
      <div
        class="col-lg-2 col-6"
        *ngFor="let item of name_coll; let i = index"
        [ngClass]="{ 'col-12': i === 2 }"
      >
        <img
          [src]="collection_square[item]"
          mat-card-lg-image
          class="hover-scale"
          (click)="sendtoCollections(item)"
        />
      </div>
    </div>
  </div>
  <!-- <div class="container4">
    <div class="row">
      <span *ngFor="let item of name_coll" (click)="sendtoCollections(item)">
        <img [src]="collection[item]" width="150px" class="hover-scale" />
        <br />
        <a>{{ item }}</a>
      </span>
    </div>
  </div> -->

  <!-- /*---------------------------------------------
---------------  spare - desk -----------------
---------------------------------------------
*/ -->

  <div class="title1 section-t-space">
    <h4>Helmet</h4>
    <h2 class="title-inner1">Spares</h2>
  </div>

  <div class="container1">
    <div class="row spares" style="align-items: center">
      <div class="col-3">
        <div style="display: flex; justify-content: end">
          <mat-card
            style="width: fit-content"
            class="card"
            (click)="routeCollections('FITTING')"
          >
            <img [src]="categories['MECHANISM']" mat-card-md-image />
            <p>Mechanism</p>
          </mat-card>
        </div>
      </div>
      <div class="col-6">
        <img [src]="categories['SPARES']" width="100%" />
      </div>
      <div class="col-3">
        <div>
          <mat-card
            style="width: fit-content"
            class="card"
            (click)="routeCollections('VISOR')"
          >
            <img [src]="categories['VISOR']" mat-card-md-image />
            <p>Visor</p>
          </mat-card>
        </div>
        <br />
        <div>
          <mat-card
            style="width: fit-content"
            class="card"
            (click)="routeCollections('PADDING')"
          >
            <img [src]="categories['PADDING']" mat-card-md-image />
            <p>Padding</p>
          </mat-card>
        </div>
      </div>
    </div>
    <br />
  </div>

  <!-- /*---------------------------------------------
---------------  spare - mobile -----------------
---------------------------------------------
*/ -->

  <div class="container2">
    <div class="container">
      <div #carouselSlides class="carousel slide">
        <div class="row">
          <div class="col-2 arrowCol">
            <span class="slider-arrow" (click)="previousSlide()">
              <span class="btn btn-light"> <i class="ti-angle-left"></i></span>
            </span>
          </div>
          <div class="col-8" style="padding: 0px">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="img-slider">
                  <div class="outer-circle">
                    <div class="inner-circle">
                      <img [src]="categories['MECHANISM']" mat-card-md-image />
                      <br />
                    </div>
                  </div>
                </div>
                <p>
                  <span
                    class="btn btn-link"
                    (click)="routeCollections('FITTING')"
                    >Mechanism</span
                  >
                </p>
              </div>
              <div class="carousel-item">
                <div class="img-slider">
                  <div class="outer-circle">
                    <div class="inner-circle">
                      <img [src]="categories['VISOR']" mat-card-md-image />
                    </div>
                  </div>
                </div>
                <p>
                  <span class="btn btn-link" (click)="routeCollections('VISOR')"
                    >Visor</span
                  >
                </p>
              </div>
              <div class="carousel-item">
                <div class="img-slider">
                  <div class="outer-circle">
                    <div class="inner-circle">
                      <img [src]="categories['PADDING']" mat-card-md-image />
                    </div>
                  </div>
                </div>
                <p>
                  <span
                    class="btn btn-link"
                    (click)="routeCollections('PADDING')"
                    >Padding</span
                  >
                </p>
              </div>
            </div>
          </div>
          <div class="col-2 arrowCol">
            <span class="slider-arrow">
              <span class="btn btn-light" (click)="nextSlide()">
                <i class="ti-angle-right"></i
              ></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- carousel - fav - heroes  -->

  <!-- <div class="container4">
    <div class="row">
      <div class="col-5 fav">
        <mat-card
          [ngClass]="{ 'btn-dark': isSelected('MARVEL') }"
          (click)="comicClick('MARVEL')"
          ><img [src]="collection['MARVEL']" width="100px"
        /></mat-card>
      </div>
      <div class="col-2 fav">
        <img [src]="collection['VS']" width="100px" />
      </div>

      <div class="col-5 fav">
        <mat-card
          [ngClass]="{ 'btn-dark': isSelected('DC') }"
          (click)="comicClick('DC')"
          ><img [src]="collection['DC']" width="100px"
        /></mat-card>
      </div>
    </div>
  </div>
  <br />
</div>
<div class="container3">
  <owl-carousel-o [options]="SliderConfig" class="edition-slider">
    <ng-container>
      <ng-template carouselSlide *ngFor="let item of edition[selectedItem]">
        <div class="edition">
          <div class="content">
            <img [src]="categories[item.key]" width="200px" />
          </div>
          <br />
          <div class="content">
            <p style="color: rgb(126, 126, 126); font-weight: bolder">
              {{ item.brand }}
            </p>
          </div>
          <div class="content">
            <p style="color: white">{{ item.details }}</p>
          </div>
          <div class="content">
            <button
              class="btn btn-link"
              (click)="sendtoCollectionbyBrand(item.brand)"
            >
              view collections
            </button>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div> -->

  <!-- <ng-template carouselSlide>
        <div class="edition">
          <div class="content">
            <img
              [src]="categories['AXOR-VENOM']"
              mat-card-md-image
              width="fit-content"
            />
          </div>
          <br />
          <div class="content"><p style="color: white">MARVEL</p></div>
          <div class="content">
            <p style="color: white">Axor Apex Venomous D/V</p>
          </div>
          <div class="content">
            <button class="btn btn-link">view product</button>
          </div>
        </div>
      </ng-template> -->
</div>
