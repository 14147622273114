import { AfterViewInit, Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-custom-slider",
  templateUrl: "./custom-slider.component.html",
  styleUrls: ["./custom-slider.component.scss"],
})
export class CustomSliderComponent implements OnInit, AfterViewInit {
  constructor(private router: Router) {}

  images = {
    "VEGA-DC": "assets/imagecategoris/vega-dc-poster.png",
    "VEGA-MARVEL": "assets/imagecategoris/vega-poster.png",
    "AXOR-MARVEL": "assets/imagecategoris/axor-poster.png",
    "AXOR-DC": "assets/imagecategoris/axor-dc-poster.png",
    "STUDDS-MARVEL": "assets/imagecategoris/studds-poster.png",
  };

  name = ["VEGA-DC", "VEGA-MARVEL", "AXOR-MARVEL", "AXOR-DC", "STUDDS-MARVEL"];
  currentIndex: number = 0;
  currentImageUrl: string = this.name[this.currentIndex];

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      let next = document.querySelector(".next");
      let prev = document.querySelector(".prev");
      let shop = document.querySelector(".btnShop");
      next.addEventListener("click", function () {
        let items = document.querySelectorAll(".item");
        document.querySelector(".slide").appendChild(items[0]);
        if (items.length > 0 && items.length > this.currentIndex) {
          this.currentIndex = this.currentIndex + 1;
          // console.log(this.currentIndex);
        } else {
          this.currentIndex = 0;
          this.currentIndex = this.currentIndex + 1;
          // console.log(this.currentIndex);
        }
      });
      prev.addEventListener("click", function () {
        let items = document.querySelectorAll(".item");
        document.querySelector(".slide").prepend(items[items.length - 1]);
        // console.log("yes");
      });
    }, 1000);
  }
  onShop(item: any) {
    // console.log(item);
    const [brand, collection] = item.split("-");
    this.router.navigate(["/shop"], {
      queryParams: { brand: brand, collection: collection },
    });
  }
}
