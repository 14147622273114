<br />
<div *ngIf="sameError" style="display: flex; justify-content: center">
  <div class="alert alert-danger" role="alert">
    <p style="margin-bottom: 0">Old and new passwords cannot be the same</p>
  </div>
</div>
<div *ngIf="diffError" style="display: flex; justify-content: center">
  <div class="alert alert-danger" role="alert">
    <p style="margin-bottom: 0">
      New password and re-entered password must match
    </p>
  </div>
</div>
<div *ngIf="wrongOld" style="display: flex; justify-content: center">
  <div class="alert alert-danger" role="alert">
    <p style="margin-bottom: 0">
      Check Your current Password <br />
      (if correct try after some time)
    </p>
  </div>
</div>
<!-- <div *ngIf="!passwordForm.get('newPass')?.valid &&
    (passwordForm.get('newPass')?.dirty|| passwordForm.get('newPass')?.touched)" style="display: flex;justify-content: center; ">
      <div  class="alert alert-danger" role="alert" >
        <p style=" margin-bottom: 0; "> Enter New Password
          </p>
       </div>
      </div> -->
<div *ngIf="success" style="display: flex; justify-content: center">
  <div class="alert alert-success" role="alert">
    <p style="margin-bottom: 0">Password Changed Successfully!</p>
  </div>
</div>

<section style="display: flex; align-items: center; justify-content: center">
  <mat-card style="width: 90%">
    <mat-card-content>
      <form
        [formGroup]="passwordForm"
        *ngIf="!forgotPassSection && !resetSection"
      >
        <mat-form-field
          appearance="outline"
          style="width: -webkit-fill-available"
        >
          <mat-label>Current Password</mat-label>
          <input
            matInput
            formControlName="oldPassword"
            type="password"
            placeholder="Enter your new password"
            name="password"
            [type]="hidePassword1 ? 'password' : 'text'"
            required
          />

          <i
            class="ti-eye"
            matSuffix
            style="top: 0"
            (click)="togglePasswordVisibility(1)"
            aria-hidden="true"
          ></i>
        </mat-form-field>
        <br />

        <mat-form-field
          appearance="outline"
          style="width: -webkit-fill-available"
        >
          <mat-label> New Password</mat-label>
          <input
            matInput
            type="password"
            formControlName="newPass"
            placeholder="Confirm your new password"
            name="confirmPassword"
            [type]="hidePassword2 ? 'password' : 'text'"
            required
          />
          <i
            class="ti-eye"
            matSuffix
            (click)="togglePasswordVisibility(2)"
            aria-hidden="true"
          ></i>
          <hr *ngIf="passwordForm.get('newPass')?.errors?.pattern" />
          <div
            class="text-danger"
            *ngIf="
              !passwordForm.get('newPass')?.valid &&
              (passwordForm.get('newPass')?.dirty ||
                passwordForm.get('newPass')?.touched)
            "
          >
            <hr *ngIf="!passwordForm.get('newPass')?.errors?.pattern" />
            <span
              class="text-danger"
              *ngIf="passwordForm.get('newPass')?.errors.required"
              >Password is required</span
            >
          </div>

          <span
            class="text-danger"
            *ngIf="passwordForm.get('newPass')?.errors?.pattern"
          >
            <p class="text-danger">
              Password must be at least 8 characters long
            </p>
            <p class="text-danger">
              Password must contain atleast one uppercase
            </p>
            <p class="text-danger">
              Password must contain atleast one lowercase
            </p>
            <p class="text-danger">Password must contain atleast one number</p>
            <p class="text-danger">
              Password must contain atleast one special characeter
            </p>
          </span>
        </mat-form-field>

        <br />

        <mat-form-field
          appearance="outline"
          style="width: -webkit-fill-available"
        >
          <mat-label>Re-Enter Password</mat-label>
          <input
            matInput
            formControlName="reNew"
            type="password"
            placeholder="Re-enter your new password"
            name="reenterPassword"
            [type]="hidePassword3 ? 'password' : 'text'"
            required
          />
          <i
            class="ti-eye"
            matSuffix
            (click)="togglePasswordVisibility(3)"
            aria-hidden="true"
          ></i>
        </mat-form-field>
        <br />
        <div
          style="align-items: center; justify-content: center; display: flex"
        >
          <button
            type="submit"
            class="btn btn-solid"
            [disabled]="!passwordForm.valid"
            (click)="checkPasswordChange()"
          >
            Reset Password
          </button>
        </div>
        <br />
        <a
          (click)="forgotPass()"
          style="float: right; display: flex; color: blue; cursor: pointer"
          >forgot password</a
        >
      </form>

      <div class="forgot-password-container" *ngIf="forgotPassSection">
        <h2
          style="
            margin-bottom: 4rem;
            display: flex;
            justify-content: center;
            font-size: 15px;
          "
        >
          ENTER EMAIL
        </h2>

        <form (ngSubmit)="onSubmit()" #forgotPasswordForm="ngForm">
          <div class="form-group">
            <!-- <label for="email">Email Address</label> -->
            <input
              type="email"
              id="email"
              name="email"
              [(ngModel)]="email"
              #emailField="ngModel"
              placeholder="enter your email"
              required
              email
              class="form-control"
            />
            <div
              *ngIf="
                emailField.invalid && (emailField.dirty || emailField.touched)
              "
              class="error-message"
            >
              <div *ngIf="emailField.errors?.required" style="color: red">
                Email is required.
              </div>
              <div *ngIf="emailField.errors?.email" style="color: red">
                Enter a valid email address.
              </div>
            </div>
          </div>
          <button
            type="submit"
            [disabled]="forgotPasswordForm.invalid"
            class="btn btn-primary"
            style="margin-bottom: 2rem"
          >
            Submit
          </button>
          <div *ngIf="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
          <div *ngIf="successMessage" class="success-message">
            {{ successMessage }}
          </div>
        </form>
      </div>
      <!-- <div class="reset-password-container" *ngIf="resetSection">
        <h2>Reset Password</h2>
        <form (ngSubmit)="onReset()" #resetPasswordForm="ngForm">
          <div class="form-group">
            <label for="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              [(ngModel)]="email"
              #emailField="ngModel"
              required
              email
              class="form-control"
            />
            <div
              *ngIf="
                emailField.invalid && (emailField.dirty || emailField.touched)
              "
              class="error-message"
            >
              <div *ngIf="emailField.errors?.required">Email is required.</div>
              <div *ngIf="emailField.errors?.email">
                Enter a valid email address.
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="code">Verification Code</label>
            <input
              type="text"
              id="code"
              name="code"
              [(ngModel)]="code"
              #codeField="ngModel"
              required
              class="form-control"
            />
            <div
              *ngIf="
                codeField.invalid && (codeField.dirty || codeField.touched)
              "
              class="error-message"
            >
              <div *ngIf="codeField.errors?.required" style="color: red">
                Verification code is required.
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="password">New Password</label>
            <input
              type="password"
              id="password"
              name="password"
              [(ngModel)]="password"
              #passwordField="ngModel"
              required
              minlength="8"
              class="form-control"
            />
            <div
              style="color: red"
              *ngIf="
                passwordField.invalid &&
                (passwordField.dirty || passwordField.touched)
              "
              class="error-message"
            >
              <div *ngIf="passwordField.errors?.required" style="color: red">
                Password is required.
              </div>
              <div *ngIf="passwordField.errors?.minlength" style="color: red">
                Password must be at least 8 characters long.
              </div>
            </div>
          </div>

          <button
            type="submit"
            [disabled]="resetPasswordForm.invalid"
            class="btn btn-primary"
          >
            Reset Password
          </button>
          <div *ngIf="errorMessage" class="error-message">
            {{ errorMessage }}
          </div>
          <div *ngIf="successMessage" class="success-message">
            {{ successMessage }}
          </div>
        </form>
      </div> -->
    </mat-card-content></mat-card
  >
</section>
