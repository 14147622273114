// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  instagram_token: 'INSTA_TOKEN',
  stripe_token: 'STRIPE_TOKEN',
  paypal_token: 'PAYPAL_TOKEN',
  rzp_key: 'rzp_live_UuPzxuQtY6bbSV',
  cognito: {
    userPoolId: 'us-east-1_1fGiktFfo',
    userPoolWebClientId: '280p388uge9f4teof35v0o28j0',
    federationTarget: "COGNITO_USER_POOLS",
    oauth: {
      domain: 'skullcrown.auth.us-east-1.amazoncognito.com',
      scope: ['email', 'openid', 'profile'],
      redirectSignIn: 'http://localhost:4200/',  // Make sure this is correctly set
      redirectSignOut: 'http://localhost:4200/',
      responseType: 'code' // or 'token'
    }
  },

 firebase :{
  apiKey: "AIzaSyA5hHdTVp30epweduSOi7HWcWVQHMDz2W0",
  authDomain: "skullcrown-49e4b.firebaseapp.com",
  projectId: "skullcrown-49e4b",
  storageBucket: "skullcrown-49e4b.appspot.com",
  messagingSenderId: "816543933602",
  appId: "1:816543933602:web:5a09858ef62bdba5a763fb",
  measurementId: "G-M0QV9XSN9W"
}

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
