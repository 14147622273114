<div class="loop-wrapper">
  <!-- <div class="mountain"></div> -->
  <!-- <div class="hill"></div> -->
  <!-- <div class="tree"></div> -->
  <!-- <div class="tree"></div> -->
  <!-- <div class="tree"></div> -->
  <!-- <div class="rock"></div> -->
  <div class="truck"></div>
  <div class="wheels"></div>
</div>
