import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { Product } from "../../classes/product";
import { Router } from "@angular/router";
import { ProductService } from "../../services/product.service";

@Component({
  selector: "app-mat-card-desk",
  templateUrl: "./mat-card-desk.component.html",
  styleUrls: ["./mat-card-desk.component.scss"],
})
export class MatCardDeskComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  title_url: string = "";
  public products: any[] = [];
  productList: any[] = [];
  screenWidth: number;
  disableOverFlowX: boolean = false;
  @Input() product: any;
  @Input() showHistoryIcon: boolean = false;
  private initialized: boolean = false;
  @ViewChild("cards2") cardElements: ElementRef;
  placeholder_img = "assets/images/product/placeholder.jpg";

  public logo = {
    KORDA: "assets/logo-small/korda-horizontal.png",

    STUDDS: "assets/logo-small/studds-horizontal.png",

    LS2: "assets/logo-small/ls2-horizontal.png",

    STEELBIRD: "assets/logo-small/steelbird-horizontal.png",

    "MT HELMETS": "assets/logo-small/mt-horizontal.png",

    AXOR: "assets/logo-small/axor-horizontal.png",

    RIDEX: "assets/logo-small/ridex-horizontal.png",

    VEGA: "assets/logo-small/vega-horizontal.png",

    SMK: "assets/logo-small/smk-horizontal.png",

    AXXIS: "assets/logo-small/axxis-horizontal.png",
    DSG: "assets/logo-small/dsg-logo.webp",
    SOLACE: "assets/logo-small/solace.png",
    ARK: "assets/logo-small/ark.png",
    // SOLACE: "",

    // VISTA: "",

    // "3M": "",

    // ARK: "",
  };
  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private productService: ProductService
  ) {}
  public ImageSrc: string;
  topLoaderCounts = [1, 2, 3, 4, 5, 6];
  topLoader: boolean = true;
  ngOnInit(): void {
    // console.log(this.product)

    this.cdr.detectChanges();
    this.productService.showLoaderSubject.subscribe((data) => {
      if (data == true || data == false) {
        this.topLoader = data;
      }
    });
  }

  ngAfterViewInit(): void {
    this.initialized = true;
    setTimeout(() => {
      this.checkOverflow();
    });
    this.cdr.detectChanges();
  }
  ngAfterViewChecked(): void {
    if (this.initialized && this.cardElements) {
      this.checkOverflow();
      this.cdr.detectChanges();
      this.initialized = false;
    }
  }
  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.checkOverflow();
    this.cdr.detectChanges();
  }

  checkOverflow() {
    if (this.cardElements) {
      const overAllWidth = this.cardElements.nativeElement.scrollWidth;
      this.screenWidth = window.innerWidth;
      this.disableOverFlowX = overAllWidth >= this.screenWidth;
      // console.log(overAllWidth,this.screenWidth,this.disableOverFlowX)
    }
  }

  sendToBoxOne(item) {
    const id = item.product_id;
    const data = btoa(id);
    const formatTitle = item.title.replace(/\s+/g, "-");

    this.router.navigate(["/shop/details", data, formatTitle]);
    this.productService.recentSearch(item);
  }
  scrollLeft(element: HTMLElement) {
    // var left =document.querySelector(".cards");
    // left.scrollBy(-200,0)
    element.scrollBy({ left: -800, behavior: "smooth" });
  }
  scrollRight(element: HTMLElement) {
    // var right =document.querySelector(".cards");
    // right.scrollBy(200,0)
    element.scrollBy({ left: 800, behavior: "smooth" });
  }
}
