<div class="onlyForDesk">
  <owl-carousel-o
    [options]="HomeSliderConfig"
    class="home-slider"
    [ngClass]="class"
  >
    <ng-container *ngFor="let slider of sliders">
      <ng-template carouselSlide>
        <div
          class="home"
          [ngClass]="textClass"
          [ngStyle]="{ 'background-image': 'url(' + slider.image + ')' }"
        >
          <div class="container">
            <div class="row">
              <div class="col">
                <div class="slider-contain">
                  <div>
                    <h4>{{ slider.title }}</h4>
                    <h1>{{ slider.subTitle }}</h1>
                    <a
                      [routerLink]="['/shop/']"
                      class="btn btn-solid"
                      [ngClass]="buttonClass"
                    >
                      {{ buttonText }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
<div class="onlyForMobile">
  <owl-carousel-o
    [options]="SliderConfig"
    class="home-slider"
    [ngClass]="class"
  >
    <ng-container *ngFor="let slider of sliders">
      <ng-template carouselSlide>
        <div
          class="home"
          [ngClass]="textClass"
          [ngStyle]="{ 'background-image': 'url(' + slider.image + ')' }"
        >
          <div class="container">
            <div class="row">
              <div class="col">
                <div
                  class="slider-contain"
                  (click)="routeCollection(slider.subTitle)"
                >
                  <div>
                    <h4>{{ slider.title }}</h4>
                    <h1>{{ slider.subTitle }}</h1>
                    <a
                      [routerLink]="['/shop/']"
                      class="btn btn-solid"
                      [ngClass]="buttonClass"
                    >
                      {{ buttonText }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </owl-carousel-o>
</div>
<!-- [queryParams]="{ category: category}"  -->
<!-- [queryParams]="{ category: category}"  -->
