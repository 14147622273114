<section class="forMobile">
  <div class="container">
    <div mat-dialog-content>
      <h6 class="no-warp">Are You Sure Want to cancel?</h6>
    </div>
    <div
      mat-dialog-actions
      class="div"
      style="display: flex; justify-content: space-evenly"
    >
      <button class="btn btn-danger" (click)="onYesClick()">Yes</button>
      <button class="btn btn-secondary ml-3" (click)="onNoClick()">No</button>
    </div>
  </div>
</section>
