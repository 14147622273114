import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router, RoutesRecognized } from "@angular/router";
import { Input } from "hammerjs";
import { filter, pairwise } from "rxjs/operators";

@Component({
  selector: "app-return-warranty",
  templateUrl: "./return-warranty.component.html",
  styleUrls: ["./return-warranty.component.scss"],
})
export class ReturnWarrantyComponent implements OnInit, OnDestroy {
  previous_Url: any;
  forTerms = false;
  forShipping = false;
  forPrivacy = false;
  forRefund = false;
  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        // console.log("previous url", events[0].urlAfterRedirects);
        if (events[0].urlAfterRedirects !== "/return-warranty-policy") {
          localStorage.setItem(
            "previous-visit",
            JSON.stringify(events[0].urlAfterRedirects)
          );
          this.previous_Url = JSON.parse(
            localStorage.getItem("previous-visit")
          );
        }
        // console.log("current url", events[1].urlAfterRedirects);
      });

    this.route.queryParams.subscribe((res) => {
      if (res["policy"] === "terms") {
        this.forTerms = true;
      } else if (res["policy"] === "shipping") {
        this.forShipping = true;
      } else if (res["policy"] === "privacy") {
        this.forPrivacy = true;
      } else {
        this.forRefund = true;
      }
    });
  }
  ngOnDestroy(): void {
    localStorage.removeItem("previous-visit");
  }

  ngOnInit(): void {}
  backToPrevious() {
    if (localStorage.getItem("previous-visit")) {
      // console.log("not checkout");
      this.previous_Url = JSON.parse(localStorage.getItem("previous-visit"));
      const decodedUrl = decodeURIComponent(this.previous_Url);
      const [pathWithParams, queryParamsString] = decodedUrl.split("?");
      const pathSegments = pathWithParams.split("/").filter(Boolean); // Split by '/' and filter out empty segments
      const basePath =
        pathSegments.length > 0
          ? pathSegments.slice(0, pathSegments.length).join("/")
          : "";
      let queryParams = {};
      if (queryParamsString) {
        queryParamsString.split("&").forEach((param) => {
          const [key, value] = param.split("=");
          queryParams[decodeURIComponent(key)] = decodeURIComponent(value); // Decode keys and values
        });
      }
      this.router.navigate([basePath], { queryParams });
    } else {
      this.router.navigate(["/"]);
    }
  }
}
