import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { Observable } from "rxjs";
import { ProductService } from "../../../services/product.service";
import { Product } from "../../../classes/product";
import { LoginModelComponent } from "../login/login.component";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "app-cart-variation",
  templateUrl: "./cart-variation.component.html",
  styleUrls: ["./cart-variation.component.scss"],
})
export class CartVariationComponent implements OnInit, OnDestroy {
  @Input() direction: string = "right"; // Default Direction Right

  public products: any[] = [];

  constructor(
    public productService: ProductService,
    private dialog: MatDialog
  ) {
    this.productService.cartItems.subscribe(
      (response) => (this.products = response)
    );
  }

  ngOnInit(): void {
    this.productService.OpenCart = false;
  }

  closeCart() {
    this.productService.OpenCart = false;
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  openPopup() {
    this.dialog.open(LoginModelComponent, {
      width: "80%",
      height: "50%",
    });
  }

  ngOnDestroy(): void {
    this.closeCart();
  }
}
