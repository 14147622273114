import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { BarRatingModule } from "ngx-bar-rating";
import { LazyLoadImageModule, scrollPreset } from "ng-lazyload-image";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { TranslateModule } from "@ngx-translate/core";
import { SlickCarouselModule } from "ngx-slick-carousel";

// Header and Footer Components
import { HeaderOneComponent } from "./header/header-one/header-one.component";
import { FooterOneComponent } from "./footer/footer-one/footer-one.component";
import { HeaderTwoComponent } from "./header/header-two/header-two.component";
import { FooterTwoComponent } from "./footer/footer-two/footer-two.component";
import { HeaderThreeComponent } from "./header/header-three/header-three.component";
import { FooterThreeComponent } from "./footer/footer-three/footer-three.component";
import { HeaderFourComponent } from "./header/header-four/header-four.component";
import { FooterFourComponent } from "./footer/footer-four/footer-four.component";

// Components
import { LeftMenuComponent } from "./components/left-menu/left-menu.component";
import { MenuComponent } from "./components/menu/menu.component";
import { SettingsComponent } from "./components/settings/settings.component";
import { CategoriesComponent } from "./components/categories/categories.component";
import { BreadcrumbComponent } from "./components/breadcrumb/breadcrumb.component";
import { ProductBoxOneComponent } from "./components/product/product-box-one/product-box-one.component";
import { ProductBoxTwoComponent } from "./components/product/product-box-two/product-box-two.component";
import { ProductBoxThreeComponent } from "./components/product/product-box-three/product-box-three.component";
import { ProductBoxFourComponent } from "./components/product/product-box-four/product-box-four.component";
import { ProductBoxFiveComponent } from "./components/product/product-box-five/product-box-five.component";
import { ProductBoxVerticalComponent } from "./components/product/product-box-vertical/product-box-vertical.component";
import { ProductBoxVerticalSliderComponent } from "./components/product/product-box-vertical-slider/product-box-vertical-slider.component";

// Modals Components
import { NewsletterComponent } from "./components/modal/newsletter/newsletter.component";
import { QuickViewComponent } from "./components/modal/quick-view/quick-view.component";
import { CartModalComponent } from "./components/modal/cart-modal/cart-modal.component";
import { CartVariationComponent } from "./components/modal/cart-variation/cart-variation.component";
import { VideoModalComponent } from "./components/modal/video-modal/video-modal.component";
import { SizeModalComponent } from "./components/modal/size-modal/size-modal.component";
import { AgeVerificationComponent } from "./components/modal/age-verification/age-verification.component";
import { CirclecolorsComponent } from "../shop/collection/widgets/circlecolors/circlecolors.component";
// Skeleton Loader Components
import { SkeletonProductBoxComponent } from "./components/skeleton/skeleton-product-box/skeleton-product-box.component";

// Layout Box
import { LayoutBoxComponent } from "./components/layout-box/layout-box.component";

// Tap To Top
import { TapToTopComponent } from "./components/tap-to-top/tap-to-top.component";

// Pipes
import { DiscountPipe } from "./pipes/discount.pipe";
import { RegisterComponent } from "./components/modal/register/register.component";
import { LoginModelComponent } from "../shared/components/modal/login/login.component";
import { SearchbarComponent } from "./components/searchbar/searchbar.component";
import { CdkOverlayOrigin, OverlayModule } from "@angular/cdk/overlay";
import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatTabsModule } from "@angular/material/tabs";
import { SearchOverlayComponent } from "./components/search-overlay/search-overlay.component";
import { MatCardProductsComponent } from "./components/mat-card-products/mat-card-products.component";
import { MatListModule } from "@angular/material/list";
import { MatCardModule } from "@angular/material/card";
import { CollectionsFlowComponent } from "./components/collections-flow/collections-flow.component";
import { MatChipsModule } from "@angular/material/chips";
import { CardsFlowDeskComponent } from "./components/cards-flow-desk/cards-flow-desk.component";
import { MatCardDeskComponent } from "./components/mat-card-desk/mat-card-desk.component";
import { CollectionsFlowDeskComponent } from "./components/collections-flow-desk/collections-flow-desk.component";
import { SubHeaderComponent } from "./components/sub-header/sub-header.component";
import { MatMenuModule } from "@angular/material/menu";
import { MatButtonModule } from "@angular/material/button";
import { MatRippleModule } from "@angular/material/core";
import { CustomCarouselComponent } from "./components/custom-carousel/custom-carousel.component";
import { CancelOrderComponent } from "./components/modal/cancel-order/cancel-order.component";
import { MatTreeModule } from "@angular/material/tree";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { FullViewComponent } from "./components/modal/full-view/full-view.component";
import { LoaderCheckoutComponent } from "./components/modal/loader-checkout/loader-checkout.component";
import { MatExpansionModule } from "@angular/material/expansion";
import { PrivacyNoticeComponent } from "./components/privacy-notice/privacy-notice.component";
import { CookiesConsentComponent } from "./components/cookies-consent/cookies-consent.component";
import { CardCategoriesComponent } from "./components/card-categories/card-categories.component";
import { CustomSliderComponent } from "./components/custom-slider/custom-slider.component";
import { Carousel } from "bootstrap";
import { PinchZoomModule } from "ngx-pinch-zoom";
import { ZoomViewComponent } from "./components/zoom-view/zoom-view.component";
import { MobileSliderComponent } from "./components/mobile-slider/mobile-slider.component";
// import { HammerModule } from '@angular/platform-browser';
@NgModule({
  declarations: [
    HeaderOneComponent,
    FooterOneComponent,
    HeaderTwoComponent,
    FooterTwoComponent,
    HeaderThreeComponent,
    FooterThreeComponent,
    HeaderFourComponent,
    FooterFourComponent,
    LeftMenuComponent,
    MenuComponent,
    SettingsComponent,
    BreadcrumbComponent,
    CategoriesComponent,
    ProductBoxOneComponent,
    ProductBoxTwoComponent,
    ProductBoxThreeComponent,
    ProductBoxFourComponent,
    ProductBoxFiveComponent,
    ProductBoxVerticalComponent,
    ProductBoxVerticalSliderComponent,
    NewsletterComponent,
    QuickViewComponent,
    CartModalComponent,
    CartVariationComponent,
    VideoModalComponent,
    SizeModalComponent,
    AgeVerificationComponent,
    SkeletonProductBoxComponent,
    LayoutBoxComponent,
    TapToTopComponent,
    DiscountPipe,
    CirclecolorsComponent,
    RegisterComponent,
    LoginModelComponent,
    SearchbarComponent,
    SearchOverlayComponent,
    MatCardProductsComponent,
    CollectionsFlowComponent,
    CardsFlowDeskComponent,
    MatCardDeskComponent,
    CollectionsFlowDeskComponent,
    SubHeaderComponent,
    CustomCarouselComponent,
    CancelOrderComponent,
    FullViewComponent,
    LoaderCheckoutComponent,
    PrivacyNoticeComponent,
    CookiesConsentComponent,
    CardCategoriesComponent,
    CustomSliderComponent,
    ZoomViewComponent,
    MobileSliderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    SlickCarouselModule,
    OverlayModule,
    MatDividerModule,
    MatListModule,
    MatCardModule,
    MatIconModule,
    BarRatingModule,
    MatTabsModule,
    MatCardModule,
    MatChipsModule,
    MatTabsModule,
    MatMenuModule,
    MatButtonModule,
    MatRippleModule,
    MatTreeModule,
    MatCheckboxModule,
    MatExpansionModule,
    PinchZoomModule,
    // HammerModule,
    LazyLoadImageModule.forRoot({
      // preset: scrollPreset // <-- tell LazyLoadImage that you want to use scrollPreset
    }),
    NgxSkeletonLoaderModule,
    TranslateModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    SlickCarouselModule,

    BarRatingModule,
    LazyLoadImageModule,
    NgxSkeletonLoaderModule,
    TranslateModule,
    HeaderOneComponent,
    FooterOneComponent,
    HeaderTwoComponent,
    FooterTwoComponent,
    HeaderThreeComponent,
    FooterThreeComponent,
    HeaderFourComponent,
    FooterFourComponent,
    BreadcrumbComponent,
    CategoriesComponent,
    ProductBoxOneComponent,
    ProductBoxTwoComponent,
    ProductBoxThreeComponent,
    ProductBoxFourComponent,
    ProductBoxFiveComponent,
    ProductBoxVerticalComponent,
    ProductBoxVerticalSliderComponent,
    NewsletterComponent,
    QuickViewComponent,
    CartModalComponent,
    CartVariationComponent,
    VideoModalComponent,
    SizeModalComponent,
    AgeVerificationComponent,
    SkeletonProductBoxComponent,
    LayoutBoxComponent,
    TapToTopComponent,
    DiscountPipe,
    CirclecolorsComponent,
    LoginModelComponent,
    SettingsComponent,
    SearchbarComponent,
    SearchOverlayComponent,
    MatCardProductsComponent,
    CollectionsFlowComponent,
    MatCardDeskComponent,
    CardsFlowDeskComponent,
    CollectionsFlowDeskComponent,
    SubHeaderComponent,
    CustomCarouselComponent,
    CancelOrderComponent,
    FullViewComponent,
    LoaderCheckoutComponent,
    PrivacyNoticeComponent,
    CookiesConsentComponent,
    CardCategoriesComponent,
    CustomSliderComponent,
    ZoomViewComponent,
    MobileSliderComponent,
  ],
})
export class SharedModule {}
