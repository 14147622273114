import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-mobile-slider",
  templateUrl: "./mobile-slider.component.html",
  styleUrls: ["./mobile-slider.component.scss"],
})
export class MobileSliderComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
