import { Component, OnInit, Input } from "@angular/core";
import { LogoSlider } from "../../../shared/data/slider";
import { ProductService } from "src/app/shared/services/product.service";

@Component({
  selector: "app-logo",
  templateUrl: "./logo.component.html",
  styleUrls: ["./logo.component.scss"],
})
export class LogoComponent implements OnInit {
  @Input() logos: any;
  totalBrands = [];
  uniqueBrands = [];
  onlyHelmets: any[] = [];
  constructor(private productService: ProductService) {
    this.productService.getProductClassifications().subscribe((data: any) => {
      const onlyBody = JSON.parse(data.body);
      // console.log(onlyBody);

      this.onlyHelmets = onlyBody.filter((item) => item.type == "HELMET");
      // console.log(this.onlyHelmets);
      this.onlyHelmets.forEach((data) =>
        this.totalBrands.push(data.brand.trim())
      );
      this.uniqueBrands = [...new Set(this.totalBrands)];
      // console.log(this.uniqueBrands);
    });
  }
  LogoSliderConfig = {
    items: 8,
    navText: [
      '<i class="fa fa-chevron-left"></i>',
      '<i class="fa fa-chevron-right"></i>',
    ], // Custom navigation text/icons
    responsive: {
      0: {
        items: 1, // Number of items visible at 0px screen width and up
      },
      600: {
        items: 3, // Number of items visible at 600px screen width and up
      },
      900: {
        items: 4,
      },
    },

    loop: true,
    nav: true, // Optionally, if you want to show navigation arrows
    dots: false, // Set dots to false to hide them
  };
  ngOnInit(): void {}

  // public LogoSliderConfig: any = LogoSlider;
  // scrollLeft(element: HTMLElement){
  //   // var left =document.querySelector(".cards");
  //   // left.scrollBy(-200,0)
  //   element.scrollBy({ left: -200, behavior: 'smooth' });
  // }
  // scrollRight(element: HTMLElement){
  //   // var right =document.querySelector(".cards");
  //   // right.scrollBy(200,0)
  //   element.scrollBy({ left: 200, behavior: 'smooth' });

  // }
}
