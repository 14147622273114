import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router, RoutesRecognized } from "@angular/router";
import { ProductService } from "src/app/shared/services/product.service";
import { userService } from "src/app/shared/services/user.service";
import { HttpClient } from "@angular/common/http";
import { WindowRefService } from "src/app/window/window-ref.service";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { CancelOrderComponent } from "src/app/shared/components/modal/cancel-order/cancel-order.component";
import { environment } from "src/environments/environment";
import { OrderService } from "src/app/shared/services/order.service";
import { ToastrService } from "ngx-toastr";
import { Overlay } from "@angular/cdk/overlay";
import { filter, pairwise } from "rxjs/operators";
import e from "express";

declare var Razorpay: any;

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.component.html",
  styleUrls: ["./checkout.component.scss"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class CheckoutComponent implements OnInit {
  addressIdDetails = {};
  orderItems: any;
  userAddress: any[] = [];
  instruction: boolean = false;
  addNote: FormGroup;
  giftCode: boolean = false;
  buyProduct: any[] = [];
  currency: any;
  id: string[] = [];
  variantId: any;
  quantity: number[] = [];
  size: number[] = [];
  address: boolean = false;
  itemtotal1: number = 0;
  itemtotal2: number = 0;
  orderTotal: number = 0;
  qty1: number = 0;
  qty2: number = 0;
  overallQuantity: number = 0;
  total: number = 0;
  ActualTotal: number = 0;
  discount: number = 0;
  lessPrice: number = 0;
  pass_id: any;
  pass_quantity: any;
  showFullTitle: boolean = false;
  billingAddress: boolean = false;
  useraddedAddress: any;
  userOnly: any;
  billingOnly: any;
  deliverClicked: boolean = false;
  billingClicked: boolean = false;
  deliverChanged: boolean = false;
  billingChanged: boolean = false;
  tabRes: any;
  result: number[] = [];
  resultQuantity: number[] = [];
  selectedIndex: number;
  selectedAddressId: number | null = null;
  addingAddress: boolean = false;
  updatedId: any;
  routeKey: boolean = false;
  receivedChangingDeliverId: any = null;
  receivedChangingBillingId: any = null;
  loading: boolean = false;
  DeskUserAddress: any;
  DeskBillingAddress: any;
  order_number: any;
  quantityByCheck: number = 0;
  overlayOpen = false;
  checked = true;
  showMessageForAgree = false;
  messageForAgree: any;
  stopStepperMoves = false;
  forAddAddressButton = false;
  product: any[] = [];
  previous_Url: any;
  quantityFromParams: any;

  constructor(
    private userService: userService,
    private orderService: OrderService,
    private route: ActivatedRoute,
    private productService: ProductService,
    private dialog: MatDialog,
    private http: HttpClient,
    private winRef: WindowRefService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private overlay: Overlay
  ) {
    this.addNote = new FormGroup({
      notes: new FormControl(""),
    });

    this.route.queryParams.subscribe((res) => {
      // console.log(res);
      const decrypt = atob(res.products);
      const parseData = JSON.parse(decrypt);
      // console.log(parseData);
      this.quantityFromParams = parseData["qty"];
      let index: number;
      this.id = parseData.product_id ? parseData.product_id.split(",") : [];
      this.quantity = parseData.qty ? parseData.qty.split(",") : [];
      this.size = parseData.size ? parseData.size.split(",") : [];
      this.variantId = parseData.id ? parseData.id.split(",") : [];
      this.orderItems = this.variantId.map(
        (id: any, index: string | number) => {
          return {
            id: Number(id),
            quantity: Number(this.quantity[index]),
          };
        }
      );
      // this.quantityByCheck = this.quantity[index];
      // console.log(this.id);
      // console.log(this.orderItems);
    });
  }

  // @ViewChild('stepper') stepper: MatStepper;

  // Method to move to the first step directly

  ngOnInit(): void {
    this.address = false;
    this.billingAddress = false;
    // console.log(this.selectedIndex);
    this.userService.getNewAddress().subscribe((res) => {
      // console.log(res);
      this.userAddress = JSON.parse(res.body);
      // console.log(this.userAddress);
      this.shippingCharge(this.userAddress[0]);

      if (this.userAddress.length) {
        this.stopStepperMoves = true;
        // console.log(this.userAddress);
        const addAddress = this.userAddress.find(
          (add) => add.is_default === true
        );
        this.shippingCharge(addAddress);

        if (addAddress) {
          this.forAddAddressButton = true;
        }
        // console.log(this.forAddAddressButton, addAddress);

        const defaultAddressId = this.userAddress.find(
          (res) => res.is_default === true
        )?.address_id;
        // console.log(defaultAddressId);
        if (defaultAddressId) {
          this.addressIdDetails["shipping"] = defaultAddressId;
          this.addressIdDetails["billing"] = defaultAddressId;
          // console.log(this.addressIdDetails);
        }
      } else {
        // if (this.userAddress.length === 1) {
        //   this.addressIdDetails["shipping"] = this.userAddress[0].address_id;
        //   this.addressIdDetails["billing"] = this.userAddress[0].address_id;
        // }
        // console.log(this.userAddress);
      }

      if (this.id) {
        this.id.forEach((data) => {
          const encrypt = btoa(data);
          this.productService.updateSlug(encrypt);
        });
        this.productService.ProBySlug$.subscribe((res) => {
          // console.log(res);
          const parsedBody = JSON.parse(res.body);
          this.product.push(parsedBody);
          const uniqueProducts = Array.from(
            new Map(
              this.product.map((item) => [item.product_id, item])
            ).values()
          );
          // console.log("Unique Products:", uniqueProducts);
          this.getProducts(uniqueProducts);
        });
      }
      // this.updatedId= this.userAddress.map(address=>address.address_id)
      // this.placeAndOrderToPay()
      // console.log(this.userAddress);
      // console.log(this.updatedId)
    });

    this.getYourAddress();
    // this.shippingCharge();
    // this.receiveData(this.updatedId)
    // this.goToFirstStep()
  }
  shippingAmount = 0;
  shippingState: string = "";
  private shippingCharge(address: any) {
    const interval = setInterval(() => {
      if (address) {
        this.shippingState = address.state;
        // console.log("pincode", this.shippingState);
        if (this.shippingState) {
          clearInterval(interval);
          this.calculateShippingFee(this.orderTotal);
        }
      }
    }, 1000);
  }
  shippedTotal = 0;
  calculateShippingFee(total: number, qtyChange?: boolean) {
    if (total <= 2000) {
      if (this.shippingState.toLowerCase().replace(/\s+/g, '') === "tamilnadu") {
        this.shippingAmount = 100;
        this.shippedTotal = total + 100;
      } else {
        this.shippingAmount = 200;
        this.shippedTotal = total + 200;
      }
      if (!qtyChange) {
        this.calculation();
      }
    } else {
      this.shippingAmount = 0;
      this.shippedTotal = 0;
      if (!qtyChange) {
        this.calculation();
      }
    }
  }
  calculation() {
    const amount: any[] = [];
    const discount: any[] = [];
    for (const pro of this.buyProduct) {
      if (pro.discount) {
        amount.push(
          (pro.price - (pro.price * pro.discount) / 100) * pro.quantity
        );
        discount.push(((pro.price * pro.discount) / 100) * pro.quantity);
      } else {
        amount.push(pro.price * pro.quantity);
      }
    }
    // console.log(amount);
    const total = amount.reduce((acc, num) => acc + num, 0);
    const disAmount = discount.reduce((acc, num) => acc + num, 0);
    this.orderTotal = total;
    this.lessPrice = disAmount;
    this.ActualTotal = total + disAmount;
    // console.log(total);
    // console.log(disAmount);
  }
  getProducts(product: any) {
    // this.productService.productsFromSubject.subscribe((res) => {
    this.buyProduct = [];

    this.id.forEach((item, index) => {
      const qty = this.quantity[index];
      const size = this.size[index];
      // console.log(item);
      const filtered = product.filter((data) => item === data.product_id);

      // if (filtered.length > 0) {
      const updateFiltered = filtered.map((res) => ({
        ...res,
        quantity: qty,
        size: size,
      }));
      // console.log(updateFiltered);
      // }
      this.buyProduct.push(...updateFiltered);
    });
    // console.log(this.buyProduct);
    // this.buyProduct.forEach((product) => {
    //   if (product.discount) {
    //     const productTotal = product.price * product.quantity;
    //     const discountedPrice =
    //       product.price - (product.price * product.discount) / 100;
    //     this.itemtotal1 = discountedPrice * product.quantity;
    //     // console.log(this.itemtotal1, productTotal);
    //     // console.log(this.qty1, this.itemtotal1);
    //     this.discount = product.discount;
    //   } else {
    //     const productTotal = product.price * product.quantity;
    //     // console.log(productTotal);
    //     this.itemtotal2 = productTotal;
    //   }
    // });
    // for (let i = 0; i < this.result.length; i++) {
    //   this.orderTotal += this.result[i];
    // }
    // for (let i = 0; i < this.resultQuantity.length; i++) {
    //   this.overallQuantity += this.resultQuantity[i];
    // }
    // this.ActualTotal = this.total + this.orderTotal-this.itemtotal1
    // console.log(this.itemtotal2, this.itemtotal1);
    this.ActualTotal = this.total;
    this.overallQuantity = this.quantityFromParams
      .split(",")
      .map(Number)
      .reduce((acc, num) => acc + num, 0);
    this.orderTotal = this.itemtotal1 + this.itemtotal2;
    // console.log(this.overallQuantity);
    if (this.discount) {
      this.lessPrice = this.total - this.itemtotal1;
    }
    // console.log(
    //   this.ActualTotal,
    //   this.orderTotal,
    //   this.overallQuantity,
    //   this.lessPrice
    // );
    this.currency = this.productService.Currency;
    // });
  }
  // next() {
  //   this.stepper.next();
  // }
  // goToFirstStep() {
  //   this.stepper.selectedIndex = 1;
  // }

  clickChange() {
    this.address = !this.address;
    this.billingAddress = false;
    this.routeKey = true;
    this.pass_id = this.id;
    this.pass_quantity = this.quantity;
    // console.log(this.pass_id , this.pass_quantity);
  }
  clickDeskBillChange() {
    this.billingAddress = !this.billingAddress;
    this.address = false;
    this.routeKey = true;
    this.pass_id = this.id;
    this.pass_quantity = this.quantity;
    // console.log(this.pass_id , this.pass_quantity);
  }
  topLoader = false;
  onOrderSuccess() {
    let DeliveryAddress: any[] = this.userAddress;
    // this.productService.showLoaderSubject.subscribe((data) => {
    //   if (data == true || data == false) {
    //     this.topLoader = data;
    //     console.log("Checkout-to-success", this.topLoader);
    //   }
    // });
    // if (this.topLoader == true) {
    this.loading = true;
    const action = "payment";
    this.sendUserAction(this.order_number, action);
    this.router.navigate(["/order/success"], {
      queryParams: { ordNum: this.order_number, from: "checkout" },
    });
    this.loading = false;

    this.cdr.detectChanges();

    const checkoutDetails = {
      deliveryAddress: DeliveryAddress.find(
        (res) => res.address_id === this.addressIdDetails["shipping"]
      ),
      BillingAddress: DeliveryAddress.find(
        (res) => res.address_id === this.addressIdDetails["billing"]
      ),
      payMethod: "",
      products: this.buyProduct,
      totalCost: {
        discount: this.discount,
        originalPrice: this.ActualTotal,
        quantity: this.overallQuantity,
        discountPrice: this.lessPrice,
      },
    };
    const Details = btoa(
      unescape(encodeURIComponent(JSON.stringify(checkoutDetails)))
    );
    // openPopup() {
    //   const dialog = this.dialog.open(ManageAddressComponent, {
    //     width: '100%',
    //     height: '100%'
    //   })
    // }
  }

  sendUserAction(ord_num, action) {
    const session_id = JSON.parse(localStorage.getItem("anonymous-user-id"));
    const user_agent = JSON.parse(localStorage.getItem("User Agent"));
    const user_id = JSON.parse(localStorage.getItem("username")) || null;
    const IP = JSON.parse(localStorage.getItem("IP")) || null;

    const data = {
      user_id: user_id,
      session_id: session_id,
      ip_address: IP,
      user_agent: user_agent,
      action_type: action,
      product_id: null,
      search_term: null,
      search_type: null,
      order_id: ord_num,
      details: {
        filters: [],
        sort_order: "asc",
      },
    };
    // console.log(data);
    this.userService.addAction(data).subscribe((res) => console.log(res));
  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  increaseQty(index: number) {
    let stock;
    if (this.buyProduct[index].variants.length === 1) {
      stock = this.buyProduct[index].variants[0].stock;
    } else {
      const variantsForQuantity = this.buyProduct[index].variants.filter(
        (v) => v.size === this.buyProduct[index].size
      );
      // console.log(variantsForQuantity);
      stock = variantsForQuantity[0].stock;
    }
    // console.log(this.buyProduct[index]);
    // console.log(stock);
    if (index >= 0 && index < this.quantity.length) {
      this.quantityByCheck = this.quantity[index];
      if (stock > this.quantityByCheck) {
        this.quantityByCheck++;
        this.overallQuantity++;
        let discount = 0;
        if (this.buyProduct[index].discount) {
          this.orderTotal +=
            this.buyProduct[index].price -
            (this.buyProduct[index].price * this.buyProduct[index].discount) /
              100;
          this.lessPrice +=
            (this.buyProduct[index].price * this.buyProduct[index].discount) /
            100;
          // console.log(
          //   this.buyProduct[index].price -
          //     (this.buyProduct[index].price * this.buyProduct[index].discount) /
          //       100
          // );
          this.calculateShippingFee(this.orderTotal, true);
        } else {
          this.orderTotal += this.buyProduct[index].price;
          // console.log(this.orderTotal);

          this.calculateShippingFee(this.orderTotal, true);
        }
        this.ActualTotal += this.buyProduct[index].price;
      } else {
        this.quantityByCheck = stock;
        this.showProductsToast(this.quantityByCheck);
      }
      this.quantity[index] = this.quantityByCheck;
      // console.log(this.quantity);
      // console.log(this.quantityByCheck);

      this.orderItems = this.variantId.map(
        (id: any, index: string | number) => {
          return {
            id: Number(id),
            quantity: Number(this.quantity[index]),
          };
        }
      );
    }
  }

  decreaseQty(index: number) {
    let stock;
    if (this.buyProduct[index].variants.length === 1) {
      stock = this.buyProduct[index].variants[0].stock;
    } else {
      const variantsForQuantity = this.buyProduct[index].variants.filter(
        (v) => v.size === this.buyProduct[index].size
      );
      // console.log(variantsForQuantity);
      stock = variantsForQuantity[0].stock;
    }
    // console.log(this.buyProduct[index]);
    // console.log(stock);
    if (index >= 0 && index < this.quantity.length) {
      this.quantityByCheck = this.quantity[index];
      if (this.quantityByCheck > 1) {
        this.quantityByCheck--;
        this.overallQuantity--;
        if (this.buyProduct[index].discount) {
          this.orderTotal -=
            this.buyProduct[index].price -
            (this.buyProduct[index].price * this.buyProduct[index].discount) /
              100;

          this.lessPrice -=
            (this.buyProduct[index].price * this.buyProduct[index].discount) /
            100;
          // console.log(
          //   this.buyProduct[index].price -
          //     (this.buyProduct[index].price * this.buyProduct[index].discount) /
          //       100
          // );
          this.calculateShippingFee(this.orderTotal, true);
        } else {
          this.orderTotal -= this.buyProduct[index].price;
          this.calculateShippingFee(this.orderTotal, true);
        }
        this.ActualTotal -= this.buyProduct[index].price;

        // this.overallQuantity = this.overallQuantity - this.quantityByCheck;
      } else {
        this.quantityByCheck = 1;
      }
      this.quantity[index] = this.quantityByCheck;

      // console.log(this.quantity);
      // console.log(this.quantityByCheck);

      this.orderItems = this.variantId.map(
        (id: any, index: string | number) => {
          return {
            id: Number(id),
            quantity: Number(this.quantity[index]),
          };
        }
      );
      // console.log(this.orderItems);
    }
  }

  placeAndOrderandPay() {
    if (this.checked) {
      this.loading = true;
      const createOrderData = {
        shipping_address_id: this.addressIdDetails["shipping"],
        billing_address_id: this.addressIdDetails["billing"],
        order_items: this.orderItems,
      };
      // console.log(createOrderData);
      this.orderService.createOrder(createOrderData).subscribe((res) => {
        this.order_number = res.order_receipt;
        // if (res) {
        //   this.loading = false;
        // }
        if (res["statusCode"] === 200) {
          this.userService.getUserData().subscribe((user) => {
            // console.log(user);

            const transactionAmount = res["transaction_amount"] * 100;
            const rzpOrderId = res["razorpay_order_id"];
            const receipt = res["order_receipt"];
            const name = user["full_name"];
            const email = user["email"];
            const phone = user["phone"];

            this.payViaRazorPay(
              transactionAmount,
              rzpOrderId,
              receipt,
              name,
              email,
              phone
            );
          });
        }
      });
    } else {
      this.showMessageForAgree = true;
      this.messageForAgree =
        "* Please read and agree the terms and conditions below";
    }
  }

  payViaRazorPay(
    transactionAmount: any,
    rzpOrderId: any,
    receipt: any,
    name: any,
    email: any,
    phone: any
  ) {
    const options = {
      key: environment.rzp_key,
      amount: transactionAmount,
      currency: "INR",
      name: "SkullCrown",
      description: "Test Transaction",
      image: "../../../../assets/images/logos/sc-logo-dark.svg",
      order_id: rzpOrderId,
      handler: (response, err) => {
        this.cdr.detectChanges();
        this.loading = true;
        // console.log(response, err, this.loading);
        this.orderService.verifyPayment(response).subscribe((res) => {
          // console.log(res);
          if (res["statusCode"] === 200) {
            this.loading = false;
            this.onOrderSuccess();
          }
        });
      },
      prefill: {
        name: name,
        email: email,
        contact: phone,
      },
      notes: {
        receipt: receipt,
      },
      theme: {
        color: "#3399cc",
      },
      modal: {
        escape: false,
        ondismiss: function () {
          // console.log("Payment popup closed");
          window.location.reload();
        },
      },
    };
    const rzp1 = new this.winRef.nativeWindow.Razorpay(options);
    rzp1.open();
  }

  getYourAddress() {
    // this.loader = true
    this.userService.getNewAddress().subscribe((res) => {
      // this.loader = false

      this.useraddedAddress = JSON.parse(res.body);
      // console.log(this.useraddedAddress);
      if (this.updatedId) {
        this.forChoosing(this.updatedId);
        this.forNext();
      } else {
        this.forStepper();
      }
    });
  }
  recieveDeliverID(event: any) {
    // console.log("id", event);
    this.receivedChangingDeliverId = event;
    if (this.receivedChangingDeliverId) {
      const foundAddress = this.useraddedAddress.find(
        (address) => address.address_id === this.receivedChangingDeliverId
      );
      // console.log("deliver", foundAddress);
      this.shippingCharge(foundAddress);
      this.DeskUserAddress = foundAddress;
      this.addressIdDetails["shipping"] = foundAddress.address_id;
      this.address = false;
    }
  }

  recieveBillID(event: any) {
    // console.log("bill id", event);
    this.receivedChangingBillingId = event;
    if (this.receivedChangingBillingId) {
      const foundAddress = this.useraddedAddress.find(
        (address) => address.address_id === this.receivedChangingBillingId
      );
      // console.log("billing", foundAddress);
      this.DeskBillingAddress = foundAddress;
      this.addressIdDetails["billing"] = foundAddress.address_id;
      this.billingAddress = false;
    }
  }
  forEditandAdd() {
    this.addingAddress = !this.addingAddress;
  }
  // forCheck(index:any){
  //   console.log('yes',index)
  //   if( this.deliverChanged == true && index == 1 ||  this.deliverChanged == true && index == 2 ){
  //     this.deliverChanged = false
  //     this.forNext()
  //     console.log('you clicked change in del')
  //   }
  //  else if(this.billingChanged == true && index == 1 ){
  //   console.log('you clicked change in bill')
  //   this.billingChanged = false
  //   this.forNext()
  //       }
  // }
  delChanged() {
    this.selectedIndex = 1;
    this.cdr.detectChanges();
    this.billingChanged = false;
    this.addingAddress = false;
    this.deliverChanged = true;
    this.selectedAddressId = null;
    this.selectedIndex = 0;

    this.cdr.detectChanges();
    // console.log(this.selectedIndex, this.billingChanged, this.deliverChanged);
  }
  continue() {
    this.cdr.detectChanges();
    this.selectedIndex = 2;
    // console.log(this.selectedIndex);
  }
  previous() {
    this.cdr.detectChanges();

    this.selectedIndex = 1;
  }
  billChanged() {
    this.selectedIndex = 2;

    this.cdr.detectChanges();

    this.deliverChanged = false;
    this.addingAddress = false;
    this.billingChanged = true;
    this.selectedIndex = 0;
    this.selectedAddressId = null;
    this.cdr.detectChanges();

    // console.log(this.selectedIndex, this.billingChanged, this.deliverChanged);
  }

  forChoosing(id: any) {
    // console.log(id);
    this.stopStepperMoves = true;

    const foundAddress = this.useraddedAddress.find(
      (address) => address.address_id === id
    );
    if (this.deliverChanged || this.billingChanged) {
      if (this.deliverChanged == true) {
        this.userOnly = foundAddress;
        this.shippingCharge(foundAddress);
        // console.log("User address selected:", this.userOnly);
        this.deliverClicked = true;
        this.selectedAddressId = id;
        this.addressIdDetails["shipping"] = id;
      } else if (this.billingChanged == true) {
        this.billingOnly = foundAddress;
        // console.log("Billing address selected:", this.billingOnly);
        this.billingClicked = true;
        this.selectedAddressId = id;
        this.addressIdDetails["billing"] = id;
      }
    } else {
      if (this.useraddedAddress.length == 1) {
        this.selectedAddressId = id;
        // console.log(foundAddress);
        // console.log(this.selectedAddressId);
        this.userOnly = foundAddress;
        this.billingOnly = foundAddress;
        this.shippingCharge(foundAddress);
        this.addressIdDetails["shipping"] = id;
        this.addressIdDetails["billing"] = id;
        this.editDefault(this.selectedAddressId);
        // this.forNext();
      }
    }
  }
  editDefault(id: number) {
    const data = {
      address_id: id,
      is_default: true,
    };
    // console.log(data);

    this.userService.editDefault(data).subscribe((res) => {
      // console.log(res);
      // this.getYourAddress();
    });
  }

  forStepper() {
    if (this.useraddedAddress.length) {
      this.selectedIndex = 1;
      this.cdr.detectChanges();
      // console.log(this.selectedIndex);
    } else {
      this.selectedIndex = 0;
      this.cdr.detectChanges();
      // console.log(this.selectedIndex);
    }
  }

  openPopup() {
    const dialog = this.dialog.open(CancelOrderComponent, {
      // width:'32%',
      // height:'25%',
      disableClose: true,
    });
    return dialog.afterClosed();
  }

  forNext() {
    if (this.deliverChanged || this.billingChanged) {
      if (this.deliverChanged == true) {
        this.selectedIndex = 1;
        // console.log("next");
        this.cdr.detectChanges();
      } else if (this.billingChanged == true) {
        this.selectedIndex = 2;
        // console.log("last");
        this.cdr.detectChanges();
      }
    } else {
      this.selectedIndex = 1;
    }
  }
  receiveData(data) {
    // if (!this.deliverChanged || this.billingChanged) {
    // console.log(data);
    this.getYourAddress();
    this.addingAddress = false;
    this.updatedId = data;
    // }
    // console.log(data);
  }
  showProductsToast(product: any) {
    this.toastr.success(
      `There are exactly ${product} products available.`,
      "Alert",
      {
        // closeButton: true,
        // progressBar: true,
        // timeOut: 5000,
        // extendedTimeOut: 2000,
        toastClass: "toast-error", // Apply custom CSS class if needed
      }
    );
  }

  overlayClose() {
    this.overlayOpen = false;
    this.conditionOverlayOpen = false;

    // document.body.classList.remove('overlay-open');
  }
  privacynotice() {
    this.conditionOverlayOpen = false;

    this.overlayOpen = true;
    this.checked = true;
  }
  checkBox(checks: boolean) {
    // console.log(checks);
    if (checks == true) {
      this.showMessageForAgree = false;
    }
  }

  conditionOverlayOpen = false;

  forConditionOfUse() {
    this.overlayOpen = false;
    this.checked = true;

    this.conditionOverlayOpen = true;
  }
  // recieveForRefresh(event: boolean) {
  //   console.log("refresh", event);
  // }

  // public updateCartQuantity(
  //   product: any,
  //   quantity: number,
  //   api_call: boolean = false
  // ): Product {
  //   return state.cart.find((items, index) => {
  //     // console.log(items,index)
  //     if (
  //       items.product_id == product.product_id &&
  //       items.variants[0].sku == product.variants[0].sku
  //     ) {
  //       const qty = state.cart[index].quantity + quantity;
  //       const stock =
  //       if (qty > 0 && stock) {
  //         // Check if newQty exceeds stock limit
  //         const maxAllowedQty = product.variants[0].stock;

  //         if (qty <= maxAllowedQty) {
  //           state.cart[index].quantity = qty;
  //         } else {
  //           state.cart[index].quantity = maxAllowedQty; // Set to maximum allowed quantity
  //           // this.toastrService.info("Your Search Results")
  //           // this.showProductsToast(maxAllowedQty);
  //         }
  //       } else if (qty <= 0) {
  //         state.cart[index].quantity = 1; // Ensure quantity does not go below 0
  //       }

  //     }})
}
