import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { from, Observable, of } from "rxjs";
import { tap, switchMap } from "rxjs/operators";
import { Auth } from "aws-amplify";
import { Router } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { AngularFireAuth } from "@angular/fire/compat/auth";

@Injectable()
export class HttpsInterceptor implements HttpInterceptor {
  private cache = new Map<string, { expiry: number; response: any }>();

  constructor(
    private authService: AuthService,
    private router: Router,
    private afAuth: AngularFireAuth
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const url: string = request.url;

    // Define caching routes
    const cacheableRoutes = ["/products/classification"];
    const needsAuthRoutes = ["/user"];

    // Check if request should be cached
    if (cacheableRoutes.some((route) => url.includes(route))) {
      const cachedResponse = this.cache.get(url);
      if (cachedResponse && cachedResponse.expiry > Date.now()) {
        return of(
          new HttpResponse({ status: 200, body: cachedResponse.response })
        );
      }
    }

    // Handle routes that need authorization headers
    if (needsAuthRoutes.some((route) => url.includes(route))) {
      return from(
        this.authService.getUserAccessToken() // Get current Firebase user
      ).pipe(
        switchMap((user: any) => {
          if (user) {
            // return from(user.getIdToken()).pipe(
            //   switchMap((token) => {
            // Add authorization token and content-type
            let moddedRequestHeader = request.headers.set(
              "Authorization",
              `Bearer ${user}`
            );
            moddedRequestHeader = moddedRequestHeader.set(
              "Content-Type",
              "application/json"
            );

            const clonedRequest = request.clone({
              headers: moddedRequestHeader,
            });

            return next.handle(clonedRequest).pipe(
              tap((event) => {
                if (event instanceof HttpResponse) {
                  // Cache response with expiry time
                  if (cacheableRoutes.some((route) => url.includes(route))) {
                    this.cache.set(url, {
                      expiry: Date.now() + 300000, // cache for 5 minutes
                      response: event.body,
                    });
                  }
                }
              })
              //   );
              // })
            );
          } else {
            // Handle case where user is not authenticated (optional: navigate to login, etc.)
            console.error("User is not authenticated");
            return next.handle(request);
          }
        })
      );
    } else {
      // Handle non-auth routes
      return next.handle(request).pipe(
        tap((event) => {
          if (event instanceof HttpResponse) {
            // Cache response with expiry time
            if (cacheableRoutes.some((route) => url.includes(route))) {
              this.cache.set(url, {
                expiry: Date.now() + 300000, // cache for 5 minutes
                response: event.body,
              });
            }
          }
        })
      );
    }
  }
}
