import { Component, OnInit, Input } from "@angular/core";
import { HomeSlider } from "../../../shared/data/slider";
import { Router } from "@angular/router";

@Component({
  selector: "app-slider",
  templateUrl: "./slider.component.html",
  styleUrls: ["./slider.component.scss"],
})
export class SliderComponent implements OnInit {
  @Input() sliders: any[];
  @Input() class: string;
  @Input() textClass: string;
  @Input() category: string;
  @Input() buttonText: string;
  @Input() buttonClass: string;

  constructor(private router: Router) {}
  public HomeSliderConfig: any = HomeSlider;

  SliderConfig = {
    items: 1,
    responsive: {
      0: {
        items: 1, // Number of items shown at mobile screen sizes
      },
      768: {
        items: 1, // Number of items shown at tablet screen sizes
      },
      1024: {
        items: 1, // Number of items shown at desktop screen sizes
      },
    },
    nav: false, // Optionally, if you want to show navigation arrows
    dots: true, // Set dots to false to hide them
  };

  slickConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  ngOnInit(): void {}

  routeCollection(item: string) {
    this.router.navigate(["/shop/"], {
      queryParams: {
        collection: item,
      },
    });
  }
}
