import { Component, Input, OnInit } from "@angular/core";
// import { Product } from '../../classes/product';
import { ProductService } from "../../services/product.service";
import { shopSideBarHelmet } from "../../constants/shop.contants";

@Component({
  selector: "app-categories",
  templateUrl: "./categories.component.html",
  styleUrls: ["./categories.component.scss"],
})
export class CategoriesComponent implements OnInit {
  public products = [];
  public collapse: boolean = true;

  constructor(public productService: ProductService) {
    // this.productService.getProducts().subscribe((product:any) => this.products = product );
    // this.products = shopSideBarHelmet.CATEGORIES
  }
  @Input() category: boolean;
  ngOnInit(): void {}

  get filterbyCategory() {
    // console.log(this.category);

    if (this.category === true) {
      this.products = shopSideBarHelmet.CATEGORIES;
      const category = [...new Set(this.products.map((product) => product))];
      return category;
    } else {
      this.products = shopSideBarHelmet.CATEGORIES_GEARS;
      const category = [...new Set(this.products.map((product) => product))];
      // console.log(category)
      return category;
    }
  }
}
