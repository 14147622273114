import {
  Component,
  OnInit,
  Injectable,
  PLATFORM_ID,
  Inject,
} from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";
import { LoginModelComponent } from "../modal/login/login.component";
import { MatDialog } from "@angular/material/dialog";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-settings",
  templateUrl: "./settings.component.html",
  styleUrls: ["./settings.component.scss"],
})
export class SettingsComponent implements OnInit {
  public products: any[] = [];
  public search: boolean = false;

  popUp: boolean = false;
  title_url: string;
  authenticated: any;

  public languages = [
    {
      name: "English",
      code: "en",
    },
    {
      name: "French",
      code: "fr",
    },
  ];

  public currencies = [
    {
      name: "Euro",
      currency: "EUR",
      price: 0.9, // price of euro
    },
    {
      name: "Rupees",
      currency: "INR",
      price: 70.93, // price of inr
    },
    {
      name: "Pound",
      currency: "GBP",
      price: 0.78, // price of euro
    },
    {
      name: "Dollar",
      currency: "USD",
      price: 1, // price of usd
    },
  ];
  isFocus: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private translate: TranslateService,
    public productService: ProductService,
    private dialog: MatDialog,
    private authService: AuthService,
    private router: Router
  ) {
    this.productService.cartItems.subscribe((response) => {
      this.products = response;
      this.products.forEach((item) => {
        item.title = item.title.trim().replace(/\s/g, "-");
      });
    });
  }

  menu: boolean = false;

  ngOnInit(): void {
    this.authService.authFUser.subscribe((res) => (this.authenticated = res));
  }

  // forHideCart(){
  // this.hideCart=!this.hideCart

  // }

  searchToggle() {
    this.search = !this.search;
  }

  changeLanguage(code) {
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code);
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency;
  }

  openPopup() {
    this.popUp = true;
    if (this.popUp === true) {
      this.dialog.open(LoginModelComponent, {
        width: "80%",
        height: "50%",
      });
    }
  }

  onFocus() {
    this.isFocus = true;
  }

  checkUser() {
    this.products.filter((res) => {
      const action = "checkout";
      this.productService.sendUserAction(res.product_id, action);
    });

    const queryParams = {
      product_id: this.products.map((res) => res.product_id).join(","),
      qty: this.products.map((res) => res.quantity).join(","),
      size: this.products.map((res) => res.variants[0].size).join(","),
      id: this.products.map((res) => res.variants[0].id).join(","),
    };
    const encrypt = btoa(JSON.stringify(queryParams));
    // console.log(this.authenticated);

    if (this.authenticated) {
      // console.log(this.products);
      this.router.navigate(["/checkout"], {
        queryParams: { products: encrypt },
      });
    } else {
      localStorage.setItem("item-for-checkout", JSON.stringify(encrypt));
      localStorage.setItem("tryingCheckOut", JSON.stringify("true"));
      this.router.navigate(["/login"]);
    }
  }
}
