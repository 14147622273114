import { isPlatformBrowser } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PinchZoomComponent } from "ngx-pinch-zoom/lib/pinch-zoom.component";
import { Product } from "src/app/shared/classes/product";
import { ProductService } from "src/app/shared/services/product.service";

@Component({
  selector: "app-full-view",
  templateUrl: "./full-view.component.html",
  styleUrls: ["./full-view.component.scss"],
})
export class FullViewComponent implements OnInit, OnDestroy {
  @Input() product: string;
  @Input() Images: any;
  allImage: any[] = [];
  @ViewChild("fullview", { static: false }) FullView: TemplateRef<any>;
  @ViewChild("pinch") pinchZoom: PinchZoomComponent;
  public closeResult: string;
  // public ImageSrc: string;
  public counter: number = 1;
  public modalOpen: boolean = false;
  // activeImageShown: boolean = true;
  // allImagesShown: boolean = false;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private modalService: NgbModal,
    public productService: ProductService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalService
        .open(this.FullView, {
          size: "lg",
          ariaLabelledBy: "modal-basic-title",
          centered: true,
          windowClass: "FullView",
        })
        .result.then(
          (result) => {
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }

    // console.log("opens");
    // console.log(this.product);
    // console.log(this.Images);
    this.allImage = this.Images;
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   if (changes.product || changes.Images) {
  //     this.updateModalContent();
  //     this.cdr.detectChanges();
  //   }
  // }                                       //for identify which one shows old  == imagesrc
  // updateModalContent() {
  //   console.log(
  //     "Updated modal content with:",
  //     this.product,
  //     this.Images,
  //     this.ImageSrc
  //   );
  // }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

  rightClick() {
    let currentIndex;
    currentIndex = this.allImage.findIndex((image) => image === this.product);
    // console.log(currentIndex);
    if (currentIndex < this.allImage.length - 1) {
      this.product = this.allImage[currentIndex + 1];
    } else {
      this.product = this.allImage[0];
    }
  }
  leftClick() {
    let currentIndex;
    currentIndex = this.allImage.findIndex((image) => image === this.product);
    // console.log(currentIndex);
    if (currentIndex > 0) {
      this.product = this.allImage[currentIndex - 1];
    } else {
      this.product = this.allImage[this.allImage.length - 1];
    }
  }
}
