<owl-carousel-o [options]="LogoSliderConfig">
  <ng-container *ngFor="let brand of uniqueBrands; let i = index">
    <ng-template carouselSlide>
      <div>
        <div class="logo-block">
          <a [routerLink]="['/shop/']" [queryParams]="{ brand }">
            <img [src]="logos[brand]" alt="logo" width="100px" />
          </a>
        </div>
      </div>
    </ng-template>
  </ng-container>
</owl-carousel-o>
