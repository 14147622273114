<div class="headerSub" *ngIf="brands">
  <button mat-button (click)="leftMenuToggle()">
    <i class="fa fa-bars sidebar-bar text-white"></i>
  </button>
  <button *ngFor="let item of sortedBrands" mat-button (click)="Onbrands(item)">
    {{ item }}
  </button>

  <!-- <button mat-button (click)="brands('STUDDS')">STUDDS</button>
<button mat-button (click)="brands('RIDEX')">RIDEX</button>
<button mat-button (click)="brands('LS2')">LS2</button>
<button mat-button (click)="brands('SMK')">SMK</button>
<button mat-button (click)="brands('AXXIS')">AXXIS</button> -->
  <!-- <button mat-button (click)="brands('LS')">LS2</button> -->
  <!-- <button mat-button (click)="brands('VEGA')">VEGA</button>
<button mat-button (click)="brands('STEELBIRD')">STEELBIRD</button>
<button mat-button (click)="brands('AXOR')">MEN'S COLLECTION</button>
<button mat-button>WEMEN'S COLLECTION</button> -->
</div>
