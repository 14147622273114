import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-cancel-order",
  templateUrl: "./cancel-order.component.html",
  styleUrls: ["./cancel-order.component.scss"],
})
export class CancelOrderComponent implements OnInit {
  @ViewChild("cancelOrderModal", { static: false })
  cancelOrderModal: TemplateRef<any>;
  public closeResult: string;
  public modalOpen: boolean = false;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private dialogRef: MatDialogRef<CancelOrderComponent>,
    private router: Router
  ) {}
  previous_Url: any;
  ngOnInit(): void {}
  onYesClick() {
    this.dialogRef.close(true);
    this.closeCheckOut();
  }

  onNoClick() {
    this.dialogRef.close(false);
  }
  closeCheckOut() {
    if (localStorage.getItem("previous-page")) {
      // console.log("not checkout");
      this.previous_Url = JSON.parse(localStorage.getItem("previous-page"));
      const decodedUrl = decodeURIComponent(this.previous_Url);
      // Split the URL into the base path and query parameters
      const [pathWithParams, queryParamsString] = decodedUrl.split("?");
      const pathSegments = pathWithParams.split("/").filter(Boolean); // Split by '/' and filter out empty segments
      const basePath =
        pathSegments.length > 0
          ? pathSegments.slice(0, pathSegments.length).join("/")
          : "";
      let queryParams = {};
      if (queryParamsString) {
        queryParamsString.split("&").forEach((param) => {
          const [key, value] = param.split("=");
          queryParams[decodeURIComponent(key)] = decodeURIComponent(value); // Decode keys and values
        });
      }
      this.router.navigate([basePath], { queryParams });
    } else {
      this.router.navigate(["/"]);
    }
  }
  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalService
        .open(this.cancelOrderModal, {
          size: "lg",
          ariaLabelledBy: "cancelOrderModal",
          centered: true,
          windowClass: "cancelOrderModal",
        })
        .result.then(
          (result) => {
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
}
