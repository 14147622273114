<div
  class="overlay-container mat-elevation-z2"
  [class.overLayTop]="searchTop"
  *ngIf="
    filteredTitles?.length > 0 ||
    filteredCollections?.length > 0 ||
    recentSearch?.length > 0
  "
>
  <mat-list>
    <div *ngIf="searchedValue.length != 0">
      <h5
        matListItemTitle
        *ngIf="headingOpen == true && filteredCollections?.length > 0"
      >
        Collections
      </h5>
      <mat-list-item
        *ngFor="let item of filteredCollections; let i = index"
        (click)="onClickCollection(item)"
        [class.active]="i === selectedIndex"
      >
        <i class="fa fa-search" style="margin-left: 2%"></i>
        {{ item }}
      </mat-list-item>

      <mat-divider></mat-divider>

      <h5
        matListItemTitle
        *ngIf="headingOpen == true && filteredTitles?.length > 0"
      >
        Products
      </h5>
      <!-- <a [routerLink]="['/shop/details/', title_url]"> -->
      <mat-list-item
        *ngFor="let item of filteredTitles; let i = index"
        (click)="onClick(item)"
        [class.active]="i === selectedIndex - this.filteredCollections.length"
      >
        <i class="fa fa-search" style="margin-left: 2%"></i>
        {{ item }}
      </mat-list-item>
      <!-- </a> -->
    </div>
    <mat-divider></mat-divider>
    <!-- <h5 matListItemTitle>Recent Searches</h5> -->
    <div *ngIf="searchedValue.length == 0 && recentSearch.length > 1">
      <mat-list-item
        *ngFor="let item of recentSearch; let j = index"
        (click)="onSearch(item)"
        [class.active]="j === selectedIndex2"
        style="font-family: Lato, sans-serif"
      >
        <i class="fa fa-history" style="margin-left: 2%"></i>

        {{ item.search_term }}
        <a style="color: blue; font-size: 10px">({{ item.search_type }})</a>

        <!-- <div *ngFor="let entry of item | keyvalue">
          {{ entry.value }}
          <a style="color: blue; font-size: 10px">({{ entry.key }})</a>
        </div> -->
      </mat-list-item>
      <mat-divider></mat-divider>
    </div>
  </mat-list>
</div>
