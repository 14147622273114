<!-- thank-you section start -->
<section class="section-d-space light-layout" style="padding-bottom: 25px">
  <div class="closeBtn">
    <button class="btn btn-light" style="font-size: large" (click)="goToHome()">
      X
    </button>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="success-text">
          <i class="fa fa-check-circle" aria-hidden="true"></i>
          <h2>thank you</h2>
          <p>Payment is successfully processsed and your order is on the way</p>
          <p>order ID: {{ order_id }}</p>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Section ends -->
<!-- order-detail section start -->
<div class="container1" *ngFor="let ord of order">
  <!-- <h3 class="text-center m-4">#{{ orderNum }}</h3> -->
  <div style="display: flex; align-items: center; justify-content: center">
    <mat-card appearance="outlined" class="m-3" style="width: 90%">
      <mat-card-content>
        <div class="row1">
          <div class="row2">
            <div
              class="row3"
              style="
                padding: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
              "
            >
              <!-- <h5>
                <b style="color: rgb(18, 187, 18); font-size: 20px">
                  ThankYou For Your Order</b
                >
              </h5> -->
            </div>
            <div class="row4" style="display: flex">
              <div class="col-10" style="padding: 0px 0px 0px 0px">
                <p style="font-size: 13px" *ngIf="ord.billing_address">
                  Billed Name : {{ ord.billing_address["full_name"] }}
                </p>
                <!-- Order No : -->
              </div>

              <div class="col-2" style="padding: 0px 0px 0px 0px">
                <p style="font-size: 13px">
                  Placed On: {{ ord.order_created | slice : 0 : 10 }}
                </p>
              </div>
            </div>
          </div>
          <hr />

          <br />
          <div
            class="row"
            *ngFor="let product of product; let i = index; let last = last"
          >
            <div
              class="col-6"
              style="padding: 0px; display: flex; justify-content: center"
            >
              <ul>
                <li>
                  <img
                    mat-card-lg-image
                    [src]="product.image"
                    alt="product.variants[0].images"
                  />
                </li>
              </ul>
            </div>
            <!-- <div class="col-1"></div> -->
            <div
              class="col-6"
              style="padding: 0px; display: flex; align-items: center"
            >
              <ul>
                <li>
                  <div class="row">
                    <div>
                      <p>
                        <b style="color: black">{{ product.title }} </b>
                        <span style="float: right"> - {{ product.size }} </span>
                      </p>
                      <p></p>
                      <p style="display: flex">
                        <b> {{ product.quantity }}'</b
                        ><span style="margin-right: 2%">x</span>
                        <b>{{ product.mrp | currency : "INR" }}</b
                        ><span style="margin-left: 2%; margin-right: 2%"
                          >= </span
                        ><b>{{ product.price | currency : "INR" }}</b>
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-5" *ngIf="!last"></div>
            <div class="col-7" *ngIf="!last">
              <hr style="margin-top: 1rem; margin-bottom: 1rem" />
            </div>
          </div>

          <hr style="margin-top: 0.2rem; margin-bottom: 0.5rem" />

          <div class="row">
            <div class="col-4">
              <div
                class="row"
                style="
                  display: flex;
                  justify-content: center;
                  background-color: rgba(128, 128, 128, 0.301);
                "
              >
                <div class="col-6" style="padding: 25px 0px 25px 0px">
                  <ul *ngIf="shippingAddress">
                    <li style="margin-bottom: 1%">
                      <h4>shipping address</h4>
                    </li>
                    <br />
                    <li style="margin-bottom: 1%">
                      {{ shippingAddress.full_name }}
                    </li>
                    <br />
                    <li style="margin-bottom: 1%">
                      <!-- {{ shippingAddress.place_name }} , -->
                      {{ shippingAddress.address }}
                    </li>
                    <br />
                    <li style="margin-bottom: 1%">
                      {{ shippingAddress.city }}
                    </li>
                    <br />
                    <li style="margin-bottom: 1%">
                      <b>
                        {{ shippingAddress.state }} -
                        {{ shippingAddress.pincode }}</b
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div
                class="row"
                style="
                  display: flex;
                  justify-content: center;
                  height: 100%;
                  background-color: rgba(202, 198, 198, 0.301);
                "
              >
                <div class="col-6" style="padding: 25px 0px 25px 0px">
                  <h4 style="margin-bottom: 7%">summary</h4>
                  <div class="row" style="display: flex">
                    <div
                      class="col-6"
                      style="padding: 0px 0px 0px 0px; float: left"
                    >
                      <ul>
                        <li style="margin-bottom: 1%">Order Date</li>
                        <br />
                        <li style="margin-bottom: 1%">Payment-method</li>
                        <br />
                        <li>Transaction-id</li>
                        <br />
                        <!-- <li><b>Total</b></li> -->
                      </ul>
                    </div>
                    <div class="col-6" style="float: right">
                      <ul>
                        <li style="margin-bottom: 1%">
                          :{{ ord.order_created | slice : 0 : 10 }}
                        </li>
                        <br />
                        <li style="margin-bottom: 1%">
                          : {{ ord.transaction["payment_method"] }}
                        </li>
                        <br />
                        <li style="margin-bottom: 1%">
                          :{{
                            ord.transaction["acquirer_upi_transaction_id"]
                              | slice : 0 : 12
                          }}
                          -
                          {{
                            ord.transaction["acquirer_upi_transaction_id"]
                              | slice : 12
                          }}
                        </li>
                        <br />
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-4"
              style="
                background-color: rgba(235, 234, 234, 0.301);
                padding: 10px 0px 15px 0px;
                justify-content: center;
                align-items: center;
                display: flex;
              "
            >
              <div
                class="row"
                style="
                  display: flex;
                  margin-left: 2%;
                  font-size: 15px;
                  width: -webkit-fill-available;
                "
              >
                <div
                  class="col-6"
                  style="padding: 0px 0px 0px 0px; float: left"
                >
                  <ul>
                    <li>sub-total</li>
                    <br />
                    <li>shipping</li>
                    <br />
                    <li>discount</li>
                    <br />
                    <!-- <li>tax(CGST + SCST)</li> -->
                    <!-- <br /> -->
                    <hr />
                    <li><b>Total</b></li>
                  </ul>
                </div>
                <div class="col-6" style="float: right">
                  <ul>
                    <li>:{{ subTotal | currency : "INR" }}</li>
                    <br />
                    <li>:{{ ord.shipping_fee | currency : "INR" }}</li>
                    <br />
                    <li>:{{ ord.total_discount_amount | currency : "INR" }}</li>
                    <br />
                    <!-- <li>:{{ tax | currency : "INR" }}</li>
                    <br /> -->
                    <hr />
                    <li>
                      <b
                        >:{{
                          amountDetails + deliveryCharges | currency : "INR"
                        }}</b
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            class="row"
            style="
              align-items: center;
              justify-content: center;
              margin-top: 3%;
              margin-bottom: 3%;
            "
          >
            <button class="btn btn-danger" (click)="downloadPdf()">
              Download Receipt
            </button>
          </div>
          <div class="container">
            <p style="font-size: 12px; display: flex; justify-content: center">
              ( This is System generated Receipt,GST Invoice will be sent along
              with the Product )
            </p>
          </div>
        </div></mat-card-content
      ></mat-card
    >
  </div>
</div>
<div class="container2" *ngFor="let ord of order">
  <!-- <h4 class="text-center m-4">Order No :#{{ orderNum }}</h4> -->
  <hr />
  <div>
    <div class="row1">
      <div class="row2">
        <div
          class="row3"
          style="
            padding: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <!-- <h5>
              <b style="color: rgb(18, 187, 18); font-size: 15px">
                ThankYou For Your Order</b
              >
            </h5> -->
        </div>
        <div class="row4" style="display: flex">
          <div class="col-8" style="font-size: 10px">
            <p style="font-size: 10px" *ngIf="ord.billing_address">
              Billed Name : {{ ord.billing_address["full_name"] }}
            </p>
          </div>

          <div class="col-4" style="padding: 0px 0px 0px 0px">
            <p style="font-size: 10px">
              Placed On:
              {{ ord.order_created | slice : 0 : 10 }}
            </p>
          </div>
        </div>
      </div>
      <hr style="margin-top: 0.2rem; margin-bottom: 0.5rem" />

      <br />
      <div
        class="row"
        *ngFor="let product of product; let i = index; let last = last"
      >
        <div
          class="col-6"
          style="padding: 0px; display: flex; justify-content: center"
        >
          <ul>
            <li>
              <img
                mat-card-md-image
                [src]="product.image"
                alt="product.variants[0].images"
              />
            </li>
          </ul>
        </div>
        <!-- <div class="col-1"></div> -->
        <div
          class="col-6"
          style="
            padding: 0px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
          "
        >
          <ul>
            <li>
              <div class="row">
                <div>
                  <p style="width: 90%">
                    <b style="color: black; font-size: 10px">{{
                      product.brand
                    }}</b>
                    <span style="flex-shrink: 0"> - {{ product.size }} </span>
                  </p>
                  <p></p>
                  <p>
                    <b>{{ product.quantity }}'</b
                    ><span style="margin-right: 2%">x</span
                    ><b>{{ product.mrp | currency : "INR" }}</b>
                    <span style="margin-right: 2%; margin-left: 2%">=</span
                    ><b>{{ product.price | currency : "INR" }}</b>
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-5" *ngIf="!last"></div>
        <div class="col-7" *ngIf="!last">
          <hr style="margin-top: 1rem; margin-bottom: 1rem" />
        </div>
      </div>

      <hr style="margin-top: 0.2rem; margin-bottom: 0.5rem" />

      <div class="row">
        <div class="col-5"></div>
        <div class="col-7">
          <div class="row" style="display: flex; font-size: 12px">
            <div
              class="col-6"
              style="
                padding: 0px 0px 0px 0px;
                float: left;
                text-transform: capitalize;
              "
            >
              <ul>
                <li>sub-total</li>
                <br />
                <li>shipping</li>
                <br />
                <li>discount</li>
                <br />
                <!-- <li>tax(CGST + SCST)</li>
                <br /> -->
                <hr />
                <li><b>Total</b></li>
              </ul>
            </div>
            <div class="col-6" style="float: right; font-size: 10px">
              <ul>
                <li style="margin-bottom: 3%">
                  :{{ subTotal | currency : "INR" }}
                </li>
                <br />
                <li style="margin-bottom: 3%">
                  :{{ ord.shipping_fee | currency : "INR" }}
                </li>
                <br />
                <li style="margin-bottom: 3%">
                  :{{ ord.total_discount_amount | currency : "INR" }}
                </li>
                <br />
                <!-- <li style="margin-bottom: 4%">:{{ tax | currency : "INR" }}</li>
                <br /> -->
                <hr />
                <li>
                  <b
                    >:{{
                      amountDetails + deliveryCharges | currency : "INR"
                    }}</b
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr style="margin-top: 0.2rem; margin-bottom: 1rem" />
      <div
        class="row"
        style="
          align-items: center;
          justify-content: center;
          margin-top: 3%;
          margin-bottom: 3%;
        "
      >
        <button class="btn btn-danger" (click)="downloadPdf()">
          Download Receipt
        </button>
      </div>
      <div class="container">
        <p style="font-size: 10px; display: flex; text-align: center">
          ( This is System generated Receipt,GST Invoice will be sent along with
          the Product )
        </p>
      </div>
      <div
        class="row"
        style="font-size: 10px; background-color: rgb(128 128 128 / 13%)"
      >
        <div class="col-6">
          <div class="row" style="display: flex; justify-content: center">
            <div class="col-6" style="padding: 25px 0px 25px 0px">
              <ul *ngIf="shippingAddress">
                <li style="margin-bottom: 1%">
                  <h4 style="font-size: 12px">shipping address</h4>
                </li>
                <br />
                <li style="margin-bottom: 1%">
                  {{ shippingAddress.full_name }}
                </li>
                <br />
                <li style="margin-bottom: 1%">
                  <!-- {{ shippingAddress.place_name }} , -->
                  {{ shippingAddress.address }}
                </li>
                <br />
                <li style="margin-bottom: 1%">
                  {{ shippingAddress.city }}
                </li>
                <br />
                <li style="margin-bottom: 1%">
                  <b>
                    {{ shippingAddress.state }} -
                    {{ shippingAddress.pincode }}</b
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row" style="display: flex">
            <div class="col-6" style="padding: 25px 0px 25px 0px">
              <h4 style="margin-bottom: 17%; font-size: 12px">summary</h4>
              <div class="row" style="display: flex">
                <div class="col-6">
                  <ul style="width: max-content">
                    <li style="margin-bottom: 5%">Order Date</li>
                    <br />
                    <li style="margin-bottom: 5%">Payment-method</li>
                    <br />
                    <li style="margin-bottom: 5%">Order-status</li>
                    <br />
                    <!-- <li><b>Total</b></li> -->
                  </ul>
                </div>
                <div class="col-6">
                  <ul
                    style="position: absolute; width: max-content; right: -50px"
                  >
                    <li style="margin-bottom: 5%">
                      :{{ ord.order_created | slice : 0 : 10 }}
                    </li>
                    <br />
                    <li style="margin-bottom: 5%">
                      : {{ ord.transaction["payment_method"] }}
                    </li>
                    <br />
                    <li style="margin-bottom: 5%">:{{ ord.payment_status }}</li>
                    <br />
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="col-lg-6">
    <div class="row order-success-sec">
      <div class="col-sm-6">
        <h4>summery</h4>
        <ul class="order-detail">
          <li>order ID: {{ order_id }}</li>
          <li>Order Date:{{ ord.order_created | slice : 0 : 10 }}</li>
          <li>Order Total: {{ ord.total_amount }}</li>
        </ul>
      </div>
      <br />
      <div class="col-sm-6">
        <h4>shipping address</h4>
        <ul class="order-detail">
          <li>{{ shippingAddress.full_name }}</li>
          <li>
            {{ shippingAddress.place_name }} ,
            {{ shippingAddress.address }}
          </li>
          <li>{{ shippingAddress.city }}</li>
          <li>
            {{ shippingAddress.state }} -
            {{ shippingAddress.pincode }}
          </li>
        </ul>
      </div> -->

<!-- <div class="col-sm-12 payment-mode">
                  <h4>payment method</h4>
                  <p>
                    Pay on Delivery (Cash/Card). Cash on delivery (COD)
                    available. Card/Net banking acceptance subject to device
                    availability.
                  </p>
                </div> -->
<!-- <div class="col-md-12">
        <div class="delivery-sec">
          <h3>expected date of delivery</h3>
          <h2>october 22, 2030</h2>
        </div>
      </div>
      <div class="col-md-12 mt-3">
        <button class="btn btn-danger" (click)="downloadedPdf()">
          Download Invoice
        </button>
      </div>
    </div>
  </div>
</div> -->

<!-- <section class="section-b-space" id="orders" *ngIf="products.length">
            <div class="container" *ngFor="let item of order">
              <div class="row">
                <div class="col-lg-6">
                  <div class="product-order">
                    <h3 class="text-center">your order details</h3>
                    <div
                      class="row product-order-detail"
                      *ngFor="let product of products; let i = index"
                    >
                      <div class="col-3"> -->
<!-- <div *ngFor="let img of product.variants[0]"> -->
<!-- <img
                          [src]="product?.variants[0]?.images[0]"
                          [alt]="product?.variants[0]?.images[0]"
                          class="img-fluid"
                        /> -->
<!-- </div> -->
<!-- </div>
                      <div class="col-3 order_detail">
                        <div>
                          <h4>product name</h4>
                          <h5>{{ product.title }}</h5>
                        </div>
                      </div> -->
<!-- <div class="col-3 order_detail">
                        <div>
                          <h4>quantity</h4>
                          <h5>{{ item.order_items[i].quantity }}</h5>
                        </div>
                      </div>
                      <div class="col-3 order_detail">
                        <div>
                          <h4>price</h4>
                          <h5>{{ product.price | currency : "INR" }}</h5>
                        </div>
                      </div>
                    </div>
                    <div class="total-sec">
                      <ul>
                        <li>
                          subtotal
                          <span>{{ subTotal | currency : "INR" }}</span>
                        </li>
                        <li>
                          shipping
                          <span>{{ deliveryCharges | currency : "INR" }}</span>
                        </li> -->
<!-- <li>
                          discount
                          <span>{{
                            item.total_discount_amount | currency : "INR"
                          }}</span>
                        </li>
                        <li>
                          tax(CGST + SCST)
                          <span>{{ tax | currency : "INR" }}</span>
                        </li>
                      </ul>
                    </div>
                    <div class="final-total">
                      <h3>
                        total
                        <span>{{
                          amountDetails + deliveryCharges | currency : "INR"
                        }}</span>
                      </h3>
                    </div>
                  </div>
                </div> -->

<!-- </div>
          </section> -->
<!-- Section ends -->
<!-- </div></mat-card-content
      ></mat-card
    >
  </div>
</div> -->
<!-- 
  </div>
</div> -->
