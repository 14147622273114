import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RoutesRecognized,
} from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "src/app/shared/services/auth.service";
import { userService } from "src/app/shared/services/user.service";
import { Location } from "@angular/common";
import { HotToastService } from "@ngneat/hot-toast";
import { error, log } from "console";
import { catchError, Observable, Subscription, throwError, timer } from "rxjs";
import firebase from "firebase/compat/app";
// import * as firebase from 'firebase/compat/app'
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { WindowRefService } from "src/app/window/window-ref.service";
import "firebase/compat/auth";
// import { getAuth, RecaptchaVerifier } from "firebase/auth";
// import {
//   getAuth,
//   RecaptchaVerifier,
//   signInWithPhoneNumber,
// } from "firebase/compat/app";
import { environment } from "src/environments/environment";
import { filter, pairwise } from "rxjs/operators";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  private recaptchaVerifier: firebase.auth.RecaptchaVerifier;
  private authStateSubscription: Subscription;

  login: FormGroup;
  loginError: boolean = false;
  loginErrorMsg: any;
  loader: boolean = false;
  tabRes: any;
  UserAccessReview: any;
  showPassField = false;
  confirmation = false;
  cofirmMail: string;
  confirmPassword: string;
  mobile_Log = false;
  windowRef: any;
  showCaptchaMsg = false;
  showErrorName = false;
  previous_Url: string = "";
  onlyMailVerification = false;
  appVerifier: any; // This should be initialized appropriately
  constructor(
    private authservice: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: userService,
    private location: Location,
    private toast: HotToastService,
    private afAuth: AngularFireAuth,
    private win: WindowRefService
  ) {
    localStorage.setItem("in_register", JSON.stringify("Register"));

    this.route.queryParams.subscribe((data) => {
      const verify = data["for"];
      if (verify) {
        this.onlyMailVerification = true;
        this.getMail();
      } else {
        this.authservice.isEmailVerified().subscribe((res) => {
          // console.log(res);
          if (res === false && localStorage.getItem("username")) {
            this.onlyMailVerification = true;
            this.getMail();
          }
        });
      }
    });
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        // console.log("previous url", events[0].urlAfterRedirects);
        if (events[0].urlAfterRedirects !== "/login") {
          const previous = events[0].urlAfterRedirects;
          if (
            previous !== "/login?for=verify" &&
            previous !== "/pages/register" &&
            previous !== "/login?tab=4"
          ) {
            localStorage.setItem(
              "previous-page",
              JSON.stringify(events[0].urlAfterRedirects)
            );
            this.previous_Url = JSON.parse(
              localStorage.getItem("previous-page")
            );
          }
        }
        // console.log("current url", events[1].urlAfterRedirects);
      });

    this.login = new FormGroup({
      email: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.email])
      ),
      password: new FormControl("", Validators.compose([Validators.required])),
    });
  }
  captcha;
  ngAfterViewInit(): void {
    this.windowRef = this.win.nativeWindow;
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "normal", // Set this to 'normal' to see the ReCAPTCHA widget
        callback: (response: any) => {
          // console.log("ReCaptcha verified", response);
        },
      }
    );
  }
  ngOnInit(): void {
    // this.onlyMailVerification = true;
    // this.checkingForCheckout = true;
    if (!firebase.apps.length) {
      firebase.initializeApp(environment.firebase);
    }

    this.route.queryParams.subscribe((res) => {
      this.tabRes = res.tab;
      this.UserAccessReview = res;
    });
    // if (localStorage.getItem("tryingCheckOut")) {
    //   this.mobile_Log = true;
    // }
  }
  mobileNo: any;
  showErrorMobile = false;
  userName: string = "" || undefined;
  getMail() {
    this.userService.getUserData().subscribe((res) => {
      if (res) {
        // console.log(res);

        const profile = JSON.parse(res.body);
        this.mailForVerify = profile["email"];
      }
    });
  }
  formatMobile() {
    const cleaned = this.mobileNo.replace(/\D/g, "");
    // console.log(cleaned);
    if (cleaned.length <= 4) {
      this.showErrorMobile = false;

      this.mobileNo = cleaned;
    } else {
      this.mobileNo =
        cleaned.slice(0, 3) +
        "-" +
        cleaned.slice(3, 6) +
        "-" +
        cleaned.slice(6, 10);
    }
    if (cleaned == "") {
      // console.log("enter a valid mobile number");
      this.showErrorMobile = true;
    } else {
      this.showErrorMobile = false;
    }
  }
  isNumber(event: KeyboardEvent) {
    const keyCode = event.keyCode || event.which;
    if (
      [46, 8, 9, 27, 13].indexOf(keyCode) !== -1 ||
      (keyCode >= 48 && keyCode <= 57)
    ) {
      // Let it happen, don't do anything
      return;
    }
    // Prevent the rest
    event.preventDefault();
  }
  validateName() {
    const regex = /^[A-Za-z]+$/;

    if (!regex.test(this.userName)) {
      this.showErrorName = true;
      // console.log("Name can only contain letters.");
    } else {
      this.showErrorName = false;
      this.isSubmitting = false;
    }
  }
  // onMobile() {

  // }
  signIn() {
    // console.log(
    //   this.login.value.email,
    //   this.login.value.password,
    //   this.login.valid
    // );
    this.loader = true;

    if (this.login.valid) {
      this.authservice
        .signInWithEmailPassword(
          this.login.value.email,
          this.login.value.password
        )
        .pipe(
          this.toast.observe({
            success: "Logged-In",
            loading: "Logging",
            error: (err: any) => `${err?.code}`,
          }),
          catchError((error) => {
            // Handle the error here
            // console.error("Login error:", error);
            this.loader = false; // Stop the loader
            return throwError(error); // Rethrow the error for further handling
          })
        )
        .subscribe({
          next: (res: any) => {
            // localStorage.setItem("user", JSON.stringify(res));
            // console.log(res, res.uid, res.emailVerified);
            this.loader = false; // Stop the loader

            if (res) {
              if (res.emailVerified === false) {
                this.authservice.checkVerifyUser.subscribe((check) => {
                  // console.log(check);
                  if (check.verified === true) {
                    this.routeAfterLogged_in();
                  } else {
                    // console.log("waiting for confirm");
                  }
                });
              } else {
                // console.log(res);
                this.routeAfterLogged_in();
              }
            }
          },
          error: (error) => {
            // This will handle the error thrown from catchError
            // console.error("Subscription error:", error);
            this.loader = false; // Stop the loader
          },
        });
    }
  }

  routeAfterLogged_in() {
    if (localStorage.getItem("tryingCheckOut")) {
      const query = JSON.parse(localStorage.getItem("item-for-checkout"));
      localStorage.removeItem("tryingCheckOut");
      localStorage.removeItem("item-for-checkout");
      this.router.navigate(["/checkout"], {
        queryParams: { products: query },
      });
    } else {
      if (localStorage.getItem("previous-page")) {
        // console.log("not checkout");
        this.previous_Url = JSON.parse(localStorage.getItem("previous-page"));
        const decodedUrl = decodeURIComponent(this.previous_Url);
        // Split the URL into the base path and query parameters
        const [pathWithParams, queryParamsString] = decodedUrl.split("?");
        const pathSegments = pathWithParams.split("/").filter(Boolean); // Split by '/' and filter out empty segments
        const basePath =
          pathSegments.length > 0
            ? pathSegments.slice(0, pathSegments.length).join("/")
            : "";
        let queryParams = {};
        if (queryParamsString) {
          queryParamsString.split("&").forEach((param) => {
            const [key, value] = param.split("=");
            queryParams[decodeURIComponent(key)] = decodeURIComponent(value); // Decode keys and values
          });
        }
        this.router.navigate([basePath], { queryParams });
      } else {
        this.router.navigate(["/"]);
      }
    }
  }

  mobileLog() {
    this.mobile_Log = true;
  }

  sign_Up_Mobile() {
    this.sign_Up_mob = true;
  }

  Sign_In_Mobile() {
    this.sign_Up_mob = false;
  }
  backToLogin() {
    this.verificationId = "";
    this.showCaptchaMsg = false;
    this.message = null;
    this.windowRef = this.win.nativeWindow;
    const interval = setInterval(() => {
      // console.log("entered");
      this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "normal",
          callback: (response: any) => {
            // console.log("ReCaptcha verified", response);
          },
        }
      );
      // console.log(this.windowRef.recaptchaVerifier);

      if (this.windowRef.recaptchaVerifier) {
        clearInterval(interval);
      }
    }, 1000);
  }

  sign_Up_mob = false;
  phoneNumber: string = "";
  verificationCode: string = "";
  verificationId: string | null = null;
  message: string | null = null;
  stopSubmitCode: boolean;
  changeButton = false;
  isSubmitting: boolean = false;
  showNameFill = false;
  onSubmitPhone() {
    this.isSubmitting = true;
    const name = this.userName || "";
    const validNumber = `+91${this.mobileNo.replace(/[-\s]/g, "")}`;
    let alreadyUser;
    this.userService.checkUserExist("phone", validNumber).subscribe((res) => {
      alreadyUser = JSON.parse(res.userSignedUp);
      this.isSubmitting = !alreadyUser;
      // console.log(alreadyUser);
      // console.log("Mobile No. :", validNumber, name);
      // console.log("name", name);
      if (alreadyUser === false) {
        this.sign_Up_mob = true;
        if (this.userName === undefined) {
          this.showNameFill = true;
        } else if (validNumber && this.userName.length) {
          this.windowRef.recaptchaVerifier.render();
          this.showCaptchaMsg = true;
          this.showNameFill = false;
        }
      } else {
        if (validNumber) {
          this.windowRef.recaptchaVerifier.render();
          this.showCaptchaMsg = true;
          this.showNameFill = false;
        }
      }
      // console.log(this.windowRef.recaptchaVerifier);
      if (this.showCaptchaMsg) {
        this.authservice
          .sendVerificationCode(validNumber, this.windowRef.recaptchaVerifier)
          .subscribe({
            next: (verificationId) => {
              this.verificationId = verificationId;
              this.message = "Verification code sent!";
              this.stopSubmitCode = false;
              this.onlyMailVerification = false;
            },
            error: (err) => {
              // console.log(err);
              this.message = "Error sending code: " + err.message;
              this.changeButton = true;
            },
          });
      }
    });
  }
  newUser() {
    if (localStorage.getItem("tryingCheckOut")) {
      localStorage.setItem("tryingCheckOutNew", JSON.stringify(true));
    }
    this.router.navigate(["/pages/register"]);
  }
  canResend = false;
  //   resendCode() {
  // this.onSubmitPhone()
  //   }
  // private startResendTimer() {
  //   this.canResend = false;
  //   timer(30000).subscribe(() => {
  //     this.canResend = true;
  //   });
  // }

  onSubmitCode() {
    if (this.stopSubmitCode === false) {
      this.authservice
        .verifyCode(this.verificationId, this.verificationCode)
        .subscribe({
          next: (data) => {
            this.authStateSubscription = this.authservice.authFUser.subscribe(
              (user) => {
                // console.log("Current user:", user);
                if (user) {
                  this.message = "Phone number verified successfully!";
                  this.addMobileUserToDb(user.uid);
                  this.stopSubmitCode = true;
                } else {
                  this.message = "Verification successful, but user not found.";
                }
              }
            );
          },
          error: (err) => {
            this.message = "Error verifying code: " + err.message;
          },
        });
    }
  }

  addMobileUserToDb(uid: string) {
    const validNumber = `+91${this.mobileNo.replace(/[-\s]/g, "")}`;

    if (uid) {
      // this.confirm = true;

      const signUpUser = {
        cognito_username: uid,
        first_name: "",
        last_name: "",
        full_name: this.userName || "",
        phone: validNumber,
        email: "",
        is_active: false,
      };
      this.userService.signUpUserData(signUpUser).subscribe((res) => {
        this.loader = false;
        // console.log(res);
      });
      // console.log(signUpUser);
      this.routeAfterLogged_in();
    }

    // this.authService.signIn("+919751595324", "Test@1234");
  }

  // deleteUser() {
  //   // this.authservice.getUserAccessToken().then((data) => console.log(data));

  //   this.authservice.deleteAccount().subscribe((res) => console.log(res));
  // }
  closeLogin() {
    this.router.navigate([this.previous_Url]);
  }
  closeMobileLog() {
    this.mobile_Log = false;
  }

  showPasswordChange() {
    this.showPassField = !this.showPassField;
  }
  recieveUserStatus(event: boolean) {
    // console.log(event);
    this.cofirmMail = this.login.value.email;
    this.confirmation = event;
  }
  ngOnDestroy(): void {
    localStorage.removeItem("tryingCheckOut");
    // console.log("Removed tryingCheckOut from localStorage");
    localStorage.removeItem("in_register");

    if (this.authStateSubscription) {
      this.authStateSubscription.unsubscribe();
    }
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  verifyMessage: string =
    "Please verify your email to complete the registration process.";
  mailForVerify: string;
  loaderForVerify = false;
  interval: any;
  onVerify() {
    this.loaderForVerify = true;

    this.authservice.sendVerificationEmail().subscribe({
      next: (res) => {
        this.toast.observe({
          success: "Mail Sent",
          loading: "Sending Mail...",
          error: (err: any) => `${err?.code}`,
        });

        this.verifyMessage =
          "We’ve sent a verification email to your inbox. Please check your email to confirm your account.";

        this.startEmailVerificationCheck();
      },
      error: (err) => {
        this.verifyMessage =
          "Error in sending verification. Please try again later.";
        // console.log("Mail not sent", err);
        this.loaderForVerify = false;
      },
    });
  }

  startEmailVerificationCheck() {
    this.interval = setInterval(() => {
      this.authservice.isEmailVerified().subscribe({
        next: (user) => {
          // console.log(user);
          if (user === true) {
            // console.log("Email confirmed");
            this.routeAfterLogged_in();
            this.loaderForVerify = false;
            clearInterval(this.interval);
            this.verifyMessage = "Your email has been successfully verified!";
          } else {
            // console.log("Waiting for confirmation");
          }
        },
        error: (err) => {
          // console.error("Error checking verification status", err);
        },
      });
    }, 2000);
  }
}
// if(this.UserAccessReview){
//   this.authservice.signIn(this.login.value.email,this.login.value.password).then((res)=>{
//     this.loader = false
//     if(this.authservice.getCurrentUserId()){
//       this.router.navigate(['/shop/details/',this.UserAccessReview])
//     }

//   })

// }else{
//     .then((res) => {
//       this.loader = false;
//       if (this.authservice.getCurrentUserId()) {
//         const reDirectUrl = this.location.back();
//         console.log(reDirectUrl);
//         this.router.navigate([reDirectUrl]);
//       }
//     })
//     .catch((err) => {
//       // this.toaster.info(err);
//       this.loader = false;
//       // this.loginErrorMsg = err
//       this.loginError = true;
//       if (err.message && err.message === "User is not confirmed.") {
//         this.confirmation = true;
//         this.cofirmMail = this.login.value.email;
//         this.confirmPassword = this.login.value.password;
//         console.log(this.login.value.email);
//         // b4188458-7031-70f2-138a-85b5c025101a
//         this.authservice
//           .resendCode(this.login.value.email)
//           .then((res) => console.log(res));
//       }
//     });
// }
// }

// setupRecaptcha(containerId: string): firebase.auth.RecaptchaVerifier {
//   return new firebase.auth.RecaptchaVerifier(containerId, {
//     size: "invisible", // You can use 'normal' for visible or 'invisible' for hidden
//     callback: (response: any) => {
//       console.log("ReCaptcha verified", response);
//     },
//   });
// }
