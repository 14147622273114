<div class="container">
  <div class="zoom">
    <div class="row1">
      <pinch-zoom
        [minScale]="1"
        [disablePan]="false"
        [limit-zoom]="10"
        [draggableImage]="true"
        [limitPan]="false"
        #pinch
        (swipeleft)="rightClick()"
        (swiperight)="leftClick()"
      >
        <img [src]="product" class="img-fluid" />
      </pinch-zoom>
    </div>
    <div class="row2">
      <div class="img-overflow">
        <mat-card *ngFor="let item of Images" (click)="imgClick(item)">
          <img [src]="item" width="50px" />
        </mat-card>
      </div>
    </div>
  </div>
</div>
