<div
  class="cookie-consent-container"
  id="cookieConsentBanner"
  *ngIf="!accepted"
>
  <div class="container" style="background-color: rgba(236, 235, 235, 0.925)">
    <div class="row">
      <div class="col-12 orangered-bdr p-3 rounded fw-bold">
        We use ; cookies to improve your experience. By continuing, you agree to
        our
        <a style="color: black">Privacy Policy</a>.
        <div class="btnForAction">
          <button
            class="btn btn-success btn-sm m-2"
            id="acceptCookies"
            (click)="acceptCookies()"
          >
            Accept
          </button>
          <button
            class="btn btn-danger btn-sm m-2"
            id="declineCookies"
            (click)="decline()"
          >
            Decline
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
