<div class="slider1">
  <div class="slide">
    <div
      *ngFor="let item of name; let i = index"
      class="item"
      [ngStyle]="{
        background: 'url(' + images[item.trim()] + ') no-repeat',
        'background-size': 'cover'
      }"
    >
      <div class="content">
        <!-- <div class="name">Marvel</div> -->
        <!-- <div class="des">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum itaque
        </div> -->
        <button (click)="onShop(item)" class="btnShop">Shop Now</button>
      </div>
    </div>

    <!-- <div
      class="item"
      style="background-image: url('assets/images/fullface.jpg')"
    >
      <div class="content">
        <div class="name">Marvel</div>
        <div class="des">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum itaque
        </div>
        <button>see more</button>
      </div>
    </div>
    <div
      class="item"
      style="background-image: url('assets/images/offroad.jpg')"
    >
      <div class="content">
        <div class="name">Marvel</div>
        <div class="des">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum itaque
        </div>
        <button>see more</button>
      </div>
    </div>
    <div class="item" style="background-image: url('assets/images/flipup.jpg')">
      <div class="content">
        <div class="name">Marvel</div>
        <div class="des">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum itaque
        </div>
        <button>see more</button>
      </div>
    </div>
    <div class="item" style="background-image: url('assets/images/axor.jpg')">
      <div class="content">
        <div class="name">Marvel</div>
        <div class="des">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum itaque
        </div>
        <button>see more</button>
      </div>
    </div>
    <div class="item" style="background-image: url('assets/images/axxis.jpg')">
      <div class="content">
        <div class="name">Marvel</div>
        <div class="des">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum itaque
        </div>
        <button>see more</button>
      </div>
    </div>
  </div> -->
  </div>
  <div class="button">
    <button class="prev"><i class="ti-arrow-left"></i></button>
    <button class="next"><i class="ti-arrow-right"></i></button>
  </div>
</div>
