import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-footer-three",
  templateUrl: "./footer-three.component.html",
  styleUrls: ["./footer-three.component.scss"],
})
export class FooterThreeComponent implements OnInit {
  @Input() class: string; // Default class
  @Input() mainFooter: boolean = true; // Default true
  @Input() subFooter: boolean = false; // Default false
  @Input() themeLogo: string = "assets/images/logos/sc-name-dark-40x140px.svg"; // Default Logo

  img_third_slide = {
    GLOVES: "assets/img-footer/gloves.png",
    JACKET: "assets/img-footer/jackets.png",
    BOOTS: "assets/img-footer/boots.png",
    BALACLAVA: "assets/img-footer/balaclava.png",
    HELMETS: "assets/img-footer/helmet.png",
    VISOR: "assets/img-footer/visor.png",
  };

  riding_gears = ["GLOVES", "JACKET", "BOOTS", "BALACLAVA"];

  helmets_parts = ["HELMETS", "VISOR"];
  public today: number = Date.now();

  constructor(private router: Router) {}

  ngOnInit(): void {}

  onShop(category: string) {
    if (category) {
      this.router.navigate(["/shop/"], {
        queryParams: { category: category },
      });
    }
  }
  inShop(collection: string) {
    if (collection === "HELMETS") {
      this.router.navigate(["/shop/"]);
    } else {
      this.router.navigate(["/shop/"], {
        queryParams: { collection: collection },
      });
    }
  }
  toTerms() {
    this.router.navigate(["/return-warranty-policy"], {
      queryParams: { policy: "terms" },
    });
  }
  toShipping() {
    this.router.navigate(["/return-warranty-policy"], {
      queryParams: { policy: "shipping" },
    });
  }
  toRefund() {
    this.router.navigate(["/return-warranty-policy"], {
      queryParams: { policy: "refund" },
    });
  }
  toPrivacy() {
    this.router.navigate(["/return-warranty-policy"], {
      queryParams: { policy: "privacy" },
    });
  }
}
