import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NavService } from "../../services/nav.service";
// import { input } from "aws-amplify";
import { AuthService } from "../../services/auth.service";

@Component({
  selector: "app-sub-header",
  templateUrl: "./sub-header.component.html",
  styleUrls: ["./sub-header.component.scss"],
})
export class SubHeaderComponent implements OnInit {
  constructor(
    public navServices: NavService,
    private router: Router,
    private authService: AuthService
  ) {}
  @Input() brands: any;

  async ngOnInit() {
    // Usage
    const userAgent = this.getUserAgent();
    // console.log("User Agent:", userAgent);
    localStorage.setItem("User Agent", JSON.stringify(userAgent));

    // let sessionId = await this.authService.getSessionId();
    // if (!sessionId) {
    //   console.log("no");
    //   // If no session ID is found, sign in anonymously
    //   sessionId = await this.authService.signInAnonymously();
    // }
    // console.log("Session ID:", sessionId);
    // localStorage.setItem("Session ID", JSON.stringify(sessionId));
  }
  // console.log(this.brands)

  leftMenuToggle(): void {
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
    // console.log(this.navServices.leftMenuToggle)
  }
  getUserAgent() {
    return navigator.userAgent;
  }
  Onbrands(title: string) {
    const brand = title.trim();
    this.router.navigate(["/shop"], { queryParams: { brand: brand } });
  }
  get sortedBrands(): string[] {
    const customOrder = [
      "MT HELMETS",
      "AXOR",
      "SMK",
      "RIDEX",
      "AXXIS",
      "LS2",
      "STEELBIRD",
      "VEGA",
      "STUDDS",
      "KORDA",
    ];
    // const otherBrands = this.brands.filter(
    //   (brand) => !customOrder.includes(brand)
    // );
    // // console.log(otherBrands)
    // return [...customOrder, ...otherBrands];
    return customOrder;
  }
}
