import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Images, Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { ProductColors } from "src/app/shared/constants/shop.contants";
import { ActivatedRoute, Router } from "@angular/router";
import { Image } from "@ks89/angular-modal-gallery";

@Component({
  selector: "app-product-box-one",
  templateUrl: "./product-box-one.component.html",
  styleUrls: ["./product-box-one.component.scss"],
})
export class ProductBoxOneComponent implements OnInit {
  @Input() product: any;
  @Input() currency: any = this.productService.Currency; // Default Currency
  @Input() thumbnail: boolean = false; // Default False
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  @Input() firstEight: number;
  title_url: string;

  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc: string;
  colorsForFilter: any[];
  showMore: boolean = false;
  tab: any;
  constructor(
    private productService: ProductService,
    private router: Router,
    private route: ActivatedRoute
  ) {}
  placeholder_img = "assets/images/product/placeholder.jpg";
  public logo = {
    KORDA: "assets/logo-small/korda-horizontal.png",

    STUDDS: "assets/logo-small/studds-horizontal.png",

    LS2: "assets/logo-small/ls2-horizontal.png",

    STEELBIRD: "assets/logo-small/steelbird-horizontal.png",

    "MT HELMETS": "assets/logo-small/mt-horizontal.png",

    AXOR: "assets/logo-small/axor-horizontal.png",

    RIDEX: "assets/logo-small/ridex-horizontal.png",

    VEGA: "assets/logo-small/vega-horizontal.png",

    SMK: "assets/logo-small/smk-horizontal.png",

    AXXIS: "assets/logo-small/axxis-horizontal.png",
    DSG: "assets/logo-small/dsg-logo.webp",
    SOLACE: "assets/logo-small/solace.png",
    ARK: "assets/logo-small/ark.png",
  };
  ngOnInit(): void {
    // console.log(this.product)
    this.route.queryParams.subscribe((data) => {
      this.tab = data.tab;
      // console.log(this.tab)
    });
    const img = this.product.first_two_images;
    // console.log(img);
    if (img?.length > 1) {
      this.onHowerChangeImage = true;
    }
    if (this.product) {
      const colors = this.product.color;
      // console.log(colors);
      this.colorsForFilter = colors?.length > 2 ? colors.slice(0, 2) : colors;
    }

    // this.colorsForFilter = this.Color(this.product.variants)
    this.title_url = this.product.title
      .trim()
      .replace(/\(/g, "")
      .replace(/\s/g, "-");
    // console.log(this.title_url)
    // console.log(this.colorsForFilter)
    if (this.loader) {
      setTimeout(() => {
        this.loader = false;
      }, 5000); // Skeleton Loader
    }

    if (window.innerWidth < 767) {
      this.onHowerChangeImage = false;
    }

    // console.log(this.firstEight);
  }

  // routeToProducts(){
  //   this.router.navigate(['/shop/details'], { queryParams: { product: this.title_url} });
  // }

  // Get Product Color product.variants.map(variant => variant.colors).flat();
  Color(variants) {
    // variants.colors = ProductColors.COLORSONEBOX
    var uniqColor = [];
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].colors) === -1 && variants[i].colors) {
        for (var color of variants[i].colors) {
          uniqColor.push(
            ProductColors.COLORSONEBOX[color.replace(/^\[\'|\'\]$/g, "")]
          );
        }
        //  /\s/g, ""
      }
    }

    // console.log([...new Set(uniqColor)], variants[0].colors)

    // this.colorsForFilter=[...new Set(uniqColor)]
    // console.log(this.colorsForFilter)
    return [...new Set(uniqColor)];
  }

  // Color(variants) {
  //   const uniqColor = [];
  //   variants.forEach((variant) => {
  //     if (variant.colors && uniqColor.indexOf(variant.colors) === -1) {
  //       uniqColor.push(variant.colors);
  //     }
  //   });
  //   return uniqColor;
  // }

  // Change Variants
  ChangeVariants(color, product) {
    product.variants.map((item) => {
      if (item.color === color) {
        product.images.map((img) => {
          if (img.image_id === item.image_id) {
            this.ImageSrc = img.src;
          }
        });
      }
    });
  }

  // Change Variants Image
  ChangeVariantsImage(src) {
    this.ImageSrc = src;
  }

  addToCart(product: any) {
    this.productService.addToCart(product);
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

  toNoSideBar(id: any, title: any, product) {
    const data = btoa(id);
    const formatTitle = title.replace(/\s+/g, "-");
    this.router.navigate(["/shop/details/", data, formatTitle], {
      queryParams: { tab: this.tab },
    });

    this.productService.recentSearch(product);
  }
}
