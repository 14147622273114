<!-- <p>collections-flow works!</p>
<div>
    <h3>{{ brandItem.brand }}</h3>
    <ul *ngFor="let collection of brandItem.collections">
      <li >{{ collection }}</li>
    </ul>
  </div> -->
<div
  class="row"
  style="display: flex"
  *ngIf="topLoader && !uniqueBrandWithCollections.length"
>
  <div class="col-6" *ngFor="let item of topLoaderCounts">
    <app-skeleton-product-box></app-skeleton-product-box>
  </div>
</div>

<div *ngFor="let brandItem of uniqueBrandWithCollections; let i = index">
  <div>
    <div style="text-decoration: underline red">
      <h4>
        <span>
          <img [src]="logo[brandItem.brand]" alt="logo" width="100px" />
        </span>
      </h4>
    </div>
    <div class="card-container-scroll">
      <div
        class="card-container"
        [ngClass]="{ centered: brandItem.collections.length < 3 }"
      >
        <div *ngFor="let collection of brandItem.collections">
          <mat-card (click)="onClick(collection)">
            <mat-card-content>
              <div>
                <div>
                  <img
                    *ngIf="img_coll[collection]"
                    [src]="img_coll[collection]"
                    width="115px"
                    height="115px"
                  />
                  <img
                    *ngIf="!img_coll[collection]"
                    [src]="img_coll['duplicate']"
                    width="115px"
                    height="115px"
                  />
                </div>
                <!-- <img [src]="images[i + 4]" width="115px" height="115px" /> -->
              </div>
              <br />
              <div>
                <b style="text-align: center; color: black; display: block">
                  <button class="btn btn-light">{{ collection }}</button></b
                >
              </div>
            </mat-card-content></mat-card
          >
        </div>
      </div>
      <br />
    </div>
  </div>
</div>
