<div class="icon-nav" >
  <ul>
      <!-- <li class="onhover-div mobile-search search-widgets" id="search-widgets">
   f
        <div>
          <a [routerLink]="['/home']">    
            <i class="ti-home" [ngClass]="{'focus':isFocus}" (click)="onFocus()"></i>
           </a>
      </div>
      </li> -->
    
   <!-- <li class="onhover-div mobile-user">
    <div >
      <i class="ti-user" id="search-widgets"></i>
    </div>

   </li> -->
   
    <!-- <li class="onhover-div mobile-setting" id="search-widgets">
      <div>
        <app-left-menu   (forCart)="receivedData($event)"></app-left-menu>
        <app-left-menu (click)="forHideCart()"  ></app-left-menu> -->

        <!-- <app-left-menu (click)="forHideCart()"  ></app-left-menu> --> 
        <!-- <img src="assets/images/icon/setting.png" class="img-fluid" alt="settong"> -->
        <!-- <i class="ti-settings"></i></div>
      <div class="show-div setting">
        <h6>language</h6>
        <ul>
          <li *ngFor="let language of languages">
            <a href="javascript:void(0)" (click)="changeLanguage(language.code)">{{ language.name }}</a>
          </li>
        </ul>
        <h6>currency</h6>
        <ul class="list-inline">
          <li *ngFor="let currency of currencies">
            <a href="javascript:void(0)" (click)="changeCurrency(currency)">{{ currency.name }}</a>
          </li>
        </ul> -->
      <!-- </div>
    </li>
    
    <li class="onhover-div mobile-cart" [class.hidden]="hideCart" >
     -->
     <li class="onhover-div mobile-cart">
      <div>
        <a [routerLink]="['/shop/cart']">
          <img src="assets/images/icon/cart.png" alt="" style="width:25px">
          <!-- <i class="fa fa-shopping-cart" ></i> 
          <i class="ti-shopping-cart"></i>  -->
        </a>
      </div>
      <span class="cart_qty_cls">{{ products.length }}</span>
      <ul class="show-div shopping-cart" *ngIf='!products.length'>
        <h5>Your cart is currently empty.</h5>
      </ul>
      <ul class="show-div shopping-cart" *ngIf='products.length'>
        <li *ngFor="let product of products">
          <div class="media">
            <a [routerLink]="['/shop/details/', product.title]">
              <img class="mr-3" [src]="product?.variants[0]?.images[0]" [alt]="product?.variants[0]?.images[0]">
            </a>
            <div class="media-body">
              <a [routerLink]="['/shop/details/', product.title]">
                <h4>{{ product?.title }}</h4>
              </a>
              <h4>
                <span>
                  {{ product?.quantity }} x 
                  {{ product?.price * productService?.Currency.price | discount:product | currency:productService?.Currency.currency:'symbol' }}
                </span>
              </h4>
            </div>
          </div>
          <div class="close-circle" (click)="removeItem(product)">
            <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
          </div>
        </li>
        <li>
          <div class="total">
            <h5>subtotal : <span>{{ getTotal | async | currency:productService?.Currency.currency:'symbol' }}</span></h5>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a [routerLink]="['/shop/cart']" class="view-cart">view cart</a>
            <a (click)="checkUser()" class="checkout">checkout</a>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>