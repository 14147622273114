import { Component, Input, OnInit } from "@angular/core";
import { filter } from "rxjs/operators";
import { ProductService } from "src/app/shared/services/product.service";

@Component({
  selector: "app-cards-flow",
  templateUrl: "./cards-flow.component.html",
  styleUrls: ["./cards-flow.component.scss"],
})
export class CardsFlowComponent implements OnInit {
  @Input() brands: any[];
  category = ["FULL FACE", "HALF FACE", "FLIP UP", "OFF ROAD"];
  image_dict = {
    fullface: "assets/imagecategoris/fullface.webp",
    openface: "assets/imagecategoris/openface.jpg",
    flipup: "assets/imagecategoris/flipup.jpg",
    offroad: "assets/imagecategoris/offroad.png",
    "MT HELMETS": "assets/imagecategoris/mt.webp",
    AXOR: "assets/imagecategoris/axor.jpg",
    SMK: "assets/imagecategoris/smk.jpg",
    RIDEX: "assets/imagecategoris/ridex.jpg",
    AXXIS: "assets/imagecategoris/Axxis.webp",
    LS2: "assets/imagecategoris/ls2.jpg",
    VEGA: "assets/imagecategoris/vega.jpg",
    STUDDS: "assets/imagecategoris/studds.webp",
    STEELBIRD: "assets/imagecategoris/steelbird.jpg",
    KORDA: "assets/imagecategoris/Korda.webp",
  };

  constructor(private productservice: ProductService) {}
  totalBrands = [];
  uniqueBrands = [];
  firstImage = [];
  helmetsOnly: any;

  ngOnInit(): void {
    // console.log(this.brands)
    // this.ifTheBrandsEmpty()
  }

  // ifTheBrandsEmpty(){

  //   if(!this.brands){
  //     this.productservice.getProducts().subscribe(
  //       (result)=>{
  //         result.forEach(data=>
  //           this.helmetsOnly.push (data.title=='HELMET'),
  //         this.totalBrands.push(this.helmetsOnly.brand.trim())
  //        )
  //       this.brands = [...new Set(this.totalBrands)]
  //       // console.log(this.uniqueBrands)
  //       // console.log(result)
  //       // this.uniqueBrands.forEach(brand => {
  //       //   const product = result.find(item => item.brand === brand);
  //       //   if (product && product.variants.length > 0 && product.variants[0].images.length > 0) {
  //       //     this.firstImage .push( product.variants[0].images[0])
  //       //     console.log(this.firstImage)
  //       // }})

  // })
  //   }
  //   else{
  //     // console.log('it has products')
  //   }
  // }
  get sortedBrands(): string[] {
    const customOrder = ["MT HELMETS", "AXOR", "SMK", "RIDEX", "AXXIS"];
    const otherBrands = this.brands.filter(
      (brand) => !customOrder.includes(brand)
    );
    // console.log(otherBrands)
    return [...customOrder, ...otherBrands];
  }
}
