import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanDeactivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from "@angular/router";
import { PagesComponent } from "src/app/pages/pages.component";
import { AuthService } from "./auth.service";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { Observable } from "rxjs";
import { verify } from "crypto";

@Injectable({
  providedIn: "root",
})
export class AuthGuard
  implements
    CanActivate,
    CanActivateChild,
    CanDeactivate<PagesComponent>,
    CanLoad
{
  constructor(
    private authService: AuthService,
    private router: Router,
    private afAuth: AngularFireAuth
  ) {}
  isAuth: boolean;
  async canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    // await this.authService.isAuthenticated().then((authenticated) => {
    //   // console.log(authenticated);
    //   this.isAuth = authenticated;
    //   // console.log(this.isAuth);
    // });
    // console.log(this.checkAuth());
    return await this.verifyAuth();
  }

  canActivateChild(
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    return this.verifyAuth();
  }

  canDeactivate(_component: PagesComponent): boolean {
    // if (component.hasUnsavedChanges()) {
    //   return window.confirm('You have unsaved changes. Do you really want to leave?');
    // }
    return true;
  }

  canLoad(): boolean {
    return this.verifyAuth();
  }

  private checkAuth(): boolean {
    if (this.isAuth == true) {
      // console.log("if");
      return true;
    } else {
      // console.log("else");
      this.router.navigate(["/login"]);
      return false;
    }
  }
  public verifyAuth(): any {
    this.checkFAuth().subscribe((isAuth) => {
      if (isAuth) {
        // console.log("User is authenticated.");
        return true;
        // Additional logic for authenticated users
      } else {
        // console.log("User is not authenticated.");
        return false;
      }
    });
  }
  private checkFAuth(): Observable<boolean> {
    console.log("entered-checkF");
    return new Observable<boolean>((observer) => {
      this.afAuth.authState.subscribe((user) => {
        if (user) {
          if (
            (user?.email && user?.emailVerified) ||
            (user?.phoneNumber && user?.email === null)
          ) {
            observer.next(true);
          } else {
            observer.next(false);
            this.router.navigate(["/login"], {
              queryParams: { for: "verify" },
            });
          }
        } else {
          observer.next(false);
          this.router.navigate(["/login"]); // Redirect to login if not authenticated
        }
      });
    });
  }
}
