<div class="card-container-scroll">
  <span class="circle-button">
    <i class="fa fa-arrow-circle-left" (click)="scrollLeft(cards1)"></i>
  </span>
  <!-- <button (click)="scrollLeft()" class="scroll-arrow left">&lt;</button> -->
  <div class="cards" #cards1>
    <mat-card appearance="outlined">
      <mat-card-content class="one">
        <div class="row">
          <div *ngFor="let categoryIndex of [0, 1, 2, 3]" class="col-6">
            <div>
              <a
                [routerLink]="['/shop/']"
                [queryParams]="{ category: category[categoryIndex] }"
              >
                <img
                  [src]="image_dict[category[categoryIndex]]"
                  width="100px"
                  height="100px"
                />
                <p>{{ category[categoryIndex] }}</p>
              </a>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <!-- brands -->

    <div *ngFor="let brand of sortedBrands; let i = index">
      <mat-card appearance="outlined" class="brands">
        <mat-card-content>
          <a [routerLink]="['/shop/']" [queryParams]="{ brand: brand }">
            <div>
              <div class="brand">{{ brand }}</div>
              <br />
              <div>
                <img mat-card-xl-image [src]="image_dict[brand]" />
              </div>
            </div>
          </a>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <!-- <button (click)="scrollRight()" class="scroll-arrow right">&gt;</button> -->
  <span class="circle-button" (click)="scrollRight(cards1)">
    <i class="fa fa-arrow-circle-right"></i>
  </span>
</div>
