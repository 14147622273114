import { Injectable } from "@angular/core";
import Amplify, { Auth } from "aws-amplify";
import { CognitoUser, CognitoUserSession } from "amazon-cognito-identity-js";
import {
  BehaviorSubject,
  catchError,
  from,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  throwError,
} from "rxjs";
import { UserSignupDetails } from "../classes/user";
import { environment } from "../../../environments/environment";
import { AngularFireAuth } from "@angular/fire/compat/auth";
import firebase from "firebase/compat/app";
import { NgxIndexedDBService } from "ngx-indexed-db";
// import { getAuth } from "firebase/auth";;
@Injectable({
  providedIn: "root",
})
export class AuthService {
  normal: CognitoUser;
  cognitoUser = new BehaviorSubject<CognitoUser | null>(null);

  signUpVerifier = new BehaviorSubject<boolean | null>(false);
  isAuthenticatedBool: boolean = false;
  reDirectUrl: any | null = null;
  constructor(
    private afAuth: AngularFireAuth,
    private indexedDb: NgxIndexedDBService
  ) {
    Amplify.configure({
      Auth: environment.cognito,
    });
  }

  isAuthenticatedUser(): boolean {
    return this.isAuthenticatedBool;
  }
  isAuthenticated(): Promise<boolean> {
    return new Promise((resolve, _) => {
      Auth.currentAuthenticatedUser()
        .then((response) => {
          if (response) {
            this.cognitoUser.next(response);
            this.isAuthenticatedBool = true;
            resolve(true);
          }
        })
        .catch((_) => {
          resolve(false);
        });
    });
  }

  signUp(userSignupDetails: UserSignupDetails) {
    // console.log(userSignupDetails);

    return new Promise((resolve, reject) => {
      Auth.signUp({
        username: userSignupDetails.email,
        password: userSignupDetails.password,
        attributes: {
          name: userSignupDetails.firstName + " " + userSignupDetails.lastName,
          "custom:firstname": userSignupDetails.firstName,
          "custom:lastname": userSignupDetails.lastName,
        },
      })
        .then((response) => {
          // console.log(response);
          if (response) {
            this.setCognitoUser(response.user);
            resolve(response);
          }
        })
        .catch((err) => reject(err));
    });
  }

  signIn(email: string, password: string): Promise<CognitoUser> {
    // console.log(email, password);

    return new Promise((resolve, reject) => {
      Auth.signIn(email, password)
        .then((response: CognitoUser) => {
          if (response) {
            // console.log(response);
            localStorage.removeItem("userRecentSearchInNosidebar");
            localStorage.removeItem("userSearches");

            localStorage.setItem(
              "username",
              JSON.stringify(response["username"])
            );
            this.setCognitoUser(response);

            resolve(response);
          }
        })
        .catch((err) => {
          // console.log(err);
          if (err.message && err.message === "User is not confirmed.") {
            // console.log("yes");
          }
          reject(err);
        });
    });
  }

  confirmSignup(email: string, code: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Auth.confirmSignUp(email, code)
        .then((response) => {
          if (response) {
            // console.log("Confirm signup response: ", response);
            resolve(response);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  forgetPassword(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Auth.forgotPassword(email)
        .then((response) => {
          if (response) {
            // console.log("Forget password response: ", response);
            resolve(response);
          }
        })
        .catch((err) => {
          // console.log(err);
          reject(err);
        });
    });
  }

  resetPassword(email: string, code: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(email, code, password)
        .then((response) => {
          if (response) {
            // console.log("Forget password reset response: ", response);
            resolve(response);
          }
        })
        .catch((err) => {
          // console.log("Forget password reset error response: ", err);
          reject(err);
        });
    });
  }

  setCognitoUser(user: CognitoUser) {
    this.cognitoUser.next(user);
  }

  async getUserSession() {
    const user = await Auth.currentAuthenticatedUser();
    return user;
  }

  async getCurrentUserId(): Promise<string> {
    const result = await Auth.currentAuthenticatedUser();
    // console.log(result);

    return result.username;
  }

  signOutUser() {
    return new Promise((resolve, _reject) => {
      Auth.signOut()
        .then(() => {
          localStorage.clear();

          resolve(true);
        })
        .catch((err) => {
          // console.error("Sign out error:", err);
          resolve(false);
        });
    });
  }

  subscribeToCognitoUser() {
    return this.cognitoUser.asObservable();
  }

  // forceResetPassword(cognitoUser: CognitoUser, password: any): Promise<CognitoUser> {
  //   return new Promise((resolve, reject) => {
  //     if (!cognitoUser || !cognitoUser.getSession) {
  //       reject(new Error('Cognito user or getSession method not available.'));
  //       return;
  //     }

  //     cognitoUser.getSession((err, session) => {
  //       if (err) {
  //         console.error('Error getting user session:', err);
  //         reject(err);
  //         return;
  //       }

  //     Auth.completeNewPassword(cognitoUser, password)
  //       .then((user: CognitoUser) => {
  //         console.log('Force reset password successful for user:', user);
  //         resolve(user);
  //       })
  //       .catch(err => {
  //         console.error('Error completing new password:', err);
  //         reject(err);
  //       });
  //   })
  // });
  // }
  // async forceResetPassword(password: string): Promise<CognitoUser> {
  //   try {
  //     const user = await Auth.currentAuthenticatedUser();

  //     if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
  //       return await Auth.completeNewPassword(user, password);
  //     } else {
  //       throw new Error('User does not require a new password');
  //     }
  //   } catch (error) {
  //     throw error;
  //   }
  // }

  async changePassword(oldPassword: any, newPassword: any) {
    // console.log(oldPassword, newPassword);
    try {
      const user = await Auth.currentAuthenticatedUser();
      const data = await Auth.changePassword(user, oldPassword, newPassword);
      // console.log(data);
    } catch (err) {
      // console.log(err);
      throw err;
    }
  }
  // async  completeNewPasswordChallenge(cognitoUser: CognitoUser, newPassword: string): Promise<any> {
  //     try {
  //         const user = await Auth.completeNewPassword(cognitoUser, newPassword, {
  //             // Any additional information or attributes you need to provide
  //         });
  //         console.log('Password change successful:', user);
  //         return user; // Return the user object or any relevant data upon success
  //     } catch (error) {
  //         console.error('Error completing new password:', error);
  //         throw error; // Throw the error for the caller to handle
  //     }
  // }

  // // Usage example
  // async resetPasswordAndFetchSession(newPassword: string) {
  //   try {
  //       // Assuming this.normal is a CognitoUser object
  //       if (!this.normal) {
  //           throw new Error('CognitoUser object (this.normal) is not defined or null.');
  //       }

  //       const updatedUser = await this.completeNewPasswordChallenge(this.normal, newPassword);

  //       // Fetch user session after completing password challenge
  //       const userSession = await Auth.currentAuthenticatedUser();
  //       console.log('User session:', userSession);

  //       // Continue with further application logic using userSession
  //   } catch (error) {
  //       console.error('Error resetting password or fetching user session:', error);
  //       // Handle error appropriately
  //   }
  // }
  forceResetPassword(password: string): Promise<CognitoUser> {
    // console.log(password);
    return new Promise((resolve, reject) => {
      this.cognitoUser.subscribe((user) => {
        Auth.completeNewPassword(user, password)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => reject(err));
      });
    });
  }

  async resendCode(username) {
    // console.log("Attempting to resend code for username:", username);
    try {
      const response = await Auth.resendSignUp(username);
      // console.log("Code resent successfully", response);
      return response;
    } catch (err) {
      // console.error("Error resending code: ", err.message || err);
    }
  }
  async handleDeleteUser() {
    try {
      await Auth.deleteUser();
      // console.log("user deleted");
    } catch (error) {
      // console.log(error);
    }
  }

  /*
    ---------------------------------------------
    ---------------  FIRE BASE  -----------------
    ---------------------------------------------
  */
  /**
   * Handle Firebase Sign-Up
   */
  signUpWithEmailPassword(
    email: string,
    password: string,
    displayName: string
  ): Observable<firebase.User | null> {
    // console.log("Sign-up function called");

    return from(
      this.afAuth.createUserWithEmailAndPassword(email, password)
    ).pipe(
      switchMap((userCredential: any) => {
        const firebaseUser = userCredential.user;
        // console.log(firebaseUser, userCredential);
        if (firebaseUser) {
          // Send email verification
          return from(firebaseUser.updateProfile({ displayName })).pipe(
            switchMap(() => {
              return from(firebaseUser.sendEmailVerification()).pipe(
                map(() => {
                  // console.log("Verification email sent.");
                  this.checkEmailVerified(email, password);
                  return firebaseUser; // Return the firebase user
                })
              );
            }),
            catchError((err) => {
              // console.error("Error sending verification email:", err);
              // Even if verification fails, return the user
              return of(firebaseUser);
            })
          );
        }
        return of(null);
      }),
      catchError((error) => {
        // console.error("Firebase sign-up error:", error);
        return of(null);
      })
    );
  }
  sendVerificationEmail(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.afAuth.currentUser
        .then((user) => {
          if (user) {
            return user
              .sendEmailVerification()
              .then(() => {
                observer.next(true);
                // observer.complete();
              })
              .catch((error) => {
                observer.error(error);
              });
          } else {
            observer.error(new Error("No user found"));
          }
        })
        .catch((error) => {
          observer.error(error);
        });
    });
  }

  private checkVerifiedUser$ = new Subject<any>();

  public get checkVerifyUser() {
    return this.checkVerifiedUser$.asObservable();
  }

  checkEmailVerified(email, password) {
    if (email && password) {
      const interval = setInterval(() => {
        if (localStorage.getItem("in_register")) {
          // console.log("interval-checking");
          this.signInWithEmailPassword(email, password).subscribe((res) => {
            console.log(res.emailVerified);
            if (res.emailVerified === true) {
              // console.log("interval-cleared");
              clearInterval(interval);
              this.checkVerifiedUser$.next({ verified: true, uid: res.uid });
              return true;
            }
          });
        } else {
          this.checkVerifiedUser$.complete();
          clearInterval(interval);
        }
      }, 3000);
    }
  }
  // /**
  //  * Handle Firebase Sign-In (Log In)
  //  */
  signInWithEmailPassword(
    email: string,
    password: string
  ): Observable<firebase.User | null> {
    // this.afAuth.authState.subscribe((res) => {
    //   console.log(res);
    // });
    return from(this.afAuth.signInWithEmailAndPassword(email, password)).pipe(
      map((userCredential: any) => {
        const firebaseUser = userCredential.user;
        localStorage.removeItem("userRecentSearchInNosidebar");
        localStorage.removeItem("userSearches");
        return firebaseUser; // Return the firebase user
      }),
      catchError((error) => {
        // console.error("Firebase login error:", error);
        return throwError(error); // Return null in case of error
      })
    );
  }
  deleteAccount(): Observable<boolean> {
    // console.log("Delete account function called");

    return from(this.afAuth.currentUser).pipe(
      switchMap((user: any) => {
        // console.log(user);
        if (user) {
          return from(user.delete()).pipe(
            map(() => {
              // console.log("User account deleted successfully.");
              return true; // Indicate success
            }),
            catchError((error) => {
              // console.error("Error deleting user account:", error);
              return of(false); // Indicate failure
            })
          );
        } else {
          // console.warn("No user is currently signed in.");
          return of(false); // Indicate failure
        }
      }),
      catchError((error) => {
        // console.error("Error retrieving current user:", error);
        return of(false); // Indicate failure
      })
    );
  }

  private AuthFUser$ = new Subject<any>();

  public get authFUser() {
    this.isAuthFUser();
    return this.AuthFUser$.asObservable();
  }

  public async isAuthFUser() {
    await this.afAuth.authState.subscribe((user) => {
      if (user) {
        // console.log(user);
        localStorage.setItem("username", JSON.stringify(user["uid"]));
        if (
          (user?.email && user?.emailVerified) ||
          (user?.phoneNumber && user?.email === null)
        ) {
          this.AuthFUser$.next(user);
        }
      }
    }); // Emit the user state
  }

  isEmailVerified(): Observable<boolean> {
    return this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return from(user.reload()).pipe(map(() => user.emailVerified));
        }
        return [false];
      })
    );
  }

  // private AuthFUserIntercept$ = new Subject<any>();

  // public get authFUserInter() {
  //   this.isAuthFUserInter();
  //   return this.AuthFUserIntercept$.asObservable();
  // }

  // public async isAuthFUserInter() {
  //   this.afAuth.authState.subscribe((user) => {
  //     if (user) {
  //       console.log(user);
  //       localStorage.setItem("username", JSON.stringify(user["uid"]));
  //       this.AuthFUserIntercept$.next(user);
  //       this.AuthFUserIntercept$.complete();
  //     }
  //   }); // Emit the user state
  // }
  //   async getFUserSession() {
  //  ;
  //   }
  /**
   * Sign In with Google (or any other OAuth provider)
   */
  async signInWithGoogle() {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      const userCredential = await this.afAuth.signInWithPopup(provider);
      const firebaseUser = userCredential.user;

      if (firebaseUser) {
        // Sign into AWS Cognito with Firebase credentials
        // await this.signInWithFirebase(firebaseUser);
      }
    } catch (error) {
      // console.error("Firebase Google login error:", error);
    }
  }

  sendPasswordResetEmail(email: string): Observable<void> {
    return from(this.afAuth.sendPasswordResetEmail(email)).pipe(
      map(() => {
        // console.log("password resent mail sent successfully.");
      }),
      catchError((error) => {
        // console.error("Error sending password reset email:", error);
        throw error; // Rethrow the error for further handling
      })
    );
  }

  // changePasswordFAuth(
  //   oldPassword: string,
  //   newPassword: string,
  //   users: any
  // ): Observable<any> {
  //   console.log(users);
  //   const user = users;

  //   if (user) {
  //     return from(
  //       firebase.auth.EmailAuthProvider.credential(user.email, oldPassword)
  //     ).pipe(
  //       switchMap(() => from(user.updatePassword(newPassword))),
  //       catchError((error) => {
  //         console.error("Error changing password:", error);
  //         return throwError(error); // Rethrow for further handling
  //       })
  //     );
  //   } else {
  //     return throwError("No user is currently signed in.");
  //   }
  // }

  sendVerificationCode(phoneNumber: string, appVerifier): Observable<string> {
    return new Observable((observer) => {
      // console.log(appVerifier);
      // const appVerifier = new firebase.auth.RecaptchaVerifier(
      //   "recaptcha-container",
      //   {
      //     size: "invisible", // Set this to 'normal' to see the ReCAPTCHA widget
      //   }
      // );
      // console.log(appVerifier);

      this.afAuth
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          observer.next(confirmationResult.verificationId);
          observer.complete();
        })
        .catch((error) => {
          // console.error("Error during SMS verification:", error);
          observer.error(error);
        });
    });
  }
  resendVerificationCode(phoneNumber: string, appVerifier): Observable<string> {
    return this.sendVerificationCode(phoneNumber, appVerifier);
  }
  // Verify the SMS code
  verifyCode(verificationId: string, code: string): Observable<any> {
    const credential = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      code
    );
    return from(this.afAuth.signInWithCredential(credential)).pipe(
      catchError((error) => {
        // console.error("Error verifying code:", error);
        return throwError(error);
      })
    );
  }
  /**
   * Log out from both Firebase and Cognito
   */
  async signOut() {
    try {
      localStorage.clear();
      this.indexedDb.clear("firebaseLocalStorageDb");

      await this.afAuth.signOut(); // Sign out from Firebase
      // console.log("User signed out successfully.");
    } catch (error) {
      // console.error("Sign-out error:", error);
    }
  }

  async getUserAccessToken(retries: number = 3): Promise<string | null> {
    for (let attempt = 0; attempt < retries; attempt++) {
      const user = await this.afAuth.currentUser;
      // console.log(user?.email);

      try {
        // console.log(user?.email, user?.phoneNumber);

        // console.log(attempt);
        const token = await user.getIdToken();
        // console.log("Access Token:", token);
        if (token) {
          attempt = 5;
        }
        return token;
      } catch (error) {
        // console.error(
        //   `Attempt ${attempt + 1}: Error retrieving access token:`,
        //   error
        // );
        if (attempt === retries) {
          return null;
        }
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
    }
    return null;
  }

  // private getAfUserAcess$ = new Subject<any>();

  // public get getAfUserAcess() {
  //   this.getUserAccessToken();
  //   return this.getAfUserAcess$.asObservable();
  // }

  // async getUserAccessToken() {
  //   const user = await this.afAuth.currentUser; // Get the current user

  //   if (user) {
  //     try {
  //       // Retrieve the access token
  //       const token = await user.getIdToken();
  //       console.log("Access Token:", token);
  //       this.getAfUserAcess$.next(token);
  //     } catch (error) {
  //       console.error("Error retrieving access token:", error);
  //     }
  //   } else {
  //     console.log("No user is currently signed in.");
  //   }
  // }
}
