<div class="forDesk">
  <ng-template class="theme-modal" #fullview let-modal>
    <div class="modal-content quick-view-modal">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="row">
          <div class="col-1" style="padding: 0px">
            <div class="side">
              <button class="btn btn-light" (click)="leftClick()">
                <li
                  class="fa fa-angle-left"
                  style="font-size: large; font-weight: bolder"
                ></li>
              </button>
            </div>
          </div>

          <!-- <div class="col-lg-6 col-xs-12"> -->
          <!-- <div class="quick-view-img"> -->
          <div class="col-10" style="padding: 0px">
            <span> <img [src]="product" class="img-fluid" /></span>
          </div>
          <div class="col-1" style="padding: 0px">
            <div class="side">
              <button class="btn btn-light" (click)="rightClick()">
                <li
                  class="fa fa-angle-right"
                  style="font-size: large; font-weight: bolder"
                ></li>
              </button>
            </div>
          </div>

          <!-- </div> -->
        </div>
      </div>
    </div>
    <!-- </div> -->
  </ng-template>
</div>

<div class="forMobile">
  <ng-template class="theme-modal" #fullview let-modal>
    <div class="modal-content quick-view-modal">
      <div class="modal-body">
        <button
          type="button"
          class="close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="row">
          <!-- <div class="col-lg-6 col-xs-12"> -->
          <!-- <div class="quick-view-img"> -->
          <img [src]="product" class="img-fluid" />
          <!-- </div> -->
        </div>
      </div>
    </div>
    <!-- </div> -->
  </ng-template>
</div>
