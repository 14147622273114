import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-circlecolors',
  templateUrl: './circlecolors.component.html',
  styleUrls: ['./circlecolors.component.scss']
})
export class CirclecolorsComponent implements OnInit {
  @Input() color :any [];

  constructor() { }

  ngOnInit(): void {
    // console.log(this.color)
  

  }

}
