import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  from,
  interval,
  of,
} from "rxjs";
import {
  map,
  startWith,
  delay,
  switchMap,
  tap,
  finalize,
} from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { Product } from "../classes/product";
import { NgxIndexedDBService } from "ngx-indexed-db";
// import "rxjs/Rx";
// import FuzzySearch from "fuzzy-search";
import Fuse from "fuse.js";
import * as CryptoJS from "crypto-js";
import { EncryptionService } from "./encryption.service";
import { userService } from "./user.service";
import { log } from "console";
import { title } from "process";
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";
import { Meta } from "@angular/platform-browser";
const state = {
  products: JSON.parse(localStorage["products"] || "[]"),
  wishlist: JSON.parse(localStorage["wishlistItems"] || "[]"),
  compare: JSON.parse(localStorage["compareItems"] || "[]"),
  cart: JSON.parse(localStorage["cartItems"] || "[]"),
};

@Injectable({
  providedIn: "root",
})
export class ProductService {
  public Currency = { name: "Rupees", currency: "₹", price: 1 }; // Default Currency
  public OpenCart: boolean = false;
  public Products;
  localValues: any[] = [];
  // cartSubject = new BehaviorSubject<Product[]>(state.cart);
  // private wishlistSubject = new BehaviorSubject<Product[]>(state.wishlist);
  isAuthenticated: boolean = false;

  // public cartItems$ = this.cartSubject.asObservable();
  // public wishlistItems$ = this.wishlistSubject.asObservable();
  private products: any[] | null = null;
  private readonly cacheKey = "products";
  private readonly cacheExpiryKey = "productsExpiry";
  private readonly cacheTTL = 3600 * 1000; // Cache time-to-live in milliseconds (e.g., 1 hour)
  private productsSubject = new ReplaySubject<any[]>(1); // Replay the last emitted value

  private isFetching = false; // Flag to indicate if an API call is in progress

  baseUrl: string =
    "https://qarselokic.execute-api.us-east-1.amazonaws.com/dev/";
  productList: Product[] = [];
  items: any[] = [];
  ProductsFilterArray: any[] = [];
  searchTerm: string = "";

  titles: any[] = [];
  filteredTitles: any[] = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private http: HttpClient,
    private toastrService: ToastrService,
    private indexedDb: NgxIndexedDBService,
    private userService: userService,
    private toastr: ToastrService,
    private meta: Meta
  ) {
    if (sessionStorage.getItem("indexdb") === null) {
      this.indexedDb.clear("products");
      sessionStorage.setItem("indexdb", "true");
    }
  }

  private showLoaderSubject$ = new Subject<boolean>();

  get showLoaderSubject() {
    return this.showLoaderSubject$.asObservable();
  }
  updateLoader(data: boolean) {
    console.log(data);

    this.showLoaderSubject$.next(data);
  }

  // META TAGS //

  resetMetaTags() {
    const og_title = "Skullcrown - Helmets for Every Style";
    const og_des =
      "Find the coolest, designed helmets for your next ride at Skullcrown.";
    const og_img =
      "https=//skullcrown-resources.s3.amazonaws.com/assets/OG+image.png";
    const og_url = "https://skullcrown.in";
    this.meta.updateTag({
      property: "og:title",
      content: `${og_title}`,
    });
    this.meta.updateTag({
      property: "og:description",
      content: `${og_des}`,
    });
    this.meta.updateTag({
      property: "og:image",
      content: `${og_img}`,
    });

    this.meta.updateTag({
      property: "og:url",
      content: `${og_url}`,
    });
  }

  private productsUpSubj$ = new BehaviorSubject<any[]>([]);

  get productsFromSubject() {
    // this.getProducts().subscribe();
    return this.productsUpSubj$.asObservable();
  }

  public updateProduct$(product: any[]) {
    // const now = Date.now();
    // // console.log(product);
    // this.productsUpSubj$.next(product);
    // this.products = product;
    // this.indexedDb
    //   .bulkAdd("products", product)
    //   .pipe(
    //     tap(() => {
    //       localStorage.setItem(
    //         this.cacheExpiryKey,
    //         (now + this.cacheTTL).toString()
    //       );
    //     })
    //   )
    //   .subscribe();
  }

  getProducts(): Observable<any[]> {
    const now = Date.now();

    const storedExpiry = localStorage.getItem(this.cacheExpiryKey);
    const isCacheExpired = storedExpiry
      ? now > parseInt(storedExpiry, 10)
      : true;
    // console.log(isCacheExpired);
    // console.log(this.products);
    if (this.products && this.products.length > 0 && !isCacheExpired) {
      // console.log("already products in db ; catch not expires");

      return of(this.products);
    }

    if (!isCacheExpired) {
      return from(this.indexedDb.getAll("products")).pipe(
        switchMap((products: any[]) => {
          // console.log("products-db", products);
          // console.log("already products in db");
          // this.updateProduct$(products);

          if (products && products.length > 0) {
            // this.products = products.map(product => this.encryptionService.decryptProduct(product));
            this.products = products;
            // console.log(this.products)
            this.updateProduct$(this.products);
            // this.productsSubject.next(this.products);
            return of(this.products);
          } else {
            return this.fetchAndCacheProducts(now);
          }
        })
      );
    } else {
      return this.fetchAndCacheProducts(now);
    }
  }

  private fetchAndCacheProducts(now: number): Observable<any[]> {
    if (!this.isFetching) {
      this.isFetching = true;
      return this.http.get<string>(this.baseUrl + "products").pipe(
        map((productsJson) => JSON.parse(productsJson["body"])),
        switchMap((products) => {
          // console.log("parsed product");

          return from(this.indexedDb.clear("products")).pipe(
            switchMap(() => {
              // console.log("cleared products from indexedDb!");

              return from(this.indexedDb.bulkAdd("products", products)).pipe(
                tap(() => {
                  localStorage.setItem(
                    this.cacheExpiryKey,
                    (now + this.cacheTTL).toString()
                  );
                  this.updateProduct$(products);

                  // this.productsSubject.next(products);
                })
              );
            })
          );
        }),

        finalize(() => {
          this.isFetching = false;
          // console.log("finalize working");
        })
      );
    } else {
      // console.log("already fetching...");
      return of([]);
    }
  }

  // Optional: Method to clear cache
  clearCache(): void {
    this.products = null;
    localStorage.removeItem(this.cacheKey);
    localStorage.removeItem(this.cacheExpiryKey);
  }

  public filteredProductsBySearch(searchTerm: string): Observable<any> {
    return this.productsFromSubject.pipe(
      switchMap((products: any) => {
        if (typeof products === "string") {
          this.titles = JSON.parse(products);
        } else {
          this.titles = products;
        }

        if (!this.titles.length) {
          // console.log("No titles found, reloading...");
          return of([]); // Return an empty array as an observable
        }

        if (!searchTerm) {
          return of(this.titles); // Return all titles if no search term
        } else {
          // Combine fields for search
          const combinedTitles = this.titles.map((product: any) => {
            const combinedString = [
              product.title,
              product.brand,
              product.collection.join(" "), // Convert collection array to string
              product.category,
              product.type,
            ].join(" ");
            return { ...product, combinedString };
          });

          const fuse = new Fuse(combinedTitles, {
            keys: ["combinedString"],
            threshold: 0.5,
          });

          this.filteredTitles = fuse
            .search(searchTerm)
            .map((item) => item.item)
            .filter((product) =>
              product.variants.some((variant) => variant.stock > 0)
            );

          return of(this.filteredTitles); // Return filtered titles
        }
      })
    );
  }

  public filteredProductsBySpecificSearch(
    searchTerm: string,
    filterKeys = ["collection", "title", "type", "brand", "category", "tags"]
  ): Observable<string[]> {
    return this.getProductClassifications().pipe(
      switchMap((products: any) => {
        var result;
        this.titles = JSON.parse(products.body);

        if (!this.titles.length) {
          // console.log("No titles found, reloading...");
          return of([]); // Return an empty array as an observable
        }
        const fuse = new Fuse(this.titles, {
          keys: filterKeys,
          threshold: 0.4,
        });
        this.filteredTitles = fuse.search(searchTerm);
        if (filterKeys[0] == "collection" || filterKeys[0] == "tags") {
          result = this.filteredTitles.map((item) =>
            item.item[filterKeys[0]].join(" ")
          );
          // Extract collections and flatten the array
          const allCollections = this.filteredTitles.reduce(
            (acc: string[], product: any) => {
              return acc.concat(product.item[filterKeys[0]]);
            },
            []
          );

          // Remove duplicates
          result = Array.from(new Set(allCollections));
        } else {
          result = this.filteredTitles.map((item) => item.item[filterKeys[0]]); // Map to array of titles
        }
        return of(result); // Return filtered titles
      })
    );
  }

  // Get Products By Slug

  private productForShop$ = new Subject<any>();

  public get ProductForShopFromSubject() {
    return this.productForShop$.asObservable();
  }
  public UpdateProductForShop(filters: any) {
    const filtersString = JSON.stringify(filters);
    // console.log("filters", filtersString);
    let params = new HttpParams().set("filters", filtersString);
    // console.log(params.toString());
    this.http
      .get<any>(`${this.baseUrl}/products`, { params })
      .subscribe((resp: any) => {
        this.productForShop$.next(resp);
        // this.productForShop$.complete();
        // console.log(resp);
      });
  }
  public UpdateProductForCollection(filters: any) {
    const filtersString = JSON.stringify(filters);
    let params = new HttpParams().set("filters", filtersString);
    // console.log(filters, params.toString());
    return this.http.get<any>(`${this.baseUrl}/products`, { params });
    // .subscribe((response: any) => {
    // this.productForShop$.next(response);
    // });
  }

  private proBySlug$ = new Subject<any>();

  public get ProBySlug$() {
    return this.proBySlug$.asObservable();
  }

  public updateSlug(item: any) {
    const slugEncrypted = atob(item);
    // console.log(slugEncrypted);
    this.http.get<any>(`${this.baseUrl}products/${slugEncrypted}`).subscribe({
      next: (response) => {
        this.proBySlug$.next(response);
      },
    });
  }
  getComments(product_id: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + `product/comment/${product_id}`);
  }
  // getProductsById(slug: string): Observable<Product> {
  //   const slugEncrypted = atob(slug);
  //   return this.http.get<any>(this.baseUrl + `products/${slugEncrypted}`);
  // }

  public getProductBySlug(slug: string): Observable<Product> {
    // console.log(slug);

    return this.productsFromSubject.pipe(
      map((items) => {
        this.items = items;
        // console.log(this.items);
        return this.items.find((item: any) => {
          this.updateSlug(item);
          const slugEncrypted = atob(slug);
          // console.log(slugEncrypted);

          return item.product_id === slugEncrypted;
        });
      })
    );
  }

  public getProductClassifications() {
    return this.http.get<any>(this.baseUrl + "products/classification");
  }

  /*
    ---------------------------------------------
    ---------------  Recent search  -----------------
    ---------------------------------------------
  */
  recentSearch(product: any) {
    //for local storage
    let savedValues: any[] = JSON.parse(
      localStorage.getItem("userRecentSearchInNosidebar") || "[]"
    );
    // const samp= new Map(savedValues.map(item=>[item.product_id,item]))
    // console.log(savedValues);
    const isProductInLocalStorage = savedValues.some((item) => {
      return item.product_id === product.product_id;
    });
    // console.log(isProductInLocalStorage);

    if (!isProductInLocalStorage) {
      savedValues.push(product);
      const action = "view_product";
      this.sendUserAction(product.product_id, action);
      const uniqueProducts = Array.from(
        new Map(savedValues.map((item) => [item?.product_id, item])).values()
      );
      // savedValues = [...new Set(savedValues)];
      localStorage.setItem(
        "userRecentSearchInNosidebar",
        JSON.stringify(uniqueProducts)
      );
      // console.log("Values saved to localStorage:", savedValues);
    }
  }

  /*
    ---------------------------------------------
    ---------------  User / non-user Action  -----------------
    ---------------------------------------------
  */
  sendUserAction(id, action) {
    const session_id = JSON.parse(localStorage.getItem("anonymous-user-id"));
    const user_agent = JSON.parse(localStorage.getItem("User Agent"));
    const user_id = JSON.parse(localStorage.getItem("username")) || null;
    const IP = JSON.parse(localStorage.getItem("IP")) || null;

    const data = {
      user_id: user_id,
      session_id: session_id,
      ip_address: IP,
      user_agent: user_agent,
      action_type: action,
      product_id: id,
      search_term: null,
      search_type: null,
      order_id: null,
      details: {
        filters: [],
        sort_order: "asc",
      },
    };
    // console.log(data);
    this.userService.addAction(data).subscribe();
  }

  /*
    ---------------------------------------------
    ---------------  Wish List  -----------------
    ---------------------------------------------
  */

  // Get Wishlist Items
  private wishlistSubject = new BehaviorSubject<any[]>(state.wishlist);

  public updateWishlist(items: any) {
    this.wishlistSubject.next(items);
    state.wishlist = items;
  }

  public get wishlistItems() {
    return this.wishlistSubject.asObservable();
  }

  // public get wishlistItems(): Observable<Product[]> {
  //   const itemsStream = new Observable((observer) => {
  //     observer.next(state.wishlist);
  //     observer.complete();
  //   });
  //   return <Observable<Product[]>>itemsStream;
  // }

  // Add to Wishlist
  public addToWishlist(product): any {
    // console.log(product);
    let wishlistItem;

    if (state.wishlist) {
      wishlistItem = state.wishlist.find((item) => {
        item.product_id === product.product_id &&
          item.variants[0].sku == product.variants[0].sku;
      });
    }
    if (!wishlistItem) {
      state.wishlist.push({
        ...product,
        quantity: 1,
      });
      const action = "wishlist_add";
      this.sendUserAction(product.product_id, action);
    }
    // const uniqueProducts: any[] = Array.from(
    //   new Map(
    //     state.wishlist.map((product) => [
    //       product.product_id && product.variant_id,
    //       product,
    //     ])
    //   ).values()
    // );
    // console.log(uniqueProducts);
    // this.wishlistSubject.next(state.wishlist);
    this.toastrService.success("Product has been added in wishlist.");
    this.updateWishlist(state.wishlist);
    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));

    const addWish: any[] = product;

    const add = {
      product_id: product.product_id,
      variant_id: product.variants[0].sku,
    };
    // console.log(add);
    this.userService.addWishlistData(add).subscribe((res) => {
      // console.log("added Wishlist!", res);
    });

    return true;
  }

  // Remove Wishlist items
  public removeWishlistItem(product: any): any {
    const index = state.wishlist.findIndex(
      (item) => item.variants[0].sku === product.variants[0].sku
    );

    // console.log(index);
    const deleted = state.wishlist.splice(index, 1);
    // console.log(state.wishlist);
    this.updateWishlist(state.wishlist);

    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    const delWish: any[] = state.wishlist;
    // this.addToWishlist()
    const action = "wishlist_remove";
    this.sendUserAction(product.product_id, action);

    deleted.forEach((res) => {
      const del = {
        product_id: res.product_id,
        variant_id: res.variants[0].sku,
        action: "Removed",
        is_active: false,
      };
      // console.log(del);
      this.userService.DeleteWishlistData(del).subscribe((res) => {
        // console.log("Deleted Wishlist!", res);
        this.userService.getWishlistData()?.subscribe((res) => {
          // console.log(res);
          const data = JSON.parse(res.body);
          // console.log(data);
        });
      });
    });

    return true;
  }

  public wishlistToCheckOut(products: any[]): any {
    const productsToRemove = products.map((product) => ({
      product_id: product.product_id,
      variant_id: product.sku,
    }));

    const deletedProducts = state.wishlist.filter((item) => {
      return productsToRemove.some(
        (p) =>
          p.product_id === item.product_id &&
          p.variant_id === item.variants[0].sku
      );
    });

    state.wishlist = state.wishlist.filter((item) => {
      return !productsToRemove.some(
        (p) =>
          p.product_id === item.product_id &&
          p.variant_id === item.variants[0].sku
      );
    });

    localStorage.setItem("wishlistItems", JSON.stringify(state.wishlist));
    this.updateWishlist(state.wishlist);
    // console.log("Updated wishlist", state.wishlist);

    deletedProducts.forEach((res) => {
      const del = {
        product_id: res.product_id,
        variant_id: res.variants[0].sku,
        action: "Moved to Checkout",
        is_active: false,
      };
      const action = "wishlist_remove";
      this.sendUserAction(res.product_id, action);

      this.userService.DeleteWishlistData(del).subscribe((response) => {
        // console.log("Deleted Wishlist Value", response);
      });
    });

    return true;
  }

  /*
    ---------------------------------------------
    -------------  Compare Product  -------------
    ---------------------------------------------
  */

  // Get Compare Items
  public get compareItems(): Observable<Product[]> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.compare);
      observer.complete();
    });
    return <Observable<Product[]>>itemsStream;
  }

  // Add to Compare
  public addToCompare(product): any {
    const compareItem = state.compare.find(
      (item) => item.product_id === product.product_id
    );

    if (!compareItem) {
      state.compare.push({
        ...product,
      });
    }
    this.toastrService.success("Product has been added in compare.");
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true;
  }

  // Remove Compare items
  public removeCompareItem(product: Product): any {
    const index = state.compare.indexOf(product);
    state.compare.splice(index, 1);
    localStorage.setItem("compareItems", JSON.stringify(state.compare));
    return true;
  }

  /*
    ---------------------------------------------
    -----------------  Cart  --------------------
    ---------------------------------------------
  */

  // Get Cart Items

  private Cartsubject = new BehaviorSubject<any[]>(state.cart);

  public updateCart(newCart: any) {
    this.Cartsubject.next(newCart);
    state.cart = newCart;
  }

  public get cartItems() {
    return this.Cartsubject.asObservable();
  }

  // Add to Cart
  public addToCart(product): any {
    // console.log(product);
    let cartItem;
    if (state.cart) {
      cartItem = state.cart.find(
        (item) =>
          item.product_id === product.product_id &&
          item.variants[0].sku == product.variants[0].sku
      );
    }
    const action = "add_to_cart";
    this.sendUserAction(product.product_id, action);
    //  const uniqueProducts:Product[] = Array.from(new Map(state.cart.map(product=>[product.product_id,
    //   product
    //  ])).values())

    //  const uniqueProducts: Product[] = Array.from(
    //   new Map(state.wishlist.map(product => [product.product_id, product])).values()
    // );
    const qty = product.quantity ? product.quantity : 1;
    const items = cartItem ? cartItem : product;
    const stock = this.calculateStockCounts(items, qty);

    // console.log(stock);

    if (!stock) return false;

    if (cartItem) {
      const maxAllowedQty = product.variants[0].stock;
      // console.log(maxAllowedQty);
      // console.log(cartItem.quantity);
      // console.log(qty);
      if (cartItem.quantity < maxAllowedQty && qty <= maxAllowedQty) {
        if (qty == maxAllowedQty) {
          cartItem.quantity = maxAllowedQty - cartItem.quantity;
          product.quantity = cartItem.quantity;
        } else {
          cartItem.quantity += qty;
          if (cartItem.quantity >= maxAllowedQty) {
            product.quantity = maxAllowedQty;
          }
          product.quantity = cartItem.quantity;
        }
        // console.log(cartItem.quantity);
      } else {
        cartItem.quantity = maxAllowedQty;
        product.quantity = 0;

        // console.log(cartItem.quantity);
      }
      // console.log(qty);
      // console.log(cartItem.quantity);
    } else {
      state.cart.push({
        ...product,
        quantity: qty,
      });
    }

    this.OpenCart = true; // If we use cart variation modal
    // console.log(state.cart);
    this.updateCart(state.cart);
    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    this.localValues = product;
    // console.log(this.localValues);
    // this.localValues.map((res)=>{
    //   const item = {
    //         product_id : res.product_id,
    //         variant_id : res.variants[0]?.sku,
    //         quantity:res.quantity,
    //       }

    const variant = product.variants[0];
    const item1 = {
      product_id: product.product_id,
      variant_id: variant.sku,
      quantity: product.quantity,
    };

    // console.log(item1);
    // this.authService.isAuthenticated().then(async (authenticated) => {
    //   this.isAuthenticated = authenticated;
    // console.log(authenticated);
    // if (this.isAuthenticated) {
    this.userService.postCartData(item1).subscribe(async (res) => {
      // console.log("db", res);
      if (res) {
        console.log("user-cart");
        await this.router.navigate(["/shop/cart"], {
          queryParams: { tab: 2 },
        });
      } else {
        await this.router.navigate(["/shop/cart"], {
          queryParams: { tab: 2 },
        });
      }
    });
    // }
    // else {
    //   this.router.navigate(["/shop/cart"], { queryParams: { tab: 2 } });
    // }
    // });

    // })
    return true;
  }

  // Update Cart Quantity
  public updateCartQuantity(
    product: Product,
    quantity: number,
    api_call: boolean = false
  ): Product {
    return state.cart.find((items, index) => {
      // console.log(items,index)
      if (
        items.product_id == product.product_id &&
        items.variants[0].sku == product.variants[0].sku
      ) {
        const qty = state.cart[index].quantity + quantity;
        const stock = this.calculateStockCounts(state.cart[index], quantity);
        if (qty > 0 && stock) {
          // Check if newQty exceeds stock limit
          const maxAllowedQty = product.variants[0].stock;

          if (qty <= maxAllowedQty) {
            state.cart[index].quantity = qty;
          } else {
            state.cart[index].quantity = maxAllowedQty; // Set to maximum allowed quantity
            // this.toastrService.info("Your Search Results")
            this.showProductsToast(maxAllowedQty);
          }
        } else if (qty <= 0) {
          state.cart[index].quantity = 1; // Ensure quantity does not go below 0
        }
        this.updateCart(state.cart);

        localStorage.setItem("cartItems", JSON.stringify(state.cart));
        let updateValue: any[] = state.cart;
        // updateValue.forEach((res)=>{
        const variant = product.variants;
        const updateCardValue = {
          product_id: product.product_id,
          variant_id: product.variants[0].sku,
          quantity: product.quantity,
          is_active: true,
        };
        // console.log(updateCardValue);
        if (api_call) {
          // console.log("Now Executing Api Call");
          this.userService.updateCartData(updateCardValue).subscribe((res) => {
            // console.log("Update cart Value", res);
          });
        }

        // })
      }
    });
  }

  showProductsToast(product: any) {
    this.toastr.success(
      `Maximum Order Quantity is  ${product} for this Product.`,
      "Alert",
      {
        // closeButton: true,
        // progressBar: true,
        // timeOut: 5000,
        // extendedTimeOut: 2000,
        toastClass: "toast-error", // Apply custom CSS class if needed
      }
    );
  }
  // Calculate Stock Counts
  public calculateStockCounts(product, quantity) {
    const qty = product.quantity + quantity;
    const stock = product.stock;
    if (stock < qty || stock == 0) {
      this.toastrService.error(
        "You can not add more items than available. In stock " +
          stock +
          " items."
      );
      return false;
    }
    return true;
  }

  // Remove Cart items
  public removeCartItem(product: Product): any {
    const index = state.cart.indexOf(product);
    const deleted = state.cart.splice(index, 1);
    this.updateCart(state.cart);
    const action = "remove_from_cart";
    this.sendUserAction(product.product_id, action);

    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    const DeleteCart: any[] = state.cart;
    // console.log(DeleteCart);

    deleted.forEach((res) => {
      const del = {
        product_id: res.product_id,
        variant_id: res.variants[0].sku,
        action: "Removed",
        is_active: false,
      };

      this.userService.DeleteCartData(del).subscribe((res) => {
        // console.log("deleted Cart Value", res);
      });
    });

    return true;
  }

  public cartToCheckOut(products: any[]): any {
    // console.log("enters");

    const productsToRemove = products.map((product) => ({
      product_id: product.product_id,
      variant_id: product.sku,
    }));

    const deletedProducts = state.cart.filter((item) => {
      return productsToRemove.some(
        (p) =>
          p.product_id === item.product_id &&
          p.variant_id === item.variants[0].sku
      );
    });

    state.cart = state.cart.filter((item) => {
      return !productsToRemove.some(
        (p) =>
          p.product_id === item.product_id &&
          p.variant_id === item.variants[0].sku
      );
    });

    localStorage.setItem("cartItems", JSON.stringify(state.cart));
    this.updateCart(state.cart);
    // console.log("Updated Cart", state.cart);

    deletedProducts.forEach((res) => {
      const del = {
        product_id: res.product_id,
        variant_id: res.variants[0].sku,
        action: "Moved to Checkout",
        is_active: false,
      };

      const action = "checkout";
      this.sendUserAction(res.product_id, action);
      this.userService.DeleteCartData(del).subscribe((response) => {
        // console.log("Deleted Cart Value", response);
      });
    });

    return true;
  }

  // Total amount
  public cartTotalAmount(): Observable<number> {
    return this.cartItems.pipe(
      map((product: Product[]) => {
        return product.reduce((prev, curr: Product) => {
          let price = curr.price;
          if (curr.discount) {
            price = curr.price - (curr.price * curr.discount) / 100;
          }
          return (prev + price * curr.quantity) * this.Currency.price;
        }, 0);
      })
    );
  }

  /*
    ---------------------------------------------
    ------------  Filter Product  ---------------
    ---------------------------------------------
  */

  // Get Product Filter

  public filterProducts(
    brands: string[],
    colors: string[],
    sizes: string[],
    search: string
  ): Observable<Product[]> {
    // console.log(brands)
    return this.filteredProductsBySearch(search).pipe(
      map((product: any) => {
        if (typeof product.body === "string") {
          this.productList = JSON.parse(product.body);
        } else {
          this.productList = product;
        }
        return this.productList.filter((product: any) => {
          // Check if product brand matches any of the brands in the filter
          const brandMatch = !brands.length || brands.includes(product.brand);
          // const  collectionMatch = product.filter(item=> item.collection.includes(collection))
          // Check if any variant matches the color and size filters
          const variantMatch = product.variants.some((variant) => {
            const colorMatch =
              !colors.length ||
              colors.some(
                (color) =>
                  variant.colors && variant.colors.includes(color.trim())
              );
            const sizeMatch = !sizes.length || sizes.includes(variant.size);
            return colorMatch && sizeMatch;
          });

          return brandMatch && variantMatch;
        });
      })
    );
  }

  // Sorting Filter
  public sortProducts(products: Product[], payload: string): any {
    // console.log(typeof products);

    if (payload === "ascending") {
      return products.sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        } else if (a.id > b.id) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "a-z") {
      return products.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        } else if (a.title > b.title) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "z-a") {
      return products.sort((a, b) => {
        if (a.title > b.title) {
          return -1;
        } else if (a.title < b.title) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "low") {
      return products.sort((a, b) => {
        if (a.price < b.price) {
          return -1;
        } else if (a.price > b.price) {
          return 1;
        }
        return 0;
      });
    } else if (payload === "high") {
      return products.sort((a, b) => {
        if (a.price > b.price) {
          return -1;
        } else if (a.price < b.price) {
          return 1;
        }
        return 0;
      });
    }
  }

  /*
    ---------------------------------------------
    ------------- Product Pagination  -----------
    ---------------------------------------------
  */
  public getPager(
    totalItems: number,
    currentPage: number = 1,
    pageSize: number = 16
  ) {
    // calculate total pages
    let totalPages = Math.ceil(totalItems / pageSize);

    // Paginate Range
    let paginateRange = 3;

    // ensure current page isn't out of range
    if (currentPage < 1) {
      currentPage = 1;
    } else if (currentPage > totalPages) {
      currentPage = totalPages;
    }

    let startPage: number, endPage: number;
    if (totalPages <= 5) {
      startPage = 1;
      endPage = totalPages;
    } else if (currentPage < paginateRange - 1) {
      startPage = 1;
      endPage = startPage + paginateRange - 1;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    // calculate start and end item indexes
    let startIndex = (currentPage - 1) * pageSize;
    let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
      (i) => startPage + i
    );

    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages,
    };
  }
}
function lastValueFrom(
  arg0: Observable<
    {
      title: any;
      description: any;
      variants: any;
      brand: any;
      tags: any;
      sale: any;
      product_id: any;
      new: any;
      collection: any;
      category: any;
      discount: any;
      type: any;
      price: any;
    } & import("ngx-indexed-db").WithID
  >
) {
  throw new Error("Function not implemented.");
}
