import {
  Component,
  OnInit,
  Input,
  HostListener,
  Output,
  ViewChild,
  AfterViewInit,
  ElementRef,
  AfterViewChecked,
} from "@angular/core";
import { LoginModelComponent } from "../../components/modal/login/login.component";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Menu, NavService } from "../../services/nav.service";
import { AuthService } from "../../services/auth.service";
import { userService } from "../../services/user.service";
import { ProductService } from "../../services/product.service";

@Component({
  selector: "app-header-one",
  templateUrl: "./header-one.component.html",
  styleUrls: ["./header-one.component.scss"],
})
export class HeaderOneComponent implements OnInit {
  @Input() class: string;
  @Input() themeLogo: string = "assets/images/logos/sc-name-dark-40x140px.svg"; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false

  @ViewChild("user") element: ElementRef;
  public stick: boolean = false;
  selectedTab: number = 0;
  isToggled: boolean = false;
  public menuItems: Menu[];
  loggedIn: string | undefined;
  getDeliveryAddress: any[] = [];
  totalBrands: any[] = [];
  uniqueBrands: any[] = [];
  isAuthenticated: boolean = false;
  products: any[];
  category: string;
  categories: any[] = [];
  uniqueCategories: any[] = [];
  brandForSide: any[] = [];
  uniquebrandForSide: any[] = [];
  anArrayForSideFilter: any;
  getIsDefaultAddressOnly: any | undefined;
  opened: boolean = false;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    public navServices: NavService,
    private authservice: AuthService,
    private userService: userService,
    private productService: ProductService
  ) {
    this.navServices.leftMenuItems.subscribe(
      (menuItems) => (this.menuItems = menuItems)
    );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
    this.productService.getProductClassifications().subscribe((data: any) => {
      const allData = JSON.parse(data.body);
      this.products = allData.filter((item) => item.type == "HELMET");
      // console.log(this.products)
      this.products.forEach((data) => this.totalBrands.push(data.brand.trim()));
      this.uniqueBrands = [...new Set(this.totalBrands)];
      // console.log(this.uniqueBrands)
      this.products.filter((item: any) => {
        if (item.category == "SPARES") {
        } else {
          this.categories.push(item.category);
        }
        this.uniqueCategories = [...new Set(this.categories)];
      });
      // console.log(  this.uniqueCategories)
    });
  }

  isTreeOpen = false; // Initial state of the tree
  checkBrandFull: boolean = false;
  checkBrandHalf: boolean = false;
  checkBrandOff: boolean = false;
  checkBrandFlip: boolean = false;
  ngOnInit(): void {
    this.route.queryParams.subscribe((res) => {
      this.selectedTab = +res["tab"] || 0;
    });

    //  console.log(this.authservice.isAuthenticatedUser(),this.authservice.isAuthenticatedBool)
    this.checkAuthentication();
  }

  checkedItems: Set<string> = new Set();
  onCheckboxChange(event: any, item: string): void {
    if (event.checked) {
      this.checkedItems.add(item);
    } else {
      this.leftMenuToggle();
      this.checkedItems.delete(item);
    }
    // console.log(this.checkedItems);
    const brandsString = Array.from(this.checkedItems).join(",");
    if (this.checkBrandFull) {
      this.router.navigate(["/shop"], {
        queryParams: { category: "FULL FACE", brand: brandsString, tab: 4 },
      });
      this.leftMenuToggle();
    } else if (this.checkBrandHalf) {
      this.router.navigate(["/shop"], {
        queryParams: { category: "HALF FACE", brand: brandsString, tab: 4 },
      });
      this.leftMenuToggle();
    } else if (this.checkBrandOff) {
      this.router.navigate(["/shop"], {
        queryParams: { category: "OFF ROAD", brand: brandsString, tab: 4 },
      });
      this.leftMenuToggle();
    } else if (this.checkBrandFlip) {
      this.router.navigate(["/shop"], {
        queryParams: { category: "FLIP UP", brand: brandsString, tab: 4 },
      });
      this.leftMenuToggle();
    }
  }
  isChecked(item: string) {
    return this.checkedItems.has(item);
  }
  leftMenuRoutes(title: string) {
    const category = title;
    if (category == "FULL FACE") {
      this.checkBrandFull = !this.checkBrandFull;
      this.checkBrandHalf = false;
      this.checkBrandFlip = false;
      this.checkBrandOff = false;
    } else if (category == "HALF FACE") {
      this.checkBrandHalf = !this.checkBrandHalf;
      this.checkBrandFull = false;
      this.checkBrandFlip = false;
      this.checkBrandOff = false;
    } else if (category == "FLIP UP") {
      this.checkBrandFlip = !this.checkBrandFlip;
      this.checkBrandFull = false;
      this.checkBrandHalf = false;
      this.checkBrandOff = false;
    } else if (category == "OFF ROAD") {
      this.checkBrandOff = !this.checkBrandOff;
      this.checkBrandFull = false;
      this.checkBrandHalf = false;
      this.checkBrandFlip = false;
    }
    this.category = category;
    this.categoryViseBrand(this.category);
    // this.leftMenuToggle()

    // this.router.navigate(['/shop'],{queryParams:{category:category}})
  }
  categoryViseBrand(category: string) {
    this.brandForSide = [];
    this.products.filter((item: any) => {
      // console.log(category);
      if (category == item.category) {
        this.brandForSide.push(item.brand);
      }
    });
    this.uniquebrandForSide = [...new Set(this.brandForSide)];
    this.anArrayForSideFilter = {
      category: category,
      brands: this.uniquebrandForSide,
    };

    // console.log(this.anArrayForSideFilter);
  }

  leftMenuRoutesAll() {
    this.router.navigate(["/shop"], {
      queryParams: { category: this.category, tab: 4 },
    });
    this.leftMenuToggle();
  }

  hideLeft: boolean = false;
  hideLogo = false;
  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (number >= 150 && window.innerWidth > 350) {
      this.stick = true;
    } else {
      this.stick = false;
    }

    const currentScrollPosition =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0;
    if (currentScrollPosition > 0) {
      this.hideLogo = true;
    } else {
      this.hideLogo = false;
    }
  }
  checkAuthentication(): void {
    // this.authservice.isAuthenticatedUser();
    // this.authservice.isAuthenticated().then((authenticated) => {
    //   this.isAuthenticated = authenticated;
    // console.log(this.isAuthenticated)
    // if (this.isAuthenticated) {
    this.authservice.authFUser?.subscribe((res) => {
      // console.log(res);
      if (res) {
        this.isAuthenticated = true;
        console.log("User is authenticated");

        // this.authservice.isAuthFUser();
      }
      if (this.isAuthenticated === true) {
        this.userService.getNewAddress()?.subscribe((res) => {
          this.getDeliveryAddress = JSON.parse(res?.body);
          this.getDeliveryAddress?.forEach((res) => {
            if (res?.is_default == true) {
              this.getIsDefaultAddressOnly = res;
            }
          });
          //  console.log(this.getDeliveryAddress);
          //  console.log(this.getIsDefaultAddressOnly);
        });
      }
      // this.loggedIn = res.attributes.name;
      // console.log(this.loggedIn);
    });

    //   } else {
    //     console.log("User is not authenticated");
    //   }
    // });
  }

  answer(item: string) {
    // console.log(item);
  }

  openPopup() {
    this.dialog.open(LoginModelComponent, {
      width: "80%",
      height: "50%",
    });
  }

  onTabChange(event: any) {
    const index = event.index;
    this.selectedTab = index;
    // console.log(this.selectedTab)
    switch (index) {
      case 0:
        this.router.navigate(["/home"], { queryParams: { tab: index } });
        break;
      case 1:
        // if (this.isAuthenticated) {
        this.router.navigate(["/pages/new-dashboard"], {
          queryParams: { tab: index },
        });
        // } else {
        //   this.router.navigate(["/login"], {
        //     queryParams: { tab: index },
        //   });
        // }
        break;
      case 2:
        this.router.navigate(["/shop/cart"], { queryParams: { tab: index } });
        break;
      case 3:
        this.router.navigate(["/shop/wishlist"], {
          queryParams: { tab: index },
        });
        break;
      case 4:
        // this.leftMenuToggle();
        // this.router.navigate([],{queryParams:{tab:index}})
        break;
      default:
        break;
    }
    // console.log(index);
  }

  leftMenuToggle(): void {
    // console.log("left menu");
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
    // console.log(this.navServices.leftMenuToggle);
  }

  toggleTree(): void {
    this.isTreeOpen = !this.isTreeOpen; // Toggle the state
  }
  toggletNavActive(item) {
    item.active = !item.active;
  }

  onHover(menuItem) {
    if (window.innerWidth > 1200 && menuItem) {
      document.getElementById("unset").classList.add("sidebar-unset");
    } else {
      document.getElementById("unset").classList.remove("sidebar-unset");
    }
  }

  leftMenuSearchRoutes(title: string) {
    const search = title;
    this.leftMenuToggle();
    this.router.navigate(["/shop"], { queryParams: { category: search } });
  }
  openSub() {
    this.opened = !this.opened;
  }

  helpAndSettings(value: string) {
    const RouteValue = value;
    if (RouteValue == "account") {
      // if (this.isAuthenticated) {
      this.leftMenuToggle();
      this.router.navigate(["/pages/new-dashboard"], {
        queryParams: { tab: 4 },
      });
      // } else {
      //   this.router.navigate(["/login"], { queryParams: { tab: 4 } });
      // }
    } else if (RouteValue == "logout") {
      this.leftMenuToggle();
      this.authservice.signOut().then((res) => console.log(res));
      this.router.navigate(["/login"], { queryParams: { tab: 4 } });
    } else if (RouteValue == "orders") {
      // if (this.isAuthenticated) {
      this.leftMenuToggle();
      this.router.navigate(["/pages/orders"], { queryParams: { tab: 4 } });
      // }
    } else if (RouteValue == "support") {
      this.leftMenuToggle();
      this.router.navigate(["/pages/customer-support"], {
        queryParams: { loader: true, tab: 4 },
      });
    } else if (RouteValue == "compare") {
      this.leftMenuToggle();
      this.router.navigate(["/pages/compare/two"], {
        queryParams: { loader: true, tab: 4 },
      });
    }
  }
}
// /pages/orders?tab=1
