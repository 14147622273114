import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ProductService } from "../../services/product.service";
import { Product } from "../../classes/product";

@Component({
  selector: "app-mat-card-products",
  templateUrl: "./mat-card-products.component.html",
  styleUrls: ["./mat-card-products.component.scss"],
})
export class MatCardProductsComponent implements OnInit {
  title_url: string = "";
  public products: any[] = [];
  productList: any[] = [];
  @Input() product: any;
  @Input() showHistoryIcon: boolean = false;
  topLoaderCounts = [1, 2];
  topLoader: boolean = true;
  constructor(private router: Router, private productService: ProductService) {}
  placeholder_img = "assets/images/product/placeholder.jpg";

  public logo = {
    KORDA: "assets/logo-small/korda-horizontal.png",

    STUDDS: "assets/logo-small/studds-horizontal.png",

    LS2: "assets/logo-small/ls2-horizontal.png",

    STEELBIRD: "assets/logo-small/steelbird-horizontal.png",

    "MT HELMETS": "assets/logo-small/mt-horizontal.png",

    AXOR: "assets/logo-small/axor-horizontal.png",

    RIDEX: "assets/logo-small/ridex-horizontal.png",

    VEGA: "assets/logo-small/vega-horizontal.png",

    SMK: "assets/logo-small/smk-horizontal.png",

    AXXIS: "assets/logo-small/axxis-horizontal.png",
    DSG: "assets/logo-small/dsg-logo.webp",
    SOLACE: "assets/logo-small/solace.png",
    ARK: "assets/logo-small/ark.png",
    // SOLACE: "",

    // VISTA: "",

    // "3M": "",
    // ARK : ''
  };
  public ImageSrc: string;
  ngOnInit(): void {
    // console.log(this.product)
    this.productService.showLoaderSubject.subscribe((data) => {
      if (data == true || data == false) {
        this.topLoader = data;
      }
    });
  }
  sendToBoxOne(item) {
    const id = item.product_id;
    const data = btoa(id);
    const formatTitle = item.title.replace(/\s+/g, "-");

    this.router.navigate(["/shop/details", data, formatTitle]);
    this.productService.recentSearch(item);
  }
}
