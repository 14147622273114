<div class="search-bar" cdkOverlayOrigin #overlayPosition="cdkOverlayOrigin">
  <div>
    <div class="search-container" [class.searchTop]="searchTop">
      <div class="input-group">
        <span
          id="onlyForMobile"
          *ngIf="showBack"
          [@fadeInOut]
          style="
            border-radius: 20px;
            background-color: transparent;
            border: none;
          "
          class="input-group-text"
          (click)="goBack()"
          ><i class="fa fa-arrow-left" style="font-size: large"></i
        ></span>
        <div class="box" style="border-radius: 30px">
          <input
            style="border: none"
            #searchInput
            type="text"
            class="form-control"
            [(ngModel)]="searchTerm"
            placeholder="Search Skullcrown.in"
            (input)="onInputChange($event)"
            (click)="openClose($event)"
            (keyup.enter)="onSearch()"
            (keyup.enter)="overlayClose()"
            (input)="openClose($event)"
          />

          <div (click)="closeIcon(); searchInput.focus()">
            <i class="fa fa-times" *ngIf="overlayOpen"></i>
          </div>

          <div class="input-group-append">
            <span
              class="input-group-text"
              style="cursor: pointer; border-radius: 20px"
              (click)="onSearch()"
            >
              <i
                class="fa fa-search"
                style="font-size: x-large; color: #ff4c3b"
              ></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="overlayPosition"
    [cdkConnectedOverlayOpen]="overlayOpen"
    (overlayOutsideClick)="overlayOpen = false"
    (backdropClick)="overlayOpen = false"
  >
    <div
      class="overlay-container"
      [ngClass]="{ blur: overlayOpen }"
      (click)="overlayClose()"
    >
      <div class="overlay-content">
        <app-search-overlay
          [inputData]="parentData"
          [searchTop]="searchTop"
          (searchFromChild)="receiveData($event)"
          (clickSearch)="receiveSearch($event)"
          (clickCollection)="receiveCollection($event)"
          (forCollectionRoute)="recieveBoolean($event)"
          (click)="overlayClose()"
        ></app-search-overlay>
      </div>
    </div>
  </ng-template>
</div>

<!-- [cdkConnectedOverlayScrollStrategy]="scrollStrategy" -->

<!-- <img src="assets/images/icon/search.png" class="img-fluid" alt="" /> -->
