import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { PinchZoomComponent } from "ngx-pinch-zoom/lib/pinch-zoom.component";

@Component({
  selector: "app-zoom-view",
  templateUrl: "./zoom-view.component.html",
  styleUrls: ["./zoom-view.component.scss"],
})
export class ZoomViewComponent implements OnInit {
  constructor() {}
  @Input() product: string;
  @Input() Images: any;
  allImage: any[] = [];
  @ViewChild("pinch") pinchZoom: PinchZoomComponent;
  ngOnInit(): void {
    // console.log(this.product);
    // console.log(this.Images);
    this.allImage = this.Images;
  }
  imgClick(img: string) {
    this.product = img;
  }
  rightClick() {
    let currentIndex;
    currentIndex = this.allImage.findIndex((image) => image === this.product);
    // console.log(currentIndex);
    if (currentIndex < this.allImage.length - 1) {
      this.product = this.allImage[currentIndex + 1];
    } else {
      this.product = this.allImage[0];
    }
  }
  leftClick() {
    let currentIndex;
    currentIndex = this.allImage.findIndex((image) => image === this.product);
    // console.log(currentIndex);
    if (currentIndex > 0) {
      this.product = this.allImage[currentIndex - 1];
    } else {
      this.product = this.allImage[this.allImage.length - 1];
    }
  }
}
