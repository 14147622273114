<div class="full" *ngIf="!forCondition">
  <div class="col-12">
    <h1 style="text-align: center">Shipping Policy</h1>
    <br />
    <p style="text-align: center">
      <strong> LAST MODIFIED: <span id="date">25 August 2024</span></strong>
    </p>
    <br /><br />
    <p>
      <strong class="website_name company_name">Sparkly Automobiles</strong>, referred to
      as "we" and "us", operates the website (<a
        href="https://skullcornw.in"
        class="website_url"
        ><span class="dashed">skullcrown.in</span></a
      >) referred to as the "Website". When you place an order through this
      Website, you accept the following terms. These terms are established to
      ensure mutual understanding and agreement between both parties regarding
      our services and to protect the interests of both parties.
    </p>
    <br />
    <h4>1. General</h4>
    <p>
      Subject to stock availability, we make an effort to keep accurate stock
      numbers on our website. However, on occasion, there may be an error in our
      stock count, leading to a situation where we cannot fulfill all your items
      at the time of purchase. If this occurs, we will deliver the available
      products to you and get in touch with you to determine if you prefer to
      wait for the restocked item or receive a refund.
    </p>
    <br />
    <h4>2. Shipping Costs</h4>
    <p>
      The shipping fee is calculated during the checkout process, based on the
      weight, dimensions, and delivery location of the items in the order. The
      shipping payment will be added to the overall purchase amount and will
      serve as the final cost for the customer.
    </p>
    <p><br /></p>
    <h4>3. Returns</h4>
    <h5>3.1 Return Due To Change Of Mind</h5>
    <p>
      At <span class="website_name company_name">skullcrown</span>, we are more
      than happy to accommodate returns based on a change of mind, provided that
      the request for return is received by us within
      <strong class="days-return"><span class="dashed">5</span></strong> days of
      receipt of the item.
    </p>
    <p>
      The item must be returned in its original packaging, unopened and in a
      condition that is suitable for resale.
    </p>
    <p>
      The customer will be responsible for paying for the return shipping and
      must arrange for the shipping themselves.
    </p>
    <p>
      Once the return is received and accepted, we will process a refund in the
      form of store credit for a future purchase.
      <a
        href="https://seguidores.app.br"
        style="text-decoration: none; color: black; cursor: auto"
        >seguidores.app.br</a
      >. We will notify you via email once the process is completed.
    </p>
    <p>
      Please note that while we will refund the value of the returned goods, we
      will not refund the shipping costs that were originally paid by the
      customer.
    </p>
    <h5>3.2 Warranty Returns</h5>
    <p>
      <span class="website_name company_name">skullcrown</span> is committed to
      fulfilling all legitimate warranty claims, but they must be submitted
      within 90 days of receiving the items.
    </p>
    <p>
      To facilitate the return process, customers will need to pay for the
      shipping upfront, but
      <span class="website_name company_name">skullcrown</span> will reimburse
      the cost upon a successful warranty claim.
    </p>
    <p>
      Once <span class="website_name company_name">skullcrown</span> receives
      the returned items for a warranty claim, they aim to process it within 7
      days.
    </p>
    <ul>
      Upon confirming your warranty claim, you will have the option to choose
      from:
      <li>A reimbursement to the payment method used;</li>
      <li>An in-store credit refund;</li>
      <li>Receiving a new item (if stock is readily available).</li>
    </ul>
    <br />
    <h4>4. Delivery Terms</h4>
    <h5>4.1 Transit Time Domestically</h5>
    <p>
      Typically, it takes 2 to 7 days for domestic shipments to be delivered.
    </p>
    <h5>4.2 Transit time Internationally</h5>
    <p>
      International shipping typically takes between 4 to 22 days in transit,
      with the exact duration dependent on the courier chosen. An accurate
      estimate can be provided during the checkout process when selecting the
      courier.
    </p>
    <h5>4.3 Dispatch time</h5>
    <p class="dispatch-time"></p>
    <p class="same-day-dispatch-no">
      Orders are typically sent out within 2 working days after payment has been
      received.
    </p>
    <p>
      The warehouse operates from Monday to Friday during standard business
      hours, except on national holidays when it is closed. During these
      holidays, measures are taken to minimize shipment delays.
    </p>
    <h5>4.4 Change Of Delivery Address</h5>
    <p>
      Regarding requests for changing the delivery address, we can alter it
      prior to the dispatch of the order.
    </p>
    <h5>4.5 P.O. Box Shipping</h5>
    <p>
      <span class="website_name company_name">skullcrown</span> will only use
      postal services for delivering to P.O. box addresses. Courier services are
      not available for these types of addresses.
    </p>
    <h5>4.6 Military Address Shipping</h5>
    <p>
      The shipping of packages to military addresses is possible with USPS,
      however, courier services do not offer this service.
    </p>
    <h5>4.7 Items Out Of Stock</h5>
    <p class="out-stock"></p>
    <p class="available-stock">
      Your order will not be dispatched until the out-of-stock item becomes
      available. In the meantime, the other items in your order will be kept on
      hold.
    </p>
    <p class="partial-stock">
      In case an item is not available, we will promptly send the available
      items and will send the rest of them as soon as they are back in stock.
    </p>
    <p class="all-stock">
      In the event of an item being unavailable, we will process a cancellation
      and refund for the out-of-stock item and proceed with shipping the
      remaining items in the order.
    </p>
    <p>
      In the event of an item being unavailable, we will process a cancellation
      and refund for the out-of-stock item and proceed with shipping the
      remaining items in the order.
    </p>
    <h5>4.8 Delivery Time Exceeded</h5>
    <p>
      Please contact us if the delivery time has surpassed the expected delivery
      window, so we can initiate an inquiry.
    </p>
    <br />
    <h4>5. Tracking Notifications</h4>
    <p>
      Once their order is dispatched, customers will receive a tracking link
      which will allow them to monitor the status of their shipment, as updated
      by the shipping provider.
    </p>
    <br />
    <h4>6. Parcels Damaged In Transit</h4>
    <p>
      In the event of a damaged parcel during transit, kindly reject it from the
      courier if possible and reach out to our customer service team. If the
      parcel was delivered in your absence, please inform our customer service
      team for further instructions.
    </p>
    <br />
    <h4>7. Duties &amp; Taxes</h4>
    <h5>7.1 Sales Tax</h5>
    <p>
      Sales tax has already been applied to the price of the goods as displayed
      on the website.
    </p>
    <h5>7.2 Import Duties &amp; Taxes</h5>
    <p class="duties">
      <span class="on-arrival"
        ><p>
          International shipments may be subject to import duties and taxes upon
          arrival in the destination country. These fees vary by country, and we
          advise you to familiarize yourself with these potential costs prior to
          placing an order with us.
        </p>
        <p>
          If you decline to pay duties and taxes upon reaching your destination
          country, the merchandise will be shipped back at your expense. You
          will receive a reimbursement for the amount paid for the goods, minus
          the cost of the return shipment. The initial shipping fee will not be
          included in the refund.
        </p></span
      >
    </p>
    <br />
    <h4>8. Cancellations</h4>
    <p>
      You may cancel your order at any time prior to dispatch. However, if your
      order has already been dispatched, kindly refer to our refund policy for
      further information.
    </p>
    <br />
    <h4>9. Insurance</h4>
    <p>
      The insurance coverage for loss and damage of parcels is up to the value
      declared by the courier.
    </p>
    <h5>9.1 Process for parcel damaged in-transit</h5>
    <p>
      We will process a refund or replacement as soon as the courier has
      completed their investigation into the claim.
    </p>
    <h5>9.2 Process for parcel lost in-transit</h5>
    <p>
      As soon as the courier finishes investigating the claim, we will proceed
      with either a refund or a replacement.
    </p>
    <br />
    <h4>10. Customer service</h4>
    <p class="email-text"></p>
    <p class="email">
      For all customer service enquiries, please email us at
      <a href="mailto:support@sparklyautomobiles.com" class="website_email">contact</a>.
    </p>
  </div>
</div>

<div *ngIf="forCondition">
  <div class="conditions-container">
    <h1>Conditions of Use</h1>
    <p>
      Welcome to our website. By accessing and using this site, you agree to the
      following terms and conditions:
    </p>
    <hr />

    <h2>1. Acceptance of Terms</h2>
    <p>
      By using this site, you agree to these conditions of use. If you do not
      agree to these terms, please do not use this site.
    </p>
    <hr />
    <h2>2. Changes to Terms</h2>
    <p>
      We reserve the right to modify these terms at any time. Changes will be
      effective immediately upon posting on the site.
    </p>
    <hr />

    <h2>3. User Responsibilities</h2>
    <p>
      You agree to use the site only for lawful purposes and in a manner that
      does not infringe on the rights of others.
    </p>
    <hr />

    <h2>4. Intellectual Property</h2>
    <p>
      All content on this site is the property of Skullcrown. You may not
      reproduce or distribute any content without permission.
    </p>
    <hr />

    <h2>5. Limitation of Liability</h2>
    <p>
      We are not liable for any damages arising from the use of this site or the
      inability to use this site.
    </p>
    <hr />

    <h2>6. Governing Law</h2>
    <p>These terms are governed by the laws of Indian government.</p>
    <hr />

    <h2>7. Contact Information</h2>
    <p>
      If you have any questions about these terms, please contact us at support@sparklyautomobiles.com.
    </p>
  </div>
</div>
