import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
  public closeResult: string;
  public modalOpen: boolean = false;

  @ViewChild("registerModal", { static: false })
  RegisterModal: TemplateRef<any>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {}

  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalService
        .open(this.RegisterModal, {
          size: "md",
          ariaLabelledBy: "register-modal",
          centered: true,
          windowClass: "RegisterModal",
        })
        .result.then(
          (result) => {
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  signUp(event: Event) {
    const value = event.target as HTMLInputElement;
    // console.log(value);
  }
}
