import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-privacy-notice",
  templateUrl: "./privacy-notice.component.html",
  styleUrls: ["./privacy-notice.component.scss"],
})
export class PrivacyNoticeComponent implements OnInit {
  constructor() {}
  @Input() forCondition: boolean;

  ngOnInit(): void {
    // console.log(this.forCondition);
  }
}
