<!-- <svg width="200" height="200"> -->
    <!-- Circle background -->
    <!-- <circle cx="100" cy="100" r="90" stroke="black" stroke-width="2" fill="none" /> -->
  
    <!-- First half circle segment -->
    <!-- <path d="M100,100 L100,10 A90,90 0 0,1 190,100 Z" [attr.fill]="colors[0]" /> -->
  
    <!-- Second half circle segment -->
    <!-- <path d="M100,100 L100,10 A90,90 0 0,0 10,100 Z" [attr.fill]="colors[1]" />
  </svg> -->
  <div class="circle-container" *ngIf="color?.length  == 1">
    <div class="circle half-circle left-half" [ngStyle]="{'background-color': color[0]}" ></div>
    <div class="circle half-circle right-half" [ngStyle]="{'background-color': color[0]}"></div>
  </div>

  <div class="circle-container" *ngIf="color?.length == 2">
    <div class="circle half-circle left-half" [ngStyle]="{'background-color': color[0]}" ></div>
    <div class="circle half-circle right-half" [ngStyle]="{'background-color': color[1]}"></div>
  </div>

  <!-- <div class="custom-control custom-checkbox collection-filter-checkbox"
  *ngFor="let color of filterbycolor">
  <input 
    type="checkbox" 
    [value]="color"
    [checked]="checked(color)"
    [id]="color"
    (change)="appliedFilter($event)"
    class="custom-control-input" />
  <span [class]="color" [ngStyle]="{'background-color': color}"></span>
  <label class="custom-control-label" for="{{ color }}">{{ color }}</label>
</div> -->
