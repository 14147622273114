<app-breadcrumb
  [title]="'Register'"
  [breadcrumb]="'Register'"
  *ngIf="!confirmation"
></app-breadcrumb>
<!-- section start -->
<!-- <div *ngIf="!confirm && loader">
  <mat-spinner></mat-spinner>
</div> -->
<section class="register-page section-b-space" *ngIf="!confirm && !loader">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <h3>create account</h3>
        <div
          *ngIf="signUpError"
          class="alert alert-danger"
          style="display: flex; align-items: center"
          role="alert"
        >
          <p
            style="
              display: flex;
              margin-bottom: 0;
              line-height: 0px;
              margin-top: 0;
            "
          >
            user already exist, please
            <a
              style="margin-bottom: 0; color: blue; cursor: pointer"
              [routerLink]="'/pages/login'"
            >
              _login</a
            >
          </p>
          <br />
          <!-- <a style="display: flex;"><p style="margin-bottom: 0;">Already a user  sign_in</p></a> -->
        </div>
        <div class="theme-card">
          <form class="theme-form" [formGroup]="Register">
            <div class="form-row">
              <div class="col-md-6 mt-2">
                <label for="fname">First Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="fname"
                  formControlName="firstname"
                  placeholder="First Name"
                  [ngStyle]="{ border: signUpError ? '1px solid red' : '' }"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    !Register.get('firstname')?.valid &&
                    Register.get('firstname')?.touched
                  "
                  >Firstname is required</span
                >
              </div>
              <div class="col-md-6 mt-2">
                <label for="lname">Last Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="lname"
                  formControlName="lastname"
                  placeholder="Last Name"
                  [ngStyle]="{ border: signUpError ? '1px solid red' : '' }"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    !Register.get('lastname')?.valid &&
                    Register.get('lastname')?.touched
                  "
                  >Lastname is required</span
                >
              </div>
            </div>
            <div class="form-row">
              <div class="col-md-6 mt-2">
                <label for="email">email</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  formControlName="email"
                  placeholder="Email"
                  [ngStyle]="{ border: signUpError ? '1px solid red' : '' }"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    !Register.get('email')?.valid &&
                    Register.get('email')?.touched
                  "
                  >Valid Email is required</span
                >
              </div>
              <div class="col-md-6 mt-2">
                <label for="password">Password</label>
                <input
                  type="password"
                  class="form-control"
                  [ngStyle]="{ border: signUpError ? '1px solid red' : '' }"
                  id="password"
                  formControlName="password"
                  placeholder="Enter your password"
                />
                <div
                  class="text-danger"
                  *ngIf="
                    !Register.get('password')?.valid &&
                    (Register.get('password')?.dirty ||
                      Register.get('password')?.touched)
                  "
                >
                  <span
                    class="text-danger"
                    *ngIf="Register.get('password')?.errors.required"
                    >Password is required</span
                  >
                  <span
                    class="text-danger"
                    *ngIf="Register.get('password')?.errors.pattern"
                  >
                    <p class="text-danger">
                      Password must be at least 8 characters long
                    </p>
                    <p class="text-danger">
                      Password must contain atleast one uppercase
                    </p>
                    <p class="text-danger">
                      Password must contain atleast one lowercase
                    </p>
                    <p class="text-danger">
                      Password must contain atleast one number
                    </p>
                    <p class="text-danger">
                      Password must contain atleast one special characeter
                    </p>
                  </span>
                </div>
              </div>
              <button
                class="btn btn-solid mt-2"
                (click)="signUp()"
                [disabled]="!Register.valid || signUpError"
              >
                create Account
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Section ends-->

<section class="register-page section-b-space" *ngIf="confirm">
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <mat-card>
          <div *ngIf="!verified">
            <mat-card-title>
              <b
                class="d-block text-center"
                [ngStyle]="{ color: otpError ? 'red' : 'black' }"
                >{{ !otpError ? "Enter Your Otp Here!" : "Invalid Otp!" }}</b
              >
            </mat-card-title>
            <mat-card-content>
              <form [formGroup]="otpForm">
                <div formArrayName="otp" class="otp-container">
                  <input
                    *ngFor="let ctrl of getOtpCode.controls; let i = index"
                    [formControlName]="i"
                    (input)="handleOtpChange($event, i)"
                    (keydown)="handleOtpChange($event, i)"
                    id="otp-{{ i }}"
                    class="otp-input"
                    maxlength="1"
                    type="number"
                  />
                </div>
                <p class="code" (click)="resendOtp()">Resend Code</p>
              </form>
            </mat-card-content>
            <mat-card-actions>
              <button class="btn btn-solid" (click)="enterYourOtp()">
                Submit
              </button>
            </mat-card-actions>
          </div>
          <div *ngIf="verified" class="d-flex align-items-center div">
            <i class="fa fa-check-circle"></i>
            <span class="ml-3 span text-success">Verified Successfully!</span>
          </div>
        </mat-card>
        <br />
      </div>
    </div>
  </div>
</section>
<div class="contain-load" *ngIf="loader">
  <div class="message-load">
    <span class="alert alert-info" *ngIf="verifyMessage">
      {{ verifyMessage }}
    </span>
  </div>
  <br />
  <div class="col-sm-12 empty-cart-cls text-center">
    <img src="assets/images/loader.gif" />
  </div>
  <div class="row loader">
    <p>Check Your Mail - {{ Register.value.email }}</p>
  </div>
  <div class="button">
    <button
      class="btn btn-solid"
      (click)="onVerify()"
      [disabled]="!isButtonDisabled"
    >
      Resend Verification
    </button>
  </div>
  <div class="timer1" *ngIf="!isButtonDisabled">
    <div class="box">
      00 :
      <a style="padding: 0px" *ngIf="countdown < 10">0</a>
      {{ countdown }}
    </div>
  </div>
  <br />
</div>
