<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option">
    <mat-tab-group
      headerPosition="below"
      [selectedIndex]="selectedTab"
      (selectedTabChange)="onTabChange($event)"
    >
      <mat-tab [ngClass]="{ active: selectedTab === 0 }">
        <ng-template mat-tab-label>
          <i
            class="fa fa-home"
            style="font-size: x-large"
            routerLink="/home"
          ></i>
        </ng-template>
      </mat-tab>
      <mat-tab [ngClass]="{ active: selectedTab === 1 }">
        <ng-template mat-tab-label>
          <i class="fa fa-user" aria-hidden="true"></i>
        </ng-template>
      </mat-tab>
      <mat-tab [ngClass]="{ active: selectedTab === 2 }">
        <ng-template mat-tab-label>
          <i class="fa fa-shopping-cart"></i>
        </ng-template>
      </mat-tab>
      <mat-tab [ngClass]="{ active: selectedTab === 1 }">
        <ng-template mat-tab-label>
          <i class="fa fa-heart" aria-hidden="true"></i>
        </ng-template>
      </mat-tab>
      <mat-tab [ngClass]="{ active: selectedTab === 4 }">
        <ng-template mat-tab-label>
          <i
            class="fa fa-bars sidebar-bar"
            aria-hidden="true"
            (click)="leftMenuToggle()"
          ></i>
          <!-- <app-left-menu *ngIf="isToggled" [toggle]="isToggled"></app-left-menu> -->
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row1">
        <div class="col-lg-6">
          <!-- <div class="header-contact">
            <ul>
              <li>Welcome to Our store Multikart</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i>Call Us: 123 - 456 - 7890</li>
            </ul>
          </div> -->
        </div>
        <!-- <div class="col-lg-6 text-right">
          <ul class="header-dropdown"> -->
        <!-- <li class="compare">
              <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>Compare</a>
            </li> -->

        <!-- <li class="onhover-dropdown mobile-account"> -->
        <!-- <i class="fa fa-user" aria-hidden="true"></i> My Account -->
        <!-- <ul class="onhover-show-div"> -->
        <!-- <li>
                  <a data-lng="en" (click)="openPopup()">
                    Login
                  </a>
                </li>
                <li>
                  <a data-lng="es">
                    Logout
                  </a>
                </li>
              </ul>
            </li>
            <li class="mobile-wishlist">
              <a [routerLink]="['/shop/wishlist']"> -->
        <!-- <i class="fa fa-heart" aria-hidden="true"></i> -->
        <!-- Wishlist
              </a>
            </li> -->
        <!-- </ul>
        </div> -->
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left" [class.hidden]="hideLogo">
            <app-left-menu></app-left-menu>
            <div class="brand-logo" [class.hidden]="hideLogo">
              <a routerLink="/home/" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo" />
              </a>
            </div>
          </div>
          <div class="address">
            <!-- <button mat-button> -->
            <a routerLink="/pages/new-dashboard">
              <div>
                <span>
                  Delivering to {{ getIsDefaultAddressOnly?.full_name }}
                </span>
                <br />
                <span>
                  <i class="fa fa-map-marker"></i>
                  {{ getIsDefaultAddressOnly?.city }}
                </span>
              </div>
            </a>

            <!-- </button> -->
          </div>

          <app-searchbar></app-searchbar>

          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
            <div>
              <app-settings></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div
      id="mySidenav"
      class="sidenav"
      [class.openSide]="navServices?.leftMenuToggle"
    >
      <a
        href="javascript:void(0)"
        class="sidebar-overlay"
        (click)="leftMenuToggle()"
        (mouseover)="onHover(false)"
      ></a>
      <nav id="unset" style="cursor: pointer">
        <div (click)="leftMenuToggle()">
          <div class="sidebar-back text-left">
            <i class="fa fa-angle-left pr-2" aria-hidden="true"></i> Back
          </div>
        </div>
        <p class="list-head">Category</p>
        <div id="list-example" class="list-group">
          <div
            class="list-group-item list-group-item-action"
            (click)="leftMenuRoutes('FULL FACE')"
          >
            <a>Full Face Helmets</a>
            <i *ngIf="!checkBrandFull" class="fa fa-chevron-right"></i>
            <i
              *ngIf="checkBrandFull"
              class="fa fa-chevron-down"
              style="color: red"
            ></i>
          </div>
          <div *ngIf="checkBrandFull">
            <div
              (click)="leftMenuRoutesAll()"
              style="margin-top: 4%; margin-bottom: 4%"
            >
              <a style="margin-left: 11%; color: red; font-weight: 900">
                <i
                  class="ti-check-box"
                  style="margin-right: 3%; font-size: large"
                  aria-hidden="true"
                ></i
                >ALL</a
              >
            </div>
            <div
              style="margin-left: 5%; color: red"
              class="list-group-item list-group-item-action"
              *ngFor="let item of anArrayForSideFilter.brands"
            >
              <mat-checkbox
                style="color: red"
                class="example-margin"
                (change)="onCheckboxChange($event, item)"
                [checked]="isChecked(item)"
              >
                <a style="font-size: 12px; font-weight: 900">{{ item }}</a>
              </mat-checkbox>

              <!-- <i class="fa fa-chevron-right"></i> -->
            </div>
          </div>
          <div
            class="list-group-item list-group-item-action"
            (click)="leftMenuRoutes('HALF FACE')"
          >
            <a>Half Face Helmets</a>
            <i *ngIf="!checkBrandHalf" class="fa fa-chevron-right"></i>
            <i
              *ngIf="checkBrandHalf"
              class="fa fa-chevron-down"
              style="color: red"
            ></i>
          </div>
          <div *ngIf="checkBrandHalf">
            <div
              (click)="leftMenuRoutesAll()"
              style="margin-top: 4%; margin-bottom: 4%"
            >
              <a style="margin-left: 11%; color: red; font-weight: 900">
                <i
                  class="ti-check-box"
                  style="margin-right: 3%; font-size: large"
                  aria-hidden="true"
                ></i
                >ALL</a
              >
            </div>
            <div
              style="margin-left: 5%; color: red"
              class="list-group-item list-group-item-action"
              *ngFor="let item of anArrayForSideFilter.brands"
            >
              <mat-checkbox
                style="color: red"
                class="example-margin"
                (change)="onCheckboxChange($event, item)"
                [checked]="isChecked(item)"
              >
                <a style="font-size: 12px; font-weight: 900">{{ item }}</a>
              </mat-checkbox>
            </div>
          </div>
          <div
            class="list-group-item list-group-item-action"
            (click)="leftMenuRoutes('FLIP UP')"
          >
            <a>Flip Up Helmets</a>
            <i *ngIf="!checkBrandFlip" class="fa fa-chevron-right"></i>
            <i
              *ngIf="checkBrandFlip"
              class="fa fa-chevron-down"
              style="color: red"
            ></i>
          </div>
          <div *ngIf="checkBrandFlip">
            <div
              (click)="leftMenuRoutesAll()"
              style="margin-top: 4%; margin-bottom: 4%"
            >
              <a style="margin-left: 11%; color: red; font-weight: 900">
                <i
                  class="ti-check-box"
                  style="margin-right: 3%; font-size: large"
                  aria-hidden="true"
                ></i
                >ALL</a
              >
            </div>
            <div
              style="margin-left: 5%; color: red"
              class="list-group-item list-group-item-action"
              *ngFor="let item of anArrayForSideFilter.brands"
            >
              <mat-checkbox
                style="color: red"
                class="example-margin"
                (change)="onCheckboxChange($event, item)"
                [checked]="isChecked(item)"
              >
                <a style="font-size: 12px; font-weight: 900">{{ item }}</a>
              </mat-checkbox>
            </div>
          </div>
          <div
            class="list-group-item list-group-item-action"
            (click)="leftMenuRoutes('OFF ROAD')"
          >
            <a>Off Road Helmets</a>
            <i *ngIf="!checkBrandOff" class="fa fa-chevron-right"></i>
            <i
              *ngIf="checkBrandOff"
              class="fa fa-chevron-down"
              style="color: red; font-size: x-large"
            ></i>
          </div>
          <div *ngIf="checkBrandOff">
            <div
              (click)="leftMenuRoutesAll()"
              style="margin-top: 4%; margin-bottom: 4%"
            >
              <a style="margin-left: 11%; color: red; font-weight: 900">
                <i
                  class="ti-check-box"
                  style="margin-right: 3%; font-size: large"
                  aria-hidden="true"
                ></i
                >ALL</a
              >
            </div>
            <div
              style="margin-left: 5%; color: red"
              class="list-group-item list-group-item-action"
              *ngFor="let item of anArrayForSideFilter.brands"
            >
              <mat-checkbox
                style="color: red"
                class="example-margin"
                (change)="onCheckboxChange($event, item)"
                [checked]="isChecked(item)"
              >
                <a style="font-size: 12px; font-weight: 900">{{ item }}</a>
              </mat-checkbox>
            </div>
          </div>
          <div
            class="list-group-item list-group-item-action"
            (click)="leftMenuRoutes('helmetaccessories')"
          >
            <a>Helmet Accessories</a>
            <i class="fa fa-chevron-right"></i>
          </div>
          <div
            class="list-group-item list-group-item-action"
            (click)="openSub()"
          >
            <a>Riding Gears</a>
            <i *ngIf="!opened" class="fa fa-chevron-right"></i>
            <i *ngIf="opened" class="fa fa-chevron-down" style="color: red"></i>
          </div>

          <div
            *ngIf="opened"
            class="list-group-item list-group-item-action"
            (click)="leftMenuSearchRoutes('GLOVES')"
          >
            <a style="margin-left: 5%; color: red">Gloves</a>
            <i class="fa fa-chevron-right" style="color: red"></i>
          </div>
          <div
            *ngIf="opened"
            class="list-group-item list-group-item-action"
            (click)="leftMenuSearchRoutes('JACKETS')"
          >
            <a style="margin-left: 5%; color: red">Jackets</a>
            <i class="fa fa-chevron-right" style="color: red"></i>
          </div>
        </div>
        <hr />
        <p class="list-head">Help & Settings</p>
        <div id="list-example" class="list-group">
          <div
            class="list-group-item list-group-item-action"
            (click)="helpAndSettings('account')"
          >
            <a>Your Account</a>
            <i class="fa fa-chevron-right"></i>
          </div>
          <div
            class="list-group-item list-group-item-action"
            (click)="helpAndSettings('orders')"
          >
            <a>Orders</a>
            <i class="fa fa-chevron-right"></i>
          </div>
          <div
            class="list-group-item list-group-item-action"
            (click)="helpAndSettings('compare')"
          >
            <a>Compare</a>
            <i class="fa fa-chevron-right"></i>
          </div>
          <div
            class="list-group-item list-group-item-action"
            (click)="helpAndSettings('support')"
          >
            <a>Customer-support</a>
            <i class="fa fa-chevron-right"></i>
          </div>
          <div
            class="list-group-item list-group-item-action"
            (click)="helpAndSettings('logout')"
          >
            <a>{{ isAuthenticated ? "Logout" : "Login" }}</a>
            <i class="fa fa-chevron-right"></i>
          </div>
          <!-- <a class="list-group-item list-group-item-action" href="#list-item-4">Item 4</a> -->
        </div>
      </nav>
    </div>
  </div>
</header>
<app-sub-header [brands]="uniqueBrands"></app-sub-header>
