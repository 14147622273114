<app-breadcrumb [title]="'Checkout'" [breadcrumb]="'Checkout'"></app-breadcrumb>
<div class="spinner-container" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>
<div class="loading-text" *ngIf="loading">
  Please wait, don't close the app...
</div>

<span class="onlyForDesk" *ngIf="!loading">
  <i
    style="
      float: right;
      margin-top: -3%;
      font-size: larger;
      cursor: pointer;
      margin-right: 2%;
    "
    class="ti-close"
    (click)="openPopup()"
  >
  </i>
</span>
<!-- section start -->
<!-- <section class="section-b-space">
    <div class="container padding-cls">
      <div class="checkout-page">
        <div class="checkout-form">
          <form>
            <div class="row">
              <div class="col-lg-6 col-sm-12 col-xs-12">
                <div class="checkout-title">
                  <h3>Billing Details</h3>
                </div>
                <div class="row">
                  <div class="form-group col-md-6 col-sm-6 col-xs-12">
                    <div class="field-label">First Name</div>
                    <input type="text" name="field-name" value="" placeholder="">
                  </div>
                  <div class="form-group col-md-6 col-sm-6 col-xs-12">
                    <div class="field-label">Last Name </div>
                    <input type="text" name="field-name" value="" placeholder="">
                  </div>
                  <div class="form-group col-md-6 col-sm-6 col-xs-12">
                    <div class="field-label">Phone</div>
                    <input type="text" name="field-name" value="" placeholder="">
                  </div>
                  <div class="form-group col-md-6 col-sm-6 col-xs-12">
                    <div class="field-label">Email Address</div>
                    <input type="text" name="field-name" value="" placeholder="">
                  </div>
                  <div class="form-group col-md-12 col-sm-12 col-xs-12">
                    <div class="field-label">Country</div>
                    <select>
                      <option>India</option>
                      <option>South Africa</option>
                      <option>United State</option>
                      <option>Australia</option>
                    </select>
                  </div>
                  <div class="form-group col-md-12 col-sm-12 col-xs-12">
                    <div class="field-label">Address</div>
                    <input type="text" name="field-name" value="" placeholder="Street address">
                  </div>
                  <div class="form-group col-md-12 col-sm-12 col-xs-12">
                    <div class="field-label">Town/City</div>
                    <input type="text" name="field-name" value="" placeholder="">
                  </div>
                  <div class="form-group col-md-12 col-sm-6 col-xs-12">
                    <div class="field-label">State / County</div>
                    <input type="text" name="field-name" value="" placeholder="">
                  </div>
                  <div class="form-group col-md-12 col-sm-6 col-xs-12">
                    <div class="field-label">Postal Code</div>
                    <input type="text" name="field-name" value="" placeholder="">
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="stripe-section">
                      <h5>stripe js</h5>
                      <div class="content">
                        <h5>dummy test</h5>
                        <table>
                          <tr>
                            <td>cart number</td>
                            <td>4242424242424242</td>
                          </tr>
                          <tr>
                            <td>mm/yy</td>
                            <td>2/2020</td>
                          </tr>
                          <tr>
                            <td>cvc</td>
                            <td>2222</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row margin-cls">
                  <div class="col-12">
                    <div class="stripe-section">
                      <h5>paypal</h5>
                      <div class="content">
                        <h5>dummy test</h5>
                        <h5>I set total payment to $0.01 for static demo</h5>
                        <table>
                          <tr>
                            <td>cart number</td>
                            <td>4152521541244</td>
                          </tr>
                          <tr>
                            <td>mm/yy</td>
                            <td>11/18</td>
                          </tr>
                          <tr>
                            <td>cvc</td>
                            <td>521</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-12 col-xs-12">
                <div class="checkout-details">
                  <div class="order-box">
                    <div class="title-box">
                      <div>Product <span> Total</span></div>
                    </div>
                    <ul class="qty">
                      <li>Pink Slim Shirt × 1 <span>$25.10</span></li>
                      <li>SLim Fit Jeans × 1 <span>$555.00</span></li>
                    </ul>
                    <ul class="sub-total">
                      <li>Subtotal <span class="count">$380.10</span></li>
                      <li>Shipping <div class="shipping">
                        <div class="shopping-option">
                          <input type="checkbox" name="free-shipping" id="free-shipping">
                          <label for="free-shipping">Free Shipping</label>
                        </div>
                        <div class="shopping-option">
                          <input type="checkbox" name="local-pickup" id="local-pickup">
                          <label for="local-pickup">Local Pickup</label>
                        </div>
                      </div>
                      </li>
                    </ul>
  
                    <ul class="total">
                      <li>Total <span class="count">$620.00</span></li>
                    </ul>
                  </div>
  
                  <div class="payment-box">
                    <div class="upper-box">
                      <div class="payment-options">
                        <ul>
                          <li>
                            <div class="radio-option">
                              <input type="radio" name="payment-group" id="payment-1" checked>
                              <label for="payment-1">Check Payments<span class="small-text">Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.</span></label>
                            </div>
                          </li>
  
                          <li>
                            <div class="radio-option">
                              <input type="radio" name="payment-group" id="payment-2">
                              <label for="payment-2">Cash On Delivery<span class="small-text">Please send a check to Store Name, Store Street, Store Town, Store State / County, Store Postcode.</span></label>
                            </div>
                          </li>
                          <li>
                            <div class="radio-option paypal">
                              <input type="radio" name="payment-group" id="payment-3">
                              <label for="payment-3">PayPal<span class="image"><img src="assets/images/paypal.png" alt="" /></span></label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="text-right">
                      <a [routerLink]="['shop/checkout']" class="btn-solid btn">Place Order</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
</section> -->
<!-- section End -->
<section class="onlyForDesk" *ngIf="!loading">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-7 mb-4 col-sm-12">
        <div class="row mt-4">
          <div class="col-12 col-lg-1 col-sm-12">
            <h3 class="text no">1</h3>
          </div>
          <div class="col-12 col-lg-3 col-sm-12">
            <h3 class="text text1">Delivery Address</h3>
          </div>
          <div class="col-12 col-lg-6 col-sm-12">
            <div *ngFor="let user of userAddress">
              <div *ngIf="user.is_default && !receivedChangingDeliverId">
                <p class="text">{{ user.full_name }}</p>
                <p class="text">
                  Door no : {{ user.place_name }} {{ user.address }}
                </p>
                <p class="text">
                  {{ user.city }} , {{ user.state }} {{ user.pincode }}
                </p>
                <div>
                  <h5 class="p text" (click)="instruction = !instruction">
                    Add Delivery Instructions
                  </h5>
                  <div *ngIf="instruction">
                    <form [formGroup]="addNote">
                      <!-- <label for="notes">Add Notes</label> -->
                      <input
                        type="text"
                        class="form-control"
                        id="notes"
                        placeholder="Add Your Delivery Instructions"
                        formControlName="notes"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="receivedChangingDeliverId && DeskUserAddress">
              <p class="text">{{ DeskUserAddress.full_name }}</p>
              <p class="text">
                Door no : {{ DeskUserAddress.place_name }}
                {{ DeskUserAddress.address }}
              </p>
              <p class="text">
                {{ DeskUserAddress.city }} , {{ DeskUserAddress.state }}
                {{ DeskUserAddress.pincode }}
              </p>
              <div>
                <h5 class="p text" (click)="instruction = !instruction">
                  Add Delivery Instructions
                </h5>
                <div *ngIf="instruction">
                  <form [formGroup]="addNote">
                    <!-- <label for="notes">Add Notes</label> -->
                    <input
                      type="text"
                      class="form-control"
                      id="notes"
                      placeholder="Add Your Delivery Instructions"
                      formControlName="notes"
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-lg-2 col-sm-12">
            <button
              type="button"
              class="btn btn-outline-primary"
              (click)="clickChange()"
            >
              {{
                !address
                  ? forAddAddressButton == false && !receivedChangingDeliverId
                    ? "add"
                    : "change"
                  : "close"
              }}
            </button>
          </div>
        </div>
        <hr />
        <div *ngIf="address">
          <div class="row">
            <div class="col-12">
              <app-manage-address
                (changingDeliverId)="recieveDeliverID($event)"
                [address]="address"
                [id]="pass_id"
                [qty]="pass_quantity"
              ></app-manage-address>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-1 col-sm-12">
            <h3 class="text no">2</h3>
          </div>
          <div class="col-12 col-lg-3 col-sm-12">
            <h3 class="text text1">Payment Method</h3>
          </div>
          <div class="col-12 col-lg-6 col-sm-12">
            <div>
              <p>
                <span class="text">Visa</span>
                <span class="ml-1">ending in 8896</span>
              </p>
            </div>
            <div>
              <div *ngFor="let user of userAddress">
                <div *ngIf="user.is_default && !receivedChangingBillingId">
                  <a class="text">Billing Address :</a>
                  <span class="ml-1 text"
                    >{{ user.full_name }},{{ user.place_name }}
                    {{ user.address }}</span
                  >
                </div>
              </div>
              <div *ngIf="receivedChangingBillingId && DeskBillingAddress">
                <a class="text">Billing Address :</a>
                <span class="ml-1 text"
                  >{{ DeskBillingAddress.full_name }},{{
                    DeskBillingAddress.place_name
                  }}
                  {{ DeskBillingAddress.address }}</span
                >
              </div>
            </div>
            <h5 (click)="giftCode = !giftCode" class="mt-2 text">
              Add a gift card or promotion code
            </h5>
            <div *ngIf="giftCode">
              <input
                type="number"
                class="input mt-2"
                placeholder="Enter Your Code"
              />
              <button class="btn btn-outline-success ml-2">apply</button>
            </div>
          </div>
          <div class="col-12 col-lg-2 col-sm-12">
            <button
              type="button"
              class="btn btn-outline-primary"
              (click)="clickDeskBillChange()"
            >
              {{
                !address
                  ? forAddAddressButton == false && !receivedChangingBillingId
                    ? "add"
                    : "change"
                  : "close"
              }}
            </button>
          </div>
        </div>
        <hr />
        <div *ngIf="billingAddress">
          <div class="row">
            <div class="col-12">
              <app-manage-address
                (changingBillingId)="recieveBillID($event)"
                [billingAddress]="billingAddress"
                [id]="pass_id"
                [qty]="pass_quantity"
              ></app-manage-address>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-1 col-sm-12">
            <h3 class="text no">3</h3>
          </div>
          <div class="col-12 col-lg-11 col-sm-12">
            <h3 class="text text1">Review Items and delivery</h3>
            <mat-card
              *ngFor="let product of buyProduct; let i = index"
              class="mt-3"
            >
              <mat-card-content>
                <h3 class="text-success text">Arriving on jul 2024</h3>
                <p>items dispatched by skullcrown</p>
                <div class="row">
                  <div class="col-12 col-lg-12 col-sm-12">
                    <!-- <div *ngFor="let product of buyProduct"> -->
                    <div class="d-flex">
                      <img
                        mat-card-md-image
                        class="align-items-center"
                        [src]="product.variants[0].images[0]"
                        alt="buyProduct.variants[0].images[0]"
                      />
                      <div style="width: -webkit-fill-available">
                        <div class="row">
                          <div class="col-8">
                            <h5 class="text">
                              {{ product.title }}
                            </h5>
                          </div>
                          <div class="col-4">
                            <span
                              style="
                                cursor: pointer;
                                border: 1px solid rgb(19, 18, 18);
                                border-radius: 50%;
                                width: 1vw;
                                height: 1vw;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                font-size: 13px;
                                min-width: 24px;
                                min-height: 24px;
                              "
                              ><b style="font-size: 13px">{{
                                product.size
                              }}</b></span
                            >
                          </div>
                        </div>

                        <h5 class="text">
                          <del *ngIf="product?.discount"
                            ><span class="money">
                              {{
                                product?.price * currency?.price
                                  | currency : currency?.currency : "symbol"
                              }}</span
                            >
                          </del>
                          {{
                            product?.price * currency?.price
                              | discount : product
                              | currency : currency?.currency : "symbol"
                          }}
                        </h5>

                        <!-- <p class="text mt-1">
                          Sold by : Sparkly Autotech Private Ltd
                        </p> -->
                        <div
                          style="
                            display: flex;
                            justify-content: flex-end;
                            width: -webkit-fill-available;
                          "
                        >
                          <span
                            class="btn btn-light"
                            style="margin-right: 1%"
                            (click)="decreaseQty(i)"
                            >-</span
                          >

                          <b
                            class="btn btn-light"
                            style="
                              background-color: white;
                              border: 1px solid rgb(122, 122, 122);
                            "
                            *ngIf="quantity.length"
                          >
                            {{ quantity[i] }}</b
                          >
                          <span
                            class="btn btn-light"
                            style="margin-left: 1%"
                            (click)="increaseQty(i)"
                            >+</span
                          >
                        </div>
                        <!-- <button class="btn btn-outline-secondary">
                          <i class="fa fa-gift"></i
                          ><span class="ml-2">Add gift Options</span>
                        </button> -->
                      </div>
                    </div>
                    <!-- </div> -->
                  </div>
                  <!-- <div class="col-12 col-lg-4 col-sm-12">
                    <div class="deliver">
                      <p class="text">Choose Your Delivery Date</p>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="option1"
                          checked
                        />
                        <label
                          class="form-check-label text-success text"
                          for="exampleRadios1"
                        >
                          friday 7th july
                        </label>
                      </div>
                      <p class="text mt-2">Free <span>Prime Delivery</span></p>
                    </div>
                  </div> -->
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
      <div
        class="col-12 col-lg-5 col-sm-12 d-flex justify-content-center mt-lg-3 mb-2"
      >
        <mat-card>
          <mat-card-content>
            <div class="container">
              <button
                [disabled]="
                  this.forAddAddressButton == false &&
                  (receivedChangingBillingId! == null ||
                    receivedChangingDeliverId! == null)
                "
                class="btn btn-warning w-100"
                (click)="placeAndOrderandPay()"
                style="margin-bottom: 2%"
              >
                Place Your Order and Pay
              </button>
              <p
                *ngIf="showMessageForAgree"
                style="
                  font-size: 13px;
                  color: #ee0c0c;
                  line-height: 1;
                  margin-top: 1rem;
                  justify-content: center;
                  display: flex;
                "
              >
                {{ messageForAgree }}
              </p>
              <p class="text text-center mt-2">
                You’ll Be Securely Redirected to Payment Portal to make Payment
                and Complete Your Purchase
              </p>
              <p class="text-center">
                by placing your order,you agree to SkullCrown's
              </p>
              <p class="text-center">
                <span>
                  <mat-checkbox
                    class="example-margin"
                    [(ngModel)]="checked"
                    (change)="checkBox($event.checked)"
                  >
                  </mat-checkbox
                ></span>
                <a
                  (click)="privacynotice()"
                  style="color: blue; cursor: pointer; margin-left: 2%"
                  >privacy notice</a
                >
                and
                <a
                  (click)="forConditionOfUse()"
                  style="color: blue; cursor: pointer"
                  >condition of use</a
                >
              </p>
              <hr />
              <p class="text">Order Summary</p>
              <!-- <div *ngFor="let user of buyProduct"> -->
              <div class="d-flex justify-content-between">
                <p class="text-start text">Items :</p>
                <p class="text-end text">{{ overallQuantity }}</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="text">Delivery :</p>
                <p class="text">{{ shippingAmount | currency : "INR" }}</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="text">Total :</p>
                <p class="text">{{ ActualTotal | currency : "INR" }}</p>
              </div>
              <div class="d-flex justify-content-between">
                <p class="text">Discount :</p>
                <p class="text">-{{ lessPrice | currency : "INR" }}</p>
              </div>
              <hr />
              <!-- <div *ngFor="let user of buyProduct"> -->
              <div class="d-flex justify-content-between">
                <p class="text text-danger">Order Total :</p>
                <p class="text text-danger" *ngIf="shippedTotal === 0">
                  {{ orderTotal | currency : "INR" }}
                </p>
                <p class="text text-danger" *ngIf="shippedTotal !== 0">
                  {{ shippedTotal | currency : "INR" }}
                </p>
              </div>
              <!-- </div> -->
              <!-- </div> -->
              <hr />
              <div>
                <p class="text text-danger">
                  Your Savings :
                  <span>{{ lessPrice | currency : "INR" }} </span>
                  <!-- ({{ discount }}) % -->
                </p>
              </div>
              <!-- <ul class="text">
                <li>Instant Bank Discount</li>
                <li>Item Discount</li>
              </ul>
              <p class="text mt-2 detail">
                Details <i class="fa fa-angle-down"></i>
              </p> -->
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</section>

<!-- mobile -->

<section class="onlyForMobile" *ngIf="!loading">
  <div class="col-12">
    <header>
      <h3>
        Order Confirmation
        <i style="float: right" class="ti-close" (click)="openPopup()"> </i>
      </h3>
    </header>
    <hr />
  </div>
  <div [ngClass]="{ ifAddressisPresent: stopStepperMoves == false }">
    <mat-stepper
      labelPosition="bottom"
      #stepper
      [linear]="true"
      [selectedIndex]="selectedIndex"
      class="{{ 'last-edited-step-' + stepper.selectedIndex }}"
    >
      <mat-step [ngClass]="{ 'stepper-edited': stepper.selectedIndex === 0 }">
        <ng-template matStepLabel>Address </ng-template>
        <div>
          <span
            >select your address
            <hr />
          </span>
          <mat-card
            style="box-shadow: none; border: dotted 1px black"
            (click)="forEditandAdd()"
          >
            <mat-card-content>
              <div
                class="row"
                style="
                  display: flex;
                  text-align: center;
                  justify-content: center;
                  cursor: pointer;
                "
              >
                <i
                  class="fa fa-plus"
                  style="
                    font-size: x-large;
                    color: rgb(93, 177, 216);
                    margin-right: 5%;
                  "
                  aria-hidden="true"
                ></i>
                <h6>Add New Address / Edit Address</h6>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div>
          <!-- (forGetNewAdded)="recieveForRefresh($event)" -->
          <app-new-address
            *ngIf="this.addingAddress"
            (AddressIdFromNewAddress)="receiveData($event)"
          ></app-new-address>
        </div>
        <br />
        <mat-card
          [ngClass]="{ 'selected-card': item.address_id === selectedAddressId }"
          (click)="forChoosing(item.address_id)"
          *ngFor="let item of useraddedAddress"
          style="margin-bottom: 5%"
        >
          <mat-card-content>
            <i class="ti-check-box" style="float: right" aria-hidden="true"></i>
            <br />
            <mat-card style="box-shadow: none; padding: 0px">
              <mat-card-content>
                <p style="font-size: 14px">
                  <b>{{ item.full_name }}</b
                  >,{{ item.address }} - {{ item.pincode }}
                </p>
                <!-- <button
                class="btn btn-light"
                (click)="forDeliver(item.address_id)"
                style="line-height: 10px; font-size: 12px"
              >
                for deliver
              </button>
              <button
                class="btn btn-light"
                (click)="forBilling(item.address_id)"
                style="line-height: 10px; font-size: 12px; float: right"
              >
                for billing
              </button> -->
                <!-- <button
                class="btn btn-light"
                (click)="forChoosing(item.address_id)"
                style="line-height: 10px; font-size: 12px; float: right"
              >
                for
              </button> -->
              </mat-card-content>
            </mat-card>
          </mat-card-content>
        </mat-card>
        <!-- <i class="ti-check-box" style="float: right" aria-hidden="true"></i>
          <br /> -->

        <br />

        <div>
          <button
            mat-button
            (click)="forNext()"
            class="btn btn-warning"
            style="float: right"
            [disabled]="!deliverClicked && !billingClicked"
          >
            Next
          </button>
        </div>
      </mat-step>

      <mat-step [ngClass]="{ 'stepper-edited': stepper.selectedIndex === 1 }">
        <ng-template matStepLabel>Order Summary</ng-template>

        <div class="row1">
          <div>
            <div>
              <mat-card class="card1">
                <mat-card-content *ngFor="let item of userAddress">
                  <!-- <ng-container *ngIf="!userOnly">
                <h5>*please choose your deliver address on previous step</h5>
              </ng-container> -->
                  <ng-container *ngIf="!userOnly && item.is_default">
                    <h5 style="align-items: center">
                      Deliver to:
                      <button
                        style="float: right"
                        mat-button
                        matStepperPrevious
                        class="btn btn-light"
                        (click)="delChanged()"
                      >
                        change
                      </button>
                    </h5>
                    <hr />
                    <p class="text" style="font-size: 16px; color: black">
                      <b>{{ item.full_name }}</b>
                    </p>
                    <p class="text" style="font-size: 14px">
                      Door no : {{ item.place_name }} {{ item.address }}
                    </p>
                    <p class="text" style="font-size: 14px">
                      {{ item.city }}, {{ item.state }}
                      {{ item.pincode }}
                    </p>
                    <div>
                      <h5
                        class="p text"
                        style="font-size: 12px; color: blue"
                        (click)="instruction = !instruction"
                      >
                        Add Delivery Instructions
                      </h5>
                      <div *ngIf="instruction">
                        <form [formGroup]="addNote">
                          <!-- <label for="notes">Add Notes</label> -->
                          <input
                            type="text"
                            class="form-control"
                            style="height: 35px"
                            id="notes"
                            placeholder="Add Your Delivery Instructions"
                            formControlName="notes"
                          />
                        </form>
                      </div>
                    </div>
                  </ng-container>
                </mat-card-content>
                <mat-card-content>
                  <ng-container *ngIf="userOnly">
                    <h5 style="align-items: center">
                      Deliver to:
                      <button
                        style="float: right"
                        mat-button
                        matStepperPrevious
                        class="btn btn-light"
                        (click)="delChanged()"
                      >
                        change
                      </button>
                    </h5>
                    <hr />
                    <p class="text" style="font-size: 16px; color: black">
                      <b>{{ userOnly.full_name }}</b>
                    </p>
                    <p class="text" style="font-size: 14px">
                      Door no : {{ userOnly.place_name }} {{ userOnly.address }}
                    </p>
                    <p class="text" style="font-size: 14px">
                      {{ userOnly.city }}, {{ userOnly.state }}
                      {{ userOnly.pincode }}
                    </p>
                    <div>
                      <h5
                        class="p text"
                        style="font-size: 12px; color: blue"
                        (click)="instruction = !instruction"
                      >
                        Add Delivery Instructions
                      </h5>
                      <div *ngIf="instruction">
                        <form [formGroup]="addNote">
                          <!-- <label for="notes">Add Notes</label> -->
                          <input
                            type="text"
                            class="form-control"
                            style="height: 35px"
                            id="notes"
                            placeholder="Add Your Delivery Instructions"
                            formControlName="notes"
                          />
                        </form>
                      </div>
                    </div>
                  </ng-container>
                </mat-card-content>
              </mat-card>
              <hr style="margin-top: 0px" />
            </div>
          </div>
        </div>
        <div class="row2">
          <div class="d-flex" *ngFor="let product of buyProduct; let i = index">
            <div>
              <mat-card
                appearance="outlined"
                class="m-2"
                height="300px"
                class="card1"
              >
                <mat-card-content>
                  <div class="row">
                    <div
                      class="col-4 col-sm-4 d-flex align-items-center justify-content-center"
                    >
                      <div class="p-4">
                        <img
                          mat-card-md-image
                          [src]="product.variants[0].images[0]"
                          alt="product.variants[0].images"
                        />
                      </div>
                    </div>
                    <!-- <div class="col-1 col-sm-1 d-flex justify-content-end align-items-center pe-0">
          
      </div> -->
                    <div class="col-8 col-sm-8 d-flex align-items-center">
                      <div>
                        <p
                          class="title"
                          [ngClass]="{
                            'long-title': product.title.length > 20
                          }"
                        >
                          <b style="font-size: 11px; color: black">{{
                            product.title
                          }}</b>
                          <!-- <span
                          style="cursor: pointer"
                          *ngIf="product.title.length > 20 && !showFullTitle"
                          >... <a (click)="showFullTitle = true"></a
                        ></span> -->
                        </p>
                        <p>
                          <span
                            class="btn btn-light"
                            style="margin-right: 1%"
                            (click)="decreaseQty(i)"
                            >-</span
                          >

                          <b
                            class="btn btn-light"
                            style="
                              background-color: white;
                              border: 1px solid rgb(122, 122, 122);
                            "
                            *ngIf="quantity.length"
                          >
                            {{ quantity[i] }}</b
                          >
                          <span
                            class="btn btn-light"
                            style="margin-left: 1%"
                            (click)="increaseQty(i)"
                            >+</span
                          >
                          <span
                            style="
                              cursor: pointer;
                              border: 1px solid rgb(19, 18, 18);
                              border-radius: 50%;
                              width: 5vw;
                              height: 5vw;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                              float: right;
                              font-size: 13px;
                              min-width: 24px;
                              min-height: 24px;
                            "
                            ><b style="font-size: 13px">{{
                              product.size
                            }}</b></span
                          >
                        </p>

                        <b>
                          <del *ngIf="product?.discount"
                            ><span class="money">
                              {{
                                product?.price * currency?.price
                                  | currency : currency?.currency : "symbol"
                              }}</span
                            >
                          </del>
                          {{
                            product?.price * currency?.price
                              | discount : product
                              | currency : currency?.currency : "symbol"
                          }}
                        </b>

                        <hr />
                        <!-- <p>Delivery on July 20-07-2024</p> -->
                      </div>
                    </div>
                    <!-- <div
                      style="
                        width: 100%;
                        align-items: center;
                        display: flex;
                        justify-content: center;
                      "
                    >
                      <h6 style="font-size: 10px; color: green">
                        <b>Sold by : Sparkly Autotech Private Ltd</b>
                      </h6>
                    </div> -->
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </div>

        <div class="row3">
          <mat-card>
            <mat-card-content
              ><div class="container">
                <b>Price Details:</b>
                <hr />
                <div class="row1" style="display: flex">
                  <div class="col-7" style="padding: 0px">
                    <ul>
                      <li><p>Items</p></li>
                      <li><p>Delivery</p></li>
                      <li><p>Sub-Total</p></li>
                      <li><p>Discount</p></li>
                    </ul>
                  </div>
                  <div class="col-5">
                    <ul>
                      <li>
                        <p>:{{ overallQuantity }}</p>
                      </li>
                      <li>
                        <p>:{{ shippingAmount | currency : "INR" }}</p>
                      </li>
                      <li>
                        <p>:{{ ActualTotal | currency : "INR" }}</p>
                      </li>
                      <li>
                        <p style="width: max-content">
                          :- {{ lessPrice | currency : "INR" }}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr />
                <div class="row2" style="display: flex">
                  <div class="col-7" style="padding: 0px">
                    <ul>
                      <li>
                        <p class="text-danger">Order Total</p>
                      </li>
                    </ul>
                  </div>
                  <div class="col-5">
                    <ul>
                      <li>
                        <p class="text-danger" *ngIf="shippedTotal === 0">
                          {{ orderTotal | currency : "INR" }}
                        </p>
                        <p class="text-danger" *ngIf="shippedTotal !== 0">
                          {{ shippedTotal | currency : "INR" }}
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <hr />
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <br />
        <div
          class="row last"
          style="width: 100%; justify-content: center; margin: 0px"
        >
          <div class="alert alert-dark" role="alert">
            <p style="font-size: 11px">
              <i
                class="fa fa-shield"
                aria-hidden="true"
                style="margin-right: 2%"
              ></i
              >Safe and Secure payments , Easy returns.
            </p>
            <p style="margin-bottom: 0px">100% Authentic products.</p>
          </div>
        </div>

        <div class="row last">
          <div class="col-6" style="padding: 0px">
            <p
              class="text-danger"
              style="float: left; text-align: center; font-size: 11px"
            >
              Your Savings :
              <span>{{ lessPrice | currency : "INR" }} </span>
              <!-- ({{ discount }}) % -->
            </p>
            <br />
            <ul style="float: left">
              <li style="font-size: 12px">Instant Bank and Item Discount</li>
              <!-- <li></li> -->
            </ul>
            <p style="float: left">Details <i class="fa fa-angle-down"></i></p>
          </div>
          <div class="col-6" style="padding: 0px">
            <button
              style="float: right"
              class="btn btn-warning"
              (click)="continue()"
            >
              Continue
            </button>
          </div>
        </div>
      </mat-step>

      <mat-step [ngClass]="{ 'stepper-edited': stepper.selectedIndex === 2 }">
        <ng-template matStepLabel>Payment</ng-template>
        <div class="row1">
          <div>
            <div>
              <mat-card class="card1">
                <mat-card-content *ngFor="let item of userAddress">
                  <!-- <ng-container *ngIf="!userOnly">
                  <h5>*please choose your deliver address on previous step</h5>
                </ng-container> -->
                  <ng-container *ngIf="!billingOnly && item.is_default">
                    <h5 style="align-items: center">
                      Billing Address:
                      <button
                        style="float: right"
                        mat-button
                        class="btn btn-light"
                        (click)="billChanged()"
                      >
                        change
                      </button>
                    </h5>
                    <hr />
                    <p class="text" style="font-size: 16px; color: black">
                      <b>{{ item.full_name }}</b>
                    </p>
                    <p class="text" style="font-size: 14px">
                      Door no : {{ item.place_name }} {{ item.address }}
                    </p>
                    <p class="text" style="font-size: 14px">
                      {{ item.city }}, {{ item.state }}
                      {{ item.pincode }}
                    </p>
                  </ng-container>
                </mat-card-content>
                <mat-card-content>
                  <ng-container *ngIf="billingOnly">
                    <h5 style="align-items: center">
                      Billing Address:
                      <button
                        style="float: right"
                        mat-button
                        (click)="billChanged()"
                        class="btn btn-light"
                      >
                        change
                      </button>
                    </h5>
                    <hr />
                    <p class="text" style="font-size: 16px; color: black">
                      <b>{{ billingOnly?.full_name }}</b>
                    </p>
                    <p class="text" style="font-size: 14px">
                      Door no : {{ billingOnly?.place_name }}
                      {{ billingOnly?.address }}
                    </p>
                    <p class="text" style="font-size: 14px">
                      {{ billingOnly?.city }}, {{ billingOnly?.state }}
                      {{ billingOnly?.pincode }}
                    </p>
                  </ng-container>
                </mat-card-content>
              </mat-card>
              <hr />
            </div>
            <button
              class="btn btn-success w-100"
              (click)="placeAndOrderandPay()"
            >
              Place Your Order and Pay
            </button>
            <p
              *ngIf="showMessageForAgree"
              style="
                font-size: 12px;
                color: #ee0c0c;
                line-height: 1;
                margin-top: 1rem;
              "
            >
              {{ messageForAgree }}
            </p>
          </div>
          <br />
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <div class="alert alert-dark" role="alert">
              <p>
                You'll be securely redircted to Visa to enter your password and
                complete your purchase
              </p>
              <p>by placing your order,you agree to SkullCrown's</p>
              <p>
                <mat-checkbox
                  class="example-margin"
                  [(ngModel)]="checked"
                  (change)="checkBox($event.checked)"
                >
                </mat-checkbox>
                <a
                  (click)="privacynotice()"
                  style="color: blue; cursor: pointer; margin-left: 2%"
                >
                  privacy notice</a
                >
                and
                <a
                  (click)="forConditionOfUse()"
                  style="color: blue; cursor: pointer"
                  >condition of use</a
                >
              </p>
            </div>
          </div>
        </div>
        <hr />
        <div>
          <button
            mat-button
            (click)="previous()"
            class="btn btn-warning"
            style="float: left"
          >
            Previous
          </button>
          <button
            mat-button
            class="btn btn-warning"
            style="float: right"
            (click)="openPopup()"
          >
            Cancel
          </button>
        </div>
      </mat-step>
    </mat-stepper>
  </div>
</section>

<!-- (click)="stepper.reset()" -->
<div cdkOverlayOrigin #overlayPosition="cdkOverlayOrigin">
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="overlayPosition"
    [cdkConnectedOverlayOpen]="overlayOpen"
  >
    <!-- (overlayOutsideClick)="overlayOpen = false"
  (backdropClick)="overlayOpen = false" -->
    <div class="overlay-container" [ngClass]="{ blur: overlayOpen }">
      <div class="overlay-content">
        <span
          (click)="overlayClose()"
          class="closerOverlay"
          style="font-size: x-large; cursor: pointer"
          >X</span
        >

        <app-privacy-notice></app-privacy-notice>
        <button
          class="btn btn-info"
          style="
            float: right;
            border-radius: 20px;
            margin-right: 2%;
            margin-bottom: 2%;
          "
          (click)="overlayClose()"
        >
          Agree
        </button>
        <br />
        <div style="opacity: 0">agreed</div>
      </div>
    </div>
  </ng-template>
</div>

<div cdkOverlayOrigin #overlayPosition="cdkOverlayOrigin">
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="overlayPosition"
    [cdkConnectedOverlayOpen]="conditionOverlayOpen"
  >
    <!-- (overlayOutsideClick)="overlayOpen = false"
  (backdropClick)="overlayOpen = false" -->
    <div class="overlay-container" [ngClass]="{ blur: conditionOverlayOpen }">
      <div class="overlay-content">
        <span
          (click)="overlayClose()"
          class="closerOverlay"
          style="font-size: x-large; cursor: pointer"
          >X</span
        >

        <app-privacy-notice
          [forCondition]="conditionOverlayOpen"
        ></app-privacy-notice>
        <button
          class="btn btn-info"
          style="
            float: right;
            border-radius: 20px;
            margin-right: 2%;
            margin-bottom: 2%;
          "
          (click)="overlayClose()"
        >
          Agree
        </button>
        <br />
        <div style="opacity: 0">agreed</div>
      </div>
    </div>
  </ng-template>
</div>
