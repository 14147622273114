import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
} from "@angular/core";
import { ProductSlider } from "../../../shared/data/slider";
import { Product } from "../../../shared/classes/product";
import { ProductService } from "../../../shared/services/product.service";
import { ActivatedRoute, Router } from "@angular/router";
import { userService } from "src/app/shared/services/user.service";
import { catchError, Observable, of } from "rxjs";
import { AuthService } from "src/app/shared/services/auth.service";
import { v4 as uuidv4 } from "uuid";
import { NgxIndexedDBService } from "ngx-indexed-db";
const { v4: uuidv4 } = require("uuid");
@Component({
  selector: "app-fashion-one",
  templateUrl: "./fashion-one.component.html",
  styleUrls: ["./fashion-one.component.scss"],
})
export class FashionOneComponent implements OnInit {
  ip = "";
  public products: any[] = [];
  public productCollections: any[] = [];
  recentSearches: any[] = [];
  totalBrands: any[] = [];
  uniqueBrands: any[] = [];
  title_url: string = "";
  cartData: any;
  onlyHelmets: any[] = [];
  onlyCollections: any[] = [];
  show: boolean = false;
  cartItems$: Observable<Product[]>;
  newProducts: any[] = [];
  constructor(
    public productService: ProductService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private userService: userService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private db: NgxIndexedDBService
  ) {
    this.route.queryParams.subscribe((data) => {
      const fromOrderSuccess = data.from;
      // console.log(fromOrderSuccess);
      if (fromOrderSuccess) {
        this.router.navigate(["/home"]);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    });
    localStorage.removeItem("scroll");
    localStorage.removeItem("count");

    // this.productService.cartSubject.subscribe(data=>
    //   console.log('cart',data)
    // );
    // console.log(this.cartItems$);
    // this.productService.cartItems.subscribe((response) => {
    //   console.log("fa-one", response);
    // });
    const filters = {
      type: "HELMET",
      brand: ["MT HELMETS", "KORDA"],
    };
    // this.productService.UpdateProductForShop(filters);
    this.productService
      .UpdateProductForCollection(filters)
      .subscribe((response: any) => {
        const products = JSON.parse(response?.body);
        this.products = products.filter((p) => {
          return p.stock === true;
        });

        // console.log(this.products);
        this.newFilter();
        this.productService
          .getProductClassifications()
          .subscribe((data: any) => {
            const onlyBody = JSON.parse(data.body);
            // console.log(onlyBody);

            this.onlyHelmets = onlyBody.filter((item) => item.type == "HELMET");
            // console.log(this.onlyHelmets);
            this.onlyHelmets.forEach((data) =>
              this.totalBrands.push(data.brand.trim())
            );
            this.uniqueBrands = [...new Set(this.totalBrands)];
            // console.log(this.uniqueBrands);
          });

        this.userService.getIp().subscribe((data: any) => {
          this.ip = data.ip;
          // console.log("IP", this.ip);
          localStorage.setItem("IP", JSON.stringify(this.ip));
        });
      });
    if (!localStorage.getItem("anonymous-user-id")) {
      this.db.getByKey("uuid", "uuid").subscribe((res: any) => {
        console.log("anonymous id :", res?.id);
        if (res !== undefined) {
          localStorage.setItem("anonymous-user-id", JSON.stringify(res.id));
        }
        const id = uuidv4();
        if (id && res === undefined) {
          console.log("no id for anonymous user");
          this.addUUID(id);
        }
      });
    }
    this.getForShowingRecentByNoSideBar();
  }
  public ProductSliderConfig: any = ProductSlider;

  images = {
    "VEGA-DC": "assets/imagecategoris/vega-dc-poster.png",
    "VEGA-MARVEL": "assets/imagecategoris/vega-poster.png",
    "AXOR-MARVEL": "assets/imagecategoris/axor-poster.png",
    "AXOR-DC": "assets/imagecategoris/axor-dc-poster.png",
    "STUDDS-MARVEL": "assets/imagecategoris/studds-poster.png",
  };
  public sliders = [
    {
      title: "Grab Now @ Exculsive Deals",
      subTitle: "MARVEL",
      // image:'assets/imagecategoris/vega-dc-poster.png'
      image: "assets/images/slider/axor.png",
    },
    {
      title: "Grab Now @ Exculsive Deals",
      subTitle: "MARVEL",
      // image: "assets/imagecategoris/vega-poster.png",

      image: "assets/images/slider/vega.png",
    },
    {
      title: "Grab Now @ Exculsive Deals",
      subTitle: "DC",
      // image: "assets/imagecategoris/axor-poster.png",

      image: "assets/images/slider/axor-dc.png",
    },
  ];

  // Collection banner
  public collections = [
    {
      image: "assets/images/collection/fashion/1.jpg",
      save: "save 50%",
      title: "men",
    },
    {
      image: "assets/images/collection/fashion/2.jpg",
      save: "save 50%",
      title: "women",
    },
  ];

  // Blog
  public blog = [
    {
      image: "assets/images/blog/1.jpg",
      date: "25 January 2018",
      title: "Lorem ipsum dolor sit consectetur adipiscing elit,",
      by: "John Dio",
    },
    {
      image: "assets/images/blog/2.jpg",
      date: "26 January 2018",
      title: "Lorem ipsum dolor sit consectetur adipiscing elit,",
      by: "John Dio",
    },
    {
      image: "assets/images/blog/3.jpg",
      date: "27 January 2018",
      title: "Lorem ipsum dolor sit consectetur adipiscing elit,",
      by: "John Dio",
    },
    {
      image: "assets/images/blog/4.jpg",
      date: "28 January 2018",
      title: "Lorem ipsum dolor sit consectetur adipiscing elit,",
      by: "John Dio",
    },
  ];

  // Logo

  public logo = {
    KORDA: "assets/logo/edited-korda.jpg",

    STUDDS: "assets/logo/edited-studds.jpg",

    LS2: "assets/logo/edited-ls2.jpg",

    STEELBIRD: "assets/logo/edited-steelbird.jpg",

    "MT HELMETS": "assets/logo/edited-mt.jpg",

    AXOR: "assets/logo/axor-logo.png",

    RIDEX: "assets/logo/edited-ride-x.jpg",

    VEGA: "assets/logo/edited-vega.jpg",

    SMK: "assets/logo/edited-smk.jpg",

    AXXIS: "assets/logo/axxis-logo.jpg",
  };

  // selectedCollection: string
  // selectedCollections=[]
  alreadyUser = false;
  ngOnInit(): void {}

  addUUID(id: string) {
    // console.log(id);
    this.db
      .add("uuid", { name: "uuid", id })
      .pipe(
        catchError((error) => {
          // console.error("Error adding UUID:", error);
          return of(null);
        })
      )
      .subscribe((result) => {
        if (result) {
          // console.log("UUID added successfully:", { id });
          this.db.getByKey("uuid", "uuid").subscribe((res: any) => {
            // console.log("anonymous id :", res?.id);
            localStorage.setItem("anonymous-user-id", JSON.stringify(res.id));
          });
        }
      });
  }

  // Product Tab collection
  getCollectionProducts(collection) {
    return this.products.filter((item) => {
      if (item.collection.find((i) => i === collection)) {
        return item;
      }
    });
  }
  logged_in_user: boolean;
  triggerByScroll = false;
  async getForShowingRecentByNoSideBar() {
    let recentSearch: any[] = [];

    const user_id = JSON.parse(localStorage.getItem("username"));
    const session_id = JSON.parse(localStorage.getItem("anonymous-user-id"));
    let id: string = "";
    if (user_id) {
      this.logged_in_user = true;
      id = user_id;
    } else {
      this.logged_in_user = false;
      id = session_id;
    }

    if (
      (!localStorage.getItem("userRecentSearchInNosidebar") ||
        !localStorage.getItem("userSearches")) &&
      id
    ) {
      await this.userService.updateViewedProduct(id, this.logged_in_user);
      await this.userService.viewedProduct.subscribe((res: any) => {
        localStorage.setItem("userRecentSearchInNosidebar", res.body);
        recentSearch = JSON.parse(res.body);
        // console.log(recentSearch);
        // console.log(recentSearch);
        if (recentSearch?.length) {
          this.recentSearches = recentSearch.slice(-12);
        }
      });
      await this.userService.updateRecentSearch(id, this.logged_in_user);
      await this.userService.recentSearchBySub.subscribe((res: any) => {
        localStorage.setItem("userSearches", res.body);
        const data = JSON.parse(res.body);
        // console.log(data);
      });
    } else {
      recentSearch = JSON.parse(
        localStorage.getItem("userRecentSearchInNosidebar")
      );
      if (recentSearch?.length) {
        this.recentSearches = recentSearch.slice(-12);
      }
    }
  }
  get reversedRecentSearches() {
    if (this.recentSearches) {
      return [...this.recentSearches].reverse();
    }
  }

  sendToBoxOne(item) {
    this.title_url = item.title.trim().replace(/\s/g, "-");
    // console.log(this.title_url);
    this.router.navigate(["/shop/details", this.title_url]);
  }

  newFilter() {
    const filters = {
      new: true,
    };
    // this.productService.UpdateProductForShop(filters);
    this.productService
      .UpdateProductForCollection(filters)
      .subscribe((response: any) => {
        const products = JSON.parse(response?.body);
        if (products) {
          const newPro = products.filter((pro) => pro.new === true);
          // console.log(newPro);

          this.newProducts = newPro;
        }
      });
  }

  lastScrollTop = 0;
  previousScroll: any;
  previousScrollany;

  @HostListener("window:scroll", ["$event"])
  onWindowScroll(event: Event) {
    const currentScrollTop =
      window.scrollY || document.documentElement.scrollTop;
    const delta = Math.abs(currentScrollTop - this.lastScrollTop);
    // Calculate throttle time based on scroll speed
    this.lastScrollTop = currentScrollTop;
    // console.log(-this.lastScrollTop);
    localStorage.setItem("scroll-Home", JSON.stringify(this.lastScrollTop));
    if (
      !localStorage.getItem("userRecentSearchInNosidebar") &&
      this.triggerByScroll === false
    ) {
      this.getForShowingRecentByNoSideBar();
      this.triggerByScroll = true;
    }
  }

  ngAfterViewInit() {
    let valueForScroll;
    let counts;

    if (localStorage.getItem("scroll-Home")) {
      const position = JSON.parse(localStorage.getItem("scroll-Home"));

      valueForScroll = position;
      this.previousScroll = position;
      // console.log(counts);
      // for (let i = 1; i <= counts; i++) {
      //   this.topLoaderCounts.push(i);
      // }

      // console.log(position);
      setTimeout(() => {
        window.scrollTo({
          top: position,
          behavior: "smooth",
        });
        localStorage.removeItem("scroll-Home");
      }, 0);
    }
  }
}
