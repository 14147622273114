<!-- breadcrumb start -->
<div><br class="onlyForMobile" /></div>
<div
  class="breadcrumb-section"
  [ngClass]="{ forCheckout: breadcrumb === 'Checkout' }"
>
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="page-title">
          <h2>{{ title }}</h2>
        </div>
      </div>

      <div class="col-sm-6" id="desk" *ngIf="breadcrumb !== 'Checkout'">
        <nav aria-label="breadcrumb" class="theme-breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a [routerLink]="'/home'">Home</a></li>
            <li class="breadcrumb-item active" aria-current="page">
              {{ breadcrumb }}
            </li>
          </ol>
        </nav>
      </div>
    </div>
  </div>
</div>
<!-- breadcrumb End -->
