import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ProductService } from "../../services/product.service";
import { catchError, map } from "rxjs/operators";
import { Router } from "@angular/router";
import { CdkConnectedOverlay } from "@angular/cdk/overlay";
import { Observable, of } from "rxjs";
// import { input } from "aws-amplify";
import { userService } from "../../services/user.service";
import { NgxIndexedDBModule, NgxIndexedDBService } from "ngx-indexed-db";

@Component({
  selector: "app-search-overlay",
  templateUrl: "./search-overlay.component.html",
  styleUrls: ["./search-overlay.component.scss"],
})
export class SearchOverlayComponent implements OnChanges {
  constructor(
    private productservice: ProductService,
    private router: Router,
    private userService: userService,
    private db: NgxIndexedDBService
  ) {}
  title: string;
  collection: string;
  titles = [];
  overLayTop: boolean = false;
  collections = [];
  searchedValue: any;
  filteredTitles: any = [];
  filteredCollections: any = [];
  recentSearch: any = [];
  @Input() inputData: any;
  @Input() searchTop: boolean = false;
  title_url: string;
  headingOpen: boolean = false;
  selectedIndex: number = 0;
  selectedIndex2: number = 0;
  selectedSearch: string = "";
  selectedRecentSearch: string = "";
  onlyCollection: string;
  collectionRoute: boolean = false;
  @Output() searchFromChild = new EventEmitter<any>();
  @Output() clickSearch = new EventEmitter<any>();
  @Output() clickCollection = new EventEmitter<any>();
  @Output() forCollectionRoute = new EventEmitter<boolean>();

  @HostListener("document:keydown", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === "ArrowUp") {
      // this.emitData()
      // this.getSelectedValue();

      event.preventDefault(); // Prevent default scrolling behavior
      if (this.selectedIndex) {
        this.selectedIndex--;
        this.getSelectedValue();

        this.emitData();
        this.emitsOnlyCollection();
      }
      if (this.searchedValue.length == 0 && this.selectedIndex2) {
        this.selectedIndex2--;
        this.getSelectedValue();
        this.emitSearch();
        this.emitsOnlyCollection();
      }
      // this.getSelectedValue();

      // this.emitData();
      // this.emitsOnlyCollection();
      this.collectionRoute =
        this.selectedIndex < this.filteredCollections.length;
      this.emitsForCollecBoolean();
    } else if (event.key === "ArrowDown") {
      event.preventDefault(); // Prevent default scrolling behavior
      if (
        this.selectedIndex <
        this.filteredCollections.length + this.filteredTitles.length - 1
      ) {
        this.selectedIndex++; // Move selection down
        this.getSelectedValue();

        this.emitData();
        this.emitsOnlyCollection();
      }
      if (
        this.searchedValue.length == 0 &&
        this.selectedIndex2 < this.recentSearch.length - 1
      ) {
        this.selectedIndex2++; // Move selection down
        this.getSelectedValue();
        this.emitSearch();

        this.emitsOnlyCollection();
      }
      // this.getSelectedValue();
      // this.emitData();

      // this.emitsOnlyCollection();
      this.collectionRoute =
        this.selectedIndex < this.filteredCollections.length;
      this.emitsForCollecBoolean();
    }
  }

  getSelectedValue() {
    if (this.searchedValue.length === 0 && this.selectedIndex2 >= 0) {
      this.selectedRecentSearch = this.getItemByIndex(this.selectedIndex2);
    } else if (
      this.selectedIndex >= 0 &&
      this.selectedIndex <= this.filteredCollections.length
    ) {
      // console.log(this.selectedIndex);
      this.onlyCollection = this.getItemByIndex(this.selectedIndex);
      // console.log(this.onlyCollection);
      if (this.selectedIndex === this.filteredCollections.length) {
        this.selectedSearch = this.filteredTitles[0];
        // console.log(this.selectedSearch);
      }
    } else if (this.selectedIndex > this.filteredCollections.length) {
      // console.log(this.selectedIndex);
      this.selectedSearch = this.getItemByIndex(this.selectedIndex);
      // console.log(this.selectedSearch);
    }
  }

  getItemByIndex(index: number): any {
    if (this.searchedValue.length === 0 && index < this.recentSearch.length) {
      return this.recentSearch[index];
    } else if (index < this.filteredCollections.length) {
      this.onlyCollection = this.filteredCollections[index];
      return this.filteredCollections[index];
    } else if (index > this.filteredCollections.length) {
      this.selectedSearch =
        this.filteredTitles[index - this.filteredCollections.length];
      return this.filteredTitles[index - this.filteredCollections.length];
    }
  }

  ngOnInit(): void {
    // this.db
    //   .getByKey("uuid", "uuid")
    //   .subscribe((res: any) => console.log(res.id));

    // this.recentSearch = this.getRecentSearch();
    //title
    // this.emitData();
    this.getRecentSearch();

    if (this.filteredCollections?.length > 0) {
      this.selectedIndex = this.filteredCollections.length - 1;
    }

    // Set selectedIndex2 similarly based on recentSearch length
    if (this.recentSearch?.length > 0) {
      this.selectedIndex2 = this.recentSearch.length - 1;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.inputData) {
      this.searchedValue = changes.inputData.currentValue;
      // console.log('New value received in child component:',  this. searchedValue);
      this.selectedIndex = 0;
      this.selectedIndex2 = 0;
      this.filters();
      // console.log(this. searchedValue)
      this.headingOpen = true;
    }
  }

  filters() {
    if (!this.searchedValue) {
      this.filteredTitles;
      this.filteredCollections;
    } else {
      this.headingOpen = true;
      this.productservice
        .filteredProductsBySpecificSearch(this.searchedValue, ["title"])
        .subscribe((result) => {
          this.filteredTitles = result.slice(0, 3);
          // console.log("titles", this.filteredTitles);
        });

      this.productservice
        .filteredProductsBySpecificSearch(this.searchedValue, ["collection"])
        .subscribe((result) => {
          this.filteredCollections = result.slice(0, 3);
          // console.log("collections", this.filteredCollections);
        });
    }
  }

  onClick(item: string) {
    this.title_url = item.trim().replace(/\s/g, "-");
    // console.log(this.title_url);
    this.selectedSearch = this.title_url;
    this.emitData();

    if (this.filteredTitles) {
      this.router.navigate(["/shop/"], {
        queryParams: { search: item.trim().replace(/\s+/g, "-") },
      });
      this.bySearch();
      const type = "search";
      this.sendUserSearch(type, item.trim().replace(/\s+/g, "-"));
    }
  }
  onClickCollection(item: any) {
    this.title_url = item.trim().replace(/\s/g, "-");
    this.onlyCollection = this.title_url;
    this.emitsOnlyCollection();
    if (this.filteredCollections) {
      this.router.navigate(["/shop/"], {
        queryParams: { collection: item.trim() },
      });
      this.bySearch();
      const type = "collection";
      this.sendUserSearch(type, item.trim().replace(/\s+/g, "-"));
    }
  }
  onKeyPress(event: KeyboardEvent, item: string) {
    if (event.key === "Enter") {
      this.onClickCollection(item);
      this.onClick(item);
      this.onSearch(item);
    }
  }
  onSearch(item: any) {
    if (item) {
      this.selectedRecentSearch = item;
      this.emitSearch();
      const queryParams = {
        [item.search_type]: item.search_term,
      };
      this.router.navigate(["/shop/"], {
        queryParams: queryParams,
      });
      // const type = [keys[0]];
      // this.sendUserSearch(type[0], searchValue);
    }
  }
  bySearch() {
    localStorage.setItem("bySearch", JSON.stringify(true));
  }
  emitData() {
    this.searchFromChild.emit(this.selectedSearch);
  }

  emitSearch() {
    this.clickSearch.emit(this.selectedRecentSearch);
  }
  emitsOnlyCollection() {
    this.clickCollection.emit(this.onlyCollection);
  }
  emitsForCollecBoolean() {
    this.forCollectionRoute.emit(this.collectionRoute);
  }

  logged_in_user: boolean;
  async getRecentSearch() {
    const user_id = JSON.parse(localStorage.getItem("username"));
    const session_id = JSON.parse(localStorage.getItem("anonymous-user-id"));
    let id: string = "";
    if (user_id) {
      this.logged_in_user = true;
      id = user_id;
    } else {
      this.logged_in_user = false;
      id = session_id;
    }
    if (id) {
      await this.userService.updateRecentSearch(id, this.logged_in_user);
      await this.userService.recentSearchBySub.subscribe((res: any) => {
        localStorage.setItem("userSearches", res.body);
        const data = JSON.parse(res.body);
        // console.log(data);
        var recentSearch = JSON.parse(localStorage.getItem("userSearches"));
        if (recentSearch != undefined) {
          // console.log(recentSearch);
          this.recentSearch = recentSearch.slice(0, 6);
          return this.recentSearch;
        }
      });
    }
  }

  async sendUserSearch(type, term) {
    const session_id = JSON.parse(localStorage.getItem("anonymous-user-id"));
    const user_agent = JSON.parse(localStorage.getItem("User Agent"));
    const user_id = JSON.parse(localStorage.getItem("username")) || null;
    const data = {
      user_id: user_id,
      session_id: session_id,
      ip_address: "192.168.1.1",
      user_agent: user_agent,
      action_type: "search",
      product_id: null,
      search_term: term,
      search_type: type,
      order_id: null,
      details: {
        filters: [],
        sort_order: "asc",
      },
    };
    // console.log(data);
    await this.userService.addAction(data).subscribe((res) => console.log(res));
    await this.getRecentSearch();
  }
}
