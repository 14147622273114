<div class="onlyForDesk">
  <ng-template class="theme-modal" #sizeChart let-modal>
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{{ product?.title }}</h5>

        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div
        style="
          background-color: rgba(128, 128, 128, 0.096);
          padding: 8px 10px;
          text-align: center;
        "
      >
        <p style="word-spacing: 2px; color: black">
          ( *Compare your measurements with the sizechart below.If your
          measurement falls between two sizes,opting for the larger size is
          recommended ).
        </p>
        <!-- <br />
            *Begin by measuring the circumference of your head with a tape measure
            or a lengthy string that you can line upwith a ruler.Start above the
            ear and circle around completely,resting it in about a half inch above
            the eye brows and at the fullest part in back.Measure multiple times
            to ensure youdon't measure to small or large. -->
      </div>
      <div class="modal-body">
        <!-- <div class="row" style="margin-right: 0px; margin-left: 0px"> -->
        <!-- <div class="col-6" style="padding: 0px"> -->
        <table
          class="table table-bordered table-dark"
          style="text-align: center"
        >
          <thead>
            <tr>
              <th scope="col">Size</th>
              <th scope="col">Cms</th>
            </tr>
          </thead>
          <tbody>
            <!-- <ng-container *ngFor="let item of sizes"> -->
            <tr *ngFor="let size of getFilteredSizes() | keyvalue">
              <th scope="row">{{ size.key }}</th>
              <td>{{ size.value }}</td>
            </tr>
            <!-- </ng-container> -->
          </tbody>
        </table>
        <div
          style="
            background-color: rgba(128, 128, 128, 0.329);
            padding: 8px 10px;
            text-align: center;
          "
        >
          <b>GET INCHES</b>
        </div>
        <div style="display: flex; justify-content: center; margin-top: 7%">
          <input [(ngModel)]="centimeters" type="number" placeholder="cm.." />
        </div>
        <div style="display: flex; justify-content: center; margin-top: 3%">
          <input [(ngModel)]="inches" readonly placeholder="inches.." />
        </div>
        <div style="display: flex; justify-content: center; margin-top: 3%">
          <button
            class="btn btn-solid"
            style="padding: 3px 15px"
            (click)="convertToInches()"
          >
            calculate
          </button>
        </div>
        <br />
        <mat-expansion-panel
          hideToggle
          style="background-color: rgba(128, 128, 128, 0.096)"
        >
          <mat-expansion-panel-header>
            <mat-panel-title> Reference Image </mat-panel-title>
            <!-- <mat-panel-description> </mat-panel-description> -->
            <i class="fa fa-angle-double-down"></i>
          </mat-expansion-panel-header>
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <img
              style="width: 300px"
              src="assets/images/SIZE_IMG_200x200.webp"
            />
          </div>
        </mat-expansion-panel>
      </div>
      <!-- <div class="col-4" style="padding: 0px"></div> -->
      <!-- <div class="col-6" style="padding: 0px">
            <img src="assets/images/SIZE_IMG_200x200.webp" />
          </div> -->
      <br />
    </div>
    <!-- </div> -->
    <!-- </div> -->
  </ng-template>
</div>
