import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-cookies-consent",
  templateUrl: "./cookies-consent.component.html",
  styleUrls: ["./cookies-consent.component.scss"],
})
export class CookiesConsentComponent implements OnInit {
  constructor() {}
  accepted = false;
  intervalTime = 20000;
  ngOnInit(): void {
    if (localStorage.getItem("cookies")) {
      this.accepted = true;
    }
  }
  acceptCookies() {
    this.accepted = true;
    localStorage.setItem("cookies", JSON.stringify("accepted"));
  }
  decline() {
    this.accepted = true;
    setInterval(() => {
      this.accepted = false;
    }, this.intervalTime);
  }
}
