import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { Router } from "@angular/router";
import { ProductService } from "../../services/product.service";

@Component({
  selector: "app-collections-flow-desk",
  templateUrl: "./collections-flow-desk.component.html",
  styleUrls: ["./collections-flow-desk.component.scss"],
})
export class CollectionsFlowDeskComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  screenWidth: number;
  containerWidth: number;
  disableOverflowX: boolean = false;
  anArrayForCheck: any[] = [];
  imageForCollection: any[] = [];
  uniqueBrandWithCollections: { brand: string; collections: string[] }[] = [];
  images = [
    "assets/imagecategoris/fullface.webp",
    "assets/imagecategoris/openface.jpg",
    "assets/imagecategoris/flipup.jpg",
    "assets/imagecategoris/offroad.png",
    "assets/imagecategoris/axor.jpg",
    "assets/imagecategoris/Axxis.webp",
    "assets/imagecategoris/Korda.webp",
    "assets/imagecategoris/ls2.jpg",
    "assets/imagecategoris/mt.webp",
    "assets/imagecategoris/ridex.jpg",
    "assets/imagecategoris/smk.jpg",
    "assets/imagecategoris/steelbird.jpg",
    "assets/imagecategoris/studds.webp",
    "assets/imagecategoris/vega.jpg",
  ];

  img_coll = {
    duplicate: "assets/collections/placeholder.jpg",
    ADONIS: "assets/collections/ADONIS.jpg",
    ALTERRA: "assets/collections/ALTERRA.jpg",
    APEX: "assets/collections/APEX.webp",
    ATOM: "assets/collections/ATOM.jpg",
    BLADE: "assets/collections/BLADE.webp",
    BOLT: "assets/collections/BOLT.jpg",
    BLAZE: "assets/collections/BLAZE.jpg",
    BRAVO: "assets/collections/BRAVO.jpg",
    BREEZE: "assets/collections/BREEZE.jpg",
    BUDS: "assets/collections/BUDS.jpg",
    CHROME: "assets/collections/CHROME.webp",
    CLIFF: "assets/collections/CLIFF.jpg",
    CREST: "assets/collections/CREST.jpg",
    CRUISER: "assets/collections/CRUISER.jpg",
    CRUX: "assets/collections/CRUX.jpg",
    CUB: "assets/collections/CUB.png",
    DAME: "assets/collections/DAME.webp",
    "DISCOVERY WAVE": "assets/collections/DISCOVERY WAVE.webp",
    DOMINATOR: "assets/collections/DOMINATOR.webp",
    DOWNTOWN: "assets/collections/DOWNTOWN.webp",
    DRAKEN: "assets/collections/DRAKEN.webp",
    DRIFTER: "assets/collections/DRIFTER.webp",
    EAGLE: "assets/collections/EAGLE.webp",
    EDGE: "assets/collections/EDGE.jpg",
    EVE: "assets/collections/EVE.jpg",
    EVO: "assets/collections/EVO.jpg",
    FEMM: "assets/collections/FEMM.png",
    FF320: "assets/collections/FF 320.jpg",
    "FF 320": "assets/collections/FF 320.jpg",
    "FF 352": "assets/collections/FF 352.jpg",
    "FF 353": "assets/collections/FF 353.webp",
    "FF 800": "assets/collections/FF 800.png",
    GULLWING: "assets/collections/GULLWING.webp",
    HUMMER: "assets/collections/HUMMER.jpeg",
    ICON: "assets/collections/ICON.png",
    IGN: "assets/collections/IGN.jpg",
    JADE: "assets/collections/JADE.jpg",
    JET: "assets/collections/JET.jpg",
    JETSTAR: "assets/collections/JET STAR.jpg",
    SABER: "assets/collections/SABER.webp",
    CHAP: "assets/collections/CHAP.png",
    ASTER: "assets/collections/ASTER.jpg",
    TOAD: "assets/collections/TOAD.webp",
    KS: "assets/collections/KS.png",
    KUKKA: "assets/collections/KUKKA.png",
    LARK: "assets/collections/LARK.jpg",
    MARSHALL: "assets/collections/MARSHALL.png",
    MOTOCROSS: "assets/collections/MOTOCROSS.webp",
    "MT THUNDER": "assets/collections/MT THUNDER.webp",
    "MX 437": "assets/collections/MX 437.jpg",
    NANO: "assets/collections/NANO.png",
    NINJA: "assets/collections/NINJA.jpg",
    "OFF ROAD": "assets/collections/OFF ROAD.jpg",
    POLARIS: "assets/collections/POLARIS.png",
    PREMIUM: "assets/collections/PREMIUM.png",
    PROFESSIONAL: "assets/collections/PROFESSIONAL.png",
    RAGE: "assets/collections/RAGE.webp",
    RAIDER: "assets/collections/RAIDER.jpg",
    RANGER: "assets/collections/RANGER.jpg",
    RAY: "assets/collections/RAY.png",
    RETRO: "assets/collections/AXOR RETRO.webp",
    "SMK RETRO": "assets/collections/SMK RETRO.jpg",
    RIDGE: "assets/collections/RIDGE.jpg",
    RYKER: "assets/collections/RYKER.jpg",
    SBA: "assets/collections/SBA.jpg",
    SBH: "assets/collections/SBH.png",
    SCORPION: "assets/collections/SCORPION.jpg",
    SEGMENT: "assets/collections/SEGMENT.webp",
    SHIFTER: "assets/collections/SHIFTER.png",
    STELLAR: "assets/collections/STELLAR.png",
    "STREAM EVO": "assets/collections/STREAM EVO.webp",
    "SHOCK WAVE": "assets/collections/SHOCK WAVE.webp",
    STREET: "assets/collections/STREET.webp",
    SUBURBAN: "assets/collections/SUBURBAN.png",
    THUNDER: "assets/collections/THUNDER.jpg",
    TITAN: "assets/collections/TITAN.webp",
    TITANIUM: "assets/collections/TITANIUM.png",
    TOURANCE: "assets/imagecategoris/Korda.webp",
    TRACK: "assets/collections/TRACK.png",
    TROOPER: "assets/collections/TROOPER.webp",
    TWISTER: "assets/collections/TWISTER.png",
    TYPHOON: "assets/collections/TYPHOON.jpg",
    URBAN: "assets/collections/URBAN.png",
    "V-CROSS": "assets/collections/V-CROSS.jpg",
    VERVE: "assets/collections/VERVE.jpg",
    VOGUE: "assets/collections/VOGUE.webp",
    VOYAGER: "assets/collections/VOYAGER.jpg",
    VINTAGE: "assets/collections/VINTAGE.webp",
    XBHP: "assets/collections/XBHP.webp",
    "X CROSS": "assets/collections/X CROSS.jpg",
  };

  @ViewChildren("cards3") containerElements: QueryList<ElementRef>;
  private initialized: boolean = false;
  topLoaderCounts = [1, 2, 3, 4, 5, 6, 7, 8];
  topLoader: boolean = true;

  constructor(
    private router: Router,
    private productService: ProductService,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {
    this.productService.getProductClassifications().subscribe((result: any) => {
      const allData = JSON.parse(result.body);
      this.uniqueBrandWithCollections = this.processBrandCollections(allData);
      // let allColl;
      // allColl = this.uniqueBrandWithCollections.map((u) => u.collections);
      // console.log(allColl);
      // this.productService.productsFromSubject.subscribe((products) => {
      //   const singleArray = allColl.flat();
      //   console.log(singleArray);
      //   const matchedCollections = new Set();
      //   const resulted = [];
      //   const collectionImages: { [key: string]: string } = {};
      //   for (const product of products) {
      //     for (const collectionItem of product.collection) {
      //       if (
      //         singleArray.includes(collectionItem) &&
      //         !matchedCollections.has(collectionItem)
      //       ) {
      //         collectionImages[collectionItem] = product?.variants[0]?.images;
      //         resulted.push(collectionImages);
      //         matchedCollections.add(collectionItem);
      //       }
      //     }
      //   }

      //   // console.log(resulted[0]);
      //   this.imageForCollection = resulted[0];
      //   // console.log(this.imageForCollection);
      // });

      this.screenWidth = window.innerWidth;
    });
  }
  public logo = {
    KORDA: "assets/logo-small/korda-horizontal.png",

    STUDDS: "assets/logo-small/studds-horizontal.png",

    LS2: "assets/logo-small/ls2-horizontal.png",

    STEELBIRD: "assets/logo-small/steelbird-horizontal.png",

    "MT HELMETS": "assets/logo-small/mt-horizontal.png",

    AXOR: "assets/logo-small/axor-horizontal.png",

    RIDEX: "assets/logo-small/ridex-horizontal.png",

    VEGA: "assets/logo-small/vega-horizontal.png",

    SMK: "assets/logo-small/smk-horizontal.png",

    AXXIS: "assets/logo-small/axxis-horizontal.png",
  };

  ngOnInit(): void {
    this.productService.showLoaderSubject.subscribe((data) => {
      if (data == true || data == false) {
        this.topLoader = data;
      }
    });
  }

  ngAfterViewInit() {
    this.initialized = true; // Reset flag after initial check

    setTimeout(() => {
      this.checkOverflow();
      this.cdr.detectChanges();
      // this.cdr.detectChanges();  // Manually trigger change detection
    });
  }

  ngAfterViewChecked() {
    // Check if initialized and containerElements are populated
    if (
      this.initialized &&
      this.containerElements &&
      this.containerElements.length > 0
    ) {
      this.checkOverflow();
      this.initialized = false;
      this.cdr.detectChanges(); // Reset flag after initial check
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.checkOverflow();
    this.cdr.detectChanges();
  }

  processBrandCollections(
    products: any[]
  ): { brand: string; collections: string[] }[] {
    const onlyHelmets = products.filter(
      (item) => item.type == "HELMET" && item.collection[0] !== "VISOR"
    );

    // console.log(onlyHelmets);

    const totalBrands = onlyHelmets.map((data) => data.brand.trim());
    const uniqueBrands = [...new Set(totalBrands)];

    return uniqueBrands.map((brand) => {
      const collections = onlyHelmets
        .filter((product) => product.brand === brand)
        .reduce((item, product) => {
          const col = product.collection[0];
          if (!item.includes(col) && col !== "") {
            item.push(col);
          }

          return item;
        }, []);
      // let allData = [];
      // this.productService.productsFromSubject.subscribe(
      //   (data) => (allData = data)
      // );

      // const customOrder = ["MT HELMETS","AXOR", "SMK","RIDEX","AXXIS"];
      // const otherBrands = uniqueBrands.filter(brand => !customOrder.includes(brand));
      // console.log(otherBrands,customOrder)
      // console.log(collections);

      return { brand: brand, collections: collections };
    });
  }

  onClick(item: string) {
    if (item) {
      this.router.navigate(["/shop/"], {
        queryParams: { collection: item.trim() },
      });
    }
  }

  scrollLeft(element: HTMLElement) {
    element.scrollBy({ left: -400, behavior: "smooth" });
  }

  scrollRight(element: HTMLElement) {
    element.scrollBy({ left: 400, behavior: "smooth" });
  }

  checkOverflow() {
    if (this.containerElements && this.containerElements.length > 0) {
      this.containerElements.forEach((containerElement: ElementRef) => {
        this.containerWidth = containerElement.nativeElement.scrollWidth; // Use scrollWidth to include content width
        this.screenWidth = window.innerWidth;

        // Check if content width exceeds visible width
        this.disableOverflowX = this.containerWidth > this.screenWidth;
        this.anArrayForCheck.push(this.disableOverflowX);
        // console.log('Container Width:', this.containerWidth, 'Screen Width:', this.screenWidth, 'Overflow:', this.disableOverflowX);
      });
    }
    // console.log('anArrayForCheck',  this.anArrayForCheck)
  }
}

//   totalBrands=[]
//   uniqueBrands=[]
//   uniqueBrandWithCollections=[]
//   title_url:string=''
//   disableOverflowX: boolean = true;
//
//   ngOnInit(): void {
//     this.productservice.getProducts().subscribe(
//       (result)=>{result.forEach(data=>this.totalBrands.push(data.brand.trim()))
//       this.uniqueBrands = [...new Set(this.totalBrands)]
//       // console.log(this.uniqueBrands)
//       // console.log(result),
//     this.uniqueBrandWithCollections = this.uniqueBrands.map(brand=>{
//       return {brand: brand,
//       collections: result
//         .filter(product => product.brand === brand)
//         .reduce((item, product) => {
//           product.collection.forEach(col => {
//             if (!item.includes(col)) {
//               item.push(col);
//             }
//           });
//           return item;
//         }, [])
//     }})
//     // console.log( this.uniqueBrandWithCollections );
//   //  this.uniqueBrands.map((res)=>{
//   // })
// })

// }

// scrollLeft(element: HTMLElement){
//   // var left =document.querySelector(".cards");
//   // left.scrollBy(-200,0)
//   element.scrollBy({ left: -200, behavior: 'smooth' });
// }
// scrollRight(element: HTMLElement){
//   // var right =document.querySelector(".cards");
//   // right.scrollBy(200,0)
//   element.scrollBy({ left: 200, behavior: 'smooth' });

// }

// @ViewChild('cards3') containerElement: ElementRef;

// ngAfterViewInit() {
//   this.checkOverflow();
//   console.log(this.checkOverflow())

// }
// @HostListener('window:resize', ['$event'])
// onResize(event) {
//   this.screenWidth = window.innerWidth;
//   this.setScreenClasses();
//   console.log( this.setScreenClasses())
//   this.checkOverflow();
//   console.log( this.disableOverflowX)
// }
// checkOverflow() {
//   if (this.containerElement && this.containerElement.nativeElement) {
//     const containerWidth = this.containerElement.nativeElement.clientWidth;
//     console.log(  containerWidth)

//     this.disableOverflowX = containerWidth >= window.innerWidth;
//         // this.overflowX = containerWidth > screenWidth ? 'auto' : 'hidden';

//   }
// }
// setScreenClasses(){
//   if(this.screenWidth <= 1400){
//     return this.screenWidth
//   }
//   if( this.screenWidth <= 1600){
//     return this.screenWidth
//   }
//   if(this.screenWidth <= 1920){
//     return this.screenWidth
//   }
// }

// // showIcon(){
// //   if(this.screenWidth<){

// //   }
// // }

// }
//     if (this.containerElements && this.containerElements.length > 0) {
//       let anyOverflow = false;
//       this.containerElements.forEach((containerElement: ElementRef) => {
//         this.containerWidth = containerElement.nativeElement.clientWidth;
//         this.screenWidth = window.innerWidth
//         if (this.containerWidth >=   this.screenWidth) {
//           anyOverflow = true;
//         }
//         console.log( this.screenWidth,this.containerWidth)

//       });
//       this.disableOverflowX = anyOverflow;
// console.log( this.disableOverflowX)
//     }
// this.disableOverflowX = false;

// Check overflow for each containerElement
