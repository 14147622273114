import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
  Input,
  AfterViewInit,
  Injectable,
  PLATFORM_ID,
  Inject,
} from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import {
  NgbModal,
  ModalDismissReasons,
  NgbActiveModal,
} from "@ng-bootstrap/ng-bootstrap";
import { ProductService } from "../../../services/product.service";
import { Product } from "../../../classes/product";
import { MatDialog } from "@angular/material/dialog";
import { LoginModelComponent } from "../login/login.component";

@Component({
  selector: "app-cart-modal",
  templateUrl: "./cart-modal.component.html",
  styleUrls: ["./cart-modal.component.scss"],
})
export class CartModalComponent implements OnInit, AfterViewInit, OnDestroy {
  popUp: boolean = false;
  signUp: boolean = true;

  @Input() product: Product;
  @Input() currency: any;

  @ViewChild("cartModal", { static: false }) CartModal: TemplateRef<any>;
  // @ViewChild("loginModal") LoginModal: LoginModelComponent;

  public closeResult: string;
  public modalOpen: boolean = false;
  public products: any[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private productService: ProductService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {}

  async openModal(product) {
    await this.productService.productsFromSubject.subscribe((response: any) => {
      if (typeof response.body === "string") {
        this.products = JSON.parse(response.body);
      }
    });
    this.products = await this.products.filter(
      (items) =>
        items.category == product.category &&
        items.product_id != product.product_id
    );
    const status = await this.productService.addToCart(product);
    // console.log("fromcartmodaal", product);
    if (status) {
      this.modalOpen = true;
      if (isPlatformBrowser(this.platformId)) {
        // For SSR
        this.modalService
          .open(this.CartModal, {
            size: "lg",
            ariaLabelledBy: "Cart-Modal",
            centered: true,
            windowClass: "theme-modal cart-modal CartModal",
          })
          .result.then(
            (result) => {
              `Result ${result}`;
            },
            (reason) => {
              this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
          );
      }
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  openPopup() {
    this.popUp = true;
    if (this.popUp === true) {
      this.dialog.open(LoginModelComponent, {
        width: "80%",
        height: "50%",
      });
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
}
