import { BrowserModule, HammerModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { ToastrModule } from "ngx-toastr";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { ShopComponent } from "./shop/shop.component";
import { PagesComponent } from "./pages/pages.component";
import { ElementsComponent } from "./elements/elements.component";
import { MatDialogModule } from "@angular/material/dialog";
import { NgxIndexedDBModule, DBConfig } from "ngx-indexed-db";
import { OverlayModule } from "@angular/cdk/overlay";
import { HttpsInterceptor } from "./shared/interceptor/interceptor";
import { AuthGuard } from "./shared/services/auth-guard.service";
import { PortalModule } from "@angular/cdk/portal";
import { FormsModule } from "@angular/forms";
import { SearchbarComponent } from "./shared/components/searchbar/searchbar.component";
import { MatIconModule } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatCardModule } from "@angular/material/card";
import { GoogleAnalyticsService } from "./shared/services/google-analytics.service";
import { MatSnackBar, MatSnackBarModule } from "@angular/material/snack-bar";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFireAuthModule } from "@angular/fire/compat/auth";
import { environment } from "src/environments/environment";


const dbConfig: DBConfig = {
  name: "UUID",
  version: 14,
  objectStoresMeta: [
    {
      store: "uuid",
      storeConfig: { keyPath: "name", autoIncrement: false },
      storeSchema: [{ name: "ID", keypath: "id", options: { unique: false } }],
    },
  ],
};

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    ShopComponent,
    PagesComponent,
    ElementsComponent,

    // SearchbarComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    MatIconModule,
    OverlayModule,
    NgbModule,
    FormsModule,
    PortalModule,
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    MatProgressSpinnerModule,
    MatCardModule,
    HammerModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: false,
      enableHtml: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    SharedModule,

    AppRoutingModule,
    MatSnackBarModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    AngularFireModule.initializeApp(environment.firebase), // Initialize Firebase
    AngularFireAuthModule // Import AngularFireAuthModule
  ],
  providers: [
    GoogleAnalyticsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpsInterceptor,
      multi: true,
    },
    // AuthGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
