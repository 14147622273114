import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { NavService, Menu } from "../../services/nav.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-left-menu",
  templateUrl: "./left-menu.component.html",
  styleUrls: ["./left-menu.component.scss"],
})
export class LeftMenuComponent implements OnInit {
  public menuItems: Menu[];

  constructor(private router: Router, public navServices: NavService) {
    this.navServices.leftMenuItems.subscribe(
      (menuItems) => (this.menuItems = menuItems)
    );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }
  @Output() forCart = new EventEmitter<any>();

  ngOnInit(): void {
    // this.navServices.leftMenuToggle = this.Toggle;
  }

  leftMenuToggle(): void {
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
    // console.log(this.navServices.leftMenuToggle)
  }

  // hideCart(){
  //   if(this.navServices.leftMenuToggle){
  //     this.forCart.emit(true)

  //   }
  //   else{
  //     this.forCart.emit(false)

  //   }
  // }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  onHover(menuItem) {
    if (window.innerWidth > 1200 && menuItem) {
      document.getElementById("unset").classList.add("sidebar-unset");
    } else {
      document.getElementById("unset").classList.remove("sidebar-unset");
    }
  }
}
