import { Component, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { AuthService } from "../../../shared/services/auth.service";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute, Router, RoutesRecognized } from "@angular/router";
import { userService } from "src/app/shared/services/user.service";
import { log } from "console";
import { validateVerticalPosition } from "@angular/cdk/overlay";
import { ToastrService } from "ngx-toastr";
import { EventEmitter } from "stream";
import { HotToastService } from "@ngneat/hot-toast";
import { filter, pairwise } from "rxjs/operators";
import { Subscription, timer } from "rxjs";
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit, OnDestroy {
  Register!: FormGroup;
  otpForm: FormGroup;
  confirm: boolean = false;
  signUpId: any;
  otpError: boolean = false;
  verified: boolean = false;
  loader: boolean = false;
  signUpError: boolean = false;
  signUpErrorMsg: any;
  signInUserName;
  signInPassword;
  isButtonDisabled = false;
  countdown: number = 40; // Start at 40 seconds
  private countdownSubscription: Subscription | null = null;
  passwordValidation: RegExp =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
  previous_Url: string;
  @Input() confirmation: any;
  @Input() confirmMail: any;
  @Input() confirmPassword: any;
  tabRes: any;
  UserAccessReview: any;
  constructor(
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private userService: userService,
    private toast: HotToastService,
    private route: ActivatedRoute
  ) {
    localStorage.setItem("in_register", JSON.stringify("Register"));
    this.router.events
      .pipe(
        filter((evt: any) => evt instanceof RoutesRecognized),
        pairwise()
      )
      .subscribe((events: RoutesRecognized[]) => {
        // console.log("previous url", events[0].urlAfterRedirects);
        if (events[0].urlAfterRedirects !== "/login") {
          const previous = events[0].urlAfterRedirects;
          if (
            previous !== "/login?for=verify" &&
            previous !== "/pages/register"
          ) {
            localStorage.setItem(
              "previous-page",
              JSON.stringify(events[0].urlAfterRedirects)
            );
            this.previous_Url = JSON.parse(
              localStorage.getItem("previous-page")
            );
          }
        }
        // console.log("current url", events[1].urlAfterRedirects);
      });
    this.route.queryParams.subscribe((res) => {
      this.tabRes = res.tab;
      this.UserAccessReview = res.review;
    });
    this.loader = true;
    this.Register = new FormGroup({
      firstname: new FormControl("", Validators.required),
      lastname: new FormControl("", Validators.required),
      email: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.email])
      ),
      password: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(this.passwordValidation),
        ])
      ),
    });

    this.otpForm = this.fb.group({
      otp: this.fb.array(new Array(6).fill("").map(() => this.fb.control(""))),
    });
  }
  ngOnDestroy(): void {
    localStorage.removeItem("in_register");
    this.countdownSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.userService.getUserData().subscribe((res) => {
      // console.log(res);
    });
    if (this.confirmation == true) {
      // console.log(this.confirmation);
      this.confirm = true;
    }
    this.loader = false;
  }

  signUp() {
    this.loader = true;
    if (this.Register.valid) {
      var data = {
        firstName: this.Register.value.firstname,
        lastName: this.Register.value.lastname,
        email: this.Register.value.email,
        password: this.Register.value.password,
      };
      this.signInUserName = this.Register.value.email;
      this.signInPassword = this.Register.value.password;

      // console.log(data);
      // console.log(this.Register.value);
      this.authService
        .signUpWithEmailPassword(
          data.email,
          data.password,
          this.Register.value.lastname
        )
        .pipe(
          this.toast.observe({
            success: "SINGED-UP",
            loading: "SIGNING - IN ...",
            error: (err) => `${err?.message}`,
          })
        )
        .subscribe((res) => {
          // console.log(res);
          this.addUserToDb(data, res.uid);
          if (res?.emailVerified === false) {
            this.countDown();

            this.authService.checkVerifyUser.subscribe((check) => {
              // console.log(check);
              if (check.verified === true) {
                // console.log("confirmed");
                this.routeAfterLogged_in();
                this.loader = false;
                this.countdownSubscription?.unsubscribe();
                // this.addUserToDb(data, check.uid);
              } else {
                // console.log("waiting for confirm");
              }
            });
          }
        });
    }
    this.Register.markAllAsTouched();
  }

  countDown() {
    this.countdown = 60;
    this.countdownSubscription = timer(0, 1000).subscribe((seconds) => {
      this.countdown = 60 - seconds;
      if (this.countdown <= 0) {
        this.verifyMessage = `If You can't receieve any mail ; you can resend `;
        this.isButtonDisabled = true;
        this.countdownSubscription?.unsubscribe();
      }
    });
  }
  verifyMessage: string = "";
  onVerify() {
    this.isButtonDisabled = false;
    this.countDown();
    this.authService.sendVerificationEmail().subscribe({
      next: (res) => {
        this.toast.observe({
          success: "Mail Sent",
          loading: "Sending Mail...",
          error: (err: any) => `${err?.code}`,
        });

        this.verifyMessage =
          "We’ve sent a verification email to your inbox. Please check your email to confirm your account.";
      },
      error: (err) => {
        this.verifyMessage =
          "Error in sending verification. Please try again later.";
        // console.log("Mail not sent", err);
      },
    });
  }

  addUserToDb(data: any, uid: string) {
    if (data) {
      // this.confirm = true;

      const signUpUser = {
        cognito_username: uid,
        first_name: this.Register.value.firstname,
        last_name: this.Register.value.lastname,
        full_name:
          this.Register.value.firstname + " " + this.Register.value.lastname,
        phone: "",
        email: this.Register.value.email,
        is_active: false,
      };
      this.userService.signUpUserData(signUpUser).subscribe((res) => {
        // console.log(res);
      });
      // console.log(signUpUser);
    }

    // this.authService.signIn("+919751595324", "Test@1234");
  }

  routeAfterLogged_in() {
    if (localStorage.getItem("tryingCheckOutNew")) {
      const query = JSON.parse(localStorage.getItem("item-for-checkout"));
      localStorage.removeItem("tryingCheckOutNew");
      localStorage.removeItem("item-for-checkout");
      this.router.navigate(["/checkout"], {
        queryParams: { products: query },
      });
    } else {
      if (localStorage.getItem("previous-page")) {
        // console.log("not checkout");
        this.previous_Url = JSON.parse(localStorage.getItem("previous-page"));
        const decodedUrl = decodeURIComponent(this.previous_Url);
        // Split the URL into the base path and query parameters
        const [pathWithParams, queryParamsString] = decodedUrl.split("?");
        const pathSegments = pathWithParams.split("/").filter(Boolean); // Split by '/' and filter out empty segments
        const basePath =
          pathSegments.length > 0
            ? pathSegments.slice(0, pathSegments.length).join("/")
            : "";
        let queryParams = {};
        if (queryParamsString) {
          queryParamsString.split("&").forEach((param) => {
            const [key, value] = param.split("=");
            queryParams[decodeURIComponent(key)] = decodeURIComponent(value); // Decode keys and values
          });
        }
        this.router.navigate([basePath], { queryParams });
      } else {
        this.router.navigate(["/"]);
      }
    }
  }
  enterYourOtp() {
    this.loader = true;
    const otp = this.getOtpCode.value.join("");
    if (otp != "") {
      if (this.confirmMail) {
        // console.log(this.confirmMail);
        this.Register.value.email = this.confirmMail;
        this.signInUserName = this.confirmMail;
        this.signInPassword = this.confirmPassword;
      }
      this.authService
        .confirmSignup(this.Register.value.email, otp)
        .then((res) => {
          // console.log(res);
          if (res) {
            this.verified = true;
            this.authService
              .signIn(this.signInUserName, this.signInPassword)
              .then((res) => {
                if (res) {
                  this.router.navigate(["/"]);
                }
              });
            this.userService
              .editUserData({
                is_verified: this.verified,
              })
              .subscribe((res) => {
                this.loader = false;
                // console.log(res);
              });
          }
        })
        .catch((err) => {
          if (err && otp != "") {
            this.otpError = true;
            // console.log(err);
            this.otpForm.reset();
          } else {
            this.otpError = false;
          }
        });
    }
  }

  resendOtp() {
    if (this.confirmMail) {
      this.authService.resendCode(this.confirmMail).then((res) => {
        // console.log(res);
      });
    } else {
      this.authService.resendCode(this.Register.value.email).then((res) => {
        // console.log(res);
      });
    }
  }

  get getOtpCode(): FormArray {
    return this.otpForm.get("otp") as FormArray;
  }

  handleOtpChange(event: KeyboardEvent, index: number) {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    if (value.length === 1 && index < this.getOtpCode.length - 1) {
      const nextInput = document.getElementById(`otp-${index + 1}`);
      if (nextInput) {
        (nextInput as HTMLInputElement).focus();
      }
    }

    if (event.key === "Backspace" && value.length === 0 && index > 0) {
      const prevInput = document.getElementById(`otp-${index - 1}`);
      if (prevInput) {
        (prevInput as HTMLInputElement).focus();
      }
    }
  }
}
