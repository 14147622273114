import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/shared/services/auth.service";
import { userService } from "src/app/shared/services/user.service";
import { CognitoUser } from "amazon-cognito-identity-js";
@Component({
  selector: "app-password",
  templateUrl: "./password.component.html",
  styleUrls: ["./password.component.scss"],
})
export class PasswordComponent implements OnInit {
  passwordForm: FormGroup;

  constructor(
    private authService: AuthService,
    private userService: userService
  ) {
    (this.passwordForm = new FormGroup({
      oldPassword: new FormControl("", Validators.required),
      newPass: new FormControl("", [
        Validators.required,
        Validators.minLength(8),
        Validators.pattern(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
        ),
      ]),
      reNew: new FormControl("", Validators.required),
    })),
      this.authService.getUserSession().then((res) => {
        // console.log(res.attributes);

        this.loggedEmail = res.attributes["email"];
        // console.log(this.loggedEmail);
      });
  }
  hidePassword1: boolean = true;
  hidePassword2: boolean = true;
  hidePassword3: boolean = true;
  data: any;
  enrty: any;
  sameError: boolean = false;
  success: boolean = false;
  diffError: boolean = false;
  wrongOld: boolean = false;
  cognitoUser: CognitoUser;
  loggedEmail: any;
  session: any;
  email: string = "";
  code: string = "";
  password: string = "";
  errorMessage: string | null = null;
  successMessage: string | null = null;
  forgotPassSection = false;
  resetSection = false;
  // passwordValidation: RegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*(),.?":{}|<>]).{8,}$/;
  @Output() unconfirmedUser = new EventEmitter<boolean>();
  ngOnInit(): void {
    // this.authService.signIn.
    this.forgotPassSection = true;

    this.authService
      .getUserSession()
      .then((session: any) => {
        // console.log("User session:", session);
        this.loggedEmail = session.attributes;
      })
      .catch((err) => {
        if (err === "The user is not authenticated") {
          // console.log("im not a verified user");
          this.emitUnconfirmedUSer();
        }
        // console.error("Error fetching user session:", err);
      });

    this.session = this.authService.getUserSession();
    // console.log(this.session);
  }
  togglePasswordVisibility(index: number): void {
    if (index === 1) {
      this.hidePassword1 = !this.hidePassword1;
    } else if (index === 2) {
      this.hidePassword2 = !this.hidePassword2;
    } else if (index === 3) {
      this.hidePassword3 = !this.hidePassword3;
    }
  }

  checkPasswordChange() {
    // console.log(this.passwordForm.value.reNew);

    // Check if old password and new password are the same
    if (
      this.passwordForm.value.oldPassword === this.passwordForm.value.newPass
    ) {
      // console.log("Error: Old and new passwords cannot be the same");
      return (
        (this.sameError = true),
        (this.diffError = false),
        (this.wrongOld = false),
        (this.success = false)
      );
    }

    // Check if new password and re-entered new password are different
    if (this.passwordForm.value.newPass !== this.passwordForm.value.reNew) {
      // console.log("Error: New password and re-entered password must match");
      return (
        (this.sameError = false),
        (this.diffError = true),
        (this.wrongOld = false),
        (this.success = false)
      );
    }

    // If all checks pass, proceed to change password
    this.authService
      .changePassword(
        this.passwordForm.value.oldPassword,
        this.passwordForm.value.reNew
      )
      .then((user) => {
        // console.log("Password reset successful for user:", user);
        (this.success = true),
          (this.sameError = false),
          (this.diffError = false),
          (this.wrongOld = false);
      })
      .catch((err) => {
        // console.error("Error resetting password:", err);
        (this.sameError = false),
          (this.diffError = false),
          (this.wrongOld = true),
          (this.success = false);
        // Handle error (e.g., display error message)
      });

    //     this.authService
    // .changePasswordFAuth(
    //   this.passwordForm.value.oldPassword,
    //   this.passwordForm.value.reNew,
    //   user
    // )
    // .subscribe({
    //   next: (user) => {
    //     console.log("Password reset successful for user:", user);
    //     this.success = true;
    //     this.sameError = false;
    //     this.diffError = false;
    //     this.wrongOld = false;
    //   },
    //   error: (err) => {
    //     console.error("Error resetting password:", err);
    //     this.sameError = false;
    //     this.diffError = false;
    //     this.wrongOld = true;
    //     this.success = false;
    //     // Optionally, display an error message to the user
    //   },
    // });
  }

  forgotPass() {
    this.forgotPassSection = true;
  }

  onSubmit(): void {
    if (this.email) {
      // console.log(this.email);
      this.authService
        .sendPasswordResetEmail(this.email)
        .subscribe((response) => {
          this.successMessage =
            "Password reset instructions have been sent to your email (note : if you are an already user).";
          // this.forgotPassSection = false;
          // this.resetSection = true;
          this.errorMessage = null;
        });
      // .catch((error) => {
      //   this.errorMessage = "An error occurred. Please try again.";
      //   this.successMessage = null;
      // });
    }
  }
  onReset(): void {
    if (this.email && this.code && this.password) {
      this.authService
        .resetPassword(this.email, this.code, this.password)
        .then((response) => {
          this.successMessage = " Your password has been successfully reset ";
          this.success = true;
          // setTimeout(()=>{
          //   this.router
          // })
          this.errorMessage = null;
        })
        .catch((error) => {
          this.errorMessage = "An error occurred. Please try again.";
          this.successMessage = null;
        });
    }
  }
  emitUnconfirmedUSer() {
    this.unconfirmedUser.emit(false);
  }
  // this.authService.forceResetPassword
  // this.authService.signIn(this.data,this.enrty).then
}
