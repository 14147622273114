import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";

const state = {
  checkoutItems: JSON.parse(localStorage["checkoutItems"] || "[]"),
};

@Injectable({
  providedIn: "root",
})
export class OrderService {
  baseUrl: string =
    "https://qarselokic.execute-api.us-east-1.amazonaws.com/dev/";

  constructor(private http: HttpClient) {}

  // Get Checkout Items
  public get checkoutItems(): Observable<any> {
    const itemsStream = new Observable((observer) => {
      observer.next(state.checkoutItems);
      observer.complete();
    });
    return <Observable<any>>itemsStream;
  }

  public createOrder(data: any): Observable<any> {
    // console.log(data);
    return this.http.post<any>(this.baseUrl + "user/order", data);
  }

  public verifyPayment(data: any): Observable<any> {
    // console.log(data);
    return this.http.post<any>(this.baseUrl + "user/payment/verify", data);
  }
  // Create order
  public createOrderOld(product: any, details: any, orderId: any, amount: any) {
    var item = {
      shippingDetails: details,
      product: product,
      orderId: orderId,
      totalAmount: amount,
    };
    state.checkoutItems = item;
    localStorage.setItem("checkoutItems", JSON.stringify(item));
    localStorage.removeItem("cartItems");
    // this.router.navigate(['/shop/checkout/success', orderId]);
  }
}
