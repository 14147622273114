<section class="section1" *ngIf="!address && !billingAddress">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <mat-card *ngIf="!loader && !newAddressSelector">
          <mat-card-content>
            <mat-list>
              <mat-list-item
                class="horizontal-mat-list-item"
                (click)="openNewAddressAsSelector()"
              >
                <span class="para"
                  >Add Your New Address <Address></Address
                ></span>
                <span class="spacer"></span>
                <i class="fa fa-chevron-right" aria-hidden="true"></i>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </mat-card>
        <span
          *ngIf="newAddressSelector"
          class="btn btn-light"
          (click)="openNewAddressAsSelector()"
          >X</span
        >
        <div *ngIf="newAddressSelector">
          <app-new-address
            [forDashboard]="forDashboardAddAddress"
            (AddressIdFromNewAddress)="receiveData($event)"
          ></app-new-address>
        </div>
        <div *ngFor="let user of userNewAddress">
          <mat-card
            class="mt-4 d-block justify-content-center"
            *ngIf="!loader && !newAddressSelector"
          >
            <mat-card-content>
              <div>
                <i class="fa fa-home solid"></i>
                <span *ngIf="user.is_default == true" class="span ml-3">
                  Default : {{ user.is_default }}</span
                >
                <hr />
              </div>
              <div>
                <h3 class="h3">{{ user.full_name }}</h3>
                <p class="para">{{ user.place_name }},{{ user.address }}</p>
                <p class="para">{{ user.city }},{{ user.state }}</p>
                <p class="para">{{ user.country }}</p>
                <p class="para">Phone Number : {{ user.phone }}</p>
                <p>
                  <span class="notes">{{ user.notes }}</span>
                </p>
                <div>
                  <h5 class="p" (click)="instruction = !instruction">
                    Add Delivery Instructions
                  </h5>
                  <div *ngIf="instruction">
                    <form [formGroup]="addNote">
                      <!-- <label for="notes">Add Notes</label> -->
                      <input
                        type="text"
                        class="w-100 form-control"
                        id="notes"
                        placeholder="Add Your Delivery Instructions"
                        formControlName="notes"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </mat-card-content>
            <mat-card-actions style="display: flex; justify-content: flex-end">
              <button
                style="border-radius: 20px"
                class="btn btn-outline-secondary"
                (click)="editYourAddress(user.address_id)"
              >
                Edit
              </button>
              <button
                style="border-radius: 20px"
                class="btn btn-outline-secondary"
                (click)="removeYourAddress(user.address_id)"
              >
                Remove
              </button>
              <button
                style="border-radius: 20px"
                class="btn btn-outline-secondary"
                *ngIf="user.is_default == false"
                (click)="editDefault(user.address_id)"
              >
                Set as Default
              </button>
            </mat-card-actions>
          </mat-card>
        </div>

        <div
          *ngIf="loader"
          class="d-flex justify-content-center align-itemscenter"
        >
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="section2">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <br />
        <div
          class="col-12 box"
          *ngIf="!loader"
          [class.add]="address"
          (click)="addNewAddress()"
        >
          <mat-card style="box-shadow: none">
            <mat-card-content>
              <div
                class="row"
                style="
                  display: flex;
                  text-align: center;
                  justify-content: center;
                  cursor: pointer;
                "
              >
                <i
                  class="fa fa-plus"
                  style="font-size: x-large; color: grey; margin-right: 5%"
                  aria-hidden="true"
                ></i>
                <h6>Add New Address</h6>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div *ngFor="let user of userNewAddress">
          <mat-card class="mt-4 d-block justify-content-center" *ngIf="!loader">
            <mat-card-content>
              <!-- <p style="float: right;" *ngIf="user.is_default == false" class="span ml-3"> set as default</p> -->
              <button
                class="btn btn-outline-secondary"
                style="
                  color: #fff;
                  background-color: white;
                  border-color: white;
                  float: right;
                  cursor: pointer;
                "
                [class.btn-success]="user.is_default"
                (click)="editDefault(user.address_id)"
              >
                <input
                  type="radio"
                  class="visually-hidden"
                  style="cursor: pointer"
                  [checked]="user.is_default"
                  (change)="editDefault(user.address_id)"
                />
                <p
                  *ngIf="user.is_default == false"
                  style="
                    font-size: 10px;
                    color: grey;
                    text-transform: lowercase;
                  "
                >
                  (set as default)
                </p>
              </button>
              <br />

              <div>
                <mat-list>
                  <mat-list-item>
                    <b> {{ user.full_name }}</b></mat-list-item
                  >
                  <mat-list-item>
                    <p>
                      <i
                        class="fa fa-map-marker"
                        style="
                          font-size: large;
                          color: rgb(7, 7, 7);
                          margin-right: 2%;
                        "
                        aria-hidden="true"
                      ></i>
                      {{ user.place_name }},{{ user.address }} - {{ user.city }}
                    </p>
                  </mat-list-item>
                  <!-- <mat-list-item>  <p>{{user.city}}</p></mat-list-item> -->
                  <mat-list-item>
                    <p>
                      <i
                        class="fa fa-phone"
                        style="
                          font-size: large;
                          color: rgb(12, 12, 12);
                          margin-right: 2%;
                        "
                        aria-hidden="true"
                      ></i
                      >{{ user.phone }}
                    </p>
                  </mat-list-item>
                  <mat-list-item *ngIf="user.notes">
                    <p>
                      <span class="notes">{{ user.notes }}</span>
                    </p>
                  </mat-list-item>
                  <mat-list-item *ngIf="user.is_default == true">
                    <div style="width: 100%">
                      <p class="p" (click)="instruction = !instruction">
                        Add Delivery Instructions
                        <i
                          class="fa fa-pencil"
                          aria-hidden="true"
                          style="margin-left: 2%"
                        ></i>
                      </p>

                      <div *ngIf="instruction">
                        <form [formGroup]="addNote">
                          <!-- <label for="notes">Add Notes</label> -->
                          <input
                            type="text"
                            class="w-100 form-control custom-input"
                            id="notes"
                            placeholder="Add Your Delivery Instructions"
                            formControlName="notes"
                          />
                        </form>
                      </div>
                    </div>
                  </mat-list-item>
                  <hr />
                  <div
                    class="row"
                    style="
                      display: flex;
                      align-items: center;
                      justify-content: space-evenly;
                    "
                  >
                    <div
                      class="col-4"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <p>{{ user.state }}</p>
                    </div>
                    <div
                      class="col-4"
                      style="
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      "
                    >
                      <p>{{ user.country }}</p>
                    </div>

                    <div
                      class="col-2"
                      (click)="editYourAddress(user.address_id)"
                      style="
                        font-size: large;
                        color: rgb(64, 120, 194);
                        cursor: pointer;
                      "
                    >
                      <i class="fa fa-edit"></i>
                    </div>
                    <div
                      class="col-2"
                      (click)="removeYourAddress(user.address_id)"
                      style="
                        font-size: large;
                        color: rgb(230, 17, 17);
                        cursor: pointer;
                      "
                    >
                      <i class="fa fa-trash" aria-hidden="true"></i>
                    </div>
                  </div>
                </mat-list>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <!-- <div class="col-12 box" *ngIf="!loader" (click)="routeToaddAdress()">
                    <mat-card style="box-shadow: none;">
                        <mat-card-content>
                            <div class="row"
                                style="display: flex;text-align: center;justify-content: center;cursor: pointer;">
                                <i class="fa fa-plus" style="font-size: x-large;color: grey;margin-right: 5%;"
                                    aria-hidden="true"></i>
                                <h6>Add New Address</h6>

                            </div>
                        </mat-card-content>
                    </mat-card>

                </div> -->
        <div
          *ngIf="loader"
          class="d-flex justify-content-center align-itemscenter"
        >
          <mat-spinner></mat-spinner>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- for checkout addresspage -->
<section *ngIf="address || billingAddress" class="section3">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <p class="text-danger">Select a Address</p>
        <mat-card class="mt-3">
          <mat-card-content>
            <mat-card-title>Your Addresses</mat-card-title>
            <hr />
            <div *ngFor="let user of userNewAddress">
              <div class="d-flex mt-3">
                <div class="form-check justify-content-between">
                  <input
                    class="form-check-input"
                    type="radio"
                    (click)="inputChangeValue(user)"
                    name="exampleRadios"
                    id="exampleRadios1"
                    value="option1"
                  />
                  <label class="form-check-label text" for="exampleRadios1">
                    {{ user.full_name }},{{ user.place_name }},{{
                      user.address
                    }},{{ user.city }},{{ user.state }} {{ user.pincode }},{{
                      user.country
                    }}
                  </label>
                </div>
                <!-- <p class="form-check-label"></p> -->
              </div>
            </div>
            <div class="mt-4" (click)="openPopupAddAddress()">
              <i class="fa fa-plus"><span class="text">Add Address</span></i>
            </div>
          </mat-card-content>
          <hr />
          <mat-card-actions class="p-2">
            <button class="btn btn-outline-info" (click)="useThisAddress()">
              Use this Address
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</section>
