<app-breadcrumb [title]="'Login'" [breadcrumb]="'Login'"></app-breadcrumb>

<section
  class="login-page section-b-space"
  *ngIf="
    !showPassField && !confirmation && !mobile_Log && !onlyMailVerification
  "
>
  <i class="ti-close btn btn-link" (click)="closeLogin()" *ngIf="!mobile_Log">
  </i>

  <!-- <span (click)="deleteUser()">X</span> -->

  <div class="container logg">
    <div class="row">
      <div class="col-lg-12" *ngIf="!loader">
        <!-- <h3 class="heading">Login</h3> -->
        <div *ngIf="loginError" class="alert alert-danger" role="alert">
          <p style="margin-bottom: 0">Invalid Username or Password</p>
        </div>
        <div class="theme-card">
          <form class="theme-form" [formGroup]="login">
            <div class="form-group">
              <label for="email">Email</label>
              <input
                type="email"
                class="form-control"
                [ngStyle]="{ border: loginError ? '1px solid red' : '' }"
                id="email"
                formControlName="email"
                placeholder="Email"
              />
              <span
                class="text-danger"
                *ngIf="
                  !login.get('email')?.valid && login.get('email')?.touched
                "
                >Email is required</span
              >
            </div>
            <div class="form-group">
              <label for="review">Password</label>
              <input
                type="password"
                class="form-control"
                [ngStyle]="{ border: loginError ? '1px solid red' : '' }"
                id="review"
                formControlName="password"
                placeholder="Enter your password"
              />
              <div
                *ngIf="
                  !login.get('password')?.valid &&
                  (login.get('password')?.dirty ||
                    login.get('password')?.touched)
                "
              >
                <span
                  class="text-danger"
                  *ngIf="login.get('password')?.errors.required"
                  >Password is required</span
                >
                <!-- <span class="text-danger" *ngIf="login.get('password')?.errors.length">Password must be at least 8 characters long.</span> -->
                <!-- <span class="text-danger" *ngIf="login.get('password')?.errors.invalidPassword">Password must start with an uppercase letter, lowercase, contain a number, and a special character.</span> -->
              </div>
            </div>
            <div class="buttons_sign">
              <button
                class="btn btn-solid"
                (click)="signIn()"
                [disabled]="!login.valid"
              >
                Log-in
              </button>
              <button class="btn btn-solid" (click)="newUser()">Create</button>
            </div>
          </form>
          <br />
          <div class="other-log">
            <div class="option-log">
              <i class="ti-minus"></i><span> Or Sign In With</span>
              <i class="ti-minus"></i>
            </div>
            <button (click)="mobileLog()">Mobile</button>
          </div>
          <div
            style="
              display: flex;
              width: -webkit-fill-available;
              justify-content: end;
              line-height: 10px;
            "
            class="btn btn-link"
          >
            <p (click)="showPasswordChange()" style="color: blue">
              forgot password
            </p>
          </div>
        </div>
      </div>

      <!-- <div class="col-lg-6 right-login" *ngIf="!loader">
        <h3>New Customer</h3>
        <div class="theme-card authentication-right">
          <h6 class="title-font">Create A Account</h6>
          <p>
            Sign up for a free account at our store. Registration is quick and
            easy. It allows you to be able to order from our shop. To start
            shopping click register.
          </p>
          <button class="btn btn-solid" routerLink="/pages/register">
            Create an Account
          </button>
        </div>
      </div> -->
      <div
        class="col-12 d-flex justify-content-center align-items-center"
        *ngIf="loader"
      >
        <mat-spinner></mat-spinner>
      </div>
    </div>
  </div>
</section>
<div *ngIf="confirmation">
  <app-register
    [confirmation]="confirmation"
    [confirmMail]="cofirmMail"
    [confirmPassword]="confirmPassword"
  ></app-register>
</div>
<div *ngIf="showPassField && !confirmation">
  <br />
  <span
    style="display: flex; margin-left: 90%; width: fit-content; padding: 8px"
    class="btn btn-light"
    (click)="showPasswordChange()"
    >X</span
  >
  <app-password (unconfirmedUser)="recieveUserStatus($event)"></app-password>
</div>
<!-- <div class="phone-login-container" *ngIf="checkingForCheckout">
  <h2>Phone Number Verification</h2>

  <form *ngIf="!verificationId" (ngSubmit)="onSubmitPhone()">
    <label for="phoneNumber">Phone Number:</label>
    <input
      type="tel"
      id="phoneNumber"
      placeholder="Enter your phone number"
      [(ngModel)]="phoneNumber"
      name="phoneNumber"
      required
    />
    <button type="submit">Send Code</button>
  </form>

  <form *ngIf="verificationId" (ngSubmit)="onSubmitCode()">
    <label for="verificationCode">Verification Code:</label>
    <input
      type="text"
      id="verificationCode"
      placeholder="Enter the verification code"
      [(ngModel)]="verificationCode"
      name="verificationCode"
      required
    />
    <button type="submit">Verify Code</button>
  </form>

  <div *ngIf="message" class="message">{{ message }}</div>
</div> -->

<!-- section start -->
<section *ngIf="mobile_Log" class="mobile_login_page">
  <i style="margin-left: 90%" class="ti-close" (click)="closeMobileLog()"> </i>

  <div class="mobile-login">
    <mat-card>
      <label
        *ngIf="!verificationId && sign_Up_mob"
        for="login-mobile"
        class="font-weight-bold text-dark"
        style="font-size: 17px; margin-bottom: 5%"
        >Name</label
      >
      <div class="name" *ngIf="!verificationId && sign_Up_mob">
        <div class="row">
          <div class="col-2"><i class="fa fa-user" aria-hidden="true"></i></div>

          <div class="col-10">
            <input
              autofocus
              type="text"
              required
              [(ngModel)]="userName"
              (input)="validateName()"
            />
          </div>
        </div>
      </div>
      <br *ngIf="showErrorName && sign_Up_mob" />
      <p style="color: red" *ngIf="showErrorName">* ENTER A VALID NAME</p>
      <br />
      <label
        *ngIf="!verificationId"
        for="login-mobile"
        class="font-weight-bold text-dark"
        style="font-size: 17px; margin-bottom: 5%"
        >Mobile</label
      >

      <div class="box" *ngIf="!verificationId">
        <div class="row1">
          <div class="col-2">+91</div>
          <div class="col-10">
            <input
              id="mobile"
              (input)="formatMobile()"
              type="tel"
              name="mobile"
              maxlength="12"
              minlength="12"
              required
              pattern="[0-9]*"
              [(ngModel)]="mobileNo"
              (keypress)="isNumber($event)"
            />
          </div>
        </div>
      </div>
      <br />
      <p style="color: red" *ngIf="showErrorMobile && !showCaptchaMsg">
        * ENTER A VALID MOBILE NUMBER
      </p>
      <p style="color: green" *ngIf="showCaptchaMsg && !verificationId">
        *PLEASE VERIFY CAPTCHA
      </p>
      <p style="color: green" *ngIf="showNameFill && !verificationId">
        *FILL THE NAME SECTION
      </p>
      <div class="form-group mt-3" *ngIf="!verificationId">
        <button
          (click)="onSubmitPhone()"
          [disabled]="
            isSubmitting ||
            (sign_Up_mob &&
              (mobileNo?.length !== 12 || userName?.length === 0)) ||
            (!sign_Up_mob && mobileNo?.length !== 12)
          "
          id="btn-submit"
          type="submit"
          class="btn btn-solid font-weight-bold"
        >
          {{ changeButton ? "Resend" : "Submit" }}
        </button>
      </div>
      <form *ngIf="verificationId" (ngSubmit)="onSubmitCode()">
        <label for="verificationCode">Verification Code:</label>
        <input
          type="text"
          id="verificationCode"
          placeholder="Enter the verification code"
          [(ngModel)]="verificationCode"
          name="verificationCode"
          required
        />
        <button type="submit" class="verify btn btn-solid">Verify Code</button>
      </form>

      <br />
      <div class="sign_up_pn" *ngIf="sign_Up_mob === false && !verificationId">
        Create an account- <a (click)="sign_Up_Mobile()"> with mobile</a>
      </div>
      <div class="sign_up_pn" *ngIf="sign_Up_mob === true && !verificationId">
        Already have an account- <a (click)="Sign_In_Mobile()"> login</a>
      </div>
    </mat-card>
  </div>
  <div *ngIf="message" class="message">{{ message }}</div>
  <br />
  <div *ngIf="verificationId" class="back_log">
    <span class="alert alert-info">
      If you’re unable to get the code, we’ll take you back to the
      <a style="color: blue" (click)="backToLogin()">login page</a></span
    >
  </div>
  <!-- <div class="captcha" *ngIf="!verificationId">
    <div id="recaptcha-container"></div>
  </div> -->
</section>

<div class="captcha" *ngIf="!verificationId && !onlyMailVerification">
  <div id="recaptcha-container"></div>
</div>

<section class="container" *ngIf="onlyMailVerification">
  <div>
    <i class="ti-close btn btn-link" (click)="closeLogin()" *ngIf="!mobile_Log">
    </i>
    <!-- <h4>Verify your Mail</h4> -->
    <div class="verify_page">
      <mat-card>
        <div class="row">
          <span class="alert alert-info">
            {{ verifyMessage }} - {{ mailForVerify }}
          </span>
        </div>
        <div class="contain-load" *ngIf="loaderForVerify">
          <div class="col-sm-12 empty-cart-cls text-center">
            <img src="assets/images/loader.gif" />
          </div>
          <div class="row loader">
            <p *ngIf="mailForVerify">Check Your Mail - {{ mailForVerify }}</p>
          </div>
          <br />
        </div>
        <div class="row">
          <button (click)="onVerify()" class="btn btn-solid">
            send verification
          </button>
        </div>
      </mat-card>
    </div>
  </div>
</section>
<!--Section ends-->
