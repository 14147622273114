import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { FullViewComponent } from "../modal/full-view/full-view.component";
// import { Input } from 'hammerjs';

@Component({
  selector: "app-custom-carousel",
  templateUrl: "./custom-carousel.component.html",
  styleUrls: ["./custom-carousel.component.scss"],
})
export class CustomCarouselComponent implements OnInit {
  // images =[

  //   'assets/imagecategoris/vega.jpg',
  //   'assets/imagecategoris/axxis.jpg',
  //   'assets/imagecategoris/studds.jpg',
  //   'assets/imagecategoris/ridex.jpg',
  //   'assets/imagecategoris/smk.jpg'
  //   ]
  selectedIndex = 0;
  blur = false;
  @Input() images = [];
  // @ViewChild("fullView") FullView: FullViewComponent;
  imagesForFullView: string;
  constructor() {}
  ngOnInit(): void {
    // console.log(this.images)
  }
  showPrev(i: number) {
    if (this.selectedIndex > 0) {
      this.blur = true;
      setTimeout(() => {
        this.selectedIndex = i - 1;
        this.blur = false;
      }, 300);
    }
  }

  showNext(i: number) {
    if (this.selectedIndex < this.images.length - 1) {
      this.blur = true;
      setTimeout(() => {
        this.selectedIndex = i + 1;
        this.blur = false;
      }, 300);
    }
  }
  checking(img: string) {
    console.log(img);
    this.imagesForFullView = img;
  }
  conditionOverlayOpen = false;

  overlayClose() {
    this.conditionOverlayOpen = false;

    // document.body.classList.remove('overlay-open');
  }
  privacynotice() {
    this.conditionOverlayOpen = false;
  }

  openFull() {
    this.blur = false;
    this.conditionOverlayOpen = true;
  }
}
