<div class="slider-container">
  <div
    class="slider-item"
    *ngFor="let image of images; let i = index"
    [ngClass]="{
      selected: i === selectedIndex,
      prev: i === selectedIndex - 1,
      next: i === selectedIndex + 1,
      hideLeft: i < selectedIndex - 1,
      hideRight: i > selectedIndex + 1
    }"
  >
    <a href="javascript:void(0)" title="Full View" (click)="openFull()">
      <img
        style="background-color: white"
        [src]="images[i]"
        class="slider-img"
        (panright)="showPrev(i)"
        (panleft)="showNext(i)"
        (click)="checking(images[i])"
      />
      <i class="fa fa-search-plus icon-overlay" [ngClass]="{ blur: blur }"></i>
    </a>
  </div>
</div>
<div cdkOverlayOrigin #overlayPosition="cdkOverlayOrigin">
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="overlayPosition"
    [cdkConnectedOverlayOpen]="conditionOverlayOpen"
  >
    <div class="overlay-container" [ngClass]="{ blur: conditionOverlayOpen }">
      <div class="overlay-content">
        <span (click)="overlayClose()" class="closerOverlay">X</span>

        <div class="zoom-view" style="margin-top: 2%">
          <app-zoom-view
            [product]="imagesForFullView"
            [Images]="images"
          ></app-zoom-view>
        </div>
        <br />
      </div>
    </div>
  </ng-template>
</div>
