import { Component, OnInit } from "@angular/core";
import { ProductService } from "../../services/product.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-collections-flow",
  templateUrl: "./collections-flow.component.html",
  styleUrls: ["./collections-flow.component.scss"],
})
export class CollectionsFlowComponent implements OnInit {
  constructor(private productservice: ProductService, private router: Router) {}
  totalBrands = [];
  uniqueBrands = [];
  uniqueBrandWithCollections = [];
  title_url: string = "";
  images = [
    "assets/imagecategoris/fullface.webp",
    "assets/imagecategoris/openface.jpg",
    "assets/imagecategoris/flipup.jpg",
    "assets/imagecategoris/offroad.png",
    "assets/imagecategoris/axor.jpg",
    "assets/imagecategoris/Axxis.webp",
    "assets/imagecategoris/Korda.webp",
    "assets/imagecategoris/ls2.jpg",
    "assets/imagecategoris/mt.webp",
    "assets/imagecategoris/ridex.jpg",
    "assets/imagecategoris/smk.jpg",
    "assets/imagecategoris/steelbird.jpg",
    "assets/imagecategoris/studds.webp",
    "assets/imagecategoris/vega.jpg",
  ];
  public logo = {
    KORDA: "assets/logo-small/korda-horizontal.png",

    STUDDS: "assets/logo-small/studds-horizontal.png",

    LS2: "assets/logo-small/ls2-horizontal.png",

    STEELBIRD: "assets/logo-small/steelbird-horizontal.png",

    "MT HELMETS": "assets/logo-small/mt-horizontal.png",

    AXOR: "assets/logo-small/axor-horizontal.png",

    RIDEX: "assets/logo-small/ridex-horizontal.png",

    VEGA: "assets/logo-small/vega-horizontal.png",

    SMK: "assets/logo-small/smk-horizontal.png",

    AXXIS: "assets/logo-small/axxis-horizontal.png",
  };
  img_coll = {
    duplicate: "assets/collections/placeholder.jpg",
    ADONIS: "assets/collections/ADONIS.jpg",
    ALTERRA: "assets/collections/ALTERRA.jpg",
    APEX: "assets/collections/APEX.webp",
    ATOM: "assets/collections/ATOM.jpg",
    BLADE: "assets/collections/BLADE.webp",
    BOLT: "assets/collections/BOLT.jpg",
    BRAVO: "assets/collections/BRAVO.jpg",
    BLAZE: "assets/collections/BLAZE.jpg",
    BREEZE: "assets/collections/BREEZE.jpg",
    BUDS: "assets/collections/BUDS.jpg",
    CHROME: "assets/collections/CHROME.webp",
    CLIFF: "assets/collections/CLIFF.jpg",
    CREST: "assets/collections/CREST.jpg",
    CRUISER: "assets/collections/CRUISER.jpg",
    CRUX: "assets/collections/CRUX.jpg",
    CUB: "assets/collections/CUB.png",
    DAME: "assets/collections/DAME.webp",
    "DISCOVERY WAVE": "assets/collections/DISCOVERY WAVE.webp",
    DOMINATOR: "assets/collections/DOMINATOR.webp",
    DOWNTOWN: "assets/collections/DOWNTOWN.webp",
    DRAKEN: "assets/collections/DRAKEN.webp",
    DRIFTER: "assets/collections/DRIFTER.webp",
    EAGLE: "assets/collections/EAGLE.webp",
    EDGE: "assets/collections/EDGE.jpg",
    EVE: "assets/collections/EVE.jpg",
    EVO: "assets/collections/EVO.jpg",
    FEMM: "assets/collections/FEMM.png",
    FF320: "assets/collections/FF 320.jpg",
    "FF 320": "assets/collections/FF 320.jpg",
    "FF 352": "assets/collections/FF 352.jpg",
    "FF 353": "assets/collections/FF 353.webp",
    "FF 800": "assets/collections/FF 800.png",
    GULLWING: "assets/collections/GULLWING.webp",
    HUMMER: "assets/collections/HUMMER.jpeg",
    ICON: "assets/collections/ICON.png",
    IGN: "assets/collections/IGN.jpg",
    JADE: "assets/collections/JADE.jpg",
    JET: "assets/collections/JET.jpg",
    JETSTAR: "assets/collections/JET STAR.jpg",
    SABER: "assets/collections/SABER.webp",
    CHAP: "assets/collections/CHAP.png",
    ASTER: "assets/collections/ASTER.jpg",
    TOAD: "assets/collections/TOAD.webp",
    KS: "assets/collections/KS.png",
    KUKKA: "assets/collections/KUKKA.png",
    LARK: "assets/collections/LARK.jpg",
    MARSHALL: "assets/collections/MARSHALL.png",
    MOTOCROSS: "assets/collections/MOTOCROSS.webp",
    "MT THUNDER": "assets/collections/MT THUNDER.webp",
    "MX 437": "assets/collections/MX 437.jpg",
    NANO: "assets/collections/NANO.png",
    NINJA: "assets/collections/NINJA.jpg",
    "OFF ROAD": "assets/collections/OFF ROAD.jpg",
    POLARIS: "assets/collections/POLARIS.png",
    PREMIUM: "assets/collections/PREMIUM.png",
    PROFESSIONAL: "assets/collections/PROFESSIONAL.png",
    RAGE: "assets/collections/RAGE.webp",
    RAIDER: "assets/collections/RAIDER.jpg",
    RANGER: "assets/collections/RANGER.jpg",
    RAY: "assets/collections/RAY.png",
    RETRO: "assets/collections/AXOR RETRO.webp",
    "SMK RETRO": "assets/collections/SMK RETRO.jpg",
    RIDGE: "assets/collections/RIDGE.jpg",
    RYKER: "assets/collections/RYKER.jpg",
    SBA: "assets/collections/SBA.jpg",
    SBH: "assets/collections/SBH.png",
    SCORPION: "assets/collections/SCORPION.jpg",
    SEGMENT: "assets/collections/SEGMENT.webp",
    SHIFTER: "assets/collections/SHIFTER.png",
    STELLAR: "assets/collections/STELLAR.png",
    "STREAM EVO": "assets/collections/STREAM EVO.webp",
    "SHOCK WAVE": "assets/collections/SHOCK WAVE.webp",
    STREET: "assets/collections/STREET.webp",
    SUBURBAN: "assets/collections/SUBURBAN.png",
    THUNDER: "assets/collections/THUNDER.jpg",
    TITAN: "assets/collections/TITAN.webp",
    TITANIUM: "assets/collections/TITANIUM.png",
    TOURANCE: "assets/imagecategoris/Korda.webp",
    TRACK: "assets/collections/TRACK.png",
    TROOPER: "assets/collections/TROOPER.webp",
    TWISTER: "assets/collections/TWISTER.png",
    TYPHOON: "assets/collections/TYPHOON.jpg",
    URBAN: "assets/collections/URBAN.png",
    "V-CROSS": "assets/collections/V-CROSS.jpg",
    VERVE: "assets/collections/VERVE.jpg",
    VOGUE: "assets/collections/VOGUE.webp",
    VINTAGE: "assets/collections/VINTAGE.webp",
    VOYAGER: "assets/collections/VOYAGER.jpg",
    XBHP: "assets/collections/XBHP.webp",
    "X CROSS": "assets/collections/X CROSS.jpg",
  };
  topLoaderCounts = [1, 2, 3, 4, 5, 6, 7, 8];
  topLoader: boolean = true;

  ngOnInit(): void {
    this.productservice.getProductClassifications().subscribe((result: any) => {
      const allData = JSON.parse(result.body);
      const onlyHelmets = allData.filter(
        (item) => item.type == "HELMET" && item.collection[0] !== "VISOR"
      );

      onlyHelmets.forEach((data) => this.totalBrands.push(data.brand.trim()));
      this.uniqueBrands = [...new Set(this.totalBrands)];
      // console.log(this.uniqueBrands)
      // console.log(result),
      this.uniqueBrandWithCollections = this.uniqueBrands.map((brand) => {
        return {
          brand: brand,
          collections: onlyHelmets
            .filter((product) => product.brand === brand)
            .reduce((item, product) => {
              const col = product.collection[0];
              if (!item.includes(col) && col !== "") {
                item.push(col);
              }

              return item;
            }, []),
        };
      });
      // console.log(this.uniqueBrandWithCollections);
      //  this.uniqueBrands.map((res)=>{
      // })
    });
    this.productservice.showLoaderSubject.subscribe((data) => {
      if (data == true || data == false) {
        this.topLoader = data;
      }
    });
  }

  onClick(item: string) {
    if (item) {
      this.router.navigate(["/shop/"], {
        queryParams: { collection: item.trim() },
      });
    }
  }
}
