export const shopSideBarHelmet = {
  CATEGORIES: ["FULL FACE", "HALF FACE", "FLIP UP", "OFF ROAD"],
  CATEGORIES_GEARS: ["GLOVES", "JACKET", "BALACLAVA", "BOOTS"],
  BRAND: [
    "STEELBIRD",
    "AXOR",
    "STUDDS",
    "RIDEX",
    "AXXIS",
    "VEGA",
    "AXOR",
    "LS2",
    "SMK",
  ],
  COLORS: [
    "ANTHRACITE",
    "ARMY GREEN",
    "BATTLE GREEN",
    "BEIGE",
    "BLACK",
    "BLUE",
    "BROWN",
    "BURGUNDY",
    "CHERRY RED",
    "COOL GREY",
    "DESERT STORM",
    "FLUORESCENT ORANGE",
    "FLUORESCENT YELLOW",
    "GOLD",
    "GREEN",
    "GREY",
    "GUN GREY",
    "HAWAI",
    "INDIGO",
    "MAROON",
    "METALLIC BLUE",
    "MINT",
    "MUSTARD",
    "NEON",
    "NEON GREEN",
    "NEON YELLOW",
    "ORANGE",
    "PINK",
    "PURPLE",
    "RED",
    "ROYAL BLUE",
    "SILVER",
    "SLATE",
    "SPORTS RED",
    "TURQUOISE",
    "WHITE",
    "WINE RED",
    "YELLOW",
  ],
  SIZE: ["S", "M", "L", "XL", "2XL"],
};

export const ProductColors = {
  COLORSONEBOX: {
    ANTHRACITE: "#C0C0C0",
    ARMYGREEN: "#4B5320",
    "BATTLE GREEN": "#5D8AA8",
    BEIGE: "#F5F5DC",
    BLACK: "BLACK",
    BLUE: "BLUE",
    BROWN: "BROWN",
    BURGUNDY: "#8C001A",
    CHERRYRED: "#C24641",
    COOLGREY: "#8c91ab",
    DESERTSTORM: "BROWN",
    FLUORESCENTORANGE: "#FE632A",
    FLUORESCENTYELLOW: " #FFFF33",
    GOLD: "#FFD700",
    GREEN: "GREEN",
    GREY: "#808080",
    GUNGREY: "#818589",
    HAWAI: "HAWAI",
    INDIGO: "INDIGO",
    MAROON: "MAROON",
    METALLICBLUE: "#6B6B6B",
    MINT: "#98FF98 ",
    MUSTARD: "#FFDB58 ",
    NEON: "NEON",
    NEONGREEN: "#16F529",
    NEONYELLOW: "#FFFF33",
    ORANGE: "ORANGE",
    PINK: "PINK",
    PURPLE: "PURPLE",
    RED: "RED",
    ROYALBLUE: "#4169e1",
    SILVER: "#C0C0C0",
    SLATE: "#708090",
    SPORTSRED: "RED",
    TURQUOISE: "#40E0D0",
    WHITE: "WHITE",
    WINERED: "#990012",
    YELLOW: "YELLOW",
  },
};
