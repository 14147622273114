<app-header-one [sticky]="true"></app-header-one>

<!-- Home slider start-->
<app-cookies-consent></app-cookies-consent>
<!-- <span (click)="deleteUser()">x</span> -->
<section class="onlyForMobile">
  <section class="p-0">
    <app-slider
      [sliders]="sliders"
      [textClass]="'text-center'"
      [category]="'fashion'"
      [buttonText]="'shop now'"
    >
    </app-slider>
  </section>
</section>
<div class="forDeskTop">
  <section class="p-0">
    <app-custom-slider></app-custom-slider>
  </section>
</div>

<!-- <app-loader-checkout></app-loader-checkout> -->
<!-- Home slider End -->
<section class="cards" class="p-0">
  <app-cards-flow [brands]="uniqueBrands"></app-cards-flow>
</section>
<!-- collection banner start-->
<section class="pb-0" class="banners">
  <app-cards-flow-desk [brands]="uniqueBrands"></app-cards-flow-desk>
  <!-- <div class="container">
    <div class="row partition2">
      <div class="col-md-6" *ngFor="let collection of collections">
        <a [routerLink]="['/shop/']" >
          <div class="collection-banner p-right text-center">
            <img [src]="collection.image" class="img-fluid" alt="collection-banner">
            <div class="contain-banner">
              <div>
                <h4>{{collection.save}}</h4>
                <h2>{{collection.title}}</h2>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div> -->
</section>

<div class="title1 section-t-space">
  <h4>Riding</h4>
  <h2 class="title-inner1">Gears</h2>
</div>
<div>
  <app-card-categories></app-card-categories>
</div>
<br />

<!-- collection banner End -->

<!-- Product slider start-->

<div class="title1 section-t-space">
  <h4>special offer</h4>
  <h2 class="title-inner1">top collection</h2>
</div>
<div class="container" class="forDeskTop">
  <div class="row">
    <div class="col-lg-6 offset-lg-3">
      <!-- <div class="product-para">
        <p class="text-center">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry, 
          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
        </p>
      </div> -->
    </div>
  </div>
</div>
<section class="section-b-space p-t-0" class="forDeskTop">
  <app-mat-card-desk [product]="products | slice : 0 : 16"></app-mat-card-desk>
  <!-- <div class="container">
    <div class="row">
      <div class="col">
        <owl-carousel-o class="product-m no-arrow" [options]="ProductSliderConfig">
          <ng-container *ngFor="let product of products | slice:0:16">
            <ng-template carouselSlide>
              <div class="product-box">
                <app-product-box-one 
                  [product]="product" 
                  [currency]="productService?.Currency"
                  [thumbnail]="true"
                  [cartModal]="true">
                </app-product-box-one>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </div>
  </div> -->
</section>

<!-- mobile -->
<section class="onlyForMobile">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 offset-lg-3">
        <!-- <div class="product-para">
        <p class="text-center">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry, 
          Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
        </p>
      </div> -->
      </div>
    </div>
  </div>
  <app-mat-card-products
    [product]="products | slice : 0 : 16"
  ></app-mat-card-products>
</section>

<!-- Product slider End -->

<!-- Parallax banner start-->
<section class="forDeskTop">
  <section class="p-0">
    <div
      class="full-banner parallax-banner1 parallax text-center p-left"
      [ngStyle]="{
        'background-image':
          'url(assets/images/parallax/APEX_Streak_Banner.webp)'
      }"
    >
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="banner-contain">
              <h2>2024</h2>
              <h3>Exclusive Model</h3>
              <!-- <h4 style="color: rgba(243, 161, 84, 0.747)">
              {{ uniqueBrands | slice : 0 : 3 }}
            </h4> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
<br />
<!-- Parallax banner End -->
<div class="title1 section-t-space">
  <h4>Recent Arrivals - from</h4>
  <h2 class="title-inner1">Signature Brands</h2>
</div>
<section class="section-b-space p-t-0" class="forDeskTop">
  <app-mat-card-desk [product]="newProducts"></app-mat-card-desk>
</section>

<section class="onlyForMobile">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 offset-lg-3"></div>
    </div>
  </div>
  <app-mat-card-products [product]="newProducts"></app-mat-card-products>
</section>

<!-- Tab product start-->
<div class="title1 section-t-space" style="margin-top: 3%">
  <h4>Brandwise</h4>
  <h2 class="title-inner1">Collections</h2>
</div>
<section class="forDeskTop">
  <app-collections-flow-desk></app-collections-flow-desk>
  <!-- <div class="container">
    <div class="row">
      <div class="col theme-tab">
        <ngb-tabset [justify]="'center'" [type]="'pills'" class="tabs tab-title">
          <ngb-tab *ngFor="let collection of productCollections">
            <ng-template ngbTabTitle>{{collection}}</ng-template>
            <ng-template ngbTabContent>
              <div class="no-slider row">
                <ng-container *ngFor="let product of getCollectionProducts(collection) | slice:0:8">
                  <div class="product-box">
                    <app-product-box-one 
                      [product]="product" 
                      [currency]="productService?.Currency"
                      [thumbnail]="true"
                      [cartModal]="true">
                    </app-product-box-one>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </div>
  </div> -->
</section>
<!-- mobile -->
<section class="onlyForMobile">
  <app-collections-flow></app-collections-flow>
</section>
<!-- <section class="onlyForMobile">
 <div class="col theme-tab">
    <ngb-tabset [justify]="'center'" [type]="'pills'" class="tabs tab-title">
      <ngb-tab *ngFor="let collection of productCollections">
        <ng-template ngbTabTitle>{{collection}}</ng-template>
        <ng-template ngbTabContent>
          <app-mat-card-products [product]="getCollectionProducts(collection) | slice:0:8"></app-mat-card-products>
  </ng-template>
</ngb-tab>
</ngb-tabset>
</div>
</section> -->

<!-- Tab product End -->

<!-- services start-->

<!-- services End -->

<!-- blog section start-->
<!-- <section class="blog p-t-0">
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="title1 section-t-space">
          <h4>Recent Story</h4>
          <h2 class="title-inner1">from the blog</h2>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <app-blog [blogs]="blog"></app-blog>
      </div>
    </div>
  </div>
</section> -->
<!-- blog section End -->

<!--  logo section start-->
<section class="section-b-space" class="forDeskTop">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <app-logo [logos]="logo"></app-logo>
      </div>
    </div>
  </div>
</section>

<!--  logo section End-->
<section class="onlyForMobile" *ngIf="recentSearches.length">
  <div class="title1">
    <h4>Pick Up</h4>
    <h2 class="title-inner1">Where You Left</h2>
  </div>
  <app-mat-card-products
    [product]="reversedRecentSearches"
    [showHistoryIcon]="true"
  ></app-mat-card-products>
</section>
<section class="forDeskTop" *ngIf="recentSearches.length">
  <div class="title1">
    <h4>Pick Up</h4>
    <h2 class="title-inner1">Where You Left</h2>
  </div>
  <app-mat-card-desk
    [product]="reversedRecentSearches"
    [showHistoryIcon]="true"
  ></app-mat-card-desk>
</section>
<!-- <div class="container">
  <section class="service border-section small-section">
    <app-services></app-services>
  </section>
</div> -->

<!-- <app-newsletter></app-newsletter> -->
<app-footer-three></app-footer-three>
