import { Component, OnInit, Input } from "@angular/core";
import { NewProductSlider } from "../../../data/slider";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-product-box-vertical-slider",
  templateUrl: "./product-box-vertical-slider.component.html",
  styleUrls: ["./product-box-vertical-slider.component.scss"],
})
export class ProductBoxVerticalSliderComponent implements OnInit {
  @Input() title: string = "New Product"; // Default
  @Input() type: string = "HELMET"; // Default Fashion
  @Input() product: any;
  products: any[] = [];
  productList: any[] = [];

  public NewProductSliderConfig: any = NewProductSlider;

  constructor(public productService: ProductService, private router: Router) {
    // console.log(typeof this.type);
    // this.productService.productsFromSubject.subscribe((response: any) => {
    //   if (typeof response.body === "string") {
    //     this.productList = JSON.parse(response.body);
    //     this.products = this.productList.filter(
    //       (item: any) => item.type === this.type && item.new === true
    //     );
    //   } else {
    //   });
    // }
  }
  ngOnInit(): void {
    if (this.product) {
      // console.log(this.product);
      this.productList = this.product;
      // console.log(this.productList);
      this.products = this.productList.filter((item: any) => item.new === true);
      if (this.products == undefined) {
        this.products = this.productList.filter(
          (item: any) => item.sale === true
        );
      }
      // console.log(this.products);
    }
  }
  toNoSideBar(id: any, title: any) {
    const data = btoa(id);
    const formatTitle = title.replace(/\s+/g, "-");
    this.router.navigate(["/shop/details/", data, formatTitle]);
  }
}
