<div class="card-container-scroll">
  <div class="card-container">
    <mat-card appearance="outlined">
      <!-- category -->

      <mat-card-content class="one">
        <div class="row">
          <div class="row1">
            <div class="col-6">
              <div>
                <a
                  [routerLink]="['/shop/']"
                  [queryParams]="{ category: category[0] }"
                >
                  <img [src]="image_dict['fullface']" width="50px" />
                  <p>{{ category[0] }}</p>
                </a>
              </div>
            </div>
            <div class="col-6">
              <div>
                <a
                  [routerLink]="['/shop/']"
                  [queryParams]="{ category: category[1] }"
                >
                  <img [src]="image_dict['openface']" width="50px" />
                  <p>{{ category[1] }}</p>
                </a>
              </div>
            </div>
          </div>

          <br />

          <div class="row2">
            <div class="col-6">
              <div>
                <a
                  [routerLink]="['/shop/']"
                  [queryParams]="{ category: category[2] }"
                >
                  <img [src]="image_dict['flipup']" width="50px" />
                  <p>{{ category[2] }}</p>
                </a>
              </div>
            </div>
            <div class="col-6">
              <div>
                <a
                  [routerLink]="['/shop/']"
                  [queryParams]="{ category: category[3] }"
                >
                  <img [src]="image_dict['offroad']" width="50px" />
                  <p>{{ category[3] }}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <!-- brands -->

    <div *ngFor="let brand of sortedBrands; let i = index">
      <mat-card appearance="outlined" class="brands">
        <mat-card-content>
          <a [routerLink]="['/shop/']" [queryParams]="{ brand: brand }">
            <div>
              <div class="brand">{{ brand }}</div>
              <br />
              <div>
                <img [src]="image_dict[brand]" width="115px" height="115px" />
              </div>
            </div>
          </a>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
