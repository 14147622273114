import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
  Input,
  Injectable,
  PLATFORM_ID,
  Inject,
} from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Product } from "../../../classes/product";

@Component({
  selector: "app-size-modal",
  templateUrl: "./size-modal.component.html",
  styleUrls: ["./size-modal.component.scss"],
})
export class SizeModalComponent implements OnInit, OnDestroy {
  @Input() product: Product;

  @ViewChild("sizeChart", { static: false }) SizeChart: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal
  ) {}

  public sizes = [
    {
      brand: "STUDDS",
      size: {
        XXL: "62 ",
        XL: "60  - 62 ",
        L: "58  - 60 ",
        M: "57  - 58 ",
        S: "56  - 57 ",
        XS: "54  - 56 ",
        XXS: "52  - 54 ",
        XXXS: "50  - 52 ",
      },
    },
    {
      brand: "VEGA",
      size: {
        XS: "54",
        S: "56",
        M: "57",
        L: "58",
      },
    },
    {
      brand: "STEELBIRD",
      size: {
        XL: "59  - 60 ",
        L: "57  - 58 ",
        M: "55  - 56 ",
        S: "53  - 54 ",
        XS: "51  - 52 ",
        XXS: "49  - 50 ",
      },
    },
    {
      brand: "SMK",
      size: {
        "Y-S": "48",
        "Y-M": "50",
        "Y-L/ XXS": "52",
        XS: "54",
        S: "55",
        M: "57",
        L: "59",
        XL: "61",
        XXL: "62",
        XXXL: "64",
        XXXXL: "65",
      },
    },
    {
      brand: "RIDEX",
      size: {
        XXL: "63  - 64 ",
        XL: "61  - 62 ",
        L: "59  - 60 ",
        M: "57  - 58 ",
        S: "55  - 56 ",
        XS: "53  - 54 ",
        XXS: "51  - 52 ",
        XXXS: "49  - 50 ",
      },
    },
    {
      brand: "MT HELMETS",
      size: {
        XS: "53-54",
        S: "55-56",
        M: "57-58",
        L: "59-60",
        XL: "61-62",
        "2XL": "63-64",
        "3XL": "65-66",
      },
    },
    {
      brand: "LS2",
      size: {
        "2XL": "63  - 64 ",
        "3XL": "65  - 66 ",
        XL: "61  - 62 ",
        L: "59  - 60 ",
        M: "57  - 58 ",
        S: "55  - 56 ",
        XS: "53  - 54 ",
        XXS: "51  - 52 ",
      },
    },
    {
      brand: "KORDA",
      size: {
        "2XL": "62  - 63 ",
        XL: "61  - 62 ",
        L: "59  - 60 ",
        M: "57  - 58 ",
        S: "55  - 56 ",
        XS: "53  - 54 ",
        XXS: "51  - 52 ",
        XXXS: "49  - 50 ",
      },
    },
    {
      brand: "AXXIS",
      size: {
        "3XS": "49-50",
        "2XS": "51-52",
        " XS": "53-54",
        S: "55-56",
        M: "57-58",
        " L": "59-60",
        XL: "61-62",
        "2XL": "63-64",
        "3XL": "65-66",
        "4XL": "67-68",
        "5XL": "69-70",
      },
    },
    {
      brand: "AXOR",
      size: {
        XS: "53 - 54",
        S: "55 - 56",
        M: "57 - 58",
        L: "59 -60",
        XL: "61 - 62",
        XXL: "63 - 64",
      },
    },
  ];
  // public Inches = {
  //   "52": "20.472",
  //   "53": "20.866",
  //   "54": "21.26 ",
  //   "55": "21.654 ",
  //   "56": "22.047",
  //   "57": "22.441",
  //   "58": "22.835",
  //   "59": "23.228",
  //   "60": "23.622",
  //   "61": "24.016",
  //   "62": "24.409",
  //   "63": "24.803",
  //   "64": "25.197",
  //   "65": "25.591",
  //   "66": "25.984",
  //   "67": "26.378",
  //   "68": "26.772",
  //   "69": "27.165",
  //   "70": "27.559",
  // };
  selectedSize: any;
  centimeters: number;
  inches: number;
  selectedSizeValues: any;
  ngOnInit(): void {
    this.getFilteredSizes();
    // console.log(this.centimeters);
  }
  convertToInches() {
    this.inches = 0;
    // console.log(this.centimeters);
    const inch = this.centimeters / 2.54;
    this.inches = inch;
    // console.log(inch);
  }
  getFilteredSizes() {
    if (this.product) {
      return (this.selectedSize =
        this.sizes.find((item) => item.brand === this.product.brand)?.size ||
        {});
    }

    // const sizeOrder = ['XXXS','3XS', 'XXS','2XS', 'XS', 'S', 'M', 'L', 'XL', 'XXL','2XL','XXXL','3XL','4XL',];
  }

  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalService
        .open(this.SizeChart, {
          size: "md",
          ariaLabelledBy: "size-modal",
          centered: true,
          windowClass: "SizeChart",
        })
        .result.then(
          (result) => {
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
}
