<br />
<div class="container" style="padding: 10px">
  <div class="row" style="align-items: center; justify-content: center">
    <div>
      <div
        *ngIf="loader"
        class="d-flex justify-content-center align-items-center"
      >
        <mat-spinner></mat-spinner>
      </div>
      <mat-card *ngIf="!loader">
        <div>
          <h3 class="h3">Add Your New Address</h3>
          <mat-card-content>
            <form [formGroup]="newAddress">
              <div class="form-group">
                <label for="inputState">Country</label>
                <select
                  id="inputState"
                  class="form-control"
                  formControlName="country"
                  disabled
                >
                  <!-- <option selected>Choose your Country</option> -->
                  <option value="India">India</option>
                </select>
              </div>
              <div class="form-group">
                <label for="email">*Fullname(First and Last name)</label>
                <input
                  type="text"
                  class="form-control"
                  formControlName="full_name"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    !newAddress.get('full_name')?.valid &&
                    newAddress.get('full_name')?.touched
                  "
                  >Enter your fullname</span
                >
              </div>
              <div class="form-group">
                <label for="review">*Mobile Number</label>
                <input
                  type="text"
                  class="form-control"
                  id="review"
                  formControlName="phone"
                  maxlength="10"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    !newAddress.get('phone')?.valid &&
                    newAddress.get('phone')?.touched
                  "
                  >Enter your phone number</span
                >
                <span *ngIf="newAddress.get('phone')?.valid"
                  >May be used to assist delivery</span
                >
              </div>
              <div class="form-group">
                <label for="review">*Pincode</label>
                <input
                  type="text"
                  class="form-control"
                  id="review"
                  formControlName="pincode"
                  placeholder="6 digits [0-9] pincode"
                  maxlength="6"
                  (keypress)="isNumber($event)"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    !newAddress.get('pincode')?.valid &&
                    newAddress.get('pincode')?.touched
                  "
                  >Enter your pincode</span
                >
              </div>
              <div class="form-group">
                <label for="review"
                  >*Flat,House no,Building,Company,Apartment</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="review"
                  formControlName="place_name"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    !newAddress.get('place_name')?.valid &&
                    newAddress.get('place_name')?.touched
                  "
                  >Enter your place_name</span
                >
              </div>
              <div class="form-group">
                <label for="review">*Area,Street,Sector,Village</label>
                <input
                  type="text"
                  class="form-control"
                  id="review"
                  formControlName="address"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    !newAddress.get('address')?.valid &&
                    newAddress.get('address')?.touched
                  "
                  >Enter your address</span
                >
              </div>
              <div class="form-group">
                <label for="review">*Landmark</label>
                <input
                  type="text"
                  class="form-control"
                  id="review"
                  formControlName="landmark"
                />
                <span
                  class="text-danger"
                  *ngIf="
                    !newAddress.get('landmark')?.valid &&
                    newAddress.get('landmark')?.touched
                  "
                  >Enter your landmark</span
                >
              </div>
              <div class="form-group row">
                <div class="col-lg-6 col-sm-12">
                  <label for="review">*Town/City</label>
                  <input
                    type="text"
                    class="form-control"
                    id="review"
                    formControlName="city"
                  />
                  <span
                    class="text-danger"
                    *ngIf="
                      !newAddress.get('city')?.valid &&
                      newAddress.get('city')?.touched
                    "
                    >Enter your city</span
                  >
                </div>
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label for="exampleFormControlSelect2">*State</label>
                    <select
                      class="form-control"
                      id="exampleFormControlSelect2"
                      formControlName="state"
                    >
                      <option *ngFor="let state of states" [value]="state">
                        {{ state }}
                      </option>
                    </select>
                    <span
                      class="text-danger"
                      *ngIf="
                        !newAddress.get('state')?.valid &&
                        newAddress.get('state')?.touched
                      "
                      >Enter your state</span
                    >
                  </div>
                </div>
              </div>
              <div class="form-check">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="exampleCheck1"
                  formControlName="is_default"
                />
                <label class="form-check-label" for="exampleCheck1"
                  >Make this my default address</label
                >
              </div>
              <div class="div">
                <div (click)="openNotes = !openNotes" class="preferences">
                  <i class="fa fa-chevron-down" aria-hidden="true"></i>
                  <label class="form-check-label label" for="notes"
                    >Add Preferences,Notes,access code and more</label
                  >
                </div>
                <div *ngIf="openNotes" class="m-3">
                  <input
                    type="text"
                    class="form-control"
                    id="notes"
                    formControlName="notes"
                    placeholder="Enter your notes"
                  />
                </div>
              </div>
            </form>
          </mat-card-content>
          <mat-card-actions>
            <button
              type="submit"
              class="btn btn-solid"
              (click)="addYourAddress()"
              *ngIf="!isEditForm"
            >
              Add address
            </button>
            <!-- <button type="submit" class="btn btn-solid" (click)="goToCheckOut()" *ngIf="addressToRouteCheckout === true" >Add address</button> -->
            <button
              type="submit"
              class="btn btn-solid"
              (click)="editAddress()"
              *ngIf="isEditForm"
            >
              Save Changes
            </button>
          </mat-card-actions>
        </div>
      </mat-card>
    </div>
  </div>
</div>
