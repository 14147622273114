import { Component, Input, OnInit } from "@angular/core";
import { ProductService } from "../../services/product.service";
// import { Input } from 'hammerjs';

@Component({
  selector: "app-cards-flow-desk",
  templateUrl: "./cards-flow-desk.component.html",
  styleUrls: ["./cards-flow-desk.component.scss"],
})
export class CardsFlowDeskComponent implements OnInit {
  @Input() brands: any[];
  category = ["FULL FACE", "HALF FACE", "FLIP UP", "OFF ROAD"];
  image_dict = {
    "FULL FACE": "assets/imagecategoris/fullface.webp",
    "HALF FACE": "assets/imagecategoris/openface.jpg",
    "FLIP UP": "assets/imagecategoris/flipup.jpg",
    "OFF ROAD": "assets/imagecategoris/offroad.png",
    "MT HELMETS": "assets/imagecategoris/mt.webp",
    AXOR: "assets/imagecategoris/axor.jpg",
    SMK: "assets/imagecategoris/smk.jpg",
    RIDEX: "assets/imagecategoris/ridex.jpg",
    AXXIS: "assets/imagecategoris/Axxis.webp",
    LS2: "assets/imagecategoris/ls2.jpg",
    VEGA: "assets/imagecategoris/vega.jpg",
    STUDDS: "assets/imagecategoris/studds.webp",
    STEELBIRD: "assets/imagecategoris/steelbird.jpg",
    KORDA: "assets/imagecategoris/Korda.webp",
  };
  constructor(private productservice: ProductService) {
    //     this.productservice.getProducts().subscribe(
    //       (result)=>{result.forEach(data=>this.totalBrands.push(data.brand.trim()))
    //       this.uniqueBrands = [...new Set(this.totalBrands)]
    //       // console.log(this.uniqueBrands)
    //       // this.uniqueBrands.forEach(brand => {
    //       //   const product = result.find(item => item.brand === brand);
    //       //   if (product && product.variants.length > 0 && product.variants[0].images.length > 0) {
    //       //     this.firstImage .push( product.variants[0].images[0])
    //       //     console.log(this.firstImage)
    //       // }})
    // })
  }
  totalBrands = [];
  uniqueBrands = [];
  firstImage = [];
  ngOnInit(): void {
    // this.checkBrands()
  }
  //    checkBrands(){
  //     if(!this.brands){
  //     this.productservice.getProducts().subscribe(
  //       (result)=>{result.forEach(data=>
  //         this.totalBrands.push(data.brand.trim())
  //        )
  //       this.brands = [...new Set(this.totalBrands)]

  // })
  //   }
  //   else{
  //     // console.log('it has products')
  //   }}
  scrollLeft(element: HTMLElement) {
    // var left =document.querySelector(".cards");
    // left.scrollBy(-200,0)
    element.scrollBy({ left: -400, behavior: "smooth" });
  }
  scrollRight(element: HTMLElement) {
    // var right =document.querySelector(".cards");
    // right.scrollBy(200,0)
    element.scrollBy({ left: 400, behavior: "smooth" });
  }
  get sortedBrands(): string[] {
    const customOrder = ["MT HELMETS", "AXOR", "SMK", "RIDEX", "AXXIS"];
    const otherBrands = this.brands.filter(
      (brand) => !customOrder.includes(brand)
    );
    // console.log(otherBrands)
    return [...customOrder, ...otherBrands];
  }
}
