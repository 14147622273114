import { validateVerticalPosition } from "@angular/cdk/overlay";
import {
  Component,
  EventEmitter,
  Inject,
  inject,
  Input,
  OnInit,
  Optional,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from "@angular/router";
// import { input } from "aws-amplify";
import { error, log } from "console";
import { ProductService } from "src/app/shared/services/product.service";
import { userService } from "src/app/shared/services/user.service";
// import { ManageAddressComponent } from "../manage-address/manage-address.component";
// import { CheckoutComponent } from "src/app/shop/checkout/checkout.component"; //issue of ngIF

@Component({
  selector: "app-new-address",
  templateUrl: "./new-address.component.html",
  styleUrls: ["./new-address.component.scss"],
})
export class NewAddressComponent implements OnInit {
  openNotes: boolean = false;
  newAddress: FormGroup;
  userNewAddress: any[] = [];
  loader: boolean = false;
  id: any;
  addressId: any;
  editValues: any;
  isEditForm: boolean = false;
  addressToRouteCheckout: boolean = false;
  product_id: any;
  qty: any;
  tab: any;
  addresses: any[] = [];
  routePath: string;
  states: string[] = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli and Daman and Diu",
    "Lakshadweep",
    "Delhi",
    "Puducherry",
  ];
  @Output() AddressIdFromNewAddress = new EventEmitter<any>();
  @Input() forDashboard: boolean = false;
  // @Output() forGetNewAdded = new EventEmitter<any>();
  constructor(
    private userService: userService,
    private route: ActivatedRoute,
    private router: Router,
    @Optional() private dialogRef: MatDialogRef<NewAddressComponent> //  @Inject(MAT_DIALOG_DATA) public data:any
  ) {
    this.newAddress = new FormGroup({
      full_name: new FormControl("", [Validators.required]),
      phone: new FormControl("", Validators.required),
      pincode: new FormControl("", Validators.required),
      country: new FormControl("India"),
      is_default: new FormControl(false),
      address: new FormControl("", Validators.required),
      city: new FormControl("", Validators.required),
      state: new FormControl("TamilNadu"),
      notes: new FormControl(""),
      place_name: new FormControl("", Validators.required),
      landmark: new FormControl(""),
    });

    this.route.queryParams.subscribe((params: any) => {
      this.addressToRouteCheckout = params.address;
      this.product_id = params.product_id;
      this.qty = params.qty;
      this.tab = params.tab;
      // console.log(this.addressToRouteCheckout, params);

      if (params && params.id) {
        try {
          const data = atob(params.id);
          this.id = JSON.parse(data);
          // console.log(this.id);
          this.isEditForm = true;
        } catch (error) {
          // console.error("Error decoding or parsing query param:", error);
        }
      } else {
        // console.log("No query parameters found");
        // Handle the case when no query parameters are present
      }
    });
    this.route.pathFromRoot.forEach((route) => {
      const routePath = route.snapshot.routeConfig?.path;
      if (routePath) {
        this.routePath = routePath;
        // console.log("Current Route Path:", this.routePath);
      }
    });
  }

  ngOnInit(): void {
    if (this.isEditForm) {
      this.getYourAddress();
    }
    // console.log(this.addressToRouteCheckout);

    // this.addressToRouteCheckout = this.data.address
  }
  isNumber(event: KeyboardEvent) {
    const keyCode = event.keyCode || event.which;
    if (
      [46, 8, 9, 27, 13].indexOf(keyCode) !== -1 ||
      (keyCode >= 48 && keyCode <= 57)
    ) {
      // Let it happen, don't do anything
      return;
    }
    // Prevent the rest
    event.preventDefault();
  }

  addYourAddress() {
    if (this.newAddress.valid) {
      // if(this.newAddress.valid){
      this.loader = true;
      const id: [] = this.product_id;
      const qty: [] = this.qty;
      // const queryParams = {
      //   product_id: id.map((res)=> res).join(','),
      //   qty:qty.map((res)=> res).join(',')
      // }
      // console.log("came into add call");
      this.userService.addNewAddress(this.newAddress.value).subscribe((res) => {
        // console.log(res);
        if (res) {
          this.userService.getNewAddress().subscribe((data: any) => {
            this.addresses = JSON.parse(data.body);
            if (this.addresses.length > 1) {
              // console.log(this.addresses[1]);

              this.addressId = this.addresses[1].address_id;
              // console.log("Address IDs:", this.addressId);
            } else {
              // console.log(this.addresses[0]);

              this.addressId = this.addresses[0].address_id;
              // console.log("Address IDs:", this.addressId);
            }
            if (this.addressId) {
              this.AddressIdFromNewAddress.emit(this.addressId);
              // console.log("Emitting address IDs:", this.addressId);
              if (this.routePath === "newaddress") {
                this.router.navigate(["/pages/addresspage"], {
                  queryParams: { tab: this.tab },
                });
              }
            }
          });
        }

        this.loader = false;
        // location.reload();
        // this.router.navigate(['/pages/checkout'],{queryParams:queryParams});
        this.closePopup();
        // this.forGetNewAdded.emit(true);
        this.newAddress.reset();
      });
      // }
    } else {
      if (this.newAddress.valid) {
        this.loader = true;
        // console.log("came into add call");
        this.userService
          .addNewAddress(this.newAddress.value)
          .subscribe((res) => {
            // console.log(res);
            this.loader = false;
            this.router.navigate(["/pages/addresspage"]);
            // this.newAddress.reset();
          });
      }
      // this.newAddress.markAllAsTouched();
    }
  }

  closePopup() {
    if (this.dialogRef) {
      this.dialogRef.close("closed!");
      window.location.reload();
    }
  }

  getYourAddress() {
    this.loader = true;
    this.userService.getNewAddress().subscribe((res) => {
      this.loader = false;
      const data = JSON.parse(res.body);
      // console.log(data);
      this.userNewAddress = data;
      this.editValues = this.userNewAddress.find(
        (res) => this.id === res.address_id
      );
      // console.log(this.editValues);
      this.newAddress.setValue({
        full_name: this.editValues.full_name,
        phone: this.editValues.phone,
        pincode: this.editValues.pincode,
        country: this.editValues.country,
        is_default: this.editValues.is_default,
        address: this.editValues.address,
        city: this.editValues.city,
        state: this.editValues.state,
        notes: this.editValues.notes,
        place_name: this.editValues.place_name,
        landmark: this.editValues.landmark,
      });
    });
  }

  editAddress() {
    // console.log(this.id , ' ' , this.newAddress.value );
    this.loader = true;
    const data = {
      address_id: this.id,
      full_name: this.newAddress.value.full_name,
      phone: this.newAddress.value.phone,
      pincode: this.newAddress.value.pincode,
      country: this.newAddress.value.country,
      is_default: this.newAddress.value.is_default,
      address: this.newAddress.value.address,
      city: this.newAddress.value.city,
      state: this.newAddress.value.state,
      notes: this.newAddress.value.notes,
      place_name: this.newAddress.value.place_name,
      landmark: this.newAddress.value.landmark,
    };
    this.userService.editNewAddress(data).subscribe((res) => {
      this.loader = false;
      this.newAddress.reset();
      this.getYourAddress();
      this.router.navigate(["/pages/addresspage"]);
    });
  }
}
